import { combineReducers } from "redux";
import { actionTypes } from "../epics";
import { userProfileReducer } from "./user-profile";
import {
  preloadedAnalyses,
  analysesReducer,
  sortReducer,
  sortedAnalysesReducer,
} from "./analyses";
import { fieldFilterKeys } from "../../config";

const preloadedUserInputs = {
  loading: false,
  error: null,
  data: {}, // Object whose keys are slugs, and values a`re objects containing input values
};

const preloadedData = {
  loading: false,
  error: null,
  data: [],
};

export const preloadedState = {
  analysesResponse: preloadedAnalyses,
  userInputs: preloadedUserInputs,
};

const preloadedGridFilters = fieldFilterKeys.reduce(
  (acc, key) => ({
    ...acc,
    [key]: true,
  }),
  {}
);

const gridFilterReducer = (state = preloadedGridFilters, action) => {
  switch (action.type) {
    case actionTypes.SET_GRID_FILTER: {
      return {
        ...state,
        ...action.payload,
      };
    }
    default:
      return state;
  }
};

const userInputsReducer = (state = preloadedUserInputs, action) => {
  switch (action.type) {
    case actionTypes.SET_USER_INPUT: {
      // optimistic pending state
      const { slug, key, value } = action.payload;
      const { data } = state;
      const { [slug]: prevInputForRow } = data;
      return {
        ...state, // possibly: set loading for each individual row?
        data: {
          ...data,
          [slug]: {
            ...prevInputForRow,
            [key]: value,
          },
        },
      };
    }
    case actionTypes.SET_USER_INPUT_SUCCESS: {
      const { data } = state;
      return {
        ...state, // possibly: set loading for each individual row?
        data: {
          ...data,
          ...action.payload,
        },
      };
    }
    case actionTypes.SET_USER_INPUT_FAILURE: {
      return state; // possibly: set error for each individual row?
    }
    case actionTypes.GET_USER_INPUTS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.GET_USER_INPUTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.GET_USER_INPUTS_SUCCESS:
      return {
        data: action.payload,
        loading: false,
        error: null,
      };
    default:
      return state;
  }
};

const mediaResourcesReducer = (state = preloadedData, action) => {
  switch (action.type) {
    case actionTypes.FETCH_MEDIA_RESOURCES:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.FETCH_MEDIA_RESOURCES_FAILURE:
      return {
        loading: false,
        data: [],
        error: action.payload,
      };
    case actionTypes.FETCH_MEDIA_RESOURCES_SUCCESS: {
      let { payload } = action;
      const { data = [], included = false } = payload || {};
      return {
        loading: false,
        data,
        error: null,
      };
    }
    default:
      return state;
  }
};

const recentUpdatesReducer = (state = preloadedData, action) => {
  switch (action.type) {
    case actionTypes.FETCH_RECENT_UPDATES:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.FETCH_RECENT_UPDATES_FAILURE:
      return {
        loading: false,
        data: [],
        error: action.payload,
      };
    case actionTypes.FETCH_RECENT_UPDATES_SUCCESS: {
      let { payload } = action;
      const { data = [], included = false } = payload || {};
      return {
        loading: false,
        data,
        error: null,
      };
    }
    default:
      return state;
  }
};

const assetGroupsReducer = (state = preloadedData, action) => {
  switch (action.type) {
    case actionTypes.FETCH_ASSET_GROUPS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.FETCH_ASSET_GROUPS_FAILURE:
      return {
        loading: false,
        data: [],
        error: action.payload,
      };
    case actionTypes.FETCH_ASSET_GROUPS_SUCCESS: {
      const { data = [], included = [] } = action.payload || {};
      return {
        loading: false,
        data,
        included,
        error: null,
      };
    }
    default:
      return state;
  }
};

const comingSoonReducer = (state = preloadedData, action) => {
  switch (action.type) {
    case actionTypes.FETCH_COMING_SOON:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.FETCH_COMING_SOON_FAILURE:
      return {
        loading: false,
        data: [],
        error: action.payload,
      };
    case actionTypes.FETCH_COMING_SOON_SUCCESS: {
      const { data = [], included = [] } = action.payload || {};
      return {
        loading: false,
        data,
        included,
        error: null,
      };
    }
    default:
      return state;
  }
};

export default combineReducers({
  userProfile: userProfileReducer,
  analysesResponse: analysesReducer,
  sortedAnalyses: sortedAnalysesReducer,
  sort: sortReducer,
  enabledGridFields: gridFilterReducer,
  userInputs: userInputsReducer,
  mediaResources: mediaResourcesReducer,
  recentUpdates: recentUpdatesReducer,
  assetGroups: assetGroupsReducer,
  comingSoon: comingSoonReducer,
});
