import React from 'react';
import { array, string } from 'prop-types';
import { Box, Typography } from '@mui/material';

import DownloadIcon from '@mui/icons-material/GetApp';

const Attachments = ({ attachments= [], title="" }) => {

  if (!attachments.length) return null

  return (
    <>
      <Typography component='h1' style={{marginBottom: 10}}>{title}</Typography>
      <Box display='flex' flexDirection="column">
        { attachments.map((attachment, i) => (
          <a href={attachment.url}
            key={i}
            style={{display:'flex', marginBottom: 5}}
            target="_blank"
          >
            <DownloadIcon style={{marginRight: 5, fontSize: 22}} />
            {attachment.filename}
          </a>
          ))
        }
      </Box>
    </>
  );
};

Attachments.propTypes = {
  attachments: array,
  title: string
};

export default Attachments;
