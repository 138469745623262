import React from 'react'
import { Chip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { alpha, darken } from '@mui/material/styles';
import { number, string } from 'prop-types';

export const newChipStyles = makeStyles(({palette: {primary, common}}) => {
  const base = {
    textTransform: 'uppercase',
    fontWeight: 700,
    letterSpacing: ".03em",
  }
  return {
    green: {
      ...base,
      background: darken(primary.main, 0.20),
      border: `1px solid ${common.white}`,
      color: common.white,
    },
    white: {
      ...base,
      background: alpha(common.white, 0.50),
      border: `1px solid ${common.white}`,
      color: common.black,
    }
  }
})


const NewChip = ({count, size='medium', variant="green"}) => {
  const classes = newChipStyles()
  const label = count ? `New (${count})` : 'New'
  return (
      <Chip
        classes={{root: classes[variant]}}
        label={label}
        size={size}
      />
  )
}

NewChip.propTypes = {
  count: number,
  size: string
}

export default NewChip
