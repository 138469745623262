import React from "react";
import { makeStyles } from "@mui/styles";
import { Card } from "@mui/material";
import { cardHover } from "../component-styles";

const useStyles = makeStyles(({ palette }) => ({
  card: {
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: palette.secondary.light
  },
  cardHover: {
    ...cardHover(palette)
  },
  cardGrow: {
    display: "flex",
    flexGrow: 1,
    minHeight: "150px"
  }
}));

const BaseCard = ({ cardGrows = false, allowHover = true, children }) => {
  const classes = useStyles();
  const rootClasses = [classes.card];
  if (cardGrows) rootClasses.push(classes.cardGrow);
  if (allowHover) rootClasses.push(classes.cardHover);
  return (
    <Card classes={{ root: rootClasses.join(" ") }} elevation={0}>
      {children}
    </Card>
  );
};

export default BaseCard;
