import React from "react";
import ReactDOM from "react-dom";
import App from "../client/components/App";
import ErrorHandler from "client/components/ErrorHandler";
import theme from "../client/themes";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import useAuthentication from "../client/hooks/useAuthentication";

// Polyfill for object-fit css property
import "objectFitPolyfill";

document.addEventListener("DOMContentLoaded", () => {
  const { isSignedIn=false, isDeviseView=false, root } = useAuthentication();
  ReactDOM.render(
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <ErrorHandler>
          <App isSignedIn={isSignedIn} isDeviseView={isDeviseView} />
        </ErrorHandler>
      </ThemeProvider>
    </StyledEngineProvider>,
    root
  );
});

// Stylesheet
import "normalize.css";
import "../client/styles/icer-ui.scss";
