/* eslint max-len: ["error", { "code": 120 }] */
import { createSelector } from "reselect";
import queryString from "query-string";
import { differenceInDays, determineComparisonDate } from "../utils/date";
import isToday from "date-fns/isToday";
import isFuture from "date-fns/isFuture";
import isBefore from "date-fns/isBefore";
import isValid from "date-fns/isValid";
import compareAsc from "date-fns/compareAsc";
import parse from "date-fns/parse";
import addDays from "date-fns/addDays";

import { isDateWithin30days, getRowSlugs, sortCollection } from "./helpers";
import { fuseSearch } from "./search";

import {
  tabKeys,
  tabConfig,
  fieldConfig,
  allFieldKeys,
  icerBenchmarkPriceKeys,
  comparisonValuesKeys,
  SEARCHABLE_FIELDS,
  SELECTED_ROW_STORAGE_KEY,
  computeHeaderProps,
  newItemPeriod,
  costDescriptions,
  costRatioFields
} from "../config";

import { formatPercent } from "../utils/formatters";

export const activeDetailIdSelector = (state, props) => {
  if (!props.match) return null;
  const {
    match: {
      params: { detailId }
    }
  } = props;
  return detailId;
};

export const activeTabKeySelector = (state, props) => {
  const {
    match: {
      params: { activeTabKey = tabKeys[0] }
    }
  } = props;
  return activeTabKey;
};

const detailsSlugSelector = (state, props) => {
  const {
    match: {
      params: { detailsSlug }
    }
  } = props;
  return detailsSlug;
};

export const searchStringSelector = (state, props) => {
  if (!props.location) return "";
  const { search = "" } = props.location;
  const urlParams = queryString.parse(search);
  // Note difference between "search" as a react-router property, vs the URL param named "search":
  const { search: searchString = "" } = urlParams;
  return searchString;
};

const enabledGridFieldsSelector = (state) => state.enabledGridFields;
export const userInputSelector = (state) => state.userInputs.data;

// Cache of analyses, by id:  (JSONAPI response is an Array)
// eslint-disable-next-line max-len
export const analysisCollectionSelector = (state) =>
  state.analysesResponse.data.reduce((acc, a) => ({ ...acc, [a.slug]: a }), {});
export const analysisCollectionArraySelector = (state) => state.analysesResponse.data;

const includedSelector = (state) => state.analysesResponse.included;

export const activeDetailName = (state, props) => {
  const report = reportSelector(state, props);
  let name = "";
  if (report) {
    name = report.attributes.specific_conditions[0];
  }
  return name;
};

// ! @deprecated! in favor of getReportSnapshotCollection which uses the dedicated
// ! asset_groups endpoint
export const reportCollectionSelector = (state, props) => {
  const { searchTerm = "" } = props;
  const collection = includedSelector(state)
    .filter((included) => included.type === "asset_group")
    .sort((a, b) => {
      return (
        a.attributes.specific_conditions[0].localeCompare(b.attributes.specific_conditions[0]) ||
        new Date(b.attributes.date_of_review) - new Date(a.attributes.date_of_review)
      );
    });
  if (searchTerm.length <= 2) {
    return collection;
  } else {
    return fuseSearch(searchTerm, {
      collection: collection.map((d) => d.attributes),
      keys: ["specific_conditions", "drugs"]
    });
  }
};

// ! @deprecated! in favor of reportSnapshotSelector which uses the dedicated
// ! asset_groups endpoint
export const reportSelector = (state, props) => {
  const reportId = props.reportId || activeDetailIdSelector(state, props);
  if (!reportId) return null;
  return reportCollectionSelector(state, props).find((r) => r.id === reportId);
};

export const reportSnapshotSelector = (state, props) => {
  const reportId = props.reportId || activeDetailIdSelector(state, props);
  if (!reportId) return null;
  return getReportSnapshotCollection(state).find((r) => r.id === reportId);
};

export const getReportSnapshotCollection = (state, { limit = 0, searchTerm = "" } = {}) => {
  const { data = [] } = state.assetGroups;
  const collection = data.filter(
    ({ attributes }) => attributes && attributes.status !== "coming_soon"
  );
  if (limit && limit > 0) return collection.slice(0, limit);
  if (searchTerm.length <= 2) {
    return collection;
  } else {
    return fuseSearch(searchTerm, {
      collection: collection.map((d) => d.attributes),
      keys: ["specific_conditions", "drugs"]
    });
  }
};

export const getComingSoonCollection = (state, { limit = null } = {}) => {
  const { data = [] } = state.comingSoon;
  if (limit && limit > 0) return data.slice(0, limit);
  return data;
};

export const comingSoonLoadingSelector = (state) => state.comingSoon.loading;

export const comingSoonMostRecentItemSelector = (state) => {
  const comingSoonItems = getComingSoonCollection(state);
  if (!comingSoonItems) return null;
  const { included: relatedObjects = [] } = state.comingSoon; // date_of_review, specific_conditions

  const items = [...comingSoonItems, ...relatedObjects];
  const comingSoonMostRecentItem = items
    .filter((item) => {
      const resourceDate = parse(
        item.attributes?.display_date || item.attributes?.date_of_review || "",
        "yyyy-MM-dd",
        new Date()
      );
      if (!isValid(resourceDate)) return false;
      return (
        isToday(resourceDate) ||
        (isFuture(resourceDate) && isBefore(resourceDate, addDays(new Date(), 30)))
      );
    })
    .sort((a, b) => {
      const resourceDateA = parse(
        a.attributes?.display_date || a.attributes?.date_of_review || "",
        "yyyy-MM-dd",
        new Date()
      );
      const resourceDateB = parse(
        b.attributes?.display_date || b.attributes?.date_of_review || "",
        "yyyy-MM-dd",
        new Date()
      );
      //return resourceDateA > resourceDateB;
      return compareAsc(resourceDateA, resourceDateB);
    })[0];

  return comingSoonMostRecentItem || null;
};

export const comingSoonSelector = (state, { limit = null } = {}) => {
  const collection = getComingSoonCollection(state, { limit });
  const { included: relatedObjects } = state.comingSoon;
  // Filter collection of asset groups to coming soon
  let report = null;
  let resource = {};
  // Update data of asset groups (AKA report snapshots) for FE components
  // And get associated objects from `included` collection
  return collection.map(({ relationships, attributes, ...item }) => {
    const idOfAssociatedWebinar = relationships?.asset_group?.data?.id;
    if (idOfAssociatedWebinar && relatedObjects.length) {
      report = relatedObjects.find(({ attributes }) => attributes.id == idOfAssociatedWebinar);
      const createdAtDate = report?.attributes?.created_at && new Date(report.attributes.created_at);
      report && createdAtDate && (report.attributes.isNew = isDateWithin30days(createdAtDate));
    } else {
      report = null;
    }
    const { drugs = [], specific_conditions = [], date_of_review: date } = report?.attributes || {};

    if (attributes && Object.keys(attributes).length) {
      const {
        display_date,
        title: webinarTitle,
        registration_url: registrationUrl,
        youtube_id: videoId,
        description = "",
        created_at,
        ...otherWebinarAttrs
      } = attributes;

      // If there's an associated report, use that status, otherwise, check resource.
      // - If no report, than we can show the resource's isNew.
      const isNew = !idOfAssociatedWebinar && isDateWithin30days(new Date(created_at));

      resource = {
        registrationUrl,
        title: webinarTitle,
        date: display_date,
        videoId,
        description,
        isNew,
        ...otherWebinarAttrs
      };
    }
    const title = specific_conditions[0] || "";
    return {
      ...item,
      title,
      report: report ? { date, title, isNew: report.attributes.isNew } : null,
      drugs,
      resource
    };
  });
};

export const newComingSoonCountSelector = (state) => {
  let count = 0;
  comingSoonSelector(state).forEach((item) => {
    (item.resource?.isNew || item.report?.isNew) && count++;
  });
  return count;
};

export const analysesLoadingSelector = (state) => state.sortedAnalyses.loading;
// the ^above allows progess indicator to be visible while initial filter & sort are 1st applied, vs below:
// export const analysesLoadingSelector = (state) => state.analysesResponse.loading;

const analysisIdSelector = (state, ownProps) => ownProps.analysisId;

// returns callback function for Array.sort:
const fuseSearchSelector = createSelector(
  [searchStringSelector, analysisCollectionArraySelector], // every member is passed: state, props
  (searchString, collection) => fuseSearch(searchString, { collection })
);

const sortedAnalysesSelector = createSelector(
  [analysisCollectionArraySelector, (state) => state.sortedAnalyses.sortedIds],
  sortCollection
);

const sortedAndFilteredAnalysesSelector = createSelector(
  [fuseSearchSelector, (state) => state.sortedAnalyses.sortedIds, (state) => state.sort.sortField],
  sortCollection
);

const addedToCompareCollectionSelector = createSelector(
  [
    userInputSelector,
    sortedAnalysesSelector,
    (state) => state.sortedAnalyses.sortedIds,
    (state) => state.sort.sortField
  ],
  (userInputs, collection, sortedIds, sortField) => {
    const filteredCollection = collection.filter((row) => {
      const { [SELECTED_ROW_STORAGE_KEY]: selected = false } = (row && userInputs[row.slug]) || {};
      return selected;
    });
    return sortCollection(filteredCollection, sortedIds, sortField);
  }
);

const filteredRowsSelector = createSelector(
  [activeTabKeySelector, addedToCompareCollectionSelector, sortedAndFilteredAnalysesSelector],
  (activeTabKey, comparisonAnalyses, sortedAnalyses) => {
    if (activeTabKey === "compare-your-own-pricing") {
      return comparisonAnalyses;
    }
    return sortedAnalyses;
  }
);

const filteredRowsForAllTabsSelector = createSelector(
  [sortedAndFilteredAnalysesSelector, addedToCompareCollectionSelector],
  (sortedAnalyses, comparisonAnalyses) => ({
    [tabConfig["drugs"]]: getRowSlugs(sortedAnalyses),
    [tabConfig["compare-your-own-pricing"]]: getRowSlugs(comparisonAnalyses),
    [tabConfig["icer-pricing"]]: getRowSlugs(sortedAnalyses)
  })
);

export const filteredAnalysisIdsSelector = createSelector(
  [filteredRowsSelector],
  (filteredAnalyses) => filteredAnalyses.map((analysis) => (analysis ? analysis.slug : null))
);

export const filteredAnalysisIdsForAllTabsSelector = createSelector(
  [filteredRowsForAllTabsSelector],
  (filteredAnalyses) => filteredAnalyses
);

export const columnConfigSelector = createSelector(
  [activeTabKeySelector, enabledGridFieldsSelector],
  (activeTabKey, enabledGridFields) => computeHeaderProps(activeTabKey, enabledGridFields) // eslint-disable-line max-len
);

export const dataRowPropsSelector = createSelector(
  [columnConfigSelector],
  ({ dataRowProps }) => dataRowProps
);

export const columnCountSelector = createSelector(
  [dataRowPropsSelector],
  // 1st subrow corresponds to full # of columns despite colspans -- each can have rowSpans > 1 :
  (dataRowProps) => dataRowProps[0].columnProps.length
);

const preloadedUserEnteredValues = {}; // empty object that will avoid mutation, across calls

export const userEnteredValuesByAnalysisIdSelector = createSelector(
  [userInputSelector, analysisIdSelector],
  (userInputs, analysisId) => {
    // until a value is set, userInputs[analysisId] is undefined:
    const { [analysisId]: userEnteredValuesForRow = preloadedUserEnteredValues } = userInputs;
    return userEnteredValuesForRow;
  }
);

export const analysisByIdSelector = createSelector(
  // id from ownProps -- e.g.: from one element of a collection
  [analysisCollectionSelector, analysisIdSelector],
  (analysisCollection, analysisId) => analysisCollection[analysisId]
);

export const analysisBySlugSelector = createSelector(
  // details id from URL path
  [analysisCollectionSelector, detailsSlugSelector],
  (analysisCollection, detailsSlug) => analysisCollection[detailsSlug]
);

export const analysesSelector = createSelector(
  // details id from URL path
  [analysisCollectionSelector],
  (analysisCollection) => analysisCollection
);

const currentConditionSelector = createSelector(
  [analysisBySlugSelector],
  (analysis) => analysis && analysis.specific_condition
);

const emptyArray = []; // avoid mutation
const analysesForConditionSelector = createSelector(
  [analysisCollectionSelector, currentConditionSelector],
  (analysisCollection, currentCondition) => {
    if (currentCondition) {
      // TODO: use id of condition relationship:
      return Object.values(analysisCollection).filter(
        (a) => a.specific_condition === currentCondition
      );
    }
    return emptyArray;
  }
);

export const fieldsForExportSelector = createSelector(
  [enabledGridFieldsSelector],
  (enabledGridFields) => {
    const fieldsForTabs = {};
    tabKeys.forEach((key) => {
      const tabIndex = tabKeys.indexOf(key);
      fieldsForTabs[tabConfig[key]] = allFieldKeys
        // filter by tab:
        .filter((k) => fieldConfig[k].tabFilters[tabIndex] === 1)
        .filter((k) => !fieldConfig[k].fixedValue && k !== "remove_from_compare")
        .filter((k) =>
          (fieldConfig[k].filters || []).reduce((accVisible, filterKey) => {
            return accVisible && enabledGridFields[filterKey] === true;
          }, true)
        ); // eslint-disable-line max-len
    });
    return fieldsForTabs;
  }
);
export const relatedDrugsForConditionSelector = createSelector(
  [analysesForConditionSelector],
  (analysesForCondition) => {
    // dedupe:
    const drugs = {};
    const drugsForCondition = analysesForCondition.reduce((acc, a) => {
      if (!drugs[a.trade_name]) {
        acc.push(a.trade_name);
        drugs[a.trade_name] = true;
      }
      return acc;
    }, []);
    return drugsForCondition.length ? drugsForCondition : emptyArray;
  }
);

export const addToCompareCountSelector = createSelector(
  [addedToCompareCollectionSelector],
  (comparisonAnalyses) => comparisonAnalyses.length
);

export const computePercentDiscount = (benchmarkPrice, comparisonPrice, comparisonKey) => {
  if (!Number.isFinite(benchmarkPrice)) {
    return null; // becomes formatted as <small>N/A</small> via formatValue
  }
  // do not divide by 0 -- test for comparisonPrice:
  if (Number.isFinite(comparisonPrice) && comparisonPrice) {
    return (100 * (benchmarkPrice - comparisonPrice)) / comparisonPrice;
  }
  if (comparisonKey === "user_proposed_price") {
    // So far, there's no table-cell formatting in these selectors, except for here?
    return "--"; // it is computable, b/c benchmarkPrice exists -- but comparisonPrice was not entered
  }
  return null;
};

const getDerivedPercentDiscount = (
  benchmarkKey,
  comparisonKey,
  userEnteredValuesByAnalysisId,
  analysisById
) => {
  if (comparisonKey === "user_proposed_price") {
    const benchmarkPrice = analysisById[benchmarkKey];
    const comparisonPrice = parseFloat(userEnteredValuesByAnalysisId[comparisonKey], 10);
    return computePercentDiscount(benchmarkPrice, comparisonPrice, comparisonKey);
  }
  return analysisById.comparisonValues[benchmarkKey][comparisonKey];
};

// Used only by browser/table view, where `fieldKey` is passed via ownProps:
const benchmarkKeySelector = (state, ownProps) => fieldConfig[ownProps.fieldKey].benchmarkKey;
const comparisonKeySelector = (state, ownProps) => fieldConfig[ownProps.fieldKey].comparisonKey;
export const priceDiscountPercentSelector = createSelector(
  [
    benchmarkKeySelector,
    comparisonKeySelector,
    userEnteredValuesByAnalysisIdSelector,
    analysisByIdSelector
  ],
  getDerivedPercentDiscount
);

// Used only by CSV-download feature:
const formatCsvItem = (k, value) => {
  // This formatting is different from the Browser table view, e.g.: raw $USD values
  const { type } = fieldConfig[k];
  switch (type) {
    case "percent": {
      if (Number.isFinite(value)) {
        // TODO: move this into formatPercent?
        return formatPercent(value);
      }
    }
    // eslint-disable-next-line no-fallthrough
    case "usd": // keep usd as raw number, without $ or commas
      const isRatioField = costRatioFields.includes(k);
      if (isRatioField && value == -1) {
        return costDescriptions[0];
      } else if (isRatioField && value == -2) {
        return costDescriptions[1];
      } else if (isRatioField && value == -3) {
        return costDescriptions[2];
      } else if (isRatioField && value < 0) {
        return 'N/A';
      } else {
        return value;
      }
    case "boolean": // e.g.: format as "yes" vs "no"?
      return value ? "Yes" : "No";
    case "enum": {
      if (value && typeof value == "object") {
        return value.join && value.join(", ");
      }
    }
    case "string":
    default:
      return value;
  }
};
const getDerivedValueForFieldKey = (k, a, userInputForAnalysis = {}) => {
  if (typeof a[k] === "undefined") {
    // TODO: flatten all derived, comparison fieldKeys, into each analysis?
    if (comparisonValuesKeys.includes(k)) {
      // comparison keys make use of a more nested structure:
      const { benchmarkKey, comparisonKey } = fieldConfig[k];
      return getDerivedPercentDiscount(benchmarkKey, comparisonKey, userInputForAnalysis, a);
    }
    // TODO: flatten all user-input-related fieldKeys, into each analysis?
    if (["add_to_compare", "user_proposed_price"].includes(k)) {
      return userInputForAnalysis[k];
    }
    console.error("FIELD KEY NOT SUPPORTED: ", k);
  }
  return a[k];
};
// invoked on-the-fly, not via Component props:
export const csvArraySelector = createSelector(
  [
    (rowIds, visibleDataFields, analysisCollection, userInputs) => [
      rowIds,
      visibleDataFields,
      analysisCollection,
      userInputs
    ]
  ], // eslint-disable-line max-len
  ([rowIds, visibleDataFields, analysisCollection, userInputs]) =>
    rowIds.map((analysisId) => {
      const a = analysisCollection[analysisId];
      const userInputForAnalysis = userInputs[analysisId];
      const csvRow = visibleDataFields.map((k) =>
        formatCsvItem(k, getDerivedValueForFieldKey(k, a, userInputForAnalysis))
      );
      return csvRow;
    })
);

const rowIdsSelector = (state, ownProps) => ownProps.rowIds;

// const chartComparisonKeys = ['50k_per_qaly', '100k_per_qaly', '150k_per_qaly', '200k_per_qaly'];
// chartDataSelector could return Array of analyses, but, maybe not?
export const chartDataSelector = createSelector(
  [rowIdsSelector, analysisCollectionSelector, userInputSelector],
  (rowIds, analysisCollection, userInputs) => {
    const chartData = rowIds
      .map((id) => analysisCollection[id])
      .map(({ slug: id, trade_name, specific_condition, differentiator }) => ({
        id,
        trade_name,
        specific_condition,
        differentiator,
        ...icerBenchmarkPriceKeys.reduce(
          (acc, k) => ({ ...acc, [k]: analysisCollection[id][k] }),
          {}
        ),
        // TODO: user_proposed_price is null or parseFloat, upstream?
        user_proposed_price:
          parseFloat(userInputs[id] && userInputs[id].user_proposed_price, 10) || null
      }));
    return chartData;
  }
);

const getAttributes = (items) => items.map(({ attributes = {} }) => attributes);
const getPublished = (items) =>
  getAttributes(items.filter(({ attributes }) => !attributes.unpublished));
const getResourceType = (items, type) =>
  items.filter(({ resource_type }) => resource_type === type);
const getPublishedResourceType = (type) => {
  return (state) => {
    return getResourceType(getPublished(state.mediaResources.data), type);
  };
};
const publishedWebinars = getPublishedResourceType("webinar");
const publishedVideos = getPublishedResourceType("resource");
const publishedDocuments = getPublishedResourceType("document");
const publishedUpdates = (state) => getPublished(state.recentUpdates.data);

const newCount = (items = []) => {
  const today = new Date();
  return items.filter((item) => {
    const { created_at, display_date } = item;
    const comparisonDate = determineComparisonDate(created_at, display_date);
    return differenceInDays([comparisonDate, today]) < newItemPeriod;
  }).length;
};

export const newWebinarsCountSelector = createSelector([publishedWebinars], newCount);
export const newVideosCountSelector = createSelector([publishedVideos], newCount);
export const newDocumentsCountSelector = createSelector([publishedDocuments], newCount);
export const newRecentUpdatesCountSelector = createSelector([publishedUpdates], newCount);
