import React from "react";

import OndemandVideoRoundedIcon from "@mui/icons-material/OndemandVideoRounded";
import DescriptionRoundedIcon from "@mui/icons-material/DescriptionRounded";

import BaseMenu from "./BaseMenu";
import VideoSettingsRoundedIcon from "../icons/VideoSettingsRoundedIcon";

const ResourcesMenu = () => {
  return (
    <BaseMenu
      menuTitle="Resources"
      MenuIcon={OndemandVideoRoundedIcon}
      items={[
        {
          text: "Webinars",
          icon: <OndemandVideoRoundedIcon />,
          href: "/webinars",
          googleAnalyticsLabel: "Resources: Webinars"
        },
        {
          text: "Demo videos",
          icon: <VideoSettingsRoundedIcon />,
          href: "/videos",
          googleAnalyticsLabel: "Resources: Videos"
        },
        {
          text: "Documents",
          icon: <DescriptionRoundedIcon />,
          href: "/documents",
          googleAnalyticsLabel: "Resources: Documents"
        }
      ]}
    />
  );
};

export default ResourcesMenu;
