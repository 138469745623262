import React from 'react';
import {
  oneOfType, oneOf, string, number
} from 'prop-types';

import { allFieldKeys } from '../../config';
import { connectToState } from '../../redux/connect-api';
import { priceDiscountPercentSelector } from '../../selectors';
import formatValue from '../../utils/formatters';

class PriceComparison extends React.PureComponent {
  render() {
    const { fieldKey, priceDiscountPercent } = this.props;
    return formatValue(fieldKey, priceDiscountPercent);
  }
}
PriceComparison.defaultProps = {
  priceDiscountPercent: null // formats as 'N/A', via formatValue
};

PriceComparison.propTypes = {
  // analysisId via ownProps:
  analysisId: string.isRequired, // eslint-disable-line react/no-unused-prop-types
  fieldKey: oneOf(allFieldKeys).isRequired,
  priceDiscountPercent: oneOfType([number, oneOf(['--'])]) // only allowed string is '--', for empty user price comparison
};

const mapStateToProps = (state, ownProps) => ({
  priceDiscountPercent: priceDiscountPercentSelector(state, ownProps)
});

export default connectToState(mapStateToProps)(PriceComparison);
