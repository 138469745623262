import React, { useCallback, useMemo } from 'react';
import {
  shape, string, object, func
} from 'prop-types';
import { withRouter } from 'react-router-dom';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import withStyles from '@mui/styles/withStyles';
import queryString from 'query-string';
import { urlEncode } from '../utils/url'

import { connectToState } from '../redux/connect-api';
import { tabKeys } from '../config';
import analytics from '../google-analytics';

import { SORTABLE_COLUMNS } from '../config/index'

const styles = ({spacing, breakpoints, shape}) => ({
  textField: {
    marginLeft: spacing(1),
    width: 350,
  },
  searchInput: {
    position: 'relative',
    borderRadius: shape.borderRadius,
    marginLeft: 0,
    width: '100%',
    [breakpoints.up('sm')]: {
      marginLeft: spacing(1),
      width: 'auto'
    }
  },
  searchIconButton: {
    '&:focus': {
      background: '#ffffff50'
    }
  },
  whiteInput: {
    color: 'white',
    '&.Mui-focused': {
      backgroundColor: 'rgba(255,255,255,.1)',
      borderColor: 'white',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'white !important',
    },
    "&:hover fieldset": {
      borderColor: 'white !important',
    },
    '&::-webkit-search-cancel-button': {
      color: 'white',
    }
  },
  whiteInputNotchedOutline: {
    color: 'white',
    borderColor: 'rgba(255,255,255,.5)',
    '&:focus': {
      borderColor: 'white',
    }
  },
  whiteInputLabel: {
    color: 'white',
    '&:hover': {
      borderColor: 'white',
      color: 'white',
    },
  },
  whiteInputLabelFocused: {
    color: 'white !important',
  },
  searchIcon: {
    color: 'white',
  }
});

const Search  = ({match={}, classes, location, history, actions}) => {
  const id = `search-${Math.random()}`
  const { setSort = null } = actions
  const { search = '' } = location

  const handleSubmit = useCallback((e) => {
    e.preventDefault();
    const searchString = e.target.search.value;
    const qs = searchString ? `?search=${urlEncode(searchString)}` : '';
    analytics.trackSearch(searchString);
    const activeTabKey = match.params?.activeTabKey ?? tabKeys[0];
    // If on last tab (report snapshot) use that key
    let tab = activeTabKey
    if (activeTabKey === 'compare-your-own-pricing') {
      tab = tabKeys[0]
    }
    if (searchString)
      // Do not sort on search (default to sorting by search score)
      setSort?.(null, 'desc', 'string');
    else {
      // Default to trade_name column if search is reset
      const fieldKey = 'trade_name'
      setSort?.(fieldKey, 'asc', SORTABLE_COLUMNS[fieldKey] || 'string');
    }
    history.push({ pathname: `/${tab}`, search: qs });
  }, [match.params.activeTabKey])

  const urlParams = queryString.parse(search);
  // Note difference between "search" as a react-router property, vs the URL param named "search":
  const searchString = urlParams.search || '';

  const endAdornment = (
    <InputAdornment position="end">
      <IconButton aria-label="search" type="submit" className={classes.searchIconButton}>
        <SearchIcon classes={{root: classes.searchIcon}}/>
      </IconButton>
    </InputAdornment>
  );

  return (
    <form onSubmit={handleSubmit}>
      <FormControl className={classes.searchInput}>
        <TextField
          id={id}
          name="search"
          label="Search by keyword"
          key={searchString}
          defaultValue={searchString}
          type="search"
          autoComplete="off"
          color="secondary"
          className={classes.textField}
          margin="dense"
          variant="outlined"
          InputLabelProps={{
            classes: {
              root: classes.whiteInputLabel,
              focused: classes.whiteInputLabelFocused,
            },
          }}
          InputProps={{
            endAdornment,
            classes: {
              root: classes.whiteInput,
              focused: classes.whiteInputFocused,
              notchedOutline: classes.whiteInputNotchedOutline,
            },
          }}
        />
      </FormControl>
    </form>
  );
}

Search.propTypes = {
  classes: object.isRequired, // eslint-disable-line react/forbid-prop-types
  location: shape({
    pathname: string.isRequired,
    search: string.isRequired
  }).isRequired,
  history: shape({
    push: func.isRequired
  }).isRequired
};

const mapStateToProps = (state) => ({
  // only need access to actions
  sort: state.sort
});

export default withRouter(withStyles(styles)(connectToState(mapStateToProps)(Search)));
