import React from 'react';
import {
  Typography,
  Grid,
  Container,
  Box,
  Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ModalWithLauncher from '../modals/ModalWithLauncher';
import LandingIllustration from './LandingIllustration';
import Survey from './Survey'
import { formId } from './config'
import analytics from '../../google-analytics';
import googleAnalytics from '../../google-analytics';

const useStyles = makeStyles(({palette, spacing, breakpoints}) => {
  const { secondary } = palette
  return {
    introWrapper: {
      paddingTop: 50,
      paddingBottom: 50,
      position: 'relative',
      background: secondary.main,
      [breakpoints.up('sm')]: {
        paddingTop: '150px',
      },
      width: '100vw',
      '&::after': {
        content: '""',
        background: secondary.main,
        minHeight: '300px',
        width: '100%',
        display: 'block',
        borderBottomLeftRadius: "50% 85%",
        borderBottomRightRadius: "50% 85%",
        position: "absolute",
        left: "-25vw",
        right: "-25vw",
        width: "150vw",
        zIndex: 0,
      }
    },
    introContainer: {
      flexDirection: 'row-reverse',
      [breakpoints.up('sm')]: {
        flexDirection: 'row',
      },
    },
    introHeading1: {
      color: 'white',
      font: 'normal normal 900 32px/38px Lato',
      lineHeight: 1,
      [breakpoints.up('sm')]: {
        font: 'normal normal 900 45px/86px Lato',
      },
    },
    introHeading2: {
      color: 'white',
      font: 'normal normal 300 60px/70px Lato',
      marginBottom: spacing(2),
      '& strong': {
        textTransform: 'uppercase',
      },
      [breakpoints.up('sm')]: {
        marginBottom: spacing(3),
        font: 'normal normal 300 78px/86px Lato',
      },
    },
    introText: {
      fontSize: 22,
      lineHeight: '32px',
      fontWeight: 300,
      color: 'white',
      marginBottom: spacing(3),
      [breakpoints.up('sm')]: {
        paddingRight: spacing(3),
      }
    },
    illustrationWrapper: {
      display: 'flex',
    },
    illustration: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flex: '1 1 100%',
      '& svg': {
        flex: '1 1 100%',
        width: '100%',
      }
    },
    actionWrapper: {
      marginBottom: spacing(1),
    },
    loginAction: {
      color: 'white',
      textAlign:  'center',
      '& a': {
        color: 'white',
        textDecoration: 'underline',
        '&:hover,&:focus': {
          color: 'rgba(255,255,255,.85)',
        }
      },
      [breakpoints.up('sm')]: {
        textAlign:  'left',
        paddingLeft: spacing(3),
      }
    },
  }
});

const buttonStyles = makeStyles(({breakpoints, spacing}) => {
  return {
    root: {
      padding: spacing(3),
      minWidth: '350px',
      background: '#FFFFFFA6',
      width: '100%',
      border: 'white 1px solid',
      alignItems: 'center',
      textAlign:  'center',
      fontSize: 18,
      color: 'black',
      justifyContent: 'flex-start',
      fontWeight: 600,
      '&:hover,&:focus': {
        background: 'white',
      },
      [breakpoints.up('sm')]: {
        width: 'auto',
        textAlign:  'left',
        alignItems: 'flex-start',
      },
    },
  }
})

const LandingIntroTools = () => {
  const classes = useStyles();
  const buttonClasses = buttonStyles();
  const handleSignIn = (event) => {
    event.preventDefault()
    const form = document.getElementById(formId)
    form && form.submit()
    googleAnalytics.trackLoginClick("Already have account")
  }
  const ModalLauncher = () => (
    <Button classes={buttonClasses} onClick={() => analytics.trackTrialRequest("Location: top of landing page")} >Request Free Trial</Button>
  )
  return (
    <Box id='intro' className={classes.introWrapper} component='section'>
      <Container >
        <Grid container spacing={2} className={classes.introContainer} >
          <Grid item xs={12} md={6}>
            <Box mb={4}>
              <Typography variant='subtitle1' className={classes.introHeading2}>
                From <strong>Insight</strong>
                <br/>
                to <strong>Action</strong>
              </Typography>
              <Typography variant='body1' className={classes.introText}>
                ICER Analytics™ revolutionizes your ability to <strong>develop formularies</strong>, <strong>negotiate drug prices</strong>, and explore new ways to <strong>apply evidence</strong> in a drive toward a health system that can achieve fair prices and fair access for all.
              </Typography>
              <Box className={classes.actionWrapper}>
                <ModalWithLauncher
                  i18nKey='surveyTrial'
                  Content={Survey}
                  Launcher={ModalLauncher}
                  mx={false}
                />
              </Box>
              <Typography className={classes.loginAction}>
                Already have an account? <a href='#' onClick={handleSignIn}><strong>Login</strong></a>
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} className={classes.illustrationWrapper} >
            <Box className={classes.illustration}>
              <LandingIllustration />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default LandingIntroTools;
