import React, { useCallback, useMemo, useState } from "react";
import { Dialog, DialogContent, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { differenceInHours } from "date-fns";
import DataAccessAlert from "./DataAccessAlert";

export const getLocalStorageItem = ({ key=null }) => {
  if (!localStorage || !key) return null;
  return localStorage.getItem(key);
};

export const setLocalStorageItem = ({ key=null, value }) => {
  if (!localStorage || !key) return null;
  localStorage.setItem(key, value);
};

const LimitedAccessModal = () => {
  const LOCAL_STORAGE_KEY = "trial-membership-modal-dismissal";
  const lastDate = useMemo(() => {
    const dateString = getLocalStorageItem({ key: LOCAL_STORAGE_KEY });
    if (!dateString) return null;
    return new Date(dateString);
  }, []);
  const [modalOpen, setModalOpen] = useState(true);
  const toggleModal = useCallback(() => {
    setLocalStorageItem({ key: LOCAL_STORAGE_KEY, value: new Date().toUTCString() });
    setModalOpen(false);
  }, []);

  if (lastDate && differenceInHours(new Date(), lastDate) < 24) {
    return null;
  }

  return (
    <Dialog fullWidth maxWidth="md" disableEscapeKeyDown={false} open={modalOpen}>
      <DialogContent>
        <DataAccessAlert
          action={
            <IconButton aria-label="Dismiss modal" onClick={toggleModal}>
              <CloseIcon />
            </IconButton>
          }
        />
      </DialogContent>
    </Dialog>
  );
};

export default LimitedAccessModal;
