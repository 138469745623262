import React from "react";
import ToolSelector from "../../ToolSelector";
import AppPage from "..";
import AppLandingNav from "./AppLandingNav";
import ComingSoonBanner from "./ComingSoonBanner";
import AppLandingContent from "./AppLandingContent";

const AppLanding = () => {
  return (
    <AppPage
      docTitle="Home"
      title="ICER Analytics: two tools in one"
      bannerHeight="Xl"
      banner={<ComingSoonBanner />}
    >
      <ToolSelector />
      <AppLandingNav />
      <AppLandingContent />
    </AppPage>
  );
};

export default AppLanding;
