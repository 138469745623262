import React from 'react';
import { shape, func } from 'prop-types';
import { withRouter } from 'react-router-dom';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import FilterListIcon from '@mui/icons-material/FilterList';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';

import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import withStyles from '@mui/styles/withStyles';

import { connectToState } from '../../redux/connect-api';
import { fieldFilterConfig } from '../../config';
import analytics from '../../google-analytics';

import { red } from '@mui/material/colors';

// TODO: eliminate need for the following, by using instance of Map for fieldFilterConfig?
const keysMappedToGroupIndex = fieldFilterConfig.reduce((acc, config, groupIndex) => {
  config.slice(1).forEach(({ key }) => { acc[key] = groupIndex; });
  return acc;
}, {});

/* keysMappedToGroupIndex, e.g.:
 {
   "user_proposed_price": 0,
   "net_price": 0,
   "wac": 0,
   "manufacturer_price": 0,
   "health_system": 1,
   "societal": 1,
   "evlyg": 2,
   "qaly": 2,
   "50k": 3,
   "100k": 3,
   "150k": 3,
   "200k": 3
 }
 */

const styles = ({ spacing, palette }) => ({
  root: {
    '& form': {
      '& .MuiFormLabel-root': {
        color: '#666666',
        fontWeight: 'bold'
      },
      '& .MuiFormControl-root': {
        marginTop: '16px',
        marginBottom: '4px',
        display: 'block',
        minWidth: 120,
        maxWidth: 300,
        '& .MuiFormControlLabel-root': {
          borderRadius: '4px',
          '&:hover:not(.Mui-disabled)': {
            // backgroundColor: '#0071ce33',
            color: '#0071ce'
          },
          // color: '#444444', // #444444 is default
          margin: '2px 0px 2px 0', // margin enables there to be a non-interactive region between clickable labels
          padding: '2px 0px 2px 16px',
          '& span': {
            padding: 0
          }
        }
      }
    },
    '& .backdrop': {
      backgroundColor: 'rgba(0, 0, 0, 0.2) !important'
    }
  },
  iconButton: {
    marginRight: spacing(.5),
    // border: '1px blue dashed'
  },
  checkbox: {
    color: palette.secondary.main,
  },
  checkboxError: {
    color: palette.error.main,
  },
});

class GridFilter extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      lastCheckedError: null,
    };
  }

  handleModalOpen = () => {
    this.setState((prevState) => ({ ...prevState, modalOpen: true }));
  }

  handleModalClose = () => {
    this.setState((prevState) => ({ ...prevState, modalOpen: false }));
  }

  applyFilter = () => {
    this.handleModalClose();
  }

  setGridFilterKey = (fieldType) => {
    const { enabledGridFields, actions: { setGridFilter } } = this.props;
    const isActive = !enabledGridFields[fieldType];
    setGridFilter({ [fieldType]: isActive });
    analytics.trackFilter(fieldType, isActive);
  }

  handleFieldSelectChange = (event) => {
    const { value: fieldType } = event.target;
    const isChecked = this.props.enabledGridFields[fieldType];
    if (this.isLastChecked(fieldType, isChecked)) {
      return this.showLastCheckedError(fieldType);
    }
    this.setState({ lastCheckedError: null })
    this.setGridFilterKey(fieldType);
  }

  showLastCheckedError = (fieldType) => {
    this.setState({ lastCheckedError: fieldType })
    if (this.lastCheckedErrorTimeout) {
      clearTimeout(this.lastCheckedErrorTimeout);
    }
    this.lastCheckedErrorTimeout = setTimeout(() => {
      this.setState({ lastCheckedError: null })
    }, 1500);
  }

  isLastChecked = (filterKey, isChecked) => {
    const { enabledGridFields } = this.props;
    const groupIndex = keysMappedToGroupIndex[filterKey];
    const checkedCount = fieldFilterConfig[groupIndex].slice(1)
      .reduce((acc, { key }) => acc + (enabledGridFields[key] === true ? 1 : 0), 0);
    if (checkedCount === 1 && isChecked) {
      return true;
    }
    return false;
  }

  renderCheckbox = ({ key, label, disabled }) => {
    const { enabledGridFields, classes } = this.props;
    const isChecked = enabledGridFields[key] === true;
    const showLastCheckedError = key === this.state.lastCheckedError;
    return (
      <Tooltip key={key} title="At least one selection is required" open={showLastCheckedError}>
        <FormControlLabel
          focused={undefined}
          label={label}
          control={(
            <Checkbox
              onChange={this.handleFieldSelectChange}
              value={key}
              checked={isChecked}
              disabled={disabled}
              color="default"
              className={showLastCheckedError ? classes.checkboxError : classes.checkbox}
            />
         )}
        />
      </Tooltip>
    );
  }

  renderFilterGroup = (group) => {
    const [heading, ...items] = group;
    return (
      <FormControl
        component="fieldset"
        key={heading}
        fullWidth
      >
        <FormLabel
          component="legend"
          focused={undefined}
        >
          {heading}
        </FormLabel>
        <FormGroup>
          {items.map(this.renderCheckbox)}
        </FormGroup>
      </FormControl>
    );
  }

  componentWillUnmount() {
    if (this.lastCheckedErrorTimeout) {
      clearTimeout(this.lastCheckedErrorTimeout);
    }
  }

  render() {
    const { classes } = this.props;
    const { modalOpen } = this.state;
    return (
      <>
        <Tooltip
          title="Filter table fields"
          aria-label="filter table"
          placement="right"
          // TransitionComponent={Zoom}
        >
          <Button
            onClick={this.handleModalOpen}
            aria-label="Customize Your Table"
            aria-controls={this.state.modalOpen ? "grid-filter-menu" : undefined}
            aria-haspopup="true"
            startIcon={<FilterListIcon />}
            className={classes.iconButton}
          >
            Customize Table
          </Button>
        </Tooltip>
        <Drawer
          id="grid-filter-menu"
          anchor="left"
          className={classes.root}
          disableEscapeKeyDown={false}
          open={modalOpen}
          // variant="temporary"
          onClose={this.handleModalClose}
          BackdropProps={{
            className: 'backdrop'
          }}
        >
          <DialogActions>
            <Button
              variant="text"
              onClick={this.handleModalClose}
            >
              Close
            </Button>
          </DialogActions>
          <DialogTitle>
            Customize Your Table
            <Typography variant="body2">
              At least one selection is required for each filter
            </Typography>
          </DialogTitle>
          <DialogContent>
            <form className={classes.container}>
              {fieldFilterConfig.map(this.renderFilterGroup)}
            </form>
          </DialogContent>
        </Drawer>
      </>
    );
  }
}

GridFilter.propTypes = {
  classes: shape({}).isRequired,
  enabledGridFields: shape({}).isRequired,
  actions: shape({
    setGridFilter: func.isRequired
  }).isRequired
};

const mapStateToProps = (state) => ({
  enabledGridFields: state.enabledGridFields
});

export default (withRouter(withStyles(styles)(connectToState(mapStateToProps)(GridFilter))));
