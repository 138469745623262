import React from 'react';
import { string, func } from 'prop-types';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { modalStyles } from './styles'

const ModalWithLauncher = ({ i18nKey, title='', Launcher, Content, mx=true }) => {
  const classes = modalStyles();
  const [modalOpen, setOpen] = React.useState(false);

  const handleModalOpen = (event) => {
    event.preventDefault()
    setOpen(true);
  };

  const handleModalClose = (event) => {
    event.preventDefault()
    setOpen(false);
  };

  const modalGraphicOrText = (
    <a // eslint-disable-line jsx-a11y/anchor-is-valid
      role="button"
      href="#"
      onClick={handleModalOpen}
      aria-label={`Open ${title}`}
      aria-haspopup="true"
    >
      <Launcher />
    </a>
  );

  return (
    <>
      { modalGraphicOrText }
      <Dialog
        fullWidth
        maxWidth="md" // 'lg' 'md' 'sm' 'xl' 'xs' false
        // fullScreen={true}
        disableEscapeKeyDown={false}
        open={modalOpen}
        onClose={handleModalClose}
        classes={{root: classes.dialogRoot}}
      >
        <DialogActions classes={{root: classes.actionRoot}}>
          <DialogTitle>
            {title}
          </DialogTitle>
          <Button
            variant="text"
            onClick={handleModalClose}
          >
            Close
          </Button>
        </DialogActions>
        <DialogContent classes={{root: mx ? classes.contentRoot : classes.contentNoPaddingXRoot}}>
          <Content />
        </DialogContent>
      </Dialog>
    </>
  );
};

ModalWithLauncher.propTypes = {
  i18nKey: string.isRequired,
  title: string,
  Launcher: func,
  Content: func
};

export default ModalWithLauncher;
