import React from "react";
import { node, string, number } from "prop-types";
import { makeStyles, useTheme } from "@mui/styles";

import { Badge, Box, Button, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { cardHover, baseCardStyle } from "../../component-styles";
import { newChipStyles } from "../NewChip";

const useStyles = makeStyles(({ palette }) => ({
  root: {
    ...baseCardStyle(palette),
    ...cardHover(palette),
    "& svg": {
      fontSize: "1.6rem !important"
    },
    "& .MuiButton-endIcon svg": {
      color: "#CCE3F5"
    }
  }
}));
const linkStyles = makeStyles(({ palette }) => ({
  linkTextContainer: {
    gap: 5,
    flex: 1,
    whiteSpace: "pre-wrap"
  },
  defaultLinkTextContainer: {
    color: palette.secondary.main
  },
  darkLinkTextContainer: {
    color: palette.common.black
  },
  linkText: {
    color: "inherit",
    flexGrow: "1",
    lineHeight: "1",
    fontWeight: 500
  },
  descriptionText: {
    color: "inherit",
    fontSize: "16px",
    lineHeight: "1"
  }
}));
const badgeStyles = makeStyles(({ breakpoints }) => {
  return {
    root: {
      alignSelf: "flex-start",
      textTransform: "uppercase",
      fontSize: 10,
      [breakpoints.up("md")]: {
        fontSize: 14
      },
      right: "0.5rem"
    },
  }
});

const NavButton = (props) => {
  const {
    href,
    linkHref,
    linkText,
    linkDescription,
    linkIcon,
    startIconBg = false,
    endIcon,
    newCount,
    variant = "default",
    handleClick,
    target,
    sx = {}
  } = props;
  const classes = useStyles();
  const linkClasses = linkStyles();
  const badgeClasses = badgeStyles();

  const { palette } = useTheme();

  return (
    <Button
      href={href}
      to={linkHref}
      component={linkHref ? Link : undefined}
      onClick={handleClick}
      classes={classes}
      variant="outlined"
      startIcon={linkIcon}
      endIcon={endIcon}
      fullWidth={true}
      key={linkText}
      target={target}
    >
      <Box
        display="flex"
        flexDirection="column"
        className={[linkClasses.linkTextContainer, linkClasses[`${variant}LinkTextContainer`]].join(
          " "
        )}
        style={{ paddingLeft: 10 }}
      >
        {startIconBg && (
          <Box
            style={{
              position: "absolute",
              left: 0,
              width: "45px",
              top: 0,
              bottom: 0,
              zIndex: 0,
              backgroundColor: palette.secondary.main
            }}
          />
        )}
        <Typography
          className={linkClasses.linkText}
          component="span"
          variant="h4"
          style={{ fontSize: sx.fontSize }}
        >
          {linkText}
        </Typography>
        {linkDescription && (
          <Typography className={linkClasses.descriptionText} component="span">
            {linkDescription}
          </Typography>
        )}
      </Box>
      {newCount > 0 && (
        <Badge classes={{root: badgeClasses.root, badge: newChipStyles().green}} color="primary" badgeContent={`${newCount} new`} />
      )}
    </Button>
  );
};

NavButton.defaultProps = {
  href: null,
  linkHref: null,
  linkIcon: null,
  linkText: "",
  newCount: 0
};

NavButton.propTypes = {
  linkHref: string,
  linkIcon: node,
  linkText: string,
  newCount: number
};

export default NavButton;
