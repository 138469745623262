import { useEffect } from "react";
import { useLocation } from "react-router";
import { APP_TITLE } from "../constants";
import googleAnalytics from "../google-analytics";

export const trackPageView = (title) => {
  let _title = `${APP_TITLE}`;
  if (title) _title += ` - ${title}`;
  document.title = _title;
  googleAnalytics.trackPageView({ title: _title, location });
};

export function usePageView(title = "") {
  let location = useLocation();
  useEffect(() => {
    trackPageView(title);
  }, [location, title]);
}
