import React, { useEffect } from "react";
import { bool, string, arrayOf, shape } from "prop-types";
import { Box, Grid, Paper, Typography } from "@mui/material";
import { withStyles } from "@mui/styles";
import ProgressIndicator from "./ProgressIndicator";
import ReportSnapshotCard from "./ReportSnapshotCard";

import { connectToState } from "../redux/connect-api";
import {
  searchStringSelector,
  activeDetailIdSelector,
  analysesLoadingSelector,
  getReportSnapshotCollection
} from "../selectors";
import { menu } from "../component-styles";
import ReportSnapshot from "./AppPages/ReportSnapshot";
import { trackPageView } from "../hooks/usePageView";
import { userIsTrialSubscriberSelector } from "../selectors/userProfile";

const styles = () => ({
  loadingWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%"
  },
  container: {
    display: "flex",
    width: "100%",
    flexDirection: "column"
  },
  wrapper: {
    width: "100%",
    overflow: "auto"
  },
  ...menu
});

class ReportSnapshots extends React.PureComponent {
  componentDidMount() {
    !this.props.loading && trackPageView("Report Snapshots");
  }

  render() {
    const { loading, reportSnapshots, classes, searchString = "", activeDetailId, subscriptionIsTrial } = this.props;


    if (activeDetailId) {
      return <ReportSnapshot reportId={activeDetailId} />;
    }

    if (loading) {
      return (
        <Box className={classes.container}>
          <Paper className={classes.menu} elevation={0} id="utility_bar" />
          <Box className={classes.loadingWrapper}>
            <ProgressIndicator active={loading} />
          </Box>
        </Box>
      );
    }

    if (!reportSnapshots.length && searchString.length) {
      return (
        <Box className={classes.loadingWrapper} py={6}>
          <Typography>
            We weren't able to find a Report Snapshot matching '{searchString}'
          </Typography>
        </Box>
      );
    }

    const searchResults = () => {
      let searchResults = "";
      if (searchString.length) {
        searchResults = ` matching: ${searchString}`;
      }
      return searchResults;
    };

    return (
      <Box className={classes.container}>
        <Paper className={classes.menu} elevation={0} id="utility_bar">
          <p className={classes.searchString}>
            {reportSnapshots.length} report snapshot(s) found{searchResults()}
          </p>
        </Paper>
        <Box m={3} flexGrow={1}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                {reportSnapshots.map((report, i) => (
                  <ReportSnapshotCard
                    subscriptionIsTrial={subscriptionIsTrial}
                    key={i}
                    attributes={report.attributes || report}
                    searchString={searchString}
                  />
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
    );
  }
}

ReportSnapshots.propTypes = {
  loading: bool,
  searchString: string,
  reportSnapshots: arrayOf(shape({})),
  activeDetailId: string,
  match: shape({
    // eslint-disable-line react/no-unused-prop-types
    url: string,
    params: shape({
      detailId: string
    })
  }).isRequired,
  location: shape({
    // eslint-disable-line react/no-unused-prop-types
    pathname: string.isRequired,
    search: string.isRequired
  }).isRequired
};

const mapStateToProps = (state, ownProps) => {
  return {
    reportSnapshots: getReportSnapshotCollection(state, ownProps),
    activeDetailId: activeDetailIdSelector(state, ownProps),
    searchString: searchStringSelector(state, ownProps),
    loading: analysesLoadingSelector(state, ownProps),
    subscriptionIsTrial: userIsTrialSubscriberSelector(state)
  };
};

export default withStyles(styles)(connectToState(mapStateToProps)(ReportSnapshots));
