import React from "react";
import { Box, Chip } from "@mui/material";
import { platformConfig } from "../config";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(({ palette }) => ({
  chip: {
    "&:focus": {
      boxShadow: `0 0 0 0.2rem ${palette.secondary.main}`
    }
  }
}));

const PlatformChips = ({ chips, style = {} }) => {
  const classes = useStyles();
  return (
    <Box display="flex" style={style}>
      {chips.map((chip, i) => {
        const tool = platformConfig.tools.find((t) => t.name === chip);
        return (
          <Chip
            key={i}
            label={chip}
            className={classes.chip}
            variant="outlined"
            style={{ marginRight: 5, marginTop: 5, backgroundColor: "white" }}
            clickable
            component="a"
            href={(tool && tool.href) || ""}
          />
        );
      })}
    </Box>
  );
};

export default PlatformChips;
