import React from "react";
import { makeStyles } from "@mui/styles";
import { Box, Chip } from "@mui/material";
import UnpublishedChip from "./UnpublishedChip";

import { newItemPeriod } from "../config";
import { differenceInDays, determineComparisonDate } from "../utils/date";
import NewChip from "./NewChip";

const useStyles = makeStyles(() => {
  return {
    publishedFlag: {
      position: "absolute",
      left: 10,
      top: 10,
      zIndex: 100
    },
  };
});

const ItemBadges = ({ item, positioned = true, size = "medium" }) => {
  const { publishedFlag } = useStyles();
  const comparisonDate = determineComparisonDate(item.created_at, item.display_date);
  const itemIsNew = differenceInDays([comparisonDate, new Date()]) < newItemPeriod;
  const {unpublished} = item;
  if (unpublished || itemIsNew) {
    return (
      <Box className={positioned ? publishedFlag : null} display="flex" gap={0.5}>
        {unpublished && <UnpublishedChip size={size} />}
        {itemIsNew && <NewChip /> }
      </Box>
    );
  } else {
    return null;
  }
};

export default ItemBadges;
