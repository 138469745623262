import React from "react";
import { arrayOf, oneOf, shape, string, bool } from "prop-types";
import Paper from "@mui/material/Paper";
import withStyles from "@mui/styles/withStyles";
import NoAccess from "./NoAccess";

import TabBar from "./TabBar";
import GridFilter from "./menus/GridFilter";
import CsvDownload from "./menus/CsvDownload";
import ComparisonChart from "./menus/ComparisonChart";
import AnalysesTable from "./AnalysesTable";
import ReportSnapshots from "./ReportSnapshots";
import { connectToState } from "../redux/connect-api";

import {
  activeTabKeySelector,
  analysesLoadingSelector,
  filteredAnalysisIdsSelector,
  filteredAnalysisIdsForAllTabsSelector
} from "../selectors";

import { tabKeys } from "../config";
import { menu } from "../component-styles";
import { userAccessStatusSelector, userIsTrialSubscriberSelector, userProfileLoadingSelector } from "../selectors/userProfile";
import LimitedAccessModal from "./LimitedAccessModal";

const styles = ({ spacing }) => ({
  alignMenu: {
    padding: "0 10px 0 10px",
    width: "100%"
  },
  scrollable: {
    overflow: "auto",
    display: "flex",
    flex: "1 1 auto",
    "& > div": {
      flexDirection: "column"
    }
  },
  ...menu,
  paper: {
    display: "flex"
  }
});

const TabNavigator = ({    activeTabKey,
    rowIds,
    rowIdsForExport,
    analysesLoading,
    classes,
    hasToolAccess,
    subscriptionIsTrial,
    userLoading,
    location,
    history,
    match}) => {
  const onDrugList = activeTabKey === "drugs";
  const onReportSnapshots = activeTabKey === "report-snapshots";
  const showGridFilter = !onDrugList && !onReportSnapshots;
  const controls = () => (
    <>
      {showGridFilter ? <GridFilter /> : null}
      <ComparisonChart disabled={analysesLoading} rowIds={rowIds} />
      <div className={classes.menuActionsSecondary}>
        <CsvDownload disabled={analysesLoading} rowIds={rowIdsForExport} />
      </div>
    </>
  );
  const searchTerm = () => {
    if (location.search) {
      return decodeURI(location.search.replace("?search=", "").replace("+", " "));
    } else {
      return "";
    }
  };

  if (!userLoading && !hasToolAccess) {
    return <NoAccess />;
  }

  let wrapperClasses = classes.alignMenu;
  if (onReportSnapshots) {
    wrapperClasses += ` ${classes.scrollable}`;
  }
  return (
    <>
      {subscriptionIsTrial && <LimitedAccessModal />}
      <TabBar location={location} match={match} history={history} activeTabKey={activeTabKey} />
      <main className={wrapperClasses} id={`tabpanel-${activeTabKey}`}>
        {!onReportSnapshots && (
          <Paper className={classes.menu} elevation={0} id="utility_bar">
            {location.search && onDrugList ? (
              <p className={classes.searchString}>
                Search keyword: <i>{searchTerm()}</i>
              </p>
            ) : null}
            {controls()}
          </Paper>
        )}
        <Paper
          className={`${classes.paper} analyses-container`}
          elevation={0}
          id="main-content"
        >
          {onReportSnapshots ? (
            <ReportSnapshots
              loading={userLoading}
              searchTerm={searchTerm()}
              location={location}
              match={match}
            />
          ) : (
            <AnalysesTable
              activeTabKey={activeTabKey}
              loading={analysesLoading}
              rowIds={rowIds}
              searchTerm={searchTerm()}
            />
          )}
        </Paper>
      </main>
    </>
  );
}

TabNavigator.propTypes = {
  classes: shape({}).isRequired,
  activeTabKey: oneOf(tabKeys).isRequired,
  analysesLoading: bool.isRequired,
  rowIds: arrayOf(string).isRequired,
  match: shape({
    // eslint-disable-line react/no-unused-prop-types
    params: shape({
      activeTabKey: oneOf(tabKeys)
    })
  }).isRequired,
  location: shape({
    // eslint-disable-line react/no-unused-prop-types
    search: string.isRequired
  }).isRequired,
  hasToolAccess: bool,
  loading: bool
};

const mapStateToProps = (state, ownProps) => {
  return {
    activeTabKey: activeTabKeySelector(state, ownProps),
    analysesLoading: analysesLoadingSelector(state, ownProps),
    rowIds: filteredAnalysisIdsSelector(state, ownProps),
    rowIdsForExport: filteredAnalysisIdsForAllTabsSelector(state, ownProps),
    hasToolAccess: userAccessStatusSelector(state),
    subscriptionIsTrial: userIsTrialSubscriberSelector(state),
    userLoading: userProfileLoadingSelector(state)
  };
};

export default withStyles(styles)(connectToState(mapStateToProps)(TabNavigator));
