import React, { useEffect } from "react";
import { arrayOf, shape, string, bool } from "prop-types";
import { Table, Box } from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import { tabKeyType } from "../constants/proptypes";
import AnalysesTableHeaderRow from "./table/AnalysesTableHeaderRow";
import AnalysesTableDataRow from "./table/AnalysesTableDataRow";
import AnalysesTableStatusRow from "./table/AnalysesTableStatusRow";
import LoginButton from "./LoginButton";
import ProgressIndicator from "./ProgressIndicator";
import { trackPageView } from "../hooks/usePageView";

const styles = () => ({
  table: {
    minWidth: "100%"
  },
  error: {
    minWidth: "100%",
    minHeight: "300px",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: 15
  }
});

class AnalysesTable extends React.PureComponent {
  handleSubmit = (e) => {
    e.preventDefault();
  };

  determinePageTitle() {
    switch (this.props.activeTabKey) {
      case "compare-your-own-pricing":
        return "Compare Your Own Pricing";
      case "icer-pricing":
        return "ICER Pricing";
      case "drugs":
        return "Drugs List";
      case "report-snapshots":
        return "Report Snapshots";
      default:
        return "Analysis";
    }
  }

  trackPageView() {
    !this.props.loading && trackPageView(this.determinePageTitle());
  }

  componentDidMount() {
    this.trackPageView();
  }

  componentDidUpdate(prevProps) {
    this.trackPageView();
  }

  render() {
    const { classes, activeTabKey, rowIds, loading, searchTerm } = this.props;
    if (loading) {
      return (
        <Box className={classes.error}>
          <ProgressIndicator active={loading} />
        </Box>
      );
    }
    if (activeTabKey !== "compare-your-own-pricing" && !rowIds.length && !searchTerm) {
      return (
        <Box className={classes.error}>
          <p>
            We're having trouble loading analyses. Thanks for your patience.
            <br /> Please try logging in again.
          </p>
          <LoginButton />
        </Box>
      );
    }
    return (
      <form className="analyses" onSubmit={this.handleSubmit}>
        <Table className={classes.table}>
          <AnalysesTableHeaderRow activeTabKey={activeTabKey} />
          {rowIds.map((analysisId) => (
            <AnalysesTableDataRow key={analysisId} analysisId={analysisId} />
          ))}
          <AnalysesTableStatusRow rowIds={rowIds} />
        </Table>
      </form>
    );
  }
}

AnalysesTable.propTypes = {
  classes: shape({}).isRequired,
  activeTabKey: tabKeyType.isRequired,
  rowIds: arrayOf(string).isRequired,
  loading: bool.isRequired,
  searchTerm: string
};

export default withStyles(styles)(AnalysesTable);
