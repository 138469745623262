import React from "react";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import { bool } from "prop-types";
import { createBrowserHistory } from "history";
import Box from "@mui/material/Box";

import StateProvider from "./StateProvider";
import I18nProvider from "./I18nProvider";
import analytics from "../google-analytics";

// Layout Component
import Header from "./Header";
import Footer from "./Footer";
import LandingAlerts from "./landing/LandingAlerts";

// Pages
import AppLanding from "./AppPages/AppLanding";
import ComingSoon from "./AppPages/ComingSoon/";
import DocumentsPage from "./AppPages/DocumentsPage";
import Landing from "./landing/Landing";
import Updates from "./AppPages/Updates";
import VideosPage from "./AppPages/VideosPage";
import Webinars from "./AppPages/Webinars";
// Tool Related Pages
import TabNavigator from "./TabNavigator";
import Demo from "./Demo";
// User Related Pages
import ChangePassword from "./AppPages/ChangePassword";
import ChangeAccountInfo from "./AppPages/ChangeAccountInfo";

import { tabKeys } from "../config";

import "../styles/icer-ui.scss";
import ReportSnapshot from "./AppPages/ReportSnapshot";
import AccessibilityChecker from "./AccessibilityChecker";
import StateInitializer from "./StateInitializer";

const history = createBrowserHistory();

const App = ({ isSignedIn, isDeviseView }) => (
  <I18nProvider>
    <StateProvider>
      <Router history={history}>
        <AccessibilityChecker>
          <Route path={`/:activeTabKey(${tabKeys.join("|")})?`}>
            <Header isDeviseView={isDeviseView} isSignedIn={isSignedIn} />
          </Route>
          <div id="spa_body">
            <Switch>
              <Route path="/demo">
                <Demo />
              </Route>
              <Route path="/(login|app_logout|users/*)">
                {/* catch this, and render nothing -- this is a Rails/Devise user login or reset-password route */}
              </Route>
              {!isSignedIn && (
                <Switch>
                  <Route path="/">
                    <Landing isSignedIn />
                  </Route>
                  <Route path="*">
                    <Redirect push={false} to="/" />
                  </Route>
                </Switch>
              )}
              {isSignedIn && (
                <StateInitializer>
                  <Box style={{ width: "100%" }}>
                    <LandingAlerts />
                  </Box>
                  <Switch>
                    <Route path="/password">
                      <ChangePassword />
                    </Route>
                    <Route path="/account">
                      <ChangeAccountInfo />
                    </Route>
                    <Route path={`/:activeTabKey(${tabKeys.join("|")})`}>
                      <Switch>
                        <Route path="/drugs/:detailsSlug" component={ReportSnapshot} />
                        <Route path="/:activeTabKey/:detailId" component={TabNavigator} />
                        <Route path="/:activeTabKey" component={TabNavigator} />
                      </Switch>
                    </Route>
                    <Route path="/coming-soon" component={ComingSoon} />
                    <Route path="/documents" component={DocumentsPage} />
                    <Route path="/tools" component={AppLanding} />
                    <Route path="/updates" component={Updates} />
                    <Route path="/videos" component={VideosPage} />
                    <Route path="/webinars" component={Webinars} />
                    <Route path="*">
                      <Redirect push={false} to={`/tools`} />
                    </Route>
                  </Switch>
                </StateInitializer>
              )}
            </Switch>
          </div>
          <Footer />
        </AccessibilityChecker>
      </Router>
    </StateProvider>
  </I18nProvider>
);

App.propTypes = {
  isSignedIn: bool.isRequired,
  isDeviseView: bool.isRequired
};

export default App;
