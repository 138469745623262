import React from "react";
import Button from "@mui/material/Button";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  skipToContent: {
    position: "absolute",
    left: -9999,
    top: theme.spacing(2),
    transform: "translateX(-50%)",
    "&:focus": {
      left: "50%"
    }
  }
}));

export default function SkipToContentLink() {
  const classes = useStyles();
  return (
    <Button
      className={classes.skipToContent}
      href="#main-content"
      variant="outlined"
      color="secondary"
    >
      Skip to main content
    </Button>
  );
}
