import React from "react";
import { makeStyles, useTheme } from "@mui/styles";
import { Box, Card, CardActionArea, CardContent, Typography } from "@mui/material";
import PlayIcon from "@mui/icons-material/PlayCircleFilled";
import { formateDate } from "../utils/date";

import PlatformChips from "./PlatformChips";
import ItemBadges from "./ItemBadges";
import { cardHover } from "../component-styles";
import googleAnalytics from "../google-analytics";

const VEIL_COLOR = "rgba(0, 0, 0, 0.35)";
const PLAY_ICON_COLOR = "rgba(255, 255, 255, .5)";

const useStyles = makeStyles(({ palette, overrides, spacing, breakpoints }) => {
  const rootBase = {
    position: "relative",
    display: "flex",
    alignItems: "stretch",
    border: `1px solid ${palette.secondary.light}`,
    flex: 1,
    "& .MuiCardContent-root": {
      flexDirection: "column"
    }
  };
  return {
    videoCardRoot: {
      ...rootBase,
      borderRadius: 4,
      ...cardHover(palette),
      "&:hover": {
        "& div::before": {
          opacity: 0
        }
      }
    },
    videoCardRootFeatured: {
      ...rootBase,
      borderBottomLeftRadius: 4,
      borderBottomRightRadius: 4,
      [breakpoints.up("lg")]: {
        borderRadius: 4
      }
    },
    videoCardRootHorizontal: {
      ...rootBase,
      "& .MuiCardContent-root": {
        minHeight: 75,
        flexDirection: "row",
        "& button": {
          minWidth: 150,
          flex: "1 1 150px"
        }
      }
    },
    videoWrapper: {
      overflow: "hidden",
      height: 0,
      paddingTop: "56.25%",
      backgroundSize: "cover",
      backgroundPosition: "center",
      position: "relative",
      "&::before": {
        content: '""',
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 1,
        background: VEIL_COLOR,
        transition: ".2s opacity ease-in-out"
      },
      "& svg": {
        fill: PLAY_ICON_COLOR,
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        zIndex: 2
      }
    },
    videoCopy: {
      display: "flex",
      flex: 1,
      padding: 0,
      "&:last-child": {
        padding: 0
      }
    },
    videoTitle: {
      fontWeight: 500,
      cursor: "pointer",
      "&:hover": {
        textDecoration: "none"
      },
      "&::after": {
        content: '""',
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },
    videoTitleWord: {
      display: "block"
    },
    videoDescription: {
      color: "black"
    },
    videoActionArea: {
      "&:hover,&:focus": {
        "& > div::before": {
          opacity: 0
        },
        "& svg": {
          opacity: 0.75
        }
      }
    },
    videoActionAreaHorizontal: {
      flex: "1 1 100px",
      maxWidth: "100px",
      "&:hover": {
        "& > div::before": {
          opacity: 0
        }
      }
    },
    disabledLink: {
      pointerEvents: "none",
      opacity: ".4"
    },
    accessFlag: {
      position: "absolute",
      top: "25%",
      width: "100%",
      zIndex: 10,
      textAlign: "center",
      background: "#eadeb3",
      paddingTop: spacing(1.5),
      paddingBottom: spacing(1.5)
    },
    accessFlagStatement: {
      textAlign: "center",
      lineHeight: 1,
      marginBottom: spacing(0.5)
    },
    chips: {
      // Needed to make chips clickable inside the card
      position: "relative",
      alignSelf: "flex-start"
    },
    rule: {
      height: 3,
      backgroundColor: palette.primary.light,
      width: "100%"
    },
    videoDetailsWrapper: {
      gap: spacing(1),
    }
  };
});

const VideoCard = ({
  video,
  handleClick,
  showDetails = true,
  showChips = true,
  isFeatured = false,
  variant = "vertical",
  sx = {}
}) => {
  const classes = useStyles();
  const { palette } = useTheme();
  const { title, description, youtube_id, platform_tags = [] } = video;
  const handleCardClick = () => {
    googleAnalytics.trackVideoThumbnailClick(title);
    handleClick(video);
  };
  const thumbnailUrl = `//img.youtube.com/vi/${youtube_id}/hqdefault.jpg`;
  const date = video.display_date ? formateDate(video.display_date) : null;

  const isHorizontal = variant === "horizontal";

  let rootClass = isFeatured ? classes.videoCardRootFeatured : classes.videoCardRoot;
  if (isHorizontal) {
    rootClass = [classes.videoCardRoot, classes.videoCardRootHorizontal].join(" ");
  }

  let playIconSize = isHorizontal ? 65 : 100;
  if (isFeatured) {
    playIconSize = 175;
  }

  return (
    <>
      <Card classes={{ root: rootClass }} elevation={0}>
        <CardContent className={classes.videoCopy}>
          <CardActionArea
            onClick={handleCardClick}
            className={classes[isHorizontal ? "videoActionAreaHorizontal" : "videoActionArea"]}
            aria-label="Video"
          >
            <Box
              className={classes.videoWrapper}
              style={{ backgroundImage: `url(${thumbnailUrl})` }}
            >
              <PlayIcon style={{ fontSize: playIconSize }} />
            </Box>
            {showChips && <ItemBadges item={video} />}
          </CardActionArea>
          {showDetails && (
            <Box
              m={2}
              display="flex"
              flexDirection="column"
              flex={1}
              className={classes.videoDetailsWrapper}
            >
              <Box display="flex" flexDirection="column">
                {date && <Typography variant="overline">{date}</Typography>}
                <Typography
                  className={classes.videoTitle}
                  variant="h3"
                  component="a"
                  style={{
                    color: isHorizontal ? "black" : palette.secondary.main,
                    fontSize: sx.fontSize
                  }}
                  onClick={handleCardClick}
                >
                  {title}
                </Typography>
              </Box>
              {description && (
                <Typography className={classes.videoDescription} variant="body1">
                  {description}
                </Typography>
              )}
              {platform_tags.length > 0 && (
                <Box className={classes.chips}>
                  <PlatformChips
                    chips={platform_tags}
                    style={{ marginTop: "auto", flexWrap: "wrap" }}
                  />
                </Box>
              )}
            </Box>
          )}
        </CardContent>
      </Card>
    </>
  );
};

export default VideoCard;
