import React from 'react';
import { makeStyles } from '@mui/styles';
import { object } from 'prop-types';
import { Box, Grid, Typography } from '@mui/material';

import DOMPurify from 'dompurify';
import Attachments from './Attachments';

const contentStyles = makeStyles(({palette, spacing, breakpoints}) => ({
  contentWrapper: {
    paddingTop: spacing(.75),
    '& p': {
      // Enforce size since this could be resized in the WYSIWYG
      fontSize: '16px !important',
      color: '#4A4A4A',
      lineHeight: '22px',
      marginTop: 0,
      '&:last-of-type': {
        marginBottom: 0,
      },
      '& br': {
        display: 'none'
      }
    },
    '& a': {
      textDecoration: 'underline',
      '&:hover': {
        textDecoration: 'none',
      }
    },
    '& ul li, & ol li': {
      // Enforce size since this could be resized in the WYSIWYG
      '&, & span': {
        fontSize: '16px !important',
      },
      color: '#4A4A4A',
      lineHeight: '20px',
      marginBottom: spacing(.75),
      paddingRight: spacing(2),
    },
    '& ul li': {
      '&::marker': {
        color: palette.secondary.main,
        fontSize: 20,
      }
    },
    '& ol li': {
      '&::marker': {
        color: palette.secondary.main,
        fontWeight: 'bold',
        fontSize: 16,
      }
    },
    '& h1': {
      fontSize: 20,
      lineHeight: '26px',
      fontWeight: 600,
      fontFamily: 'Lato',
      color: '#4A4A4A',
      marginTop: 0
    },
    '& h2': {
      fontSize: 16,
      lineHeight: '20px',
      fontFamily: 'Lato',
      color: '#4A4A4A',
      marginTop: 0
    },
    '& blockquote': {
      marginLeft: 0,
      paddingLeft: 40,
      paddingRight: spacing(3),
      position: 'relative',
      fontSize: '16px !important',
      color: '#4A4A4A',
      lineHeight: '22px',
      '&::before': {
        content: "'\"'",
        fontSize: 60,
        position: 'absolute',
        display: 'block',
        fontFamily: 'cursive',
        top: 0,
        left: 5,
        color: palette.secondary.light,
        lineHeight: 1,
        height: 30,
        width: 30,
        overflow: 'hidden',
      }
    }
  }
}))

const HTMLContentBlock = ({dirtyHTML='', attachments=[]}) => {
  const classes = contentStyles()
  const cleanContent = DOMPurify.sanitize(dirtyHTML, {
    ADD_ATTR: ['target']
  })
  return (
    <Box className={classes.contentWrapper}>
      <div dangerouslySetInnerHTML={{__html: cleanContent}} />
      { attachments.length > 0 &&
        <Attachments attachments={attachments} title="Related Resources" />
      }
    </Box>
  )
}

export default HTMLContentBlock
