import React, { useEffect } from "react";
import { bool, array } from "prop-types";
import { Box, Grid, Paper, Typography } from "@mui/material";
import ProgressIndicator from "../ProgressIndicator";
import AppPageContentWrapper from "./AppPageContentWrapper";

import AppPage from ".";
import DocumentGrid from "../DocumentGrid";
import LandingTitle from "../landing/LandingTitle";

import { connectToState } from "../../redux/connect-api";
import { APP_TITLE } from "../../constants";
import useUserHasAccess from "../../hooks/useUserHasAccess";
import AccessFlag from "../AccessFlag"

const DocumentsPage = ({ mediaResources, loading }) => {
  const userHasAccess = useUserHasAccess()
  useEffect(() => {
    document.title = `${APP_TITLE} - Documents`;
  }, []);
  const documents = mediaResources
    .filter((r) => r.attributes.resource_type === "document")
    .map((r) => r.attributes);

  if (loading) {
    return (
      <Paper id="" elevation={0}>
        <ProgressIndicator active={loading} />
      </Paper>
    );
  }

  if (!userHasAccess) {
    return <AccessFlag toolName="Documents" />
  }

  if (!documents.length) {
    return (
      <AppPage title="Documents">
        <Box
          my={4}
          display="flex"
          justifyContent="center"
          alignContent="center"
          flexDirection="column"
        >
          <Typography variant="h3" component="h2" align="center" style={{ marginBottom: 10 }}>
            There are currently no documents to display.
          </Typography>
        </Box>
      </AppPage>
    );
  }

  return (
    <AppPage title="Documents">
      <AppPageContentWrapper>
        <Grid container style={{ paddingTop: 50 }}>
          <Grid item sm={12} style={{ marginBottom: 60 }}>
            <DocumentGrid
              Header={() => (
                <Box mb={4}>
                  <LandingTitle>Download our ICER Analytics training documents:</LandingTitle>
                </Box>
              )}
              documents={documents}
            />
          </Grid>
        </Grid>
      </AppPageContentWrapper>
    </AppPage>
  );
};

DocumentsPage.defaultProps = {
  loading: true,
  mediaResources: []
};

DocumentsPage.propTypes = {
  loading: bool,
  mediaResources: array
};

const mapStateToProps = (state) => {
  return {
    loading: state.userProfile.loading || state.mediaResources.loading,
    mediaResources: state.mediaResources.data
  };
};

export default connectToState(mapStateToProps)(DocumentsPage);
