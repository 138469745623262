import React, { useEffect } from "react";
import { bool, array } from "prop-types";
import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Link } from "react-router-dom";

import AppPage from "..";
import { connectToState } from "../../../redux/connect-api";
import { comingSoonSelector } from "../../../selectors";
import ComingSoonCard from "./ComingSoonCard";
import ProgressIndicator from "../../ProgressIndicator";
import AppPageContentWrapper from "../AppPageContentWrapper";
import { APP_TITLE } from "../../../constants/index";
import useUserHasAccess from "../../../hooks/useUserHasAccess";
import AccessFlag from "../../AccessFlag"

const useStyles = makeStyles(() => {
  return {
    cardWrapper: {
      display: "flex",
      flexDirection: "column",
      gap: "30px"
    }
  };
});

const ComingSoon = ({ loading, comingSoonItems }) => {
  const userHasAccess = useUserHasAccess()
  const classes = useStyles();
  useEffect(() => {
    document.title = `${APP_TITLE} - Coming Soon`;
  }, []);

  if (loading) {
    return (
      <ProgressIndicator active />
    )
  }

  if (!userHasAccess) {
    return <AccessFlag toolName="Coming Soon" />
  }

  return (
    <AppPage title="Coming Soon" loading={loading}>
      <AppPageContentWrapper>
        {!comingSoonItems.length && (
          <Box display="flex" flexDirection="column" alignItems="center">
            <Typography variant="h3" component="h2" style={{ marginBottom: 10 }}>
              There are currently no report snapshots or webinars planned for the immediate future.
            </Typography>
            <Typography>
              Check out our archived <Link to="/report-snapshots">report snapshots</Link> or{" "}
              <Link to="/webinars">webinars</Link> in the meantime.
            </Typography>
          </Box>
        )}
        {!!comingSoonItems.length && (
          <Box className={classes.cardWrapper}>
            {comingSoonItems.map((props, i) => (
              <ComingSoonCard {...props} key={`coming-soon-item-${i}`} />
            ))}
          </Box>
        )}
      </AppPageContentWrapper>
    </AppPage>
  );
};

ComingSoon.propTypes = {
  loading: bool,
  comingSoonItems: array
};

const mapStateToProps = (state) => {
  return {
    loading: state.userProfile.loading || state.comingSoon.loading,
    comingSoonItems: comingSoonSelector(state)
  };
};

export default connectToState(mapStateToProps)(ComingSoon);
