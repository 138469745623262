import React from "react";
import { Zoom, Tooltip } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { tooltipStyle } from "../component-styles";
import googleAnalytics from "../google-analytics";
import { useLocation } from "react-router-dom/cjs/react-router-dom";

const CustomTooltip = ({
  content,
  title = "",
  id = "",
  controlHeight = true,
  contentReversed = false,
  icon = null
}) => {
  const location = useLocation();
  const TooltipContent = React.forwardRef(function TooltipContent(props, ref) {
    const classes = tooltipStyle();
    let rootClass = controlHeight ? classes.root : classes.rootNoMinHeight;
    let iconClass = classes.icon;
    if (contentReversed) {
      rootClass += ` ${classes.reversed}`;
      iconClass += ` ${classes.iconLeft}`;
    }
    let Icon = icon;
    if (!Icon) {
      Icon = InfoIcon;
    }
    return (
      <div {...props} ref={ref} className={rootClass}>
        {content}
        <Icon className={iconClass} />
      </div>
    );
  });
  return (
    <Tooltip
      title={title || content}
      aria-label={`tooltip for ${id}`}
      placement="top"
      TransitionComponent={Zoom}
      onOpen={() => {
        let _content = title && typeof title === "string" ? title : "";
        if (!_content) {
          _content =
            content &&
            content.props &&
            content.props.children &&
            typeof content.props.children === "string"
              ? content.props.children
              : content.props.children.props &&
                content.props.children.props.children &&
                typeof content.props.children.props.children === "string"
              ? content.props.children.props.children
              : "Content unavailable - the content may be an HTML element";
        }
        googleAnalytics.trackTooltipOpen({
          label: id || location.pathname,
          content: _content
        });
      }}
    >
      <TooltipContent />
    </Tooltip>
  );
};

export default CustomTooltip;
