import React from "react";
import SvgIcon from "@mui/material/SvgIcon";
import { DOCUMENT_ICON_COLOR } from "./PdfIcon";

const PowerpointIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <g>
        <path
          fill={DOCUMENT_ICON_COLOR}
          d="M15,7.5c-0.8,0-1.5-0.7-1.5-1.5V0H6C4.3,0,3,1.3,3,3v18c0,1.7,1.3,3,3,3h12c1.7,0,3-1.3,3-3V7.5H15z"
        />
        <path
          fill="#D24625"
          stroke="#FFFFFF"
          stroke-width="0.1905"
          stroke-miterlimit="10"
          d="M12.6,11.2H9.4c-0.6,0-1.1,0.5-1.1,1.1v4.9v2.6c0,0.6,0.5,1.1,1.1,1.1c0.6,0,1.1-0.5,1.1-1.1v-1.5h2.1
		c2,0,3.6-1.6,3.6-3.6C16.1,12.8,14.5,11.2,12.6,11.2z M12.6,16.1h-2.1v-2.6h2.1c0.7,0,1.3,0.6,1.3,1.3S13.3,16.1,12.6,16.1z"
        />
        <path fill={DOCUMENT_ICON_COLOR} d="M21,6l-6-6v4.3c0,1,0.8,1.7,1.7,1.7H21z" />
      </g>
    </SvgIcon>
  );
};

export default PowerpointIcon;
