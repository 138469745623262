import isToday from "date-fns/isToday";
import isPast from "date-fns/isPast";
import isAfter from "date-fns/isAfter";
import subDays from "date-fns/subDays";

export const getRowSlugs = (rows) => rows.map(({ slug }) => slug);

export const isDateWithin30days = (date) => {
  if (!date) return false;
  return isToday(date) || (isPast(date) && isAfter(date, subDays(new Date(), 30)));
};

export const sortCollection = (collection = [], sortedIds = [], sortField = null) => {
  // If no active sort return collection as is
  // e.g. during the initial search, we want to sort items by search score
  if (!sortField) return collection;
  const collectionIds = collection.map(({ slug }) => slug);
  const actualIds = sortedIds.filter((id) => collectionIds.includes(id));
  const sortedCollection = actualIds.map((id) => collection.find((a) => a.slug === id));
  return sortedCollection;
};
