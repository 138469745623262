import useAuthentication from "../hooks/useAuthentication";

const EC_ROUTES = ["drugs", "compare-your-own-pricing", "icer-pricing", "report-snapshots"];
const RESOURCE_ROUTES = ["webinars", "videos", "documents", "report-snapshots"];
const UPDATES_ROUTES = ["updates", "comming-soon"];
const ACCOUNT_ROUTES = ["account", "password"];

const checkIfIsInRoutes = (routes, url) => {
  if (!url) return false;
  return routes.some((route) => url.includes(route));
};
class Analytics {
  constructor() {
    this.userCanSavePrice = false;
  }

  postEvent = (data) => {
    if (process.env.RAILS_ENV === "test") return
    const { isSignedIn = false } = useAuthentication()
    if (!isSignedIn) return
    // API Tracking
    var apiData = JSON.parse(JSON.stringify(data));
    apiData.eventType = data.eventType || "event";
    let category = data.eventCategory;
    const url = window.location.pathname || data.url;
    if (data.eventType === "pageview" && data.url) {
      if (checkIfIsInRoutes(EC_ROUTES, url)) {
        category = "Evidence Compendium";
      } else if (checkIfIsInRoutes(RESOURCE_ROUTES, url)) {
        category = "Resources";
      } else if (checkIfIsInRoutes(UPDATES_ROUTES, url)) {
        category = "Updates";
      } else if (checkIfIsInRoutes(ACCOUNT_ROUTES, url)) {
        category = "Profile";
      } else if (url.includes("tools")) {
        category = "Logged in landing page (tools)";
      } else {
        category = "Landing or other page";
      }
    }
    apiData.url = url || window.location.pathname;
    apiData.eventCategory = category;
    apiData.createdAt = new Date();

    var csrf = document.querySelector('meta[name="csrf-token"]').content;
    var xhr = new XMLHttpRequest();
    xhr.open("POST", "/api/user_events");
    xhr.setRequestHeader("X-CSRF-Token", csrf);
    xhr.send(JSON.stringify(apiData));
  };

  #trackEvent = (data, eventType = "click") => {
    if (process.env.RAILS_ENV === "test") return
    this.postEvent(data);
    if (typeof gtag === "undefined" || gtag === undefined || !gtag) {
      console.warn("Google analytics script is not loaded");
      return;
    }
    const { eventCategory, eventAction, eventLabel } = data;
    gtag("event", eventType, {
      category: eventCategory,
      action: eventAction,
      label: eventLabel
    });
  };

  trackCustomEvent = (
    { eventCategory, eventAction, eventLabel = "", ...otherDimensions },
    eventType = "click"
  ) => {
    if (eventCategory && eventAction) {
      this.#trackEvent(
        {
          eventCategory,
          eventAction,
          eventLabel,
          ...otherDimensions
        },
        eventType
      );
    }
  };

  trackPageView = ({ title, location }) => {
    this.postEvent({
      url: window.location.href,
      eventType: "pageview",
      eventCategory: "view",
      eventLabel: title
    });
  };

  trackExternalLinkClick = ({ url, location }) => {
    this.#trackEvent({
      eventCategory: "External link click",
      eventAction: location,
      eventLabel: url
    });
  };

  trackBreadcrumbClick = ({ whereTo, currentPage }) => {
    this.trackCustomEvent({
      eventCategory: "Breadcrumbs",
      eventAction: `To: ${whereTo}`,
      eventLabel: `From: ${currentPage}`
    });
  };

  trackTooltipOpen = ({ label, content }) => {
    this.trackCustomEvent(
      {
        eventCategory: "Tooltips",
        eventAction: typeof label === "string" ? label : "Label unknown",
        eventLabel:
          typeof content === "string" ? content : "Content unknown - it's probably an HTML element"
      },
      "mouseover"
    );
  };

  trackHeaderNav = ({ action, label }) => {
    let _action = action;
    switch (action) {
      case "item":
        _action = "Menu Item Click";
        break;
      case "menu":
        _action = "Menu Item Click";
        break;
      case "tab":
        _action = "Tab Click";
        break;
    }
    this.#trackEvent({
      eventCategory: "Header navigation",
      eventAction: _action,
      eventLabel: label
    });
  };

  trackTrialRequest = (location) => {
    this.#trackEvent({
      eventCategory: "Trial",
      eventAction: "Request",
      eventLabel: `Location: ${location}`
    });
  };

  trackVideoThumbnailClick = (videoLabel) => {
    this.#trackEvent({
      eventCategory: "Videos",
      eventAction: "Click thumbnail",
      eventLabel: videoLabel
    });
  };

  trackDownload = ({ fileName, location = "" }) => {
    this.#trackEvent({
      eventCategory: "Downloads",
      eventAction: fileName,
      eventLabel: location
    });
  };

  trackLoginClick = (location = "") => {
    this.#trackEvent({
      eventCategory: "Login",
      eventAction: "Click",
      eventLabel: location
    });
  };

  trackCompare = (id, added) => {
    this.#trackEvent({
      eventCategory: "add-to-compare",
      eventAction: added ? "add" : "remove",
      eventLabel: id
    });
  };

  trackPrice = (id, price) => {
    // price is currently a String, in Redux state
    if (this.userCanSavePrice) {
      this.#trackEvent({
        eventCategory: "user-proposed-price",
        eventAction: "change",
        eventLabel: id
        // eventValue: parseInt(price, 10) // eventValue must be a positive integer
      });
    }
  };

  trackFilter = (fieldType, isActive) => {
    this.#trackEvent({
      eventCategory: "filter",
      eventAction: isActive ? "add" : "remove",
      eventLabel: fieldType
    });
  };

  trackSort = (fieldKey, sortDirection) => {
    this.#trackEvent({
      eventCategory: "sort",
      eventAction: sortDirection,
      eventLabel: fieldKey
    });
  };

  // different order for arguments?
  trackChart = (chartAction, actionDetails) => {
    this.#trackEvent({
      eventCategory: "comparison-chart",
      eventAction: chartAction, // open/close, vs changing menus?
      eventLabel: actionDetails
      // Send row ids, applied filters, and user proposed price?
      //     fieldsObject: chartContext
    });
  };

  trackCSV = () => {
    this.#trackEvent({
      eventCategory: "csv",
      eventAction: "download"
      // Send row ids, applied filters, and user proposed price?
      //     fieldsObject: csvContext
    });
  };

  trackDocument = (documentName) => {
    this.#trackEvent({
      eventCategory: "document",
      eventAction: "click",
      eventLabel: documentName
    });
  };

  trackSearch = (searchTerm) => {
    if (searchTerm) {
      this.#trackEvent(
        {
          eventCategory: "Search",
          eventAction: "By keyword",
          eventLabel: searchTerm
        },
        "search"
      );
    }
  };

  trackTabNavigation = (tabKey) => {
    this.#trackEvent({
      eventCategory: "tab-navigation",
      eventAction: "change",
      eventLabel: tabKey
    });
  };

  trackAuthentication = (isLogin) => {
    this.#trackEvent(
      {
        eventCategory: "authentication",
        eventAction: isLogin ? "login" : "logout"
      },
      "authentication"
    );
  };

  trackPageNav = (tabName, clickOrScroll) => {
    this.#trackEvent({
      eventCategory: "landing page nav",
      eventAction: tabName,
      eventLabel: clickOrScroll
    });
  };
}

// single instance, as long as the class is not exported:
export default new Analytics();

/* eslint-disable max-len */
/**
  GA4 events with `gtag`
  https://developers.google.com/analytics/devguides/collection/ga4/events?client_type=gtag
 */
