import React from 'react';
import {
  shape, string, func, bool
} from 'prop-types';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import withStyles from '@mui/styles/withStyles';

import { SELECTED_ROW_STORAGE_KEY } from '../../config';
import { connectToState } from '../../redux/connect-api';
import { userEnteredValuesByAnalysisIdSelector } from '../../selectors';
import analytics from '../../google-analytics';

const styles = (theme) => ({
  checkboxLabel: {
    margin: '0',
  },
  checkboxDefault: {
    padding: '0'
  },
  checkboxRemove: {
    // color: '#c00 !important',
    padding: '0'
  }
});

const removedCircleIcon = <RemoveCircleIcon />;

class RowSelector extends React.PureComponent {
  getUserEnteredValueByKey = (analysisId, fieldKey) => {
    const { userEnteredValuesForRow } = this.props;
    const { [fieldKey]: userEnteredValue } = userEnteredValuesForRow;
    if (!userEnteredValue) {
      return null;
    }
    return userEnteredValue;
  }

  handleRowSelect = () => {
    const { analysisId, actions: { setUserInput } = {} } = this.props;
    const isChecked = this.getUserEnteredValueByKey(analysisId, SELECTED_ROW_STORAGE_KEY) || false;
    setUserInput(analysisId, SELECTED_ROW_STORAGE_KEY, !isChecked);
    analytics.trackCompare(analysisId, !isChecked);
  }

  handleClick = (e) => {
    this.handleRowSelect(e);
  }

  render() {
    const {
      analysisId, label, useRemoveIcon, classes
    } = this.props;

    const isChecked = this.getUserEnteredValueByKey(analysisId, SELECTED_ROW_STORAGE_KEY) || false;

    const checkedIcon = useRemoveIcon ? removedCircleIcon : undefined;

    const checkboxClass = useRemoveIcon ? classes.checkboxRemove : classes.checkboxDefault;

    return (
      <FormControlLabel
        label={label}
        className={classes.checkboxLabel}
        aria-label="Add to Compare"
        control={(
          <Checkbox // eslint-disable-line jsx-a11y/mouse-events-have-key-events
            checked={isChecked}
            checkedIcon={checkedIcon}
            className={checkboxClass}
            color="secondary"
          />
          )}
      />
    );
  }
}

RowSelector.defaultProps = {
  // fieldKey: 'add_to_compare'
};

RowSelector.propTypes = {
  analysisId: string.isRequired,
  // fieldKey: oneOf(allFieldKeys),
  label: string.isRequired,
  userEnteredValuesForRow: shape({}).isRequired,
  useRemoveIcon: bool.isRequired,
  classes: shape({}).isRequired,
  actions: shape({
    setUserInput: func.isRequired
  }).isRequired
};

const mapStateToProps = (state, ownProps) => ({
  userEnteredValuesForRow: userEnteredValuesByAnalysisIdSelector(state, ownProps)
});

export default withStyles(styles)(connectToState(mapStateToProps)(RowSelector));
