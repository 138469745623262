export const months = ["January","February","March","April","May","June","July","August","September","October","November","December"];

export const formateDate = (date='', options={}) => {
  let _date = date
  const { format='m Y' } = options
  if (_date) {
    const dateObj = new Date(date)
    if (format === 'm Y') {
      _date = `${months[dateObj.getUTCMonth()]} ${dateObj.getUTCFullYear()}`
    } else if (format === 'm d, Y') {
      _date = `${months[dateObj.getUTCMonth()]} ${dateObj.getUTCDate()}, ${dateObj.getUTCFullYear()}`
    }
  }
  return _date
}

export const differenceInDays = ([startDate, endDate]) => (
  (endDate.getTime() - startDate.getTime()) / (1000 * 3600 * 24)
)

export const determineComparisonDate = (createdDate, displayDate) => {
  let comparisonDate = createdDate || displayDate
  if (createdDate && displayDate) {
    comparisonDate = Math.max(
      new Date(createdDate).getTime(),
      new Date(displayDate).getTime()
    )
  }
  return new Date(comparisonDate)
}
