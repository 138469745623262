import React from 'react';
import { array } from 'prop-types';
import { Box } from '@mui/material/';

import { makeStyles } from '@mui/styles';

const styles = makeStyles(() => ({
  legend: {
    display: 'flex',
    '& > div': {
      display: 'inline-block',
      whiteSpace: 'nowrap',
      top: '0px',
      left: '13px',
      margin: '4px 34px 0px 7px'
    }
  },
  rectangle: {
    display: 'inline-block',
    width: '10px',
    height: '10px',
    marginLeft: '8px',
    marginRight: '4px'
  }
}))

const ComparisonChartLegend = ({items=null}) => {
  const classes = styles()

  const _items = items || {
    '$150K': '#489BE0',
    '$100K': '#46BBA0',
    'Your Price': '#484349',
  }

  const rectangle = (color) => (
    <div className={classes.rectangle} style={{ backgroundColor: `${color}` }} />
  );

  const itemElement = (name, i) => (
    <Box key={i} display='flex' alignItems='center'>
      {rectangle(_items[name])} {name}
    </Box>
  )

  return (
    <Box className={classes.legend}>
      { Object.keys(_items).map((name, i) => itemElement(name, i) ) }
    </Box>
  )
}

ComparisonChartLegend.propTypes = {
  items: array
}

export default ComparisonChartLegend;
