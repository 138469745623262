import React from 'react';
import parseISO from 'date-fns/parseISO';
import format from 'date-fns/format';
import { i18n } from '../components/I18nProvider';
import InfoIcon from '@mui/icons-material/Info';
import CustomTooltip from '../components/CustomTooltip';
import DescriptionIcon from '@mui/icons-material/Description';
import {
  fieldConfig,
  tooltipCopy,
  costDescriptions,
  costRatioFields
} from '../config';
import CustomPopover from '../components/CustomPopover';

const isNumeric = (v) => Number.isFinite(v);

const usdFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  minimumFractionDigits: 0,
  notation: 'standard',
  compactDisplay: 'short',
  currencyDisplay: 'symbol', // "name"
  // "unitDisplay": "narrow",
  // "useGrouping": true, // comma separator
  // "minimumFractionDigits": 2,
  // "maximumFractionDigits": 2,
  // "minimumSignificantDigits": 3,
  // "maximumSignificantDigits": 3,
  currency: 'USD'
});

const formatUSD = (v) => usdFormatter.format(v);

export const formatPercent = (v) => `${v.toFixed(2)}%`;

// const fieldsTranslations = i18n.t('fields');
// console.info('fieldsTranslations: ', fieldsTranslations);
// console.info('fieldsTranslations.orphan_statuses: ', fieldsTranslations.orphan_statuses);
// latest EcmaScript standards will iterate over keys in POJO in same order as definition?

const formatValue = (fieldKey, value = '', data={}, _type=null) => {
  const config = fieldConfig[fieldKey];
  const type = _type || config.type

  const keysWithTooltip = {
    wac_price_label: {
      title: tooltipCopy.wac,
    },
  }
  const keysWithPopover = {
    manufacturer_price_label: (data) => ({
      content: tooltipCopy.map,
      icon: Object.keys(data).length ? DescriptionIcon : InfoIcon,
    })
  }

  if (fieldKey === 'orphan_statuses') {
    if (value.length === 0) {
      return i18n.t('fields.orphan_statuses.values.no_values');
    }
    return value.map((v) => i18n.t(`fields.orphan_statuses.values.${v}`)).join(', ');
  }

  if ((type === 'usd' || type === 'percent')) {
    if (value === null) {
      return <small>N/A</small>;
    }
    if (costRatioFields.includes(fieldKey) && value == -1 && config.type === 'usd') {
      return <small>{costDescriptions[0]}</small>;
    } else if (costRatioFields.includes(fieldKey) && value == -2 && config.type === 'usd') {
      return <small>{costDescriptions[1]}</small>;
    } else if (costRatioFields.includes(fieldKey) && value == -3 && config.type === 'usd') {
      return <small>{costDescriptions[2]}</small>;
    } else if (costRatioFields.includes(fieldKey) && value < 0 && config.type === 'usd') {
      return <small>N/A</small>;
    }
    if (typeof value === 'string' || React.isValidElement(value)) {
      return value;
    }
  }
  switch (type) {
    // TODO: use currency library for both (precision of) calculations and formatting (commas):
    case 'usd':
      return isNumeric(value) ? formatUSD(value) : '--';
    case 'usd-range': {
      if (value && isNumeric(value[0]) && isNumeric(value[1])) {
        return `${formatUSD(value[0])} - ${formatUSD(value[1])}`;
      }
      return <small>(invalid USD range)</small>;
    }
    case 'percent':
      return isNumeric(value) ? formatPercent(value) : '--';
    case 'percent-range': {
      if (value && isNumeric(value[0]) && isNumeric(value[1])) {
        return `${formatPercent(value[0])} - ${formatPercent(value[1])}`;
      }
      return <small>(invalid percent range)</small>;
    }
    case 'date': {
      try {
        return value ? format(parseISO(value), 'MMM yyyy') : value;
      } catch (e) {
        return `invalid date format: ${value}`;
      }
    }
    case 'boolean': {
      return value ? 'Yes' : 'No';
    }
    case 'enum': {
      return value.join(', ');
    }
    case 'string':
    default:
      if (keysWithTooltip[fieldKey]) {
        const { title, icon=null } = keysWithTooltip[fieldKey]
        return (
          <CustomTooltip
            id={ fieldKey }
            icon={ icon }
            title={ title }
            content={ value }
            controlHeight={ false }
            contentReversed={ true }
          />
        )
      } else if (keysWithPopover[fieldKey]) {
        const { content, icon=null } = keysWithPopover[fieldKey](data)
        return (
          <CustomPopover
            id={ fieldKey }
            icon={ icon }
            title={ value }
            data={ data }
            content={ content }
            controlHeight={ false }
            contentReversed={ true }
          />
        )
      }
      return value;
  }
};

export default formatValue;
