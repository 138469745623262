import { actionTypes } from '../epics';

const preloadedUserProfile = {
  loading: true,
  error: null,
  data: {}
};

// eslint-disable-next-line import/prefer-default-export
export const userProfileReducer = (state = preloadedUserProfile, action) => {
  switch (action.type) {
    case actionTypes.FETCH_USER_PROFILE:
      return {
        loading: true,
        error: null,
        data: state.data
      };
    case actionTypes.FETCH_USER_PROFILE_FAILURE:
      return {
        loading: false,
        error: action.payload,
        data: state.data
      };
    case actionTypes.FETCH_USER_PROFILE_SUCCESS: {
      const { data: { attributes } } = action.payload;
      return {
        loading: false,
        error: null,
        data: attributes
      };
    }
    default:
      return state;
  }
};
