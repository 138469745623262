import React from 'react';
import { makeStyles } from '@mui/styles';
import { object } from 'prop-types';
import { Box, Grid, Typography } from '@mui/material';

import { formateDate } from '../../utils/date'
import ItemBadges from '../ItemBadges';
import HTMLContentBlock from './HTMLContentBlock'

const itemStyles = makeStyles(({palette, spacing, breakpoints}) => ({
  itemWrapper: {
    marginBottom: 30,
  },
  dateContainer: {
    display: 'flex',
    alignItems: 'stretch',
    justifyContent: 'flex-end',
    marginBottom: spacing(1),
    [breakpoints.up('md')]: {
      marginBottom: 0,
    },
  },
  dateWrapper: {
    display:'flex',
    flex: 1,
    alignItems: 'flex-start',
    position: 'relative',
    paddingRight: 20,
    alignItems: 'center',
    [breakpoints.up('lg')]: {
      flexDirection:'column',
      alignItems: 'flex-start',
    }
  },
  date: {
    color: palette.primary.main,
    fontSize: 24,
    lineHeight: '30px',
    fontWeight: 'bold',
    marginBottom: 0,
    [breakpoints.up('md')]: {
      marginBottom: spacing(1),
    },
  },
  pointLine: {
    position: 'absolute',
    right: 5,
    top: 10,
    bottom: -45,
    display: 'none',
    [breakpoints.up('lg')]: {
      display: 'block',
    },
    '&::before': {
      content: "''",
      display: 'block',
      position: 'absolute',
      height: 12,
      width: 12,
      top: 0,
      left: 0,
      background: palette.primary.main,
      borderRadius: '50%',
      border: '1px solid white',
      zIndex: 3,
    },
    '&::after': {
      content: "''",
      display: 'block',
      position: 'absolute',
      width: 1,
      top: 1,
      left: 6,
      bottom: 0,
      background: palette.primary.main,
      zIndex: 2,
    }
  },
}))

const RecentUpdatesListItem = ({item}) => {
  if (!item) return null
  const classes = itemStyles()
  return (
    <Box className={classes.itemWrapper} component='article'>
      <Grid container>
        <Grid container item xs={12} spacing={3}>
          <Grid item xs={12} lg={4} className={classes.dateContainer}>
            <Box className={classes.dateWrapper}>
              <Box mr={2}>
                <Typography variant='h2' className={classes.date}>
                  {formateDate(item.display_date, {format: 'm d, Y'})}
                </Typography>
              </Box>
              <ItemBadges item={item} positioned={false} size='small' />
              <div className={classes.pointLine} ></div>
            </Box>
          </Grid>
          <Grid item xs={12} lg={8}>
            <HTMLContentBlock dirtyHTML={item.body_html} attachments={item.attachments} />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

RecentUpdatesListItem.propTypes = {
  item: object,
};

export default RecentUpdatesListItem
