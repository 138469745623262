import React, { useCallback, useMemo, useState } from "react";
import { Box, Link, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(({ palette }) => {
  return {
    lightLink: {
      color: palette.common.white,
      textDecoration: "none",
      '&:hover': {
        color: palette.common.white,
        textDecoration: "underline",
      }
    },
  };
});

export const ClippedParagraph = ({
  text,
  characterCount = 160,
  toggleText = ["[more]", "[less]"],
  textColor="light"
}) => {
  const classes = useStyles();
  const clippedText = useMemo(() => {
    if (text.length <= characterCount) return text;
    return text.substring(0, characterCount) + "...";
  }, [text, characterCount]);
  const [renderedText, setRenderedText] = useState(clippedText);
  const [textIsClipped, setTextIsClipped] = useState(true);
  const [currentToggleText, setCurrentToggleText] = useState(toggleText[0]);
  const handleToggleText = useCallback(
    (event) => {
      event.currentTarget.blur();
      event.preventDefault();
      if (textIsClipped) {
        setRenderedText(text);
        setTextIsClipped(false);
        setCurrentToggleText(toggleText[1]);
      } else {
        setRenderedText(clippedText);
        setTextIsClipped(true);
        setCurrentToggleText(toggleText[0]);
      }
    },
    [text, clippedText, toggleText, textIsClipped]
  );
  return (
    <Box>
      <Typography component="span" style={{color: textColor === "light" ? "white" : "black"}}>{renderedText}</Typography>
      {text.length > characterCount && (
        <Typography component="span">
          {" "}
          <Link href="#" onClick={(event) => handleToggleText(event)} className={textColor === "light" ? classes.lightLink : undefined}>
            {currentToggleText}
          </Link>
        </Typography>
      )}
    </Box>
  );
};
