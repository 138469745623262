import React from 'react';
import { node, string } from 'prop-types';

// Possibly, if necessary, integrate google-analytics, here?
const OutboundLink = ({ href, children }) => (
  <a
    href={href}
    target="_blank"
    rel="noopener noreferrer"
  >
    {children}
  </a>
);

OutboundLink.propTypes = {
  href: string.isRequired,
  children: node.isRequired
};

export default OutboundLink;
