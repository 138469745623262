import React from "react";
import { Alert, AlertTitle, Button, Link, Typography } from "@mui/material";

const DataAccessAlert = ({ label = "ICER's most recent analyses are", action }) => (
  <Alert severity="warning" action={action}>
    <AlertTitle sx={{ fontWeight: "bold" }}>Data access is limited during the free trial period.</AlertTitle>
    <Typography>
      {label} only available to subscribers.{" "}
      Please{" "}
      <Link href="mailto:analytics@icer.org">contact us</Link>
      {" "}for more information.
    </Typography>
  </Alert>
);

export default DataAccessAlert;
