import React from "react";
import { Typography } from "@mui/material";
import { useTheme } from "@mui/styles";

export const ReportSnapshotHeading = ({ text }) => {
  const { palette } = useTheme();
  return (
    <Typography
      variant="h3"
      component="h3"
      style={{ marginBottom: "15px", color: palette.secondary.main }}
    >
      {text}
    </Typography>
  );
};
