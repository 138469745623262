import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { Box, Breadcrumbs, Container, Typography } from "@mui/material";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import { usePageView } from "../../hooks/usePageView";
import googleAnalytics from "../../google-analytics";

export const appPageTitleStyle = (breakpoints) => ({
  fontSize: 28,
  marginBottom: 5,
  fontWeight: 700,
  [breakpoints.up("md")]: {
    fontSize: 36
  }
});

const useStyles = makeStyles(({ palette, breakpoints, spacing }) => {
  const defaultBgColor = palette.secondary.main;
  const bannerHeights = {
    md: 100,
    lg: 150,
    xl: 240
  };
  const baseBannerStyle = {
    backgroundColor: defaultBgColor,
    display: "flex",
    color: palette.text.light
  };
  const bannerStyle = {
    flexDirection: "column",
    minHeight: bannerHeights["md"],
    justifyContent: "center",
    ...baseBannerStyle,
    alignItems: "center",
    [breakpoints.up("md")]: {
      minHeight: bannerHeights["lg"],
      "& h2": {
        fontSize: 45
      }
    }
  };
  const heightMediaQuery = "@media (max-height: 900px)";
  return {
    appPageMain: {
      position: "relative",
      minWidth: "100%",
      overflow: "auto"
    },
    appPageContainer: {
      flex: "1 1 100%",
      minWidth: "100%",
      marginBottom: 75,
      paddingBottom: spacing(4)
    },
    appPageBanner: bannerStyle,
    appPageBannerXl: {
      ...bannerStyle,
      minHeight: bannerHeights["lg"],
      justifyContent: "flex-start",
      paddingTop: spacing(4.5),
      [breakpoints.up("md")]: {
        minHeight: bannerHeights["xl"]
      },
      [heightMediaQuery]: {
        minHeight: 150,
        paddingTop: spacing(2.5)
      }
    },
    appPageTitle: appPageTitleStyle(breakpoints),
    appPageHeroTitle: {
      ...appPageTitleStyle(breakpoints),
      textAlign: "center"
    },
    appPageBreadcrumbs: {
      color: "inherit"
    },
    appPageBreadcrumbsLink: {
      color: palette.text.light,
      display: "flex",
      alignItems: "center"
    }
  };
});

const AppPage = ({ title = "", docTitle = "", bannerHeight = "", banner = null, children }) => {
  // Update document title and trigger page view event
  usePageView(docTitle || title);

  const classes = useStyles();
  const location = useLocation();
  let bannerClass = "appPageBanner";
  if (bannerHeight) {
    bannerClass += bannerHeight;
  }
  return (
    <main className={classes.appPageMain} id="scroll-container">
      <Box component="section" className={classes.appPageContainer} id="main-content">
        {banner}
        {title && (
          <Box className={classes[bannerClass]} component="section">
            <Container>
              {location.pathname.includes("/tools") ? (
                <Box px={4}>
                  <Typography variant="h2" component="h1" className={classes.appPageHeroTitle}>
                    {title}
                  </Typography>
                </Box>
              ) : (
                <Box px={0}>
                  <Breadcrumbs aria-label="breadcrumb" className={classes.appPageBreadcrumbs}>
                    <Link
                      to="/"
                      className={classes.appPageBreadcrumbsLink}
                      onClick={() =>
                        googleAnalytics.trackBreadcrumbClick({
                          whereTo: "Home",
                          currentPage: location.pathname
                        })
                      }
                    >
                      <HomeRoundedIcon fontSize="small" style={{ marginRight: "0.3em" }} />
                      Home
                    </Link>
                    <Typography className={classes.appPageBreadcrumbsLink}>{title}</Typography>
                  </Breadcrumbs>
                  <Typography variant="h2" component="h1" className={classes.appPageTitle}>
                    {title}
                  </Typography>
                </Box>
              )}
            </Container>
          </Box>
        )}
        {children}
      </Box>
    </main>
  );
};

export default AppPage;
