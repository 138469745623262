import React from "react";
import { node, string } from "prop-types";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";

import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import OutboundLink from "./OutboundLink";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiDialogActions-root": {
      justifyContent: "space-between",
      padding: "8px 16px 8px 16px",
      "& .MuiDialogTitle-root": {
        padding: 0
      }
    },
    "& .MuiDialogContent-root": {
      margin: "0 0 8px 0", // only on bottom
      padding: "0 16px 8px 16px"
    }
  }
}));

const Modal = ({ i18nKey, icon, children }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [modalOpen, setOpen] = React.useState(false);

  const handleModalOpen = () => {
    setOpen(true);
  };

  const handleModalClose = () => {
    setOpen(false);
  };

  const modalId = `${i18nKey}-modal`;
  const title = t(`${i18nKey}.title`);
  const body = t(`${i18nKey}.body`);
  const tooltip = t(`${i18nKey}.tooltip`);

  const modalGraphicOrText = icon ? (
    <IconButton
      aria-controls={modalOpen ? modalId : undefined}
      onClick={handleModalOpen}
      aria-label={title}
      aria-haspopup="true"
    >
      {icon}
    </IconButton>
  ) : (
    <a
      role="button"
      href="#"
      onClick={handleModalOpen}
      aria-label={`Open ${title}`}
      aria-controls={modalOpen ? modalId : undefined}
      aria-haspopup="true"
    >
      {title}
    </a>
  );

  const modalLauncher = !tooltip ? (
    modalGraphicOrText
  ) : (
    <Tooltip title={tooltip}>{modalGraphicOrText}</Tooltip>
  );

  return (
    <>
      {modalLauncher}
      <Dialog
        id={modalId}
        fullWidth
        maxWidth="md" // 'lg' 'md' 'sm' 'xl' 'xs' false
        disableEscapeKeyDown={false}
        open={modalOpen}
        onClose={handleModalClose}
        className={classes.root}
      >
        <DialogActions>
          <DialogTitle>{title}</DialogTitle>
          <Button variant="text" onClick={handleModalClose}>
            Close
          </Button>
        </DialogActions>
        <DialogContent>
          <ReactMarkdown skipHtml={true} components={{ a: OutboundLink }}>
            {body}
          </ReactMarkdown>
          {children}
        </DialogContent>
      </Dialog>
    </>
  );
};

Modal.propTypes = {
  i18nKey: string.isRequired,
  icon: node,
  children: node
};

Modal.defaultProps = {
  icon: null,
  children: null
};

export default Modal;
