import React from 'react';
import {
  oneOf, shape, func, string
} from 'prop-types';
import { connectToState } from '../redux/connect-api';
import withStyles from '@mui/styles/withStyles';
import { AppBar, Tab, Tabs } from '@mui/material';

import Color from 'color';
import AddToCompareCountIndicator from './AddToCompareCountIndicator';
import AppBreadcrumbs from './AppBreadcrumbs'
import Search from './Search'
import { tabKeys, tabConfig } from '../config';
import analytics from '../google-analytics';
import {
  activeDetailName
} from '../selectors';

function a11yProps(tabKey) {
  return {
    id: `tab-${tabKey}`,
    'aria-controls': `tabpanel-${tabKey}`
  };
}

const styles = ({ palette, spacing, breakpoints }) => {
  const defaultBgColor = palette.secondary.main;
  const defaultSelectedBgColor = 'white';
  const defaultMinWidth = {
    md: 100
  };
  const getTextColor = (color) => {
    if (Color(color).isLight()) return palette.text.primary;
    return palette.common.white;
  };
  return {
    appBar: {
      flexDirection: 'row',
      background: palette.secondary.main,
      marginBottom: 0,
      boxShadow: 'none',
      paddingTop: spacing(1),
      paddingLeft: spacing(4),
      paddingRight: spacing(4),
      alignItems: 'flex-end',
    },
    searchInput: {
      marginLeft: 'auto',
      marginTop: spacing(1),
      marginBottom: spacing(1),
    },
    indicator: {
      display: 'none'
    },
    tabsRoot: {
      marginBottom: 0,
      minHeight: '24px',
      alignItems: 'flex-end',
    },
    tabRoot: ({ bgColor = defaultBgColor, minWidth = defaultMinWidth }) => ({
      opacity: 1,
      overflow: 'initial',
      padding: '0px',
      minHeight: '24px',
      paddingLeft: spacing(2),
      paddingRight: spacing(2),
      paddingBottom: spacing(1),
      paddingTop: spacing(1),
      borderTopLeftRadius: spacing(1),
      borderTopRightRadius: spacing(1),
      color: 'white',
      border: '1px solid rgba(255,255,255,.4)',
      fontSize: '16px',
      // backgroundColor: "rgba(0,0,0,.05)",
      borderBottom: 'none',
      transition: '0.2s',
      marginRight: 3,
      zIndex: 2,
      marginTop: spacing(0.5),
      textTransform: 'initial',
      [breakpoints.up('md')]: {
        minWidth: minWidth.md
      },
      '&:before': {
        transition: '0.2s'
      },
      '&:not(:first-of-type)': { // separator bar
        '&:before': {
          content: '" "',
          position: 'absolute',
          left: 0,
          display: 'block',
          height: 20,
          width: 1,
          zIndex: 1,
          marginTop: spacing(0.5),
          backgroundColor: palette.primary.light
        }
      },
      '&.Mui-selected:before': {
        opacity: 0
      },
      '&:focus': {
        backgroundColor: 'rgba(255,255,255,.8)',
      },
      '&:hover,&:focus': {
        '&:not(.Mui-selected)': {
          backgroundColor: 'rgba(255,255,255,.7)',
          color: 'black',
        },
        '&::before': {
          opacity: 0
        },
        '&.MuiTab-root:before': {
          opacity: 0
        }
      },
      '&.Mui-selected': {
        color: 'red',
        backgroundColor: defaultSelectedBgColor,
        color: getTextColor(defaultSelectedBgColor),
        fontWeight: 800,
        '&.MuiTab-root': {
          zIndex: 1
        },
        '&.MuiTab-root:before': {
          opacity: 0
        }
      }
    }),
  };
};


const TabLabel = ({ tabKey, activeTabKey }) => {
  if (tabKey === 'compare-your-own-pricing') {
    return (
      <AddToCompareCountIndicator
        label={tabConfig[tabKey]}
        active={activeTabKey === 'compare-your-own-pricing'}
      />
    );
  }
  return tabConfig[tabKey];
};

class TabBar extends React.PureComponent {
  render() {
    const {
      classes={},
      history,
      match: { params: { activeTabKey = tabKeys[0], detailId = null } },
      location: { search = '' },
      detailName,
    } = this.props;

    const handleChange = (event, tabKey) => {
      analytics.trackTabNavigation(tabKey);
      history.push({ pathname: `/${tabKey}`, search });
    };

    return (
      <AppBar
        id="tab-bar"
        position="static"
        // color="transparent" // debugging
        className={classes.appBar}
        component="div"
        role="navigation"
        aria-label="Evidence Compendium Navigation"
      >
        <div className={classes.leftNav} >
          <AppBreadcrumbs
            location={location}
            activeTabKey={activeTabKey}
            detailName={detailName}
          />
          <Tabs
            value={activeTabKey}
            onChange={handleChange}
            aria-label="Table tabs"
            classes={{root: classes.tabsRoot, indicator: classes.indicator}}
          >
            {tabKeys.map((tabKey, i) => (
              <Tab
                key={tabKey}
                value={tabKey}
                classes={{root: classes.tabRoot, selected: classes.selected }}
                tabIndex={0}
                label={(
                  <TabLabel
                    tabKey={tabKey}
                    activeTabKey={activeTabKey}
                  />
                )}
                {...a11yProps(tabKey)}
              />
            ))}
          </Tabs>
        </div>
        <div className={classes.searchInput} >
          <Search />
        </div>
      </AppBar>
    );
  }
}

TabBar.propTypes = {
  location: shape({
    pathname: string,
    search: string
  }),
  match: shape({
    params: shape({
      activeTabKey: oneOf(tabKeys),
      detailId: string,
    })
  }),
  history: shape({
    push: func
  }),
  classes: shape({}).isRequired,
};


const mapStateToProps = (state, ownProps) => {
  return {
    detailName: activeDetailName(state, ownProps),
  }
};

export default withStyles(styles)(connectToState(mapStateToProps)(TabBar));
