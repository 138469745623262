import React, { useState } from 'react';
import { arrayOf, func, string, bool } from 'prop-types';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
} from '@mui/material/';

import { makeStyles } from '@mui/styles';
import ComparisonChartContent from './ComparisonChartContent';
import analytics from '../../google-analytics';

const useStyles = makeStyles(({spacing}) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  dialogPaper: { },
  dialogActions: {
    padding: spacing(3),
    paddingBottom: 0,
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  dialogTitle: {
    margin: 0,
    marginBottom: spacing(2),
    padding: 0
  },
  dialogContent: {
    padding: spacing(2),
    paddingTop: 0,
    paddingBottom: spacing(4),
    margin: 0
  },
  dialogContentPaper: {
    padding: 0,
  },
  formControl: {
    marginRight: spacing(2),
    minWidth: 120
  },
  selectEmpty: {
    margin: 0,
    padding: 0
  },
}));


const ComparisonChartModal = ({ rowIds, modalOpen, onClose }) => {
  const classes = useStyles();
  const [selectedChartOptions, setValues] = useState({
    perspective: 'health_system', // health_system, societal -- Same order as GridFilter
    measure: 'qaly' // evlyg, qaly -- Same order as GridFilter
  });

  const handleOptionChange = (event) => {
    const { name, value } = event.target;
    setValues((oldValues) => ({
      ...oldValues,
      [name]: value
    }));
    analytics.trackChart('change-option', { [name]: value, rowIds });
  };

  const { perspective, measure } = selectedChartOptions;
  const comparisonKeys = [
    `${perspective}_vbpb_100k_per_${measure}`,
    `${perspective}_vbpb_150k_per_${measure}`
  ];

  const chartHeight = 500;

  const Form = () => (
    <Box display='flex' alignItems='center' mb={2}>
      <FormControl
        // variant="outlined"
        className={classes.formControl}
      >
        <InputLabel htmlFor="select-perspective">
          Perspective
        </InputLabel>
        <Select
          value={selectedChartOptions.perspective}
          className={classes.selectChartOption}
          onChange={handleOptionChange}
          // labelWidth={labelWidth}
          inputProps={{
            name: 'perspective',
            id: 'select-perspective'
          }}
        >
          <MenuItem value="health_system">Health System</MenuItem>
          <MenuItem value="societal">Societal</MenuItem>
        </Select>
      </FormControl>
      <FormControl
        // variant="outlined"
        className={classes.formControl}
      >
        <InputLabel htmlFor="select-measure">
          Measure
        </InputLabel>
        <Select
          value={selectedChartOptions.measure}
          className={classes.selectChartOption}
          onChange={handleOptionChange}
          // labelWidth={labelWidth}
          inputProps={{
            name: 'measure',
            id: 'select-measure'
          }}
        >
          <MenuItem value="evlyg">per evLYG</MenuItem>
          <MenuItem value="qaly">per QALY</MenuItem>
        </Select>
      </FormControl>
    </Box>
  )

  return (
    <Dialog
      id="comparison-chart-modal"
      fullWidth
      maxWidth="lg" // 'lg' 'md' 'sm' 'xl' 'xs' false
      disableEscapeKeyDown={false}
      open={modalOpen}
      onClose={onClose}
      classes={{paper: classes.dialogPaper}}
    >
      <DialogActions
        className={classes.dialogActions}
      >
        <Box>
          <DialogTitle className={classes.dialogTitle}>
            Compare My Price to ICER Prices
          </DialogTitle>
          <Form />
        </Box>
        <Button
          onClick={onClose}
        >
          Close
        </Button>
      </DialogActions>
      <DialogContent
        className={classes.dialogContent}
      >
        <Paper
          className={classes.dialogContentPaper}
          elevation={0}
        >
          <ComparisonChartContent
            comparisonKeys={comparisonKeys}
            rowIds={rowIds}
            height={chartHeight}
            selectedChartOptions={selectedChartOptions}
          />
        </Paper>
      </DialogContent>
    </Dialog>
  );
};

ComparisonChartModal.defaultProps = {
};

ComparisonChartModal.propTypes = {
  // match: matchType.isRequired,
  rowIds: arrayOf(string).isRequired,
  modalOpen: bool.isRequired,
  onClose: func.isRequired
};

export default ComparisonChartModal;
