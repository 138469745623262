import React, { useEffect } from "react";
import { bool, array } from "prop-types";
import { Box, Grid, Paper, Typography } from "@mui/material";
import ProgressIndicator from "../ProgressIndicator";
import AppPage from ".";

import { connectToState } from "../../redux/connect-api";
import CurrentVideo from "../CurrentVideo";
import VideoGrid from "../VideoGrid";
import LandingTitle from "../landing/LandingTitle";
import AppPageContentWrapper from "./AppPageContentWrapper";
import { APP_TITLE } from "../../constants";
import { usePageView } from "../../hooks/usePageView";
import useUserHasAccess from "../../hooks/useUserHasAccess";
import AccessFlag from "../AccessFlag"

const Webinars = ({ mediaResources, loading }) => {
  const userHasAccess = useUserHasAccess()
  const webinars = mediaResources
    .filter((r) => r.attributes.resource_type === "webinar")

  if (loading) {
    return (
      <Paper id="" elevation={0}>
        <ProgressIndicator active={loading} />
      </Paper>
    );
  }

  if (!userHasAccess) {
    return <AccessFlag toolName="Webinars" />
  }

  if (!mediaResources.length) {
    return (
      <AppPage title="Webinars">
        <Box
          my={4}
          display="flex"
          justifyContent="center"
          alignContent="center"
          flexDirection="column"
          component="section"
        >
          <Typography variant="h3" component="h2" align="center" style={{ marginBottom: 10 }}>
            We'll be posting the first webinar soon.
          </Typography>
          <Typography align="center">
            Check out our{" "}
            <a
              href="https://www.youtube.com/channel/UCEIQ-x96XBnHGcrfJ-oMBgQ/videos"
              target="_blank"
            >
              Youtube channel
            </a>{" "}
            in the meantime.
          </Typography>
        </Box>
      </AppPage>
    );
  }

  const currentWebinar = webinars[0].attributes;
  let archivedWebinars = [...webinars];
  archivedWebinars.shift();
  archivedWebinars = archivedWebinars.map((v) => v.attributes);

  // TODO: Remove Header component?
  return (
    <AppPage title="Webinars">
      <AppPageContentWrapper>
        <Grid container>
          <Grid item sm={12} style={{ marginBottom: 50 }}>
            {currentWebinar && (
              <Box mb={4}>
                <CurrentVideo video={currentWebinar} borderRadius={4} hasLeftBorder={true} />
              </Box>
            )}
          </Grid>
          {archivedWebinars.length > 0 && (
            <Grid item sm={12} style={{ marginBottom: 60 }}>
              <VideoGrid
                Header={() => (
                  <Box mb={4}>
                    <LandingTitle>Archive:</LandingTitle>
                  </Box>
                )}
                videos={archivedWebinars}
              />
            </Grid>
          )}
        </Grid>
      </AppPageContentWrapper>
    </AppPage>
  );
};

Webinars.defaultProps = {
  loading: true,
  mediaResources: []
};

Webinars.propTypes = {
  loading: bool,
  mediaResources: array
};

const mapStateToProps = (state) => {
  return {
    loading: state.userProfile.loading || state.mediaResources.loading,
    mediaResources: state.mediaResources.data
  };
};

export default connectToState(mapStateToProps)(Webinars);
