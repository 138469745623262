import React from "react";
import { oneOfType, oneOf, arrayOf, shape, node, func, number, string } from "prop-types";
import TableCell from "@mui/material/TableCell";
import TableSortLabel from "@mui/material/TableSortLabel";
import withStyles from "@mui/styles/withStyles";
import CustomTooltip from "../CustomTooltip";

import { connectToState } from "../../redux/connect-api";
import analytics from "../../google-analytics";

import { SORTABLE_COLUMNS } from "../../config";

const TOOLTIP_FIELDS = ["date_of_review"];

const styles = () => ({
  tableCell: {
    "&.remove_from_compare": {
      // color: '#c00'
    }
  },
  tableSortLabel: {
    '&:focus': {
      '& .MuiTableSortLabel-icon': {
        opacity: 1
      }
    }
  }
});

class AnalysesTableHeaderCell extends React.PureComponent {
  handleSortClick = () => {
    const {
      fieldKey,
      actions: { setSort }
    } = this.props;
    const { sort: { sortField, sortDirection = "asc" } = {} } = this.props;
    // unless existing sortField is the same as fieldKey, sort by ascending:
    const nextSortDirection = fieldKey === sortField && sortDirection === "asc" ? "desc" : "asc";
    setSort(fieldKey, nextSortDirection, SORTABLE_COLUMNS[fieldKey] || "string");
    analytics.trackSort(fieldKey, nextSortDirection);
  };

  renderLabel = () => {
    const { fieldKey, children, tooltip } = this.props;
    const isSortable = fieldKey && Object.keys(SORTABLE_COLUMNS).includes(fieldKey);
    let content = children;
    if (TOOLTIP_FIELDS.includes(fieldKey)) {
      content = <CustomTooltip id={fieldKey} content={children} title={tooltip} />;
    }
    if (isSortable) {
      const { sort: { sortField, sortDirection = "asc" } = {} } = this.props;
      const active = fieldKey === sortField;
      const thisTabSortDirection = active ? sortDirection : "asc";
      // <small>({thisTabSortDirection})</small>
      return (
        <TableSortLabel
          active={active}
          direction={thisTabSortDirection}
          onClick={this.handleSortClick}
          className={this.props.classes.tableSortLabel}
        >
          {content}
        </TableSortLabel>
      );
    }
    return content;
  };

  render() {
    const { rowSpan, colSpan, classes, className, align } = this.props;

    return (
      <TableCell // eslint-disable-line jsx-a11y/mouse-events-have-key-events
        rowSpan={rowSpan}
        colSpan={colSpan}
        onClick={this.handleMouseClick}
        className={`${classes.tableCell} ${className}`}
        component="th"
        align={align}
      >
        {this.renderLabel()}
      </TableCell>
    );
  }
}

AnalysesTableHeaderCell.defaultProps = {
  colSpan: 1,
  rowSpan: 1,
  fieldKey: null,
  children: null
};

AnalysesTableHeaderCell.propTypes = {
  colSpan: number,
  rowSpan: number,
  fieldKey: string,
  className: string.isRequired,
  align: string.isRequired,
  sort: shape({
    sortField: oneOf(Object.keys(SORTABLE_COLUMNS)),
    sortDirection: oneOf(["asc", "desc"])
  }),
  classes: shape({}).isRequired,
  actions: shape({
    setSort: func.isRequired
  }).isRequired,
  children: oneOfType([arrayOf(node), node])
};

const mapStateToProps = (state) => ({
  sort: state.sort
});

export default withStyles(styles)(connectToState(mapStateToProps)(AnalysesTableHeaderCell));
