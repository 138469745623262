import React from "react";
import { Box, Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import VideoPlayer from "./details/VideoPlayer";

const VideoDialog = ({ modalOpen, toggleModal, video }) => (
  <Dialog
    fullWidth
    maxWidth="md"
    disableEscapeKeyDown={false}
    open={modalOpen}
    onClose={toggleModal}
  >
    <DialogActions classes={{}}>
      <Button variant="text" onClick={toggleModal} startIcon={<CloseIcon />}>
        Close
      </Button>
    </DialogActions>
    <DialogContent>
      {video && (video.id || video.youtube_id) && (
        <Box style={{ minHeight: "550px" }}>
          <VideoPlayer video={video} isFeatured={true} />
        </Box>
      )}
    </DialogContent>
  </Dialog>
);

export default VideoDialog;
