import React from 'react';
import {
  oneOf, shape, string, func, bool
} from 'prop-types';
import withStyles from '@mui/styles/withStyles';

import { allFieldKeys } from '../../config';
import { connectToState } from '../../redux/connect-api';
import { userEnteredValuesByAnalysisIdSelector } from '../../selectors';
import analytics from '../../google-analytics';
import debounce from 'lodash/debounce';

export const PRICE_INPUT_BLUR_EVENT = 'price-input-blur';

const styles = () => ({
  priceInput: {
    '&.user_proposed_price': {
      textAlign: 'right'
    },
    '&:invalid': {
      color: 'red'
    },
    '&:disabled': {
      userSelect: 'none',
      color: '#aaa',
      backgroundColor: '#fff',
      border: '1px solid #eee'
    }
  }
});

// test whole string, and allow decimal
const matchNumericString = /^[0-9.]+$/ig;

class PriceInput extends React.PureComponent {
  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
    this.debounceTrackPrice = debounce(this.debouncePriceChange.bind(this), 650);
  }

  debouncePriceChange = (name, value) => {
    const { analysisId, actions: { setUserInput } } = this.props;
    setUserInput(analysisId, name, value);
    analytics.trackPrice(analysisId, value);
  }

  handlePriceChange = (e) => {
    const { name, value } = e.target;
    this.debounceTrackPrice(name, value);
  }

  getUserEnteredValueByKey = (analysisId, fieldKey) => {
    const { userEnteredValuesForRow } = this.props;
    const { [fieldKey]: userEnteredValue } = userEnteredValuesForRow;
    if (!userEnteredValue) {
      return null;
    }
    return userEnteredValue;
  }

  getPrice = () => {
    const { analysisId, fieldKey } = this.props;
    const price = this.getUserEnteredValueByKey(analysisId, fieldKey);
    if (price) {
      return parseInt(price, 10);
    }
    return null;
  }

  cancelEvent = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
  };

  testAndCancelEvent = (e) => {
    // Prevent non-numeric input from being pasted, or via "undo" :
    const {
      type, key, data, metaKey, clipboardData
    } = e.nativeEvent;

    let testValue;
    if (type === 'keydown') {
      if (key !== 'Meta' && !metaKey) {
        // if meta key, like "<CMD> v (paste)", then event is not canceled
        if (key.length === 1) {
          testValue = key;
        }
      }
    } else if (type === 'input') {
      testValue = data; // 'input' detected in lieu of 'paste'
    } else if (type === 'paste') {
      testValue = clipboardData.getData('Text');
    }

    if (testValue !== undefined) { // testValue is undefined for some valid meta keys (undo,redo)
      // if keyCode exists, regular numbers, or 0-9 from a numeric keypad:
      // (e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 96 && e.keyCode <= 105);
      if (!matchNumericString.test(`${testValue}`)) {
        this.cancelEvent(e);
      }
    }
  };

  handleInputEvent = (e) => {
    // e.persist(); // FOR DEBUGGING, see: https://reactjs.org/docs/events.html
    switch (e.keyCode) {
      case 8: // backspace
      case 46: // delete
      // always allow backspace or delete:
        return;
      default:
      // disallow non-numeric input, including copy/paste, and undo/redo:
        this.testAndCancelEvent(e, 'handleInputEvent');
    }
  };

  handleClick = () => {
    if (this.inputRef.current) {
      this.inputRef.current.focus();
    }
  }

  handleBlur = () => {
    const inputDomNode = this.inputRef.current;
    const priceInputBlurEvent = new CustomEvent(PRICE_INPUT_BLUR_EVENT, {
      detail: {
        target: inputDomNode
      },
      bubbles: true
    });
    inputDomNode.dispatchEvent(priceInputBlurEvent);
  }

  render() {
    const { disabled, fieldKey, classes } = this.props;

    return (
      <input
        disabled={disabled}
        name={fieldKey}
        placeholder="Enter Price"
        maxLength="7"
        size="9"
        pattern="[0-9]{2,7}"
        autoComplete="off"
        className={`${fieldKey} ${classes.priceInput}`}
        ref={this.inputRef}
        onChange={this.handlePriceChange}
        defaultValue={this.getPrice()}
        // onKeyDownCapture={this.handleInputEvent}
        onKeyDown={this.handleInputEvent}
        // onPasteCapture={this.handleInputEvent}
        onPaste={this.handleInputEvent}
        // onInputCapture={this.handleInputEvent}
        onInput={this.handleInputEvent}
        onBlur={this.handleBlur}
      />
    );
  }
}

PriceInput.defaultProps = {
  fieldKey: 'user_proposed_price'
};

PriceInput.propTypes = {
  disabled: bool.isRequired,
  analysisId: string.isRequired,
  fieldKey: oneOf(allFieldKeys),
  userEnteredValuesForRow: shape({}).isRequired,
  classes: shape({}).isRequired,
  actions: shape({
    // getUserInputs: func.isRequired,
    setUserInput: func.isRequired
  }).isRequired
};

const mapStateToProps = (state, ownProps) => ({
  userEnteredValuesForRow: userEnteredValuesByAnalysisIdSelector(state, ownProps)
});
export default withStyles(styles)(connectToState(mapStateToProps)(PriceInput));
