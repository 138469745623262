import React from "react";
import { NavLink } from "react-router-dom";
import { Breadcrumbs, Typography } from "@mui/material";
import { useBreadcrumbStyles } from "../config/styles";
import { tabConfig } from "../config";
import googleAnalytics from "../google-analytics";

const AppBreadcrumbs = ({ location, detailName = "", activeTabKey }) => {
  const breadcrumbClasses = useBreadcrumbStyles();
  const trackClick = (whereTo) => {
    googleAnalytics.trackBreadcrumbClick({
      whereTo,
      currentPage: location.pathname
    });
  };
  return (
    <Breadcrumbs
      aria-label="breadcrumb"
      separator="/"
      classes={{
        root: breadcrumbClasses.root,
        separator: breadcrumbClasses.separator
      }}
    >
      <NavLink
        onClick={() => trackClick("/drugs")}
        activeStyle={{ fontWeight: "bold" }}
        className={breadcrumbClasses.breadcrumb}
        to={{
          pathname: `/drugs`,
          search: location.search
        }}
      >
        <Typography component="h1" variant="body2" className={breadcrumbClasses.typography}>
          Evidence Compendium
        </Typography>
      </NavLink>
      <NavLink
        onClick={() => trackClick(`/${activeTabKey}`)}
        activeStyle={{ fontWeight: detailName ? "normal" : "bold" }}
        className={breadcrumbClasses.breadcrumb}
        to={{
          pathname: `/${activeTabKey}`,
          search: location.search
        }}
      >
        <Typography component="h2" variant="body2" className={breadcrumbClasses.typography}>
          {tabConfig[activeTabKey]}
        </Typography>
      </NavLink>
      {detailName && (
        <Typography style={{ fontWeight: "bold" }} className={breadcrumbClasses.breadcrumb}>
          {detailName}
        </Typography>
      )}
    </Breadcrumbs>
  );
};

export default AppBreadcrumbs;
