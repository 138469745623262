import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import AppPage from ".";
import { APP_TITLE } from "../../constants";
import { usePageView } from "../../hooks/usePageView";
import RecentUpdates from "../recent_updates/RecentUpdates";
import AppPageContentWrapper from "./AppPageContentWrapper";
import useUserHasAccess from "../../hooks/useUserHasAccess";
import AccessFlag from "../AccessFlag";
import ProgressIndicator from "../ProgressIndicator";

const Updates = () => {
  const loadingUser = useSelector((state) => state.userProfile.loading);
  const userHasAccess = useUserHasAccess();

  if (loadingUser) {
    return (
      <ProgressIndicator active />
    )
  }

  if (!userHasAccess) {
    return <AccessFlag toolName="Updates" />;
  }

  return (
    <AppPage title="Monthly Updates">
      <AppPageContentWrapper>
        <RecentUpdates limit={0} />
      </AppPageContentWrapper>
    </AppPage>
  );
};

export default Updates;
