import React from "react";
import { Link, Typography } from "@mui/material";
import { Alert } from "@mui/material";
import TipsAndUpdatesRoundedIcon from "../../icons/TipsAndUpdatesRoundedIcon";
import { connectToState } from "../../../redux/connect-api";
import { comingSoonMostRecentItemSelector } from "../../../selectors";
import googleAnalytics from "../../../google-analytics";
import { formateDate } from "../../../utils/date";
import { Link as RouterLink } from "react-router-dom";

const renderMostRecentItem = (comingSoonMostRecentItem) => {
  let resourceType, formatedDate, title;
  if (comingSoonMostRecentItem?.type === "asset_group") {
    const { specific_conditions, date_of_review } = comingSoonMostRecentItem?.attributes;
    title = specific_conditions[0] || "";
    resourceType = "Assessment";
    formatedDate = formateDate(date_of_review, { format: "m d, Y" });
  } else {
    const { resource_type, display_date } = comingSoonMostRecentItem?.attributes;
    title = comingSoonMostRecentItem?.attributes?.title || "";
    resourceType = resource_type.charAt(0).toUpperCase() + resource_type.slice(1);
    formatedDate = formateDate(display_date, { format: "m d, Y" });
  }
  return (
    <>
      <strong>{resourceType} coming soon</strong> - {title} on {formatedDate}
    </>
  );
};

const ComingSoonBanner = ({ loading, comingSoonMostRecentItem }) => {
  if (loading || !comingSoonMostRecentItem) return null;
  return (
    <Alert severity="info" icon={<TipsAndUpdatesRoundedIcon />} style={{ paddingLeft: 25 }}>
      <Typography variant="h4" component="span" style={{ display: "inline-block", marginRight: 8 }}>
        {comingSoonMostRecentItem && renderMostRecentItem(comingSoonMostRecentItem)}
        {!comingSoonMostRecentItem && "New assessments coming soon."}
      </Typography>
      <Link
        variant="h5"
        component={RouterLink}
        to="/coming-soon"
        onClick={() =>
          googleAnalytics.trackCustomEvent({
            eventCategory: "Landing Page",
            eventAction: "Alert link click",
            eventLabel: "Coming soon"
          })
        }
      >
        {comingSoonMostRecentItem && "More info."}
        {!comingSoonMostRecentItem && "Click to review."}
      </Link>
    </Alert>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.comingSoon.loading,
    comingSoonMostRecentItem: comingSoonMostRecentItemSelector(state)
  };
};

export default connectToState(mapStateToProps)(ComingSoonBanner);
