import React from 'react'
import {
  Button,
  Container,
  Grid,
  Paper,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

const styles = makeStyles(({spacing}) => ({
  accessWarningContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: spacing(4),
  },
  accessWarningCard: {
    padding: 20,
    textAlign: 'center',
  },
}))

const NoAccess = () => {
  const classes = styles()
  return (
    <Container className={classes.accessWarningContainer}>
      <Grid item xs={6}>
        <Paper elevation={1} className={classes.accessWarningCard}>
          <p>Your organization currently does not have access to this tool</p>
          <Button href='mailto:analytics@icer.org?subject=Access Request: Evidence Compendium' target='_blank'>
            Request Access
          </Button>
        </Paper>
      </Grid>
    </Container>
  )
}

export default NoAccess
