import React from 'react'
import { node, string } from 'prop-types'
import { makeStyles } from '@mui/styles'
import {
  Grid,
  Typography,
} from '@mui/material'

const useStyles = makeStyles(({ breakpoints }) => ({
  wrapper: {
    marginBottom: '1.2rem',
    padding: '0 0.7rem',
  },
  item: {
    flexGrow: 1,
    minWidth: '300px',
    [breakpoints.up('sm')]: {
      maxWidth: '50%',
    },
    [breakpoints.up('md')]: {
      maxWidth: '33.3%',
    },
  },
  groupTitle: {
    fontSize: '1.4rem',
    marginBottom: '0.4rem',
  },
}))

const ButtonGroup = props => {
  const { children, groupTitle } = props
  const classes = useStyles()

  return (
    <div className={ classes.wrapper }>
      <Typography className={ classes.groupTitle }>
        { groupTitle }
      </Typography>
      <Grid container
        spacing={ 3 }
      >
        { React.Children.map(children, ( child, index ) => (
            <Grid key={ index } item className={ classes.item }>
              { child }
            </Grid>
          ))
        }
      </Grid>
    </div>
  )
}

ButtonGroup.defaultProps = {
  children: null,
  groupTitle: '',
}

ButtonGroup.propTypes = {
  children: node,
  groupTitle: string,
}

export default ButtonGroup
