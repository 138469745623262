import React from 'react';
import Alert from '@mui/material/Alert';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  root: {
    fontSize: 16,
  }
}))

const getSeverity = (type) => {
  let severity = type;
  if (type === 'notice') {
    severity = 'info';
  } else if (!['error', 'warning', 'info', 'success'].includes(severity)) {
    severity = 'warning';
  }

  return severity;
};


const LandingAlerts = () => {
  const domContainer = document.getElementById("icer-spa")
  const classes = useStyles()
  if (domContainer) {
    const alerts = JSON.parse(domContainer.dataset.alerts)
    if (alerts && alerts.length) {
      return (
        <>
          { alerts.map(([type, message], i) => <Alert key={i} severity={getSeverity(type)} classes={{root: classes.root}}> {message} </Alert>) }
        </>
      )
    } else {
      return null
    }
  } else {
    return null
  }
}

export default LandingAlerts;
