import React, { useEffect } from "react";
import { bool, array } from "prop-types";
import { Box, Container, Grid, Paper, Typography } from "@mui/material";
import ProgressIndicator from "../ProgressIndicator";

import AppPage from ".";
import VideoGrid from "../VideoGrid";
import LandingTitle from "../landing/LandingTitle";

import { connectToState } from "../../redux/connect-api";
import AppPageContentWrapper from "./AppPageContentWrapper";
import { APP_TITLE } from "../../constants";
import { usePageView } from "../../hooks/usePageView";
import useUserHasAccess from "../../hooks/useUserHasAccess";
import AccessFlag from "../AccessFlag"

const VideosPage = ({ mediaResources, loading }) => {
  const userHasAccess = useUserHasAccess()
  const videos = mediaResources
    .filter((r) => r.attributes.resource_type === "resource")
    .map((r) => r.attributes);

  if (loading) {
    return (
      <Paper id="" elevation={0}>
        <ProgressIndicator active={loading} />
      </Paper>
    );
  }

  if (!userHasAccess) {
    return <AccessFlag toolName="Videos" />
  }

  if (!videos.length) {
    return (
      <AppPage title="Videos">
        <AppPageContentWrapper>
          <Typography variant="h3" component="h2" align="center" style={{ marginBottom: 10 }}>
            There are currently no videos to display.
          </Typography>
          <Typography align="center">
            Check out our{" "}
            <a
              href="https://www.youtube.com/channel/UCEIQ-x96XBnHGcrfJ-oMBgQ/videos"
              target="_blank"
            >
              Youtube channel
            </a>{" "}
            in the meantime.
          </Typography>
        </AppPageContentWrapper>
      </AppPage>
    );
  }

  return (
    <AppPage title="Demo Videos">
      <AppPageContentWrapper>
        <Grid container>
          <Grid item sm={12} style={{ marginBottom: 60 }}>
            <VideoGrid
              Header={() => (
                <Box mb={4}>
                  <LandingTitle>
                    Watch our collection of short demo videos to explore the possibilities of ICER
                    Analytics:
                  </LandingTitle>
                </Box>
              )}
              videos={videos}
            />
          </Grid>
        </Grid>
      </AppPageContentWrapper>
    </AppPage>
  );
};

VideosPage.defaultProps = {
  loading: true,
  mediaResources: []
};

VideosPage.propTypes = {
  loading: bool,
  mediaResources: array
};

const mapStateToProps = (state) => {
  return {
    loading: state.userProfile.loading || state.mediaResources.loading,
    mediaResources: state.mediaResources.data
  };
};

export default connectToState(mapStateToProps)(VideosPage);
