import { Link as RouterLink, useLocation } from "react-router-dom";
import { bool } from "prop-types";
import React from "react";
import { AppBar, Link, Toolbar, Typography } from "@mui/material/";
import { makeStyles } from "@mui/styles";
import { headerLinkStyles } from "../config/styles";

import Search from "./Search";
import HelpIcon from "@mui/icons-material/ContactSupport";
import UserProfile from "./UserProfile";
import AnalyticsLogo from "../styles/icons/analytics-logo.svg";
import Modal from "./modals/Modal";
import VideoPlayer from "./details/VideoPlayer";
import ToolTabs from "./ToolTabs";
import LoginButton from "./LoginButton";
import ResourcesMenu from "./menus/ResourcesMenu";
import UpdatesMenu from "./menus/UpdatesMenu";
import SkipToContentLink from "./SkipToContentLink";
import googleAnalytics from "../google-analytics";

const useStyles = makeStyles(({ palette, breakpoints }) => ({
  appBar: {
    backgroundColor: palette.common.white,
    display: "flex",
    flexDirection: "row",
    paddingLeft: 15, // Aligned with IM tool's header margins
    borderBottom: `${palette.grey[200]} 1px solid`
  },
  appBarJustified: {
    justifyContent: "space-between"
  },
  headerSearch: {
    display: "none"
  },
  toolbar: {
    padding: 0,
    paddingLeft: 15,
    paddingRight: 15,
    flex: "1 1 auto",
    maxWidth: "100%",
    alignItems: "stretch"
  },
  navSecondary: {
    marginLeft: "auto",
    display: "flex",
    alignItems: "center"
  },
  logoLink: {
    display: "flex",
    flex: "0 1 100px",
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: 5,
    paddingRight: 5,
    minWidth: 100
  },
  logoImage: {
    maxHeight: 40.5
  },
  title: {
    flexGrow: 1,
    display: "none",
    [breakpoints.up("sm")]: {
      display: "block"
    }
  }
}));

/**
 * Right now, this launches the "Review Demo Modal", but eventually,
 *   this could be a menu like UserProfile, where one of the links launches the same modal.
 */
const Help = () => {
  const classes = headerLinkStyles();
  return (
    <Link
      href="mailto:analytics@icer.org"
      target="_blank"
      classes={{ root: classes.root }}
      style={{ borderLeft: "0.5px solid grey" }}
      onClick={() => googleAnalytics.trackHeaderNav({ action: "item", label: "Contact Us" })}
    >
      <HelpIcon />
      <Typography component="span">Contact Us</Typography>
    </Link>
  );
};

const LandingButtons = () => {
  return (
    <>
      <LoginButton />
    </>
  );
};

const Header = ({ isSignedIn, isDeviseView }) => {
  const classes = useStyles();
  const location = useLocation();

  const isToolSelectorPage = location.pathname.includes("tools");
  const isGlobalPage = Boolean(
    location.pathname.match(/^\/(tools|webinars|videos|coming-soon|documents|updates|soon|account|password)$/)
  );
  const isLandingPage = !isSignedIn && !isDeviseView;

  const userProfileIsEnabled = !isLandingPage && isSignedIn && !isDeviseView;
  const showToolButtons = userProfileIsEnabled && !isLandingPage && !isToolSelectorPage;

  // const debug = `isSignedIn:${isSignedIn}, isDeviseView:${isDeviseView}`;
  let appBarClasses = classes.appBar;
  if (isLandingPage || isToolSelectorPage) {
    appBarClasses += ` ${classes.appBarJustified}`;
  }

  return (
    <AppBar id="main-header" position="fixed" className={appBarClasses} elevation={0}>
      <RouterLink
        to="/"
        className={classes.logoLink}
        onClick={() => googleAnalytics.trackHeaderNav({ action: "item", label: "Logo (home)" })}
      >
        <img className={classes.logoImage} src={AnalyticsLogo} alt="ICER Analytics" />
      </RouterLink>
      <SkipToContentLink />
      <Toolbar className={classes.toolbar}>
        <ToolTabs classes showAlways={showToolButtons} showActiveTab={!isGlobalPage} />
        <div className={classes.navSecondary}>
          {isLandingPage && <LandingButtons />}
          <div className={classes.headerSearch}>{userProfileIsEnabled && <Search />}</div>
          {userProfileIsEnabled && <ResourcesMenu />}
          {userProfileIsEnabled && <UpdatesMenu />}
          {userProfileIsEnabled && <Help />}
          {userProfileIsEnabled && <UserProfile />}
        </div>
      </Toolbar>
    </AppBar>
  );
};

Header.propTypes = {
  isSignedIn: bool.isRequired,
  isDeviseView: bool.isRequired
};

Header.defaultProps = {};

export default Header;
