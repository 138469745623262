import React from 'react';

import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import Card from '@mui/material/Card';
import VideoPlayer from './details/VideoPlayer';

import ShareThumb from '../styles/thumbnails/share-thumb.svg';

const useStyles = makeStyles((theme) => ({
  demo: {
    // border: '9px #f0f solid',
    backgroundColor: '#F8F8F8',
    display: 'flex',
    // textAlign: 'center',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    '& > .scrollable': {
      maxWidth: 'calc(100vw - (2 * var(--table-gutter-width)))',
      maxHeight: 'calc(100vh - var(--main-header-height) - var(--footer-height))',
      overflow: 'auto',
      position: 'relative',
      paddingLeft: 'var(--table-gutter-width)',
      paddingRight: 'var(--table-gutter-width)'
    }

  },
  intro: {
    marginLeft: '15px',
    textAlign: 'left',
    flexDirection: 'column',
    fontSize: '20px',
    fontWeight: 'bold',
    '& > h6': {
      fontSize: '32px'
    }
  },
  watch: {
    fontSize: '24px',
    margin: 0
  },
  moreFeatures: {
    fontSize: '16px',
    margin: '5px',
    fontWeight: 'normal'
  },
  share: {
    // marginLeft: '250px',
    textAlign: 'left',
    display: 'flex'
    // flexDirection: 'column',
  },
  video: {

  },
  download: {
    width: '275px'
  },
  shareHead: {
    backgroundColor: theme.palette.primary.main,
    padding: '10px',
    '& > p': {
      fontSize: '20px',
      fontWeight: 'bold',
      color: theme.palette.common.white,
      textAlign: 'center',
      margin: '16px 25px 16px 25px'
    }
  },
  shareBody: {
    padding: '10px',
    textAlign: 'center',
    '& > p': {
      fontSize: '14px',
      fontWeight: 'normal',
      textAlign: 'center',
      padding: '0px 10px',
      margin: '0'
    },
    '& > a': {
      display: 'block',
      fontSize: '12px',
      padding: '5px 0px 5px 0px'
    }

  },
  downloadCard: {
    margin: '15px 0px 25px 15px'
  },
  quotes: {
    marginTop: '15px',
    textAlign: 'center',
    '& > h6': {
      fontSize: '32px'
    }
  }
}));


const Demo = () => {
  const classes = useStyles();

  return (
    <div className={classes.demo}>
      <div
        className="scrollable"
      >
        <div className={classes.intro}>
          <Typography
            variant="h6"
            color="primary"
          >
            Ready to see the Evidence Compendium?
          </Typography>
          <p className={classes.watch}>
            Watch the brief demo below
          </p>
          <p className={classes.moreFeatures}>
            More features coming soon. Stay tuned!
          </p>
        </div>
        <div className={classes.share}>
          <div
            className={classes.video}
          >
            <VideoPlayer
              video={{ id: '_bIAG1Xb0jI', title: '' }}
              width={640}
              height={360}
            />
          </div>
          <Card className={classes.downloadCard}>
            <div className={classes.download}>
              <div className={classes.shareHead}>
                <p>
                  Sharing this product with your team?
                </p>
              </div>
              <div className={classes.shareBody}>
                <p>Tell them all about it with this summary</p>
                <a href="/ICER Analytics_Two-Pager.pdf">DOWNLOAD</a>
                <img src={ShareThumb} alt="Demo PDF" />
              </div>
            </div>
          </Card>
        </div>
        {/*
        <div className={classes.quotes}>
          <Typography
            variant="h6"
            color="primary"
          >
            What people are saying
          </Typography>
        </div>
        */}
      </div>
    </div>
  );
};

export default Demo;
