// ScrollSpy component is in development
// https://github.com/mui-org/material-ui/issues/16359#issuecomment-617365879

import { useEffect, useMemo } from 'react';
import throttle from 'lodash/throttle';

const useThrottledOnScroll = (callback, {delay=166}) => {
  const throttledCallback = useMemo(
    () => (callback ? throttle(callback, delay) : noop),
    [callback, delay]
  );

  useEffect(() => {
    if (throttledCallback === noop) {
      return undefined;
    }

    const root = document.getElementById('scroll-container') || window
    root.addEventListener('scroll', throttledCallback);
    return () => {
      root.removeEventListener('scroll', throttledCallback);
      throttledCallback.cancel();
    };
  }, [throttledCallback]);
};

const noop = () => {};

export default useThrottledOnScroll;
