import React from "react";
import LandingIntro from "./LandingIntro";
import LandingTools from "./LandingTools";
import LandingLearnMore from "./LandingLearnMore";
import LandingAlerts from "./LandingAlerts";
import Box from "@mui/material/Box";
import Survey from "./Survey";
import ModalOpened from "../modals/ModalOpened";

import queryString from "query-string";
import { trackPageView } from "../../hooks/usePageView";

class Landing extends React.PureComponent {
  componentDidMount() {
    window.objectFitPolyfill();
    trackPageView("Landing page");
  }

  render() {
    const parsedParams = queryString.parse(window.location.search);
    const showModal = !!parsedParams.request_trial;
    const renderModal = () => <ModalOpened i18nKey="surveyTrial" Content={Survey} mx={false} />;
    return (
      <Box className="landing">
        {showModal ? renderModal() : null}
        <LandingAlerts />
        <LandingIntro />
        <LandingTools />
        <LandingLearnMore />
      </Box>
    );
  }
}

export default Landing;
