const useAccessibilityAudit = () => {
  const runTest = async () => {
    if (process.env.RAILS_ENV === 'test' || process.env.RUN_ACCESSIBILITY_AUDIT !== 'true') return

    const [{assertAccessible}, sa11yRules, axe] = await Promise.all([
      import('@sa11y/assert'),
      import('@sa11y/preset-rules'),
      import("axe-core")
    ])

    try {
      console.log("Running AXE accessibility audit")
      console.log("---------------------------------")
      const results = await axe.run()
      console.log("AXE accessibility results")
      if (results?.violations?.length) {
        console.error("AXE accessibility found violations")
        console.table(
          results.violations.map(({description, help, impact}) => ({page: location.pathname, description, help, impact}))
        )
      }
      console.log()
    } catch (error) {
      console.error("Error while running running AXE accessibility audit", error)
    }

    try {
      console.log("Running sa11y accessibility audit")
      console.log("---------------------------------")
      await assertAccessible(document, sa11yRules, null);
    } catch(error) {
      console.error("Error while running running sa11y accessibility audit", error)
    }
  }
  runTest()
};

export default useAccessibilityAudit
