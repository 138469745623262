import React from "react";
import { Box, Chip, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  inline: {
    display: "flex",
    alignItems: "baseline"
  },
  listWrapper: {
    display: "flex",
    flexWrap: "wrap",
    [breakpoints.down("md")]: {
      marginTop: spacing(1)
    }
  },
  chipTitle: {
    marginBottom: 5
  },
  chipTitleInline: {
    marginRight: 10,
    whiteSpace: "nowrap",
    fontWeight: 600
  },
  chip: {
    marginRight: 4,
    marginTop: 4,
    marginBottom: 4,
    [breakpoints.down("md")]: {
      background: "none",
      height: "auto",
      "&:not(:last-of-type) :after": {
        content: '","',
        display: "inline"
      },
      "& .MuiChip-label": {
        padding: 0
      }
    }
  },
  darkChip: {
    background: "#f5f5f5",
    [breakpoints.down("md")]: {
      background: "none",
      "&:not(:last-of-type) :after": {
        content: '","',
        display: "inline"
      }
    }
  }
}));

const DrugList = ({
  drugs = [],
  inline = false,
  darkChips = false,
  inlineText = "",
  showTitle = true
}) => {
  const classes = useStyles();
  let chipClass = classes.chip;
  if (darkChips) {
    chipClass += ` ${classes.darkChip}`;
  }
  if (inline) {
  }
  return (
    <Box className={inline ? classes.inline : ""}>
      {showTitle && (
        <Typography className={inline ? classes.chipTitleInline : classes.chipTitle}>
          {inlineText ? inlineText : "Drugs in report"}{" "}
        </Typography>
      )}
      <Box className={classes.listWrapper}>
        {drugs.map((d, i) => (
          <Chip label={d.trim()} key={`related-drug-${i}`} className={chipClass} />
        ))}
      </Box>
    </Box>
  );
};

export default DrugList;
