import React from 'react';
import { Paper } from '@mui/material';
import ProgressIndicator from './ProgressIndicator'

const WrappedProgressIndicator = ({loading, id="", elevation=0, minHeight=500}) => (
  <Paper id={id} elevation={elevation} style={{minHeight: `${minHeight}px`}} >
    <ProgressIndicator active={loading} />
  </Paper>
)

export default WrappedProgressIndicator
