import React from "react";
import ECLogo from "../../images/ec.svg";
import IMLogo from "../../images/im.svg";
import { isAfter } from "date-fns";

// Data (eg analyses performed) before this data is are considered legacy
// Important when making the determination to suppress data based on subscription status
export const LEGACY_DATA_DATE = new Date("12/31/20");
export const isDataSuppressibleForTrial = (date) => {
  let _date = date;
  if (typeof date === "string") {
    _date = new Date(date);
  } else if (!(date instanceof Date)) {
    return true;
  }
  return isAfter(_date, LEGACY_DATA_DATE);
};

// Number of days an item is considered new
export const newItemPeriod = 31;

export const surveyLinks = {
  trial: "https://www.surveymonkey.com/r/MNJQW5K",
  watchVideo: "https://www.surveymonkey.com/r/V7XQW2F"
};

export const platformConfig = {
  welcomeMessage: `
    ICER Analytics™ is a new cloud-based platform that facilitates the use of ICER’s
    evidence reports and underlying analyses by decision-makers as they develop medical policy,
    establish drug pricing, and negotiate drug reimbursement.
  `,
  tools: [
    {
      id: "ec",
      name: "Evidence Compendium",
      description: "Streamline the use of ICER analyses so you can make the best decisions",
      details: [
        "Enter your own pricing to compare to ICER price benchmarks",
        "View curated report material and videos from ICER appraisal committee meetings"
      ],
      to: "/drugs",
      image: ECLogo
    },
    {
      id: "im",
      name: "Interactive Modeler",
      description: "Share your assumptions based on new evidence using ICER's models",
      details: [
        "Analyze key aspects of individual economic models produced for ICER Evidence Reports",
        "Customize parameter inputs to the model based on your prices, data, or assumptions"
      ],
      href: window.env.IM_URL,
      image: IMLogo
    }
  ]
};

export const tooltipCopy = {
  wac: "WAC as of date of review or drug approval",
  dateOfReview: "Note: ICER’s research prior to 2017 is not included in the EC",
  map: (classes = {}, data = {}) => (
    <>
      <p className={classes.description}>
        Manufacturer's Alternative Price: this price reflects the manufacturer's alternative inputs
        to the ICER model. <br /> ICER does not vet these alternative inputs.
      </p>
      {data.url ? (
        <p className={classes.description}>
          <a href={data.url || ""} className={classes.link} target="_blank">
            Click here
          </a>{" "}
          to see a summary of all changes made to the model.
        </p>
      ) : null}
    </>
  )
};

export const costDescriptions = [
  "Cost Saving",                 // ratioField == -1
  "Less Costly, Less Effective", // ratioField == -2
  "More Costly, Less Effective"  // ratioField == -3
]

export const tabKeys = ["drugs", "compare-your-own-pricing", "icer-pricing", "report-snapshots"];

// use i18n for tab labels?
export const tabConfig = {
  drugs: "Drug List",
  "compare-your-own-pricing": "Compare Your Own Pricing",
  "icer-pricing": "ICER Pricing",
  "report-snapshots": "Report Snapshots"
};

// used to generate "compare" keys, configs, and values; determines left-to-right order in table:
export const icerBenchmarkPriceKeys = [
  "health_system_vbpb_50k_per_qaly",
  "health_system_vbpb_100k_per_qaly",
  "health_system_vbpb_150k_per_qaly",
  "health_system_vbpb_200k_per_qaly",
  "health_system_vbpb_50k_per_evlyg",
  "health_system_vbpb_100k_per_evlyg",
  "health_system_vbpb_150k_per_evlyg",
  "health_system_vbpb_200k_per_evlyg",
  "societal_vbpb_50k_per_qaly",
  "societal_vbpb_100k_per_qaly",
  "societal_vbpb_150k_per_qaly",
  "societal_vbpb_200k_per_qaly",
  "societal_vbpb_50k_per_evlyg",
  "societal_vbpb_100k_per_evlyg",
  "societal_vbpb_150k_per_evlyg",
  "societal_vbpb_200k_per_evlyg"
];

// Determines if there is a manufacturersPrice to show for the row.
export const manufacturersPricePriceKeys = [
  "manufacturer_health_system_vbpb_50k_per_qaly",
  "manufacturer_health_system_vbpb_100k_per_qaly",
  "manufacturer_health_system_vbpb_150k_per_qaly",
  "manufacturer_health_system_vbpb_200k_per_qaly",
  "manufacturer_health_system_vbpb_50k_per_evlyg",
  "manufacturer_health_system_vbpb_100k_per_evlyg",
  "manufacturer_health_system_vbpb_150k_per_evlyg",
  "manufacturer_health_system_vbpb_200k_per_evlyg",
  "manufacturer_societal_vbpb_50k_per_qaly",
  "manufacturer_societal_vbpb_100k_per_qaly",
  "manufacturer_societal_vbpb_150k_per_qaly",
  "manufacturer_societal_vbpb_200k_per_qaly",
  "manufacturer_societal_vbpb_50k_per_evlyg",
  "manufacturer_societal_vbpb_100k_per_evlyg",
  "manufacturer_societal_vbpb_150k_per_evlyg",
  "manufacturer_societal_vbpb_200k_per_evlyg"
];

// also determines top-down order of derived "compare" values, in each cell:
export const icerComparisonKeys = [
  "user_proposed_price",
  "manufacturer_price",
  // "net_price", // NOTE: Removed from comparison table
  "wac"
];

const NBSP = "\xa0";

export const baseFieldConfig = {
  // filters: array — Filters in Customize Table filter panel
  // fixedValue: boolean
  // label: string — table header text
  // tabFilters: array — eg [0,1,0] if 1, field shows on corresponding tab based on the index
  // type: string|number — type used in sorting to avoid numbers being sorted as strings
  // suppressible: boolean
  //   - NOTE: If you update suppressible, also update Analysis.attr_suppressible_for_trial?
  generic_name: {
    label: "Generic Name",
    tabFilters: [1, 0, 1],
    type: "string",
    suppressible: false
  },
  add_to_compare: {
    label: "Add to Compare",
    tabFilters: [1, 0, 1],
    type: "boolean",
    suppressible: false
  },
  remove_from_compare: {
    label: "Remove from Compare",
    tabFilters: [0, 1, 0],
    type: "string",
    suppressible: false
  },
  trade_name: {
    label: "Trade Name",
    tabFilters: [1, 1, 1],
    type: "string",
    suppressible: false
  },
  specific_condition_tags: {
    label: "Specific Condition Search Tags",
    tabFilters: [0, 0, 0],
    type: "string",
    suppressible: false
  },
  drug_tags: {
    label: "Drug Search Tags",
    tabFilters: [0, 0, 0],
    type: "string",
    suppressible: true
  },
  method_of_administration: {
    label: `Method${NBSP}of Administration`,
    tabFilters: [1, 0, 0],
    type: "string",
    suppressible: true
  },
  specific_condition: {
    label: "Condition: Specific",
    tabFilters: [1, 0, 0],
    type: "string",
    suppressible: false
  },
  general_condition: {
    label: "Condition: General",
    tabFilters: [1, 0, 0],
    type: "string",
    suppressible: true
  },
  date_of_review: {
    label: `Date${NBSP}of Review`,
    tabFilters: [1, 1, 1],
    type: "date",
    suppressible: false
  },
  manufacturer: {
    label: "Manufacturer",
    tabFilters: [1, 0, 0],
    type: "string",
    suppressible: true
  },
  population_and_comparator: {
    label: "Population and Comparator",
    tabFilters: [1, 1, 1],
    type: "string",
    suppressible: true
  },
  access_and_affordability_alert: {
    label: "Access and Affordability Alert",
    tabFilters: [1, 0, 0],
    type: "boolean",
    suppressible: true
  },
  orphan_statuses: {
    label: "Breakthrough/Orphan/Ultra-Rare",
    tabFilters: [1, 0, 0],
    type: "enum",
    suppressible: true
  },
  appraisal_committee: {
    label: "Appraisal Committee",
    tabFilters: [1, 0, 0],
    type: "string",
    suppressible: true
  },
  type_of_review: {
    label: `Type${NBSP}of Review`,
    tabFilters: [1, 0, 0],
    type: "string",
    suppressible: true
  },
  ebm_rating: {
    label: "EBM Rating",
    tabFilters: [1, 0, 0],
    type: "string",
    suppressible: true
  },
  annual_dosage_assumptions: {
    label: "Annual Dosage Assumptions",
    tabFilters: [1, 1, 0],
    type: "string",
    suppressible: true
  },
  user_proposed_price: {
    filters: ["user_proposed_price"],
    label: "",
    tabFilters: [0, 1, 0],
    type: "usd",
    suppressible: true
  },
  net_price: {
    filters: [],
    label: `Est. Net Price (As${NBSP}of${NBSP}Date${NBSP}of${NBSP}Review)`,
    tabFilters: [0, 0, 1],
    type: "usd",
    suppressible: true
  },
  manufacturer_price: {
    filters: ["manufacturer_price"],
    label: "",
    tabFilters: [0, 1, 0],
    type: "usd",
    suppressible: true
  },
  wac_price_placeholder: {
    filters: [], // always present
    label: "",
    fixedValue: "\u00a0", // nbsp
    tabFilters: [1, 1, 0],
    type: "string",
    suppressible: true
  },
  wac: {
    filters: ["wac"],
    label: "",
    tabFilters: [0, 1, 0],
    type: "usd",
    suppressible: true
  },
  icer_vbpb_price_label: {
    filters: [], // always present
    label: "",
    fixedValue: "ICER Price",
    tabFilters: [0, 1, 0],
    type: "string",
    suppressible: false
  },
  user_proposed_price_label: {
    filters: [], // always present
    label: "",
    fixedValue: "Your Price",
    tabFilters: [0, 1, 0],
    type: "string",
    suppressible: false
  },
  net_price_label: {
    filters: [], // always present
    label: "",
    fixedValue: "Est. Net Price",
    tabFilters: [0, 0, 0],
    type: "string",
    suppressible: false
  },
  manufacturer_price_label: {
    filters: [], // always present
    label: "",
    fixedValue: "Manufacturer Alt. Price",
    tabFilters: [0, 1, 0],
    type: "string",
    suppressible: false
  },
  wac_price_label: {
    filters: [], // always present
    label: "",
    fixedValue: "WAC",
    tabFilters: [0, 1, 0],
    type: "string",
    suppressible: false
  },
  health_system_cost_ratio_qaly: {
    label: `Health${NBSP}System Cost‑Effectiveness ($/QALY)`, // non-breaking hyphens: \u2011
    tabFilters: [0, 0, 1],
    type: "usd",
    suppressible: true
  },
  health_system_cost_ratio_evlyg: {
    label: `Health${NBSP}System Cost‑Effectiveness ($/evLYG)`,
    tabFilters: [0, 0, 1],
    type: "usd",
    suppressible: true
  },
  societal_cost_ratio_qaly: {
    label: `Modified${NBSP}Societal Cost‑Effectiveness ($/QALY)`,
    tabFilters: [0, 0, 1],
    type: "usd",
    suppressible: true
  },
  societal_cost_ratio_evlyg: {
    label: `Modified${NBSP}Societal Cost‑Effectiveness ($/evLYG)`,
    tabFilters: [0, 0, 1],
    type: "usd",
    suppressible: true
  },
  health_system_vbpb_50k_per_qaly: {
    // V
    filters: ["health_system", "50k", "qaly"],
    label: "50K/QALY",
    tabFilters: [0, 1, 1],
    type: "usd",
    suppressible: true
  },
  health_system_vbpb_100k_per_qaly: {
    // W
    filters: ["health_system", "100k", "qaly"],
    label: "100K/QALY",
    tabFilters: [0, 1, 1],
    type: "usd",
    suppressible: true
  },
  health_system_vbpb_150k_per_qaly: {
    // X
    filters: ["health_system", "150k", "qaly"],
    label: "150K/QALY",
    tabFilters: [0, 1, 1],
    type: "usd",
    suppressible: true
  },
  health_system_vbpb_200k_per_qaly: {
    // Y
    filters: ["health_system", "200k", "qaly"],
    label: "200K/QALY",
    tabFilters: [0, 1, 1],
    type: "usd",
    suppressible: true
  },
  health_system_vbpb_50k_per_evlyg: {
    // Z
    filters: ["health_system", "50k", "evlyg"],
    label: "50K/evLYG",
    tabFilters: [0, 1, 1],
    type: "usd",
    suppressible: true
  },
  health_system_vbpb_100k_per_evlyg: {
    // AA
    filters: ["health_system", "100k", "evlyg"],
    label: "100K/evLYG",
    tabFilters: [0, 1, 1],
    type: "usd",
    suppressible: true
  },
  health_system_vbpb_150k_per_evlyg: {
    // AB
    filters: ["health_system", "150k", "evlyg"],
    label: "150K/evLYG",
    tabFilters: [0, 1, 1],
    type: "usd",
    suppressible: true
  },
  health_system_vbpb_200k_per_evlyg: {
    // AC
    filters: ["health_system", "200k", "evlyg"],
    label: "200K/evLYG",
    tabFilters: [0, 1, 1],
    type: "usd",
    suppressible: true
  },
  societal_vbpb_50k_per_qaly: {
    // AD
    filters: ["societal", "50k", "qaly"],
    label: "50K/QALY",
    tabFilters: [0, 1, 1],
    type: "usd",
    suppressible: true
  },
  societal_vbpb_100k_per_qaly: {
    // AE
    filters: ["societal", "100k", "qaly"],
    label: "100K/QALY",
    tabFilters: [0, 1, 1],
    type: "usd",
    suppressible: true
  },
  societal_vbpb_150k_per_qaly: {
    // AF
    filters: ["societal", "150k", "qaly"],
    label: "150K/QALY",
    tabFilters: [0, 1, 1],
    type: "usd",
    suppressible: true
  },
  societal_vbpb_200k_per_qaly: {
    // AG
    filters: ["societal", "200k", "qaly"],
    label: "200K/QALY",
    tabFilters: [0, 1, 1],
    type: "usd",
    suppressible: true
  },
  societal_vbpb_50k_per_evlyg: {
    // AH
    filters: ["societal", "50k", "evlyg"],
    label: "50K/evLYG",
    tabFilters: [0, 1, 1],
    type: "usd",
    suppressible: true
  },
  societal_vbpb_100k_per_evlyg: {
    // AI
    filters: ["societal", "100k", "evlyg"],
    label: "100K/evLYG",
    tabFilters: [0, 1, 1],
    type: "usd",
    suppressible: true
  },
  societal_vbpb_150k_per_evlyg: {
    // AJ
    filters: ["societal", "150k", "evlyg"],
    label: "150K/evLYG",
    tabFilters: [0, 1, 1],
    type: "usd",
    suppressible: true
  },
  societal_vbpb_200k_per_evlyg: {
    // AK
    filters: ["societal", "200k", "evlyg"],
    label: "200K/evLYG",
    tabFilters: [0, 1, 1],
    type: "usd",
    suppressible: true
  },
  shimming_placeholder: {
    filters: [], // always present?
    label: "",
    fixedValue: "\u00a0", // nbsp
    tabFilters: [1, 1, 1],
    type: "string",
    suppressible: true
  }
};

const generateVbpbLayoutConfig = (benchmarkPriceKey) => ({
  label: baseFieldConfig[benchmarkPriceKey].label,
  keys: icerComparisonKeys.reduce(
    (accumulatedKeysInCell, comparisonKey) =>
      accumulatedKeysInCell.concat(`${comparisonKey}_vs_${benchmarkPriceKey}`),
    [benchmarkPriceKey]
  )
});

export const nestedColumnGroups = [
  // This Array specifies the order of columns:
  {
    label: "",
    // no spanning super-header: each of the following key will render a <th rowspan=2> in 1st tr of thead:
    keys: [
      "add_to_compare",
      "remove_from_compare",
      "trade_name",
      "generic_name",
      "specific_condition",
      "date_of_review",
      "population_and_comparator",
      "net_price",
      "general_condition",
      "method_of_administration",
      "manufacturer",
      "access_and_affordability_alert",
      "orphan_statuses",
      "appraisal_committee",
      "type_of_review",
      "ebm_rating",
      "annual_dosage_assumptions"
    ]
  },
  {
    label: "Comparison Prices",
    keys: [
      {
        // the following keys render as data subrows, within tbody:
        keys: [
          "icer_vbpb_price_label", // data subrow 1 th
          "user_proposed_price_label", // data subrow 2 th
          "manufacturer_price_label", // data subrow 3 th
          // "net_price_label",               // data subrow X th NOTE: Removed from comparison table
          "wac_price_label" // data subrow 4 th
        ]
      },
      {
        // the following keys render as data subrows, within tbody:
        keys: [
          null, // for row: ICER vbpb prices  // data subrow 1 th (empty)
          "user_proposed_price", // data subrow 2 th
          "manufacturer_price", // data subrow 3 th
          // "net_price", // Q                // data subrow X th NOTE: Removed from comparison table
          "wac" // P                          // data subrow 4 th
        ]
      }
    ]
  },
  {
    label: "",
    // no spanning super-header: each of the following key will render a <th rowspan=2> in 1st tr of thead:
    keys: [
      "health_system_cost_ratio_qaly", // R
      "health_system_cost_ratio_evlyg", // S
      "societal_cost_ratio_qaly", // T
      "societal_cost_ratio_evlyg" // U
    ]
  },
  {
    label: "Health System Perspective",
    // spanning super-header exists: each of the following key will render a <th rowspan=1> in the 2nd tr of thead:
    keys: icerBenchmarkPriceKeys
      .filter((k) => k.includes("health_system"))
      .map(generateVbpbLayoutConfig)
  },
  {
    label: "Modified Societal Perspective",
    // spanning super-header exists: each of the following key will render a <th rowspan=1> in the 2nd tr of thead:
    keys: icerBenchmarkPriceKeys.filter((k) => k.includes("societal")).map(generateVbpbLayoutConfig)
  }
].map((group) => {
  // convert keys to always be Arrays:
  // eslint-disable-next-line no-param-reassign
  group.keys = group.keys.map((keys) => (typeof keys === "string" ? new Array(keys) : keys));
  return group;
});

// FIELD_KEY: TYPE
export const SORTABLE_COLUMNS = {
  date_of_review: "string",
  general_condition: "string",
  generic_name: "string",
  health_system_cost_ratio_evlyg: "number",
  health_system_cost_ratio_qaly: "number",
  manufacturer: "string",
  access_and_affordability_alert: "boolean",
  method_of_administration: "string",
  net_price: "number",
  orphan_statuses: "string",
  societal_cost_ratio_evlyg: "number",
  societal_cost_ratio_qaly: "number",
  specific_condition: "string",
  trade_name: "string"
};

export const costRatioFields = [
  "health_system_cost_ratio_qaly",
  "health_system_cost_ratio_evlyg",
  "societal_cost_ratio_qaly",
  "societal_cost_ratio_evlyg"
];

export const SECONDARY_SORT_FIELDS = ["trade_name", "population_and_comparator"];

export const SEARCHABLE_FIELDS = [
  { name: "trade_name", weight: 2 },
  { name: "generic_name", weight: 2 },
  { name: "specific_condition", weight: 1 },
  { name: "general_condition", weight: 1 },
  "manufacturer",
  // "orphan_statuses", Removed when searching with fuse.js
  "drug_tags",
  "specific_condition_tags"
];

export const SELECTED_ROW_STORAGE_KEY = "add_to_compare";

// make sure these values stay aligned with (currently provisional) columnKeys:
export const stickyRowHeadingKeys = ["add_to_compare", "remove_from_compare", "trade_name"];

export const fieldFilterConfig = [
  [
    "Display the Following Prices: ",
    {
      key: "user_proposed_price", // both entry "user_proposed_price" field, and "user_proposed_price_vs_ ..." fields
      label: "Your Price"
    },
    {
      key: "manufacturer_price",
      label: "Manufacturer’s Alternative Price"
    },
    {
      key: "wac",
      label: "WAC"
    }
  ],
  [
    "Perspective: ",
    {
      key: "health_system",
      label: "Health System"
    },
    {
      key: "societal",
      label: "Modified Societal"
    }
  ],
  [
    "Measures: ",
    {
      key: "evlyg",
      label: "per evLYG"
    },
    {
      key: "qaly",
      label: "per QALY"
    }
  ],
  [
    "Pricing: ",
    {
      key: "50k",
      label: "50K"
    },
    {
      key: "100k",
      label: "100K"
    },
    {
      key: "150k",
      label: "150K"
    },
    {
      key: "200k",
      label: "200K"
    }
  ]
];

export const fieldFilterKeys = fieldFilterConfig.reduce(
  (acc, config) => acc.concat(config.slice(1).map(({ key }) => key)),
  []
); // eslint-disable-line max-len

// eslint-disable-next-line max-len
const generateComparisonFieldConfig = () =>
  icerBenchmarkPriceKeys.reduce((accumulatedFieldConfigs, benchmarkPriceKey) => {
    icerComparisonKeys.forEach((comparisonKey) => {
      const fieldName = `${comparisonKey}_vs_${benchmarkPriceKey}`;
      const baseConfig = baseFieldConfig[benchmarkPriceKey];
      const baseFilter = baseConfig.filters.slice();
      const filters = baseFilter.concat(comparisonKey);

      // const isQaly = benchmarkPriceKey.includes("qaly") ? 1 : 0;
      // const isEvlyg = benchmarkPriceKey.includes("evlyg") ? 1 : 0;

      accumulatedFieldConfigs[fieldName] = {
        // eslint-disable-line no-param-reassign
        filters,
        label: baseFieldConfig[benchmarkPriceKey].label,
        tabFilters: [0, 1, 0],
        benchmarkKey: benchmarkPriceKey,
        comparisonKey,
        type: "percent",
        suppressible: true
      };
    });
    return accumulatedFieldConfigs;
  }, {});

const comparisonFieldConfig = generateComparisonFieldConfig();

export const fieldConfig = { ...baseFieldConfig, ...comparisonFieldConfig };

// allFieldKeys also used by ESLint:
export const allFieldKeys = Object.keys(fieldConfig);

export const comparisonValuesKeys = Object.keys(comparisonFieldConfig);

export const getType = (fieldKey) => {
  const config = fieldConfig[fieldKey];
  if (!config) {
    return "";
  }
  if (!config.type) {
    return "";
  }
  return config.type;
};

export const getAlignment = (fieldKey) => {
  const type = getType(fieldKey);
  switch (type) {
    case "usd":
    case "usd-range":
    case "percent":
    case "percent-range":
    case "date":
      return "right";
    case "string":
      return "left";
    default:
      return "left";
  }
};

// TODO: i18n:
export const docLabels = {
  // currently, used for img alt text (labels are built into SVG as <path> elements)
  report_at_a_glance_upload: "Report at a Glance",
  presentation_upload: "Presentation Slides",
  patient_perspectives: "Patient Perspectives",
  evidence_summary_upload: "Evidence Summary",
  recommendation_upload: "Recommendations for Fair Pricing and Fair Access"
};

const keyPattern = new RegExp(/[^0-9a-zA-Z]+/, "gi");
const toKey = (s) => s.toLowerCase().replace(keyPattern, "-");

const subRowKeys = [
  "icer_vbpb_price_row",
  "user_proposed_price_row",
  "manufacturer_price_row",
  // "net_price_row",
  "wac_price_row"
];

export const computeHeaderProps = (tabKey, enabledGridFields = {}) => {
  const tabIndex = tabKeys.indexOf(tabKey);
  let row1Props = [];
  let row2Props = [];
  let dataRowColumnsConfigs = [];
  const activeFilters = Object.keys(enabledGridFields).filter((k) => !enabledGridFields[k]);
  nestedColumnGroups.forEach((group) => {
    const columnConfigs = group.keys
      .map((itemsInColumn) => {
        const keysInColumn = itemsInColumn instanceof Array ? itemsInColumn : itemsInColumn.keys;
        let rowSpan = itemsInColumn instanceof Array ? 4 : 1;
        if (tabIndex === 2) {
          rowSpan = 1;
        }
        const firstKey = keysInColumn.find((fieldKey) => fieldKey); // ignore null values
        const multiRowLabel = itemsInColumn.label || "";
        return {
          renderKey: toKey(multiRowLabel + firstKey),
          multiRowLabel,
          firstKey,
          fieldsInColumn: keysInColumn.map((fieldKey) => {
            if (!fieldKey) {
              return {
                rowSpan,
                isFiltered: true, // if fieldKey is null, this is a shim
                renderKey: `shimming_placeholder-${firstKey}`,
                fieldKey: "shimming_placeholder"
              };
            }
            const { tabFilters, filters = [] } = fieldConfig[fieldKey];
            const isOnTab = tabFilters[tabIndex] === 1;
            const firstFilterMatch = activeFilters.find((filterKey) => filters.includes(filterKey));

            const isFiltered = !isOnTab || !!firstFilterMatch;
            return {
              rowSpan,
              isFiltered,
              renderKey: toKey(`${multiRowLabel} ${fieldKey}`),
              fieldKey: isFiltered ? "shimming_placeholder" : fieldKey
            };
          })
        };
      })
      .filter(({ fieldsInColumn }) => {
        // Prune whole columns if no items are visible:
        const firstVisible = fieldsInColumn.find(({ isFiltered }) => !isFiltered);
        return !!firstVisible;
      });

    if (columnConfigs.length) {
      const { label: spanningLabel } = group;
      dataRowColumnsConfigs = dataRowColumnsConfigs.concat(columnConfigs);
      if (spanningLabel) {
        const allColumnsHaveLabels = columnConfigs.every((column) => !!column.multiRowLabel);
        row1Props.push({
          renderKey: toKey(spanningLabel),
          colSpan: columnConfigs.length,
          rowSpan: allColumnsHaveLabels ? 1 : 2,
          align: "center",
          label: spanningLabel
        });
        if (allColumnsHaveLabels) {
          // { multiRowLabel, "fieldsInColumn": [{ "fieldKey": firstKey }] }, indexInGroup
          row2Props = row2Props.concat(
            columnConfigs.map((config) => {
              const { multiRowLabel, fieldsInColumn } = config;
              const fieldKeys = fieldsInColumn.map(({ fieldKey }) => fieldKey);
              return {
                renderKey: toKey(`${spanningLabel} ${multiRowLabel} ${fieldKeys[0]}`),
                colSpan: 1,
                rowSpan: 1,
                classes: fieldKeys.join(" "),
                align: "left",
                label: multiRowLabel // label for individual column below spanningLabel
              };
            })
          );
        }
      } else {
        // no spanning header, rowspan=2
        // { multiRowLabel, "fieldsInColumn": [{ "fieldKey": firstKey }] }, indexInGroup
        row1Props = row1Props.concat(
          columnConfigs.map((config) => {
            const { multiRowLabel, fieldsInColumn } = config;
            const fieldKeys = fieldsInColumn.map(({ fieldKey }) => fieldKey);
            const [firstKey] = fieldKeys;
            const label = multiRowLabel || fieldConfig[fieldKeys[0]].label;
            return {
              fieldKey: firstKey,
              renderKey: toKey(label),
              colSpan: 1,
              rowSpan: 2,
              classes: fieldKeys.join(" "),
              align: "left",
              label // no spanningLabel
            };
          })
        );
      }
    }
  });

  // Array of subRowProps, with references to appropriate columnConfigs, regardless of rowspan:
  const dataRowProps = [];
  subRowKeys.forEach((subRowKey, rowNum) => {
    if (tabIndex === 2 && subRowKey !== "icer_vbpb_price_row") {
      return;
    }
    dataRowProps[rowNum] = {
      rowRenderKey: subRowKey,
      columnProps: dataRowColumnsConfigs.reduce((accColumnProps, { fieldsInColumn }) => {
        const propsForCell = fieldsInColumn[rowNum];
        if (propsForCell) {
          accColumnProps.push(propsForCell);
        }
        return accColumnProps;
      }, [])
    };
  });

  return {
    row1Props,
    row2Props,
    dataRowProps
  };
};
