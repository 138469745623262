import { useEffect } from "react";
import useAccessibilityAudit from "../hooks/useAccessibilityAudit"
import { useLocation } from "react-router-dom";

// Run the accessibility audit of whatever this component wraps (children)
// Notes:
//  - does not run in development mode unless RUN_ACCESSIBILITY_AUDIT is set
//  - should/does not run in production
const AccessibilityChecker = ({children}) => {
  let pathname = ""
  try {
    const location = useLocation();
    pathname = location.pathname
  } catch {
    console.warn("Cannot determine route/location — AccessibilityChecker probably called outside of React Router context")
  }
  useEffect(() => useAccessibilityAudit(), [pathname]);
  return children
}

export default AccessibilityChecker
