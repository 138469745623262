import React from "react";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    "& h4": {
      // border: '1px dashed red',
      marginBottom: "2px",
      "& a": {
        // anchor destinations
        cursor: "default",
        color: theme.palette.text.primary,
        textDecoration: "none"
      }
    },
    "& h5": {
      // color: 'red',
      margin: 0
    },
    "& li": {
      // border: '1px dashed #f0f',
      marginTop: 0
    },
    "& p": {
      // border: '1px dashed green',
      marginTop: 0
    },
    "& a": {}
  }
}));

const PrivacyContent = React.memo(() => {
  const classes = useStyles();

  /* eslint-disable max-len */
  return (
    <div className={classes.root}>
      <p>LAST UPDATED: JUNE 8, 2021</p>
      <p>
        Evidence for Healthcare Improvement d/b/a the Institute for Clinical and Economic Review
        ("ICER", "we", "us" or "our") is committed to protecting and preserving your privacy. This
        policy, together with our Terms of Use and Conditions located at{" "}
        <a target="_blank" href="https://analytics.icer.org/">https://analytics.icer.org/</a>,{" "}
        <a target="_blank" href="https://icer-review.org/">https://icer-review.org/</a>; and{" "}
        <a target="_blank" href="https://icer.org/">https://icer.org/</a>, sets out the basis on which any Personal
        Information and Anonymous Information (each as defined below) we collect from you, or that
        you ("you" and "your") provide to us, will be processed, stored, shared, and used by us. By
        accessing or using our website(s) available at{" "}
        <a target="_blank" href="https://analytics.icer.org/">https://analytics.icer.org/</a>,{" "}
        <a target="_blank" href="https://icer-review.org/">https://icer-review.org/</a>; and{" "}
        <a target="_blank" href="https://icer.org/">https://icer.org/</a> (each, a "Site" and collectively, the
        "Sites"), you are accepting the practices described in this Privacy Policy ("Privacy
        Policy").
      </p>
      <p>
        By using this Site, you consent to the use of information that you provide to us in
        accordance with this Privacy Policy. We may update this Privacy Policy from time to time so
        please review this Privacy Policy regularly. If we materially alter our Privacy Policy, we
        may notify you by posting a notice on our Site. Your continued use of or access to the Site
        will be deemed your agreement that your information may be used in accordance with the new
        policy.{" "}
        <strong>
          BY ACCESSING OR USING THE SITE, YOU AGREE TO THE TERMS OF THIS PRIVACY POLICY; IF YOU DO
          NOT AGREE, DO NOT ACCESS OR OTHERWISE USE THE SITE
        </strong>
        .
      </p>
      <p>
        We have structured our Site so that you can visit it without identifying yourself or
        revealing any Personal Information, however some functions available on the Site may require
        you to disclose certain Personal Information. If you choose to provide us with Personal
        Information, it will be used in accordance with this Privacy Policy.
      </p>

      <h4>Terms of Service</h4>
      <p>
        Please read this Privacy Policy carefully to fully understand how we collect, share and
        protect information about you. This Policy is incorporated into and is a part of the Terms
        of Use and Conditions of this Site (the "Terms"). To access the Terms{" "}
        <a target="_blank" href="https://icer-review.org/about/terms-and-conditions/">click here</a>.
      </p>
      <p>
        In order to deliver personalized and relevant information to you through our Site, we may
        collect certain information from users. Please do not provide any sensitive information. If
        you should do so inadvertently, we may use and disclose that information in the same manner
        as any other information you provide to us.
      </p>

      <h4>Linked Sites and Service Providers</h4>
      <p>
        This Privacy Policy does not address, and ICER is not responsible for, the privacy,
        information, or other data collection, storage, and use practices of any third party
        operating any site or property to which the Sites link (each, a "Linked Site") or third
        parties who may provide certain services in connection with your access and use of, and
        ICER's ability to provide certain products and services to you through, the Site as further
        detailed herein, including, without limitation, Facebook, Inc., Apple Inc., Google Inc.,
        Microsoft Corp., Research In Motion, any wireless carriers and any third party (each, a
        "Service Provider"). ICER recommends you review the privacy policies of each Linked Site or
        Service Provider prior to accessing or using the Linked Sites or Site. The inclusion of a
        link to a Linked Site does not imply ICER's endorsement of, or affiliation with, the Linked
        Site. TO THE FULLEST EXTENT PERMITTED BY LAW, YOU HEREBY RELEASE ICER AND EACH OF THE OTHER
        ICER PARTIES AS DEFINED BELOW) FROM ANY CLAIMS OR DAMAGES OF ANY KIND OR NATURE, KNOWN OR
        UNKNOWN, SUSPECTED OR UNSUSPECTED, DISCLOSED OR UNDISCLOSED, RELATING TO THE DATA
        COLLECTION, STORAGE, USE, AND SHARING OF YOUR OR ANY THIRD PARTY'S PERSONAL INFORMATION OR
        ANONYMOUS INFORMATION BY ANY SERVICE PROVIDER. YOU AGREE, UNDERSTAND AND INTEND THAT THIS
        ASSUMPTION OF RISK AND RELEASE IS BINDING UPON YOU AND YOUR HEIRS, EXECUTORS, AGENTS,
        ADMINISTRATORS AND ASSIGNS IN PERPETUITY.
      </p>

      <h4 id="info_we_collect">Information We Collect</h4>
      <p>
        In operating our Site, we may collect and process Personal Information and Anonymous
        Information such as the following data about you:
      </p>
      <ul>
        <li>
          Details of your visits to our Site and the resources that you access, including, but not
          limited to, traffic data, location data, weblogs and other communication data, including
          your IP address, the date and time you accessed or left the Site and which pages you
          viewed. Some parts of our Site use cookies and other technologies to collect this
          information about your general internet usage. See section below, titled 'Information We
          Collect Automatically," to learn more.
        </li>
        <li>
          Information that you provide directly to us. See section below titled "Information We
          Collect Directly From You".
        </li>
      </ul>
      <p>
        "Personal Information" is information that can be used to identify you (whether alone or in
        combination), such as your first and last name, email address, zip code/post code, billing
        address, shipping address, phone number, payment card information, age, and any other
        information you choose to provide.
      </p>
      <p>
        "Anonymous Information" is aggregated or pseudonimized information which cannot be used to
        identify you, such as information about ICER's users that it renders in such a way that it
        no longer identifies or references an individual user.
      </p>

      <p>
        <strong>Information We Collect Directly From You</strong>. When you access our Site and
        Services you may provide us Personal Information directly as follows:{" "}
      </p>
      <ul>
        <li>
          To Create an Account: When you sign up for an ICER account, you may be requested to
          provide your name, email address, employer or organization affiliation, phone number,
          address, business sector information, and if a payor, the number of covered lives.
        </li>
        <li>
          To use the Evidence Compendium (EC) application in ICER Analytics: When you enter data
          into the Evidence Compendium program for comparison, including, without limitation, net
          price ("EC Data"), EC Data are stored so such EC Data are available for your next
          sessions. EC Data are not accessed by any third party, but may be used by ICER solely for
          the purposes of providing the Services by and through the Sites. When you subscribe to
          ICER Analytics, you have the right to opt out of the aforementioned EC Data storage
          function, in which case the EC Data entered are not stored or saved.
        </li>
        <li>
          To use the Interactive Modeler (IM) application in ICER Analytics: When you enter data
          into the Interactive Modeler program for computation ("IM Data"), IM Data are stored so
          such IM Data are available for your next sessions. IM Data are not accessed by any third
          party, but may be used by ICER solely for the purposes of providing the Services by and
          through the Sites. When you subscribe to ICER Analytics, you have the right to opt out of
          the aforementioned IM Data storage function, in which case the IM Data entered are not
          stored or saved.
        </li>
        <li>
          To Sign Up for Our Newsletter: When you sign up for our newsletter, you may be asked to
          provide your name, email address, and other contact information.
        </li>
        <li>
          To Contact Us: When you contact us through our Sites or otherwise, you may be requested to
          provide your full name, email address, and any account information.
        </li>
      </ul>

      <p>
        <strong>Information We Collect Automatically.</strong> We and our Service Providers may
        collect certain Personal Information automatically as the result of your use of the Site and
        other web analytics services. This information generally includes:
      </p>
      <ul>
        <li>
          <u>Usage Data:</u> When you use the Site, we and our Service Providers may track and
          collect usage data and information about your interactions with the Site such as the pages
          that you visit, the links you click, and the date and time your device accesses the Site
          or our servers.
        </li>
        <li>
          <u>Cookies, Web Beacons, and Log Data:</u> Like most companies, when you visit or interact
          with the Site, we and our Service Providers may use cookies and other tracking
          technologies for storing information, including pixels, web beacons, "clear GIFs," local
          storage, and device identifiers to help provide, protect and improve the Site.
          <ul>
            <li>
              An Internet Protocol (IP) address is a number that is automatically assigned to your
              device from which you are accessing the Site by your Internet Service Provider (ISP),
              and is identified and logged automatically in our server log files whenever you visit
              the Site, along with the time of the visit and the activity on the Site, browser type
              and operating system, device information, device event information, and the page
              you've viewed or engaged with before or after using the Site. We and our Service
              Providers use IP addresses for purposes such as calculating Site usage levels, helping
              diagnose server problems, administering the Site and determining your approximate
              geographic location.
            </li>
            <li>
              "Cookies" are small pieces of information that a website attaches to your browser when
              you visit a website. We may use both "session" Cookies (which expire once you close
              your web browser) and "persistent" Cookies (which stay on your computer until deleted)
              to provide you with a more personal and interactive experience with the Site. When you
              visit a website, that website's computer asks your computer for permission to store
              this file in a part of your hard drive specifically designated for cookies. Each
              website can send its own cookie to your browser if your browser's preferences allow
              it. We do not store Personal Information in any cookies on your computer. Persistent
              cookies can be removed by following your Internet browser's directions within the help
              tab. However, in order to use certain aspects of the Site, your web browser must
              accept cookies, and therefore some aspects of the Site may not work properly if you
              choose to disable cookies.
            </li>
            <li>
              We may also use cookies, pixels, "Web Beacons" (which are usually small, transparent
              graphic images) and navigational data like Uniform Resource Locators (URLs) to gather
              information regarding the date and time of your visit, the features and information
              for which you searched and viewed, or the e-mail you opened. This type of information
              is collected to make the Site more useful to you and to tailor the experience with us
              to meet your interests and needs.
            </li>
          </ul>
        </li>

        <li>
          <u>Geographic Location Information:</u> If you use any location-enabled products or
          services, you may be sending us location information (e.g. IP address). When you use a
          location-enabled service, we may collect and process information about your precise
          geographical location, such as GPS signals sent by a mobile device. We and our Service
          Providers and partners may use your device's physical location to provide you with
          personalized location-based services, content and advertisements. In some instances, you
          may be permitted to allow or deny such uses of your device's location, but if you choose
          to deny such uses, we may not be able to provide you with the applicable personalized
          services and content.
        </li>
        <li>
          <u>Device Information:</u> Certain information is collected by most browsers, such as your
          Media Access Control (MAC) address, device type (Windows or Macintosh), screen resolution,
          operating system version, Internet browser type and version and the type and version of
          the Site that you are using. We and our Service Providers may also collect a unique device
          identifier assigned by us or our Service Providers to the device from which you are
          accessing the Site and other transactional information for the device that we and our
          Service Providers may use to serve content and advertisements to the device.
        </li>
        <li>
          <u>
            Software Development Kits (<strong>"SDK(s)</strong>"):
          </u>{" "}
          We may use third parties including our business partners and Service Providers who use
          mobile SDKs to collect information and information related to how mobile devices interact
          with the Site. An SDK is computer code that app developers can include in their apps to
          enable ads to be shown, data to be collected and related services and functionality to be
          implemented. A mobile SDK is in effect the mobile app version of a pixel tag or beacon. We
          may use this technology, for instance, to analyze how you interact with content and
          advertisements and to enable analytics or other features through mobile devices.
        </li>
        <li>
          <u>Aggregated Information</u>: We may aggregate Personal Information so that the
          end-product does not personally identify you or any other user of the Site, for example,
          by using Personal Information to calculate the percentage of our users who like a
          particular product or service.
        </li>
      </ul>

      <h4>Information from Third Parties.</h4>
      <p>
        We engage third parties, such as Google Analytics, to provide analytics about the use of our
        Site. These third-party ad networks and exchange providers may use third-party cookies,
        pixels, or similar technologies to collect information, including your device identifier, IP
        address, or identifier for advertising. The information these third parties collect may be
        used to help us in providing you with more relevant advertising that we serve on or off the
        Site, and as described above. We use Google Analytics, an analytics tool provided by Google,
        Inc., to collect and process data about your use of the Site including when you visit the
        Website, URLs of the websites that you visit prior to visiting the Site and when you visit
        those websites, and IP addresses assigned to the devices from where you access the Internet.
        Google sets and reads cookies to collect this data and your web browser will automatically
        send data collected by those cookies to Google. Google uses this data to provide us with
        reports that we will use to improve the Site's structure and content. For more information
        on how Google uses this data, visit{" "}
        <a target="_blank" href="https://policies.google.com/privacy">Google's Privacy Policy</a>
        {" "}and Google's page on{" "}
        <a target="_blank" href="https://policies.google.com/technologies/partner-sites">
          How Google uses data when you use our partners' sites or apps
        </a>
        . Furthermore, third-party cookies are covered by the third parties' privacy policy. If you
        would like more information about this practice and to know your choices about not having
        this information used by these companies, please visit the Network Advertising Initiative at
        www.networkadvertising.org or the Digital Advertising Alliance at{" "}
        <a target="_blank" href="http://www.aboutads.info">www.aboutads.info</a>.
      </p>

      <h4 id="use_and_sharing">Use and Sharing of Your Information</h4>
      <p>We may collect such information for uses that include, but may not be limited to:</p>
      <ul>
        <li>
          enabling us to provide our services to you in the best possible manner, including to help
          us operate, evaluate, develop, analyze, manage and improve our products, Sites and
          Services;
        </li>
        <li>
          auditing related to a current interaction with you and concurrent transactions; certain
          short-term, transient uses;
        </li>
        <li>
          detecting security incidents, protecting against malicious, deceptive, fraudulent or
          illegal activity, or prosecuting those responsible for that activity;
        </li>
        <li>
          debugging to identify and repair errors that impair existing intended functionality;
          undertaking internal research for technological development and demonstration;
        </li>
        <li>
          undertaking activities to verify or maintain the quality or safety of a service or device
          that is owned, manufactured, manufactured for, or controlled by us, and to improve,
          upgrade, or enhance the service or device that is owned, manufactured for, or controlled
          by us;
        </li>
        <li>
          to provide you with information relating to our organization, or to monitor and conduct an
          analysis of our Site traffic and usage patterns;
        </li>
        <li>
          to communicate with you via a newsletter or in connection with any service or information
          requests submitted by you;
        </li>
        <li>
          administering and improving our Site, including troubleshooting, data analysis, testing,
          research, statistical and survey purposes;
        </li>
        <li>
          As ICER believes to be necessary or appropriate: (i) under applicable law, including laws
          outside your country of residence; (ii) to comply with legal process; (iii) to respond to
          requests from public or government authorities, including public or government authorities
          outside your country of residence; (iv) to enforce the Terms and Privacy Policy; (v) to
          protect ICER's operations; (vi) to protect ICER's rights, privacy, safety or property, or
          that of you or others; or (vii) to allow ICER to pursue available remedies or limit the
          damages that ICER may sustain.
        </li>
      </ul>
      <p>
        We also may de-identify and/or aggregate information to create aggregate or de-identified
        data sets and reports in order to assess, improve, and develop ICER's business, products,
        and services; prepare benchmarking reports; and for other research and analytics purposes.
      </p>
      <p>
        ICER will not sell, share, or rent your Personal Information to others in ways different
        from what is disclosed in this statement, or otherwise without your consent.
        Notwithstanding, ICER may disclose your Personal Information or Anonymous Information to
        third parties as follows:
      </p>
      <ul>
        <li>
          To its Service Providers to (i) provide the services necessary for them to perform tasks
          on ICER's behalf, including, without limitation, hosting, data analysis, process
          transactions, email delivery, and other similar tasks; or (ii) provide advertising,
          campaign measurement, online and mobile analytics, and related services. These Service
          Providers access and use of such Personal Information and Anonymous Information to allow
          ICER better reach individuals with relevant ads and measure ICER's ad campaigns, or to
          better understand how individuals interact with ICER's Sites and online services over time
          and across devices;
        </li>
        <li>
          To courts, law enforcement agencies, and governmental authorities (i) to comply with
          applicable law, including laws outside your country of residence; (ii) to comply with
          legal process; (iii) to respond to requests from public or government authorities,
          including public or government authorities outside your country of residence; (iv) to
          enforce the Terms; (v) to protect ICER's operations; (vi) to protect ICER's rights,
          privacy, safety or property, or that of you or others; or (vii) to allow ICER to pursue
          available remedies or limit the damages that ICER may sustain; and
        </li>
        <li>
          As part of a sale, merger or change in control of ICER, or in preparation for any of these
          events. Any other entity that buys ICER or part of its business will have the right to
          continue to use your data as set out in this Privacy Policy unless you agree otherwise.
        </li>
      </ul>
      <p>
        <strong>
          Notwithstanding the foregoing, ICER may share or sell aggregated or Anonymous Information,
          but not identifying information, to third parties in accordance with applicable law.
          However, ICER never sells the net pricing EC Data or IM Data you enter into the Evidence
          Compendium program of ICER Analytics. Such pricing information is proprietary and
          sensitive, and ICER will not share such information with any third party. ICER may retain
          such information solely for the purposes of providing its services by and through the
          Sites, unless you opt-out of the storage and saving function of EC Data or IM Data when
          you subscribe to ICER Analytics as otherwise outlined herein.
        </strong>
      </p>

      <h4>Security</h4>
      <p>
        To ensure that your information is secure, we have put in place commercially suitable
        physical, electronic, and managerial procedures to safeguard and secure the information we
        collect on our Site.
      </p>
      <p>
        Nonetheless, the transmission of information via the internet is not completely secure and
        therefore we cannot guarantee the security of data sent to us electronically on our Site,
        and transmission of such data is therefore entirely at your own risk.
      </p>

      <h4>Protected Information</h4>
      <p>
        ICER is neither a "covered entity" nor a "business associate" under the Health Insurance
        Portability and Accountability Rule of 1996 and its implementing regulations (collectively,
        "<strong>HIPAA</strong>"). It is important to note that HIPAA does not necessarily apply to
        an entity or person simply because there is health information involved in an arrangement
        and we believe that HIPAA does not apply to your transactions or communications with ICER.
      </p>

      <h4>Your choices regarding our use and disclosure of your Personal Information.</h4>
      <p>
        In some jurisdictions, including the EEA, you may exercise certain rights to access, request
        copies of, change or correct, request our deletion or transfer, object to or reject certain
        processing of your Personal Information. You may, of course, decline to share certain
        Personal Information with us. In such circumstances we may not be able to provide to you
        some or all of the features and functionality found on the Site.
      </p>

      <h4>California Privacy Rights</h4>
      <p>
        California law allows California residents to ask companies with whom they have an
        established business relationship to provide certain information about the companies’
        sharing of personal information with third parties for direct marketing purposes. We do not
        share any California consumer personal information with third parties for marketing purposes
        without consent.
      </p>
      <p>
        California users who wish to request further information about our compliance with this law
        or have questions or concerns about our privacy practices may contact us using the contact
        information set forth below.
      </p>

      <h4>European Economic Area Notice: </h4>
      <p>
        If you are accessing and using our Site and Services and are located in the European
        Economic Area ("<strong>EEA</strong>") or the United Kingdom (the "<strong>UK</strong>"),
        the following additional information (the "<strong>Notice</strong>") applies with respect to
        your Personal Information. If you have questions about how we handle your Personal
        Information or would like to exercise any of your rights described in this Notice, please
        reach out to us in writing by contacting us at:{" "}
        <a href="mailto:info@icer-review.org">info@icer-review.org</a> or Institute for Clinical and
        Economic Review, 14 Beacon Street, 8<sup>th</sup> Floor, Boston, MA 02108 with the subject
        line: EEA Data Rights. Each clause of this Privacy Policy shall apply to you to the maximum
        extent permitted under applicable law.
      </p>
      <p>
        If you are located in the EEA or the UK, the following rights apply to you: subject to
        applicable law, you have the right to request access to and rectification of the Personal
        Information ICER maintains about you, to request the restriction of the processing of your
        Personal Information or to object to that processing on grounds relating to your situation.
        As described under "Opting Out" below, you have a right to unsubscribe from any marketing
        communications we may send to you. In addition, you may have the right to request erasure of
        your Personal Information from ICER's systems in certain circumstances provided by
        applicable law. Subject to applicable law, where technically feasible and following your
        written request and verifiable identification, we will provide you with a copy of your EEA
        or UK Personal Information in a structured, commonly used, machine readable format.{" "}
      </p>
      <p>
        <strong>
          How you can access, change or suppress your Personal Information or opt-out of other
          services.
        </strong>{" "}
        Subject to any limitations noted in this Privacy Policy, if you would like to review,
        correct, update, suppress, delete or otherwise limit our use or sale of your Personal
        Information that you have previously provided to us, you may contact us by mailing us at:
        Institute for Clinical and Economic Review, 14 Beacon Street, 8<sup>th</sup> Floor, Boston,
        MA 02108, and include your name, address, and e-mail address, or emailing us at{" "}
        <a href="mailto:info@icer-review.org">info@icer-review.org</a> with "Legal" in the subject
        line. In your request, please make clear what Personal Information you would like to have
        changed and what limitations (if any) you would like to place on our use of your Personal
        Information. We will try to comply with your request as soon as reasonably practicable. We
        may need to retain certain information for recordkeeping purposes, and there may also be
        residual information that will remain within our databases and other records which will not
        be removed. We are not responsible for changing, removing or suppressing information from
        the databases of unaffiliated third parties, including any of our service providers, with
        which we have already shared your Personal Information.
      </p>

      <h4>Verifying Requests:</h4>
      <p>
        To help protect your privacy and maintain security, we will take steps to verify your
        identity before granting you access to your Personal Information or complying with your
        request. In addition, if you exercise your right to request specific pieces of your Personal
        Information, we will require you to sign a declaration under penalty of perjury that you are
        the consumer whose Personal Information is the subject of the request.
      </p>

      <h4>Opting Out</h4>
      <p>
        We give you many choices regarding our use and disclosure of your Personal Information for
        marketing purposes. Please note that, if you opt-out, we may not be able to remove your
        Personal Information from the databases of unaffiliated third parties with which we have
        already shared your Personal Information as of the date that we process your opt-out
        request. Please also note that, if you do opt-out from receiving marketing-related messages
        from us, we may still send administrative messages to you, such as related to pending
        orders, returns, or refunds.
      </p>
      <p>
        <strong>Opting Out of EC Data or IM Data Storage</strong>: When subscribing to ICER
        Analytics, you will be presented with the option to opt in or opt out of the saving and
        storage of EC Data or IM Data.
      </p>
      <p>
        <strong>Opt Out of Google Analytics</strong>. To opt out of Google Analytics, you may
        install the Google Analytics Opt-Out Browser found at{" "}
        <a target="_blank" href="https://tools.google.com/dlpage/gaoptout">
          https://tools.google.com/dlpage/gaoptout
        </a>
        . To opt out of Google Analytics for display advertising or customize Google display network
        ads, you can visit the <a target="_blank" href="https://www.google.com/settings/ads">Google Ads Settings</a>{" "}
        page found at{" "}
        <a target="_blank" href="https://adssettings.google.com/authenticated">
          https://adssettings.google.com/authenticated
        </a>
        .
      </p>

      <h4>"Do Not Track" Signals</h4>
      <p>
        Note that your browser settings may allow you to automatically transmit a "Do Not Track"
        signal to sites and online services you visit. There is no consensus among industry
        participants as to what "Do Not Track" means in this context. Like many websites and online
        services, we currently do not alter our practices when we receive a "Do Not Track" signal
        from a visitor's browser. To learn more about cookies and any "Do Not Track" features,
        please visit <a target="_blank" href="http://www.allaboutcookies.org/">http://www.allaboutcookies.org/</a>{" "}
        and <a target="_blank" href="http://www.allaboutdnt.com/">http://www.allaboutdnt.com</a>. If you choose to
        decline cookies, then some or all of the features, functionality and promotions available
        through the Site that may not be available to you. If you do not want information collected
        through the use of cookies, there is a procedure on most devices through which the Site are
        accessed that allows you to decline the use of cookies.
      </p>

      <h4>Adobe Flash LSOs</h4>
      <p>
        If your device stores Flash LSOs, and if you do not want Flash LSOs stored on your device
        through which you access the Site, you can adjust the settings of your Flash player to block
        Flash LSO storage using the tools contained using the Website Storage Settings Panel. You
        can also control Flash LSOs by going to the Global Storage Settings Panel and following the
        instructions (which may include instructions that explain, for example, how to delete
        existing Flash LSOs (referred to as "information" on the Macromedia site), how to prevent
        Flash LSOs from being placed on your device without you being asked, and (for Flash Player 8
        and later) how to block Flash LSOs that are not being delivered by the operator of the page
        you are on at the time). Please note that setting the Flash Player to restrict or limit
        acceptance of Flash LSOs may reduce or impede the functionality of some Flash applications,
        including, potentially, Flash applications used in connection with the Site or our content.
        We expect our service providers and technology suppliers to honor any decision by you to
        restrict or limit Flash LSOs, but we cannot guarantee that they will do so.
      </p>
      <p>
        <strong>Mobile Device Settings</strong>. On your mobile device, enable the "Limit Ad
        Tracking" setting in your iOS phone's settings, or the setting to "Opt out of Ads
        Personalization" in your Android phone's settings. To opt-out of location tracking when
        using our Site, you can use the settings of your device. You can disable mobile analytics
        and bug reporting through these same settings as well.
      </p>
      <p>
        For more information about analytics and advertising cookies and how you can opt out, you
        can visit the Network Advertising Initiative's opt-out page found at{" "}
        <a target="_blank" href="http://optout.networkadvertising.org/?c=1">
          http://optout.networkadvertising.org/?c=1
        </a>{" "}
        , the Digital Advertising Alliance's opt-out page found at{" "}
        <a target="_blank" href="http://optout.aboutads.info/?c=2&lang=EN">
          http://optout.aboutads.info/?c=2&lang=EN
        </a>
        , or EDAA's Your Online Choices page found at{" "}
        <a target="_blank" href="http://www.youronlinechoices.com/">http://www.youronlinechoices.com/</a>.
      </p>

      <h4>Users Only of Legal Age of Majority</h4>
      <p>
        Our Site is designed and intended for those who have reached the age of majority (18 years
        of age). By using our Site, you affirm that you are at least 18 years of age or older. We
        are not liable for any damages that may result from a user's misrepresentation of age.
      </p>
      <p>
        No one under age 13 is authorized to submit any information, including personally
        identifying information, on our Site. Under no circumstances may anyone under age 13 use our
        Site. Parents or legal guardians of children under 13 cannot agree to these terms on their
        behalf.
      </p>

      <h4>Contacting Us</h4>
      <p>
        We welcome any queries, comments, requests or complaints you may have regarding this Privacy
        Policy. Please do not hesitate to contact us at:
      </p>
      <p>Institute for Clinical and Economic Review</p>
      <address>
        <div>
          14 Beacon Street, 8<sup>th</sup> Floor
        </div>
        <div>Boston, MA 02108</div>
        <div>(617) 528-4013</div>
        <div>
          <a href="mailto:info@icer-review.org">info@icer-review.org</a>
        </div>
      </address>
    </div>
  );
});

PrivacyContent.propTypes = {};

export default PrivacyContent;
