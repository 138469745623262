const useAuthentication = () => {
  const root = document.getElementById("icer-spa");
  const {
    isSignedIn="false",
    isDeviseView="false",
    applicationLoginUrl="/users/auth/cognito",
    applicationLoginMethod="post"
  } = root?.dataset || {};
  return {
    isSignedIn: isSignedIn === "true",
    isDeviseView: isDeviseView === "true",
    applicationLoginUrl,
    applicationLoginMethod,
    root
  };
}

export default useAuthentication;
