import { from, of } from "rxjs";
import { createEpicMiddleware, combineEpics, ofType } from "redux-observable";

import { catchError, map, switchMap, concatMap, mergeMap, debounceTime } from "rxjs/operators";

import { ajax } from "rxjs/ajax";

import analytics from "../../google-analytics";

const endpoints = {
  ANALYSES: "/api/analyses",
  ASSET_GROUPS: "/api/asset_groups", // AKA Report Snapshot
  MEDIA_RESOURCES: "/api/media_resources", // Webinar, Document, etc
  COMING_SOON: "/api/media_resources/coming_soon",
  RECENT_UPDATES: "/api/recent_updates",
  USER_ANALYSES: "/api/user_analyses",
  USER_PROFILE: "/api/users/me"
};

export const actionTypes = {
  INITIALIZE_AFTER_LOGIN: "INITIALIZE_AFTER_LOGIN",
  FETCH_ANALYSES: "FETCH_ANALYSES",
  FETCH_ANALYSES_SUCCESS: "FETCH_ANALYSES_SUCCESS",
  FETCH_ANALYSES_FAILURE: "FETCH_ANALYSES_FAILURE",
  FETCH_USER_PROFILE: "FETCH_USER_PROFILE",
  FETCH_USER_PROFILE_SUCCESS: "FETCH_USER_PROFILE_SUCCESS",
  FETCH_USER_PROFILE_FAILURE: "FETCH_USER_PROFILE_FAILURE",
  FETCH_MEDIA_RESOURCES: "FETCH_MEDIA_RESOURCES",
  FETCH_MEDIA_RESOURCES_SUCCESS: "FETCH_MEDIA_RESOURCES_SUCCESS",
  FETCH_MEDIA_RESOURCES_FAILURE: "FETCH_MEDIA_RESOURCES_FAILURE",
  FETCH_RECENT_UPDATES: "FETCH_RECENT_UPDATES",
  FETCH_RECENT_UPDATES_SUCCESS: "FETCH_RECENT_UPDATES_SUCCESS",
  FETCH_RECENT_UPDATES_FAILURE: "FETCH_RECENT_UPDATES_FAILURE",
  FETCH_ASSET_GROUPS: "FETCH_ASSET_GROUPS",
  FETCH_ASSET_GROUPS_SUCCESS: "FETCH_ASSET_GROUPS_SUCCESS",
  FETCH_ASSET_GROUPS_FAILURE: "FETCH_ASSET_GROUPS_FAILURE",
  SET_GRID_FILTER: "SET_GRID_FILTER",
  SET_SORT: "SET_SORT",
  APPLY_SORT: "APPLY_SORT",
  GET_USER_INPUTS: "GET_USER_INPUTS",
  GET_USER_INPUTS_SUCCESS: "GET_USER_INPUTS_SUCCESS",
  GET_USER_INPUTS_FAILURE: "GET_USER_INPUTS_FAILURE",
  SET_USER_INPUT: "SET_USER_INPUT",
  SET_USER_INPUT_SUCCESS: "SET_USER_INPUT_SUCCESS",
  SET_USER_INPUT_FAILURE: "SET_USER_INPUT_FAILURE",
  FETCH_COMING_SOON: "FETCH_COMING_SOON",
  FETCH_COMING_SOON_SUCCESS: "FETCH_COMING_SOON_SUCCESS",
  FETCH_COMING_SOON_FAILURE: "FETCH_COMING_SOON_FAILURE"
};

const initializeAfterLoginEpic = (action$) =>
  action$.pipe(
    ofType(actionTypes.INITIALIZE_AFTER_LOGIN),
    // Dispatch the following three actions:
    mergeMap(() =>
      of(
        {
          type: actionTypes.GET_USER_INPUTS
        },
        {
          type: actionTypes.FETCH_USER_PROFILE
        },
        {
          type: actionTypes.FETCH_MEDIA_RESOURCES
        },
        {
          type: actionTypes.FETCH_RECENT_UPDATES
        },
        {
          type: actionTypes.FETCH_ANALYSES
        },
        {
          type: actionTypes.FETCH_ASSET_GROUPS
        },
        {
          type: actionTypes.FETCH_COMING_SOON
        }
      )
    )
  );

const analysesEpic = (action$, state$, { api }) =>
  action$.pipe(
    ofType(actionTypes.FETCH_ANALYSES),
    // debounceTime(200), // rxjs/operators, e.g.: for auto-suggest
    // delay(5000), // rxjs/operators to delay 5 seconds, e.g.: to implement/test a loader
    // switchMap: pending subscriptions are cancelled (use only last emitted value)
    switchMap(() =>
      from(api.fetchAnalyses()).pipe(
        map((result) => ({
          type: actionTypes.FETCH_ANALYSES_SUCCESS,
          payload: result
        })),
        // Uncaught errors that reach action$.pipe() will terminate it, and
        // it will no longer listen for new actions:
        catchError((error) =>
          of({
            type: actionTypes.FETCH_ANALYSES_FAILURE,
            // error could be structured further, but redux state needs to consist of serializable POJOs:
            payload: String(error),
            error: true
          })
        )
      )
    )
  );

const userProfileEpic = (action$, state$, { api }) =>
  action$.pipe(
    ofType(actionTypes.FETCH_USER_PROFILE),
    switchMap(() =>
      from(api.fetchUserProfile()).pipe(
        map((result) => ({
          type: actionTypes.FETCH_USER_PROFILE_SUCCESS,
          payload: result
        })),
        catchError((error) =>
          of({
            type: actionTypes.FETCH_USER_PROFILE_FAILURE,
            payload: String(error),
            error: true
          })
        )
      )
    )
  );

const mediaResourcesEpic = (action$, state$, { api }) =>
  action$.pipe(
    ofType(actionTypes.FETCH_MEDIA_RESOURCES),
    switchMap(() =>
      from(api.fetchMediaResources()).pipe(
        map((result) => ({
          type: actionTypes.FETCH_MEDIA_RESOURCES_SUCCESS,
          payload: result
        })),
        catchError((error) =>
          of({
            type: actionTypes.FETCH_MEDIA_RESOURCES_FAILURE,
            payload: String(error),
            error: true
          })
        )
      )
    )
  );

const recentUpdatesEpic = (action$, state$, { api }) =>
  action$.pipe(
    ofType(actionTypes.FETCH_RECENT_UPDATES),
    switchMap(() =>
      from(api.fetchRecentUpdates()).pipe(
        map((result) => ({
          type: actionTypes.FETCH_RECENT_UPDATES_SUCCESS,
          payload: result
        })),
        catchError((error) =>
          of({
            type: actionTypes.FETCH_RECENT_UPDATES_FAILURE,
            payload: String(error),
            error: true
          })
        )
      )
    )
  );

const assetGroupsEpic = (action$, state$, { api }) =>
  action$.pipe(
    ofType(actionTypes.FETCH_ASSET_GROUPS),
    switchMap(() =>
      from(api.fetchAssetGroups()).pipe(
        map((result) => ({
          type: actionTypes.FETCH_ASSET_GROUPS_SUCCESS,
          payload: result
        })),
        catchError((error) =>
          of({
            type: actionTypes.FETCH_ASSET_GROUPS_FAILURE,
            payload: String(error),
            error: true
          })
        )
      )
    )
  );

const comingSoonEpic = (action$, state$, { api }) =>
  action$.pipe(
    ofType(actionTypes.FETCH_COMING_SOON),
    switchMap(() =>
      from(api.fetchComingSoon()).pipe(
        map((result) => ({
          type: actionTypes.FETCH_COMING_SOON_SUCCESS,
          payload: result
        })),
        catchError((error) =>
          of({
            type: actionTypes.FETCH_COMING_SOON_FAILURE,
            payload: String(error),
            error: true
          })
        )
      )
    )
  );

const applySortEpic = (action$, state$) =>
  action$.pipe(
    ofType(
      actionTypes.FETCH_ANALYSES,
      actionTypes.FETCH_ANALYSES_SUCCESS,
      actionTypes.FETCH_ANALYSES_FAILURE,
      actionTypes.SET_SORT
    ),
    // brief, async delay after updating column header state, before (more expensive) sorting:
    debounceTime(5),
    map(() => {
      const { analysesResponse, sort } = state$.value;
      return {
        type: actionTypes.APPLY_SORT,
        payload: { analysesResponse, sort }
      };
    })
  );

const getUserInputsEpic = (action$, state$, { api }) =>
  action$.pipe(
    ofType(actionTypes.GET_USER_INPUTS),
    switchMap(() =>
      from(api.fetchUserInputs()).pipe(
        map((result) => {
          const payload = result.response.data.reduce(
            (acc, { attributes }) => ({
              ...acc,
              [attributes.analysis_id]: attributes
            }),
            {}
          );
          return {
            type: actionTypes.GET_USER_INPUTS_SUCCESS,
            payload
          };
        }),
        catchError((error) =>
          of({
            type: actionTypes.GET_USER_INPUTS_FAILURE,
            payload: String(error),
            error: true
          })
        )
      )
    )
  );

const setUserInputEpic = (action$, state$, { api }) =>
  action$.pipe(
    ofType(actionTypes.SET_USER_INPUT),
    // concatMap: do not subscribe to the next Observable until the current one completes.
    // eslint-disable-next-line max-len
    concatMap(({ payload: { slug, key, value } }) =>
      from(api.updateUserInput(slug, key, value)).pipe(
        map((result) => {
          const { analysis_id: id, add_to_compare, user_proposed_price } = result.response;
          const payload = {
            [id]: {
              add_to_compare,
              user_proposed_price
            }
          };
          return {
            type: actionTypes.SET_USER_INPUT_SUCCESS,
            payload
          };
        }),
        catchError((error) =>
          of({
            type: actionTypes.SET_USER_INPUT_FAILURE,
            payload: String(error),
            error: true
          })
        )
      )
    )
  );

class ECApi {
  constructor() {
    this.userCanSavePrice = false;
  }

  subscribeToUserProfile = (obs$) =>
    obs$.subscribe(
      (subscription) => {
        const { data = null } = subscription;
        let userCanSavePrice = false;
        if (data && data.attributes) {
          const { users_can_save_price = false } = data.attributes.organization;
          userCanSavePrice = users_can_save_price;
        }
        this.userCanSavePrice = userCanSavePrice;
        analytics.userCanSavePrice = userCanSavePrice;
      },
      (error) => console.error(error)
    );

  get requestOptions() {
    // eslint-disable-line class-methods-use-this
    const csrfMetaElement = document.getElementsByName("csrf-token")[0]; // does not exist in rspec/test environment
    return {
      // TODO: refresh token, for long sessions?
      "X-CSRF-Token": csrfMetaElement && csrfMetaElement.content,
      "Content-Type": "application/json"
    };
  }

  fetchAnalyses = () => ajax.getJSON(endpoints.ANALYSES);

  fetchUserProfile = () => {
    const obs$ = ajax.getJSON(endpoints.USER_PROFILE);
    this.subscribeToUserProfile(obs$);
    return obs$;
  };

  fetchMediaResources = () => {
    const obs$ = ajax.getJSON(endpoints.MEDIA_RESOURCES);
    return obs$;
  };

  fetchRecentUpdates = () => {
    const obs$ = ajax.getJSON(endpoints.RECENT_UPDATES);
    return obs$;
  };

  fetchAssetGroups = () => {
    const obs$ = ajax.getJSON(endpoints.ASSET_GROUPS);
    return obs$;
  };

  fetchComingSoon = () => {
    const obs$ = ajax.getJSON(endpoints.COMING_SOON);
    return obs$;
  };

  fetchUserInputs = () => ajax.get(endpoints.USER_ANALYSES, {}, this.requestOptions);

  updateUserInput = (analysisId, fieldKey, value) => {
    const requestBody = {
      data: {
        type: "user_analyses",
        attributes: {
          analysis_id: analysisId,
          [fieldKey]: value
        }
      }
    };
    if (fieldKey === "user_proposed_price" && !this.userCanSavePrice) {
      // rx pipeables can be either an Observable, Promise, Array, or Iterable:
      return Promise.resolve();
    }
    return ajax.patch(endpoints.USER_ANALYSES, requestBody, this.requestOptions);
  };
}

export const rootEpic = combineEpics(
  initializeAfterLoginEpic,
  analysesEpic,
  userProfileEpic,
  mediaResourcesEpic,
  recentUpdatesEpic,
  assetGroupsEpic,
  comingSoonEpic,
  applySortEpic,
  getUserInputsEpic,
  setUserInputEpic
);

export default createEpicMiddleware({
  dependencies: { api: new ECApi() }
});
