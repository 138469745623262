export const userProfileLoadingSelector = (state) => {
  return state.userProfile?.loading
};

export const userAccessStatusSelector = (state) => (
  state.userProfile.data.ec_access
)

export const userIsTrialSubscriberSelector = (state) => (
  state.userProfile.data.organization?.ec_subscription === "trial"
)
