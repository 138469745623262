// el.closest is not polyfilled by core-js:
// https://github.com/zloirock/core-js#iterable-dom-collections
export const getAnalysisRowGroup = (el) => { // eslint-disable-line import/prefer-default-export
  try {
    return el.closest('tbody');
  } catch (e) {
    // e.g., MSIE 11
    let currentNode = el;
    let i = 10;
    while (--i) { // eslint-disable-line no-plusplus
      // console.error('currentNode.tagName: ', currentNode.tagName);
      if (currentNode.tagName === 'TBODY') {
        return currentNode;
      }
      currentNode = currentNode.parentNode;
    }
  }
  return null;
};
