import React from "react";
import EnvironmentErrorDialog from "./ErrorDialog";
import logToSentry from "client/utils/logToSentry";

class EnvironmentErrorHandler extends React.Component {
  constructor() {
    super();
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true });
    logToSentry(error, info);
  }

  render() {
    const { hasError } = this.state;
    if (hasError) {
      return <EnvironmentErrorDialog variant="default" />;
    }
    return this.props.children;
  }
}

export default EnvironmentErrorHandler;
