import React from "react";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Container from "@mui/material/Container";

const useStyles = makeStyles(({ spacing }) => {
  return {
    accessFlag: {
      width: "100%",
      marginTop: spacing(2),
      padding: spacing(4),
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center"
    },
    accessFlagStatement: {
      textAlign: "center",
      marginBottom: spacing(2)
    }
  };
});

const AccessFlag = ({ toolName }) => {
  const classes = useStyles();
  return (
    <Container>
      <Paper className={classes.accessFlag}>
        <Typography className={classes.accessFlagStatement}>
          Your organization currently does not have access to this tool
        </Typography>
        <Button
          size="small"
          color="secondary"
          target="_blank"
          href={`mailto:analytics@icer.org?subject=Access Request: ${toolName}`}
        >
          Request Access
        </Button>
      </Paper>
    </Container>
  );
};

export default AccessFlag;
