import React from "react";
import { array, func } from "prop-types";
import { Button, Dialog, DialogActions, DialogContent, Grid } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import VideoCard from "./VideoCard";
import VideoPlayer from "./details/VideoPlayer";

const propsAreSame = (preProps, nextProps) => {
  return preProps.videos && nextProps.videos && preProps.videos.length === nextProps.videos.length;
};

const Videos = React.memo(({ videos, handleSelectVideo, Header = null }) => {
  return (
    <>
      {Header && <Header />}
      <Grid container spacing={2}>
        {videos.map((video, i) => (
          <Grid
            key={i}
            item
            xs={12}
            sm={6}
            lg={4}
            style={{ display: "flex", alignItems: "stretch" }}
            component="article"
          >
            <VideoCard video={video} handleClick={handleSelectVideo} />
          </Grid>
        ))}
      </Grid>
    </>
  );
}, propsAreSame);

class VideoGrid extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      activeVideo: {
        title: "",
        id: ""
      }
    };
  }

  render() {
    const { Header, videos } = this.props;
    const { activeVideo } = this.state;

    const handleSelectVideo = (resource) => {
      this.setState((prevState) => ({
        ...prevState,
        modalOpen: true,
        activeVideo: {
          title: resource.title,
          id: resource.youtube_id,
          description: resource.description,
          tags: resource.platform_tags
        }
      }));
    };
    const handleModalClose = () => {
      this.setState({
        activeVideo: { title: "", id: "", tags: [], description: "" },
        modalOpen: false
      });
    };

    return (
      <>
        <Videos videos={videos} handleSelectVideo={handleSelectVideo} />
        <Dialog
          fullWidth
          maxWidth="md"
          open={this.state.modalOpen}
          onClose={handleModalClose}
        >
          <DialogActions style={{ paddingBottom: 0 }}>
            <Button variant="text" onClick={handleModalClose} startIcon={<CloseIcon />}>
              Close
            </Button>
          </DialogActions>
          <DialogContent>
            <div style={{ minHeight: "550px" }}>
              {activeVideo.id && <VideoPlayer video={activeVideo} isFeatured={true} />}
            </div>
          </DialogContent>
        </Dialog>
      </>
    );
  }
}

VideoGrid.propTypes = {
  videos: array,
  handleSelectVideo: func,
  Header: func
};

export default VideoGrid;
