import React from "react";
import { makeStyles } from "@mui/styles";
import { bool, func, node, number, object, oneOfType, string } from "prop-types";
import { Box, Button, Container, Typography, CircularProgress } from "@mui/material";
import { Link } from "react-router-dom";
import googleAnalytics from "../google-analytics";

const useStyles = makeStyles(({ breakpoints, palette, spacing }) => {
  const horizontalPadding = {
    paddingLeft: spacing(2),
    paddingRight: spacing(2)
  };
  return {
    wrapper: {
      marginBottom: spacing(12),
      padding: 0,
      position: "relative"
    },
    border: {
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: "#e7e7e7",
      borderRadius: 4,
      margin: "12px 12px 16px",
      padding: [0, spacing(2), 0, spacing(2)],
      [breakpoints.down("sm")]: {
        padding: [0, spacing(1), 0, spacing(1)]
      }
    },
    header: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      transform: "translateY(-50%)",
      paddingRight: spacing(2)
    },
    footer: {
      paddingLeft: spacing(3),
      transform: "translateY(50%)"
    },
    contentWrapper: {
      paddingLeft: (props) => spacing(props.paddingX),
      paddingRight: (props) => spacing(props.paddingX),
      paddingBottom: spacing(4)
    },
    titleBox: {
      backgroundColor: "white",
      display: "flex",
      alignItems: "center"
    },
    wrapperIcon: {
      color: palette.primary.main,
      fontSize: "2.5rem",
      transform: "translate(-50%, 5px)",
      background: "white",
      paddingBottom: "10px"
    },
    wrapperTitle: {
      color: palette.secondary.main,
      fontSize: "2rem",
      paddingRight: spacing(2)
    },
    wrapperDescription: {
      marginBottom: spacing(4)
    },
    button: {
      borderColor: palette.secondary.main,
      color: palette.secondary.main,
      textTransform: "none",
      backgroundColor: "white",
      "&:hover": {
        backgroundColor: palette.secondary.veryLight
      }
    },
    buttonWrapper: {
      display: "inline-block",
      backgroundColor: "white",
      ...horizontalPadding,
      [breakpoints.down("xs")]: {
        display: "none"
      }
    }
  };
});
const ResourceWrapper = (props) => {
  const {
    children,
    descriptionPaddingX,
    paddingX,
    wrapperLimit,
    wrapperCount = 0,
    wrapperDescription = null,
    wrapperHref,
    WrapperIcon,
    wrapperTitle,
    loading
  } = props;

  const classes = useStyles({ paddingX: paddingX });

  const buttonAttributes = {
    component: Link,
    variant: "outlined",
    className: classes.button,
    to: wrapperHref
  };

  const showMoreButtons = wrapperCount > 0;

  let buttonText = (
    <Typography>
      View all {wrapperTitle} ({wrapperCount}){" "}
    </Typography>
  );

  if (wrapperCount <= wrapperLimit) {
    buttonText = <Typography>View {wrapperTitle} Page</Typography>;
  }

  return (
    <Container component="section" className={classes.wrapper}>
      {loading && <CircularProgress aria-label="Loading" />}
      {!loading && (
        <Box className={classes.border}>
          <Box className={classes.header}>
            <Box className={classes.titleBox}>
              <WrapperIcon className={classes.wrapperIcon} />
              <Typography component="h2" className={classes.wrapperTitle}>
                {wrapperTitle}
              </Typography>
            </Box>
            {showMoreButtons && (
              <Box className={classes.buttonWrapper}>
                <Button
                  {...buttonAttributes}
                  onClick={() => {
                    googleAnalytics.trackCustomEvent({
                      eventCategory: "Landing Page",
                      eventAction: "Click view all (Top)",
                      eventLabel: wrapperTitle
                    });
                  }}
                >
                  {buttonText}
                </Button>
              </Box>
            )}
          </Box>
          <Box className={classes.contentWrapper}>
            {wrapperCount === 0 && (
              <Typography className={classes.wrapperLabel}>
                There are currently no {wrapperTitle.toLowerCase()} to display.
              </Typography>
            )}
            {wrapperCount > 0 && wrapperDescription && (
              <Box px={descriptionPaddingX}>
                <Typography className={classes.wrapperDescription}>{wrapperDescription}</Typography>
              </Box>
            )}
            {wrapperCount > 0 && children}
          </Box>
          {showMoreButtons && (
            <Box className={classes.footer}>
              <Box className={classes.buttonWrapper}>
                <Button
                  {...buttonAttributes}
                  onClick={() => {
                    googleAnalytics.trackCustomEvent({
                      eventCategory: "Landing Page",
                      eventAction: "Click view all (Bottom)",
                      eventLabel: wrapperTitle
                    });
                  }}
                >
                  {buttonText}
                </Button>
              </Box>
            </Box>
          )}
        </Box>
      )}
    </Container>
  );
};

ResourceWrapper.defaultProps = {
  descriptionPaddingX: 0,
  paddingX: 5
};

export default ResourceWrapper;
