import React from 'react';
import {
  arrayOf, string, number, bool
} from 'prop-types';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { withRouter } from 'react-router-dom';
import withStyles from '@mui/styles/withStyles';

import { tabKeyType } from '../../constants/proptypes';
import ProgressIndicator from '../ProgressIndicator';

import { connectToState } from '../../redux/connect-api';
import {
  activeTabKeySelector,
  searchStringSelector,
  analysesLoadingSelector,
  columnCountSelector
} from '../../selectors';

const styles = () => ({
  infoCell: {
    padding: 10,
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%'
  },
})
class AnalysesTableStatusRow extends React.PureComponent {
  render() {
    const {
      columnCount, analysesLoading, activeTabKey, searchString, rowIds, classes
    } = this.props;
    let statusMessage = null;
    if (analysesLoading) {
      statusMessage = <ProgressIndicator active={analysesLoading} />;
    } else if (activeTabKey === 'compare-your-own-pricing' && rowIds.length === 0) {
      statusMessage = <h2>Pick one or more drugs from a different tab.</h2>;
    } else if (searchString && rowIds.length === 0) {
      statusMessage = <h2>No results for your search: “{searchString}”</h2>;
    }

    if (statusMessage) {
      return (
        <TableBody className="status-row">
          <TableRow>
            <TableCell colSpan={columnCount} >
              <div className={classes.infoCell}>
                {statusMessage}
              </div>
            </TableCell>
          </TableRow>
        </TableBody>
      );
    }
    return null;
  }
}

AnalysesTableStatusRow.propTypes = {
  activeTabKey: tabKeyType.isRequired,
  searchString: string.isRequired,
  analysesLoading: bool.isRequired,
  columnCount: number.isRequired,
  rowIds: arrayOf(string).isRequired
};

const mapStateToProps = (state, ownProps) => ({
  activeTabKey: activeTabKeySelector(state, ownProps),
  searchString: searchStringSelector(state, ownProps),
  analysesLoading: analysesLoadingSelector(state),
  columnCount: columnCountSelector(state, ownProps)
});


export default withStyles(styles)(withRouter(connectToState(mapStateToProps)(AnalysesTableStatusRow)));
