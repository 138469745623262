import React from "react";
import SvgIcon from "@mui/material/SvgIcon";
import { DOCUMENT_ICON_COLOR } from "./PdfIcon";

const WordIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <g>
        <path
          fill={DOCUMENT_ICON_COLOR}
          d="M15,7.5c-0.8,0-1.5-0.7-1.5-1.5V0H6C4.4,0,3,1.3,3,3v18c0,1.7,1.3,3,3,3H18c1.6,0,3-1.3,3-3V7.5H15z"
        />
        <path
          fill={DOCUMENT_ICON_COLOR}
          d="M20.3,5.3l-4.6-4.6C15.4,0.4,15,0.6,15,1v3.2c0,1,0.8,1.8,1.8,1.8H20C20.3,6,20.5,5.6,20.3,5.3z"
        />
        <path
          fill="#1554B7"
          stroke="#FFFFFF"
          stroke-width="0.1905"
          stroke-miterlimit="10"
          d="M17.2,11.3c-0.6-0.2-1.2,0.2-1.4,0.8l-1.2,4.2L13.1,12c-0.2-0.5-0.6-0.8-1.1-0.8c-0.5,0-0.9,0.3-1.1,0.8
		l-1.5,4.2l-1.2-4.2h0c-0.2-0.6-0.8-0.9-1.4-0.8c-0.6,0.2-0.9,0.8-0.8,1.4l2.2,7.5c0.1,0.5,0.6,0.8,1,0.8c0.5,0,0.9-0.3,1.1-0.8
		l1.6-4.5l1.6,4.5c0.2,0.5,0.6,0.8,1.1,0.8c0.5,0,0.9-0.3,1-0.8l2.2-7.5C18.1,12.1,17.8,11.5,17.2,11.3z"
        />
      </g>
    </SvgIcon>
  );
};

export default WordIcon;
