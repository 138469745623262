import React from 'react';
import AppBar from '@mui/material/AppBar';
import { makeStyles } from '@mui/styles';

import GlossaryContent from './modals/GlossaryContent';
import PrivacyContent from './modals/PrivacyContent';
import TermsContent from './modals/TermsContent';

import Modal from './modals/Modal';

const useStyles = makeStyles((theme) => ({
  appBar: {
    // boxShadow: 'none',
    top: 'auto',
    bottom: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    whiteSpace: 'nowrap',
    fontSize: 12,
    borderTop: '1px solid #f9f9f9',
    '& a': {
      color: theme.palette.text.gray
    },
    '& a:hover, &a:focus': {
      color: theme.palette.secondary.main
    },
    '& > nav': {
      textAlign: 'center',
      '& > span': {
        color: theme.palette.text.gray,
        padding: theme.spacing(1)
      }
    }
  }
}));

const Footer = () => {
  const classes = useStyles();

  return (
    <AppBar
      id="footer"
      component="footer"
      color="inherit" // "transparent" to debug
      className={classes.appBar}
      elevation={0}
    >
      <nav>
        <span>
          <Modal
            i18nKey="modals.glossary"
          >
            <GlossaryContent
              i18nKey="modals.glossary.terms"
            />
          </Modal>
        </span>
        <span>
          |
        </span>
        <span>
          <Modal
            i18nKey="modals.privacy"
          >
            <PrivacyContent />
          </Modal>
        </span>
        <span>
          |
        </span>
        <span>
          <Modal
            i18nKey="modals.terms"
          >
            <TermsContent />
          </Modal>
        </span>
        <span>
          |
        </span>
        <span>
          <a href="mailto:analytics@icer.org">Contact us</a>
        </span>
        <span>
          |
        </span>
        <span>
          © ICER, {new Date().getFullYear()}
        </span>
      </nav>
    </AppBar>
  );
};

export default Footer;
