import React from 'react';
import Box from '@mui/material/Box';
import Popover from '@mui/material/Popover';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import { tooltipStyle, popoverStyle } from '../component-styles'

export default function CustomPopover(props) {
  const {
    title='',
    data={},
    content='',
    id='',
     controlHeight=true,
    contentReversed=false,
    icon=null
  } = props
  const popoverClasses = popoverStyle()
  const { root, paper } = popoverClasses
  const PopoverTrigger = React.forwardRef(function TooltipContent(props, ref) {
    const classes = tooltipStyle()
    let rootClass = controlHeight ? classes.root : classes.rootNoMinHeight
    let iconClass = classes.icon
    if (contentReversed) {
      rootClass += ` ${classes.reversed}`
      iconClass += ` ${classes.iconLeft}`
    }
    let Icon = icon
    if (!Icon) {
      Icon = InfoIcon
    }
    return (
      <div {...props} ref={ref} className={rootClass + ` ${popoverClasses.trigger}`} >
        { title }
        <Icon  className={iconClass} />
      </div>
    )
  });

  return (
    <PopupState variant="popover" popupId="demo-popup-popover">
      {(popupState) => (
        <div>
          <PopoverTrigger {...bindTrigger(popupState)} />
          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            classes={{root, paper}}
            elevation={2}
          >
            <Box p={2} className={popoverClasses.box}>
              { content(popoverClasses, data) }
            </Box>
          </Popover>
        </div>
      )}
    </PopupState>
  );
}
