import React from "react";
import { Box, Container } from "@mui/material";

const AppPageContentWrapper = ({ children }) => (
  <Box
    my={4}
    display="flex"
    justifyContent="center"
    alignContent="center"
    flexDirection="column"
    component="section"
  >
    <Container>{children}</Container>
  </Box>
);

export default AppPageContentWrapper;
