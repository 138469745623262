import readCsrfToken from "client/utils/readCsrfToken";
import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { actionTypes } from "client/redux/epics";

const headers = {
  "Content-Type": "application/json",
  "X-CSRF-Token": readCsrfToken(),
};
const parseJSON = (response) => {
  return response.text().then(function (text) {
    return text ? JSON.parse(text) : {};
  });
};
export const useChangePassword = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  if (error) {
    throw new Error(error);
  }
  const cb = useCallback((input) => {
    setLoading(true);
    return fetch("/cognito_password", {
      method: "PATCH",
      headers,
      body: JSON.stringify({ user: input }),
    })
      .then((response) => {
        setLoading(false);
        if (response.ok) {
          return parseJSON(response);
        } else {
          setError("response failed");
        }
      })
      .catch((e) => setError(e.message || "request failed"));
  }, []);
  return [cb, loading];
};

export const useChangeUser = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [error, setError] = useState();
  if (error) {
    throw new Error(error);
  }
  const cb = useCallback((input) => {
    setLoading(true);
    return fetch("/api/users/me", {
      method: "PATCH",
      headers,
      body: JSON.stringify({ user: input }),
    })
      .then((response) => {
        setLoading(false);
        if (response.ok) {
          return parseJSON(response).then((data) => {
            if (!data.errors) {
              dispatch({
                type: actionTypes.FETCH_USER_PROFILE_SUCCESS,
                payload: data,
              });
            }
            return data;
          });
        } else {
          setError("response failed");
        }
      })
      .catch((e) => setError(e.message || "request failed"));
  }, []);
  return [cb, loading, dispatch];
};
