import React from "react";
import { connectToState } from "../redux/connect-api";
import { bool, shape } from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Link as MaterialLink } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { platformConfig } from "../config";
import { alpha } from "@mui/material/styles";
import googleAnalytics from "../google-analytics";
import { Link } from "react-router-dom";
import useThrottledOnScroll from '../hooks/useThrottleScroll';

const useParentTabStyles = makeStyles(({ breakpoints }) => ({
  root: {
    transition: ".25s opacity ease-in-out",
    opacity: 0,
    display: "none",
    [breakpoints.up("lg")]: {
      display: "flex"
    }
  },
  scroller: {
    display: "flex",
    alignItems: "stretch"
  },
  indicator: {
    height: 3
  }
}));
const useTabStyles = makeStyles(({ palette }) => {
  const color = palette.secondary.main;
  return {
    root: {
      color: color,
      textDecoration: "none",
      "&:hover,&:focus": {
        background: alpha(color, 0.1),
        textDecoration: "none"
      },
      "&.Mui-selected": {
        pointerEvents: "none",
        fontWeight: 600
      }
    }
  };
});

const ToolTabs = ({ loading, toolAccess, showAlways = true, showActiveTab = true }) => {
  const tabsClasses = useParentTabStyles();
  const tabClasses = useTabStyles();
  const { tools } = platformConfig;
  const [tabsVisible, showTabs] = React.useState(false);

  useThrottledOnScroll(() => {
    const container = document.getElementById('scroll-container')
    if (!container) return;
    showTabs(container.scrollTop > 800);
  }, { delay: 500 })

  const tabsStyle = { opacity: 1 };
  if (!showAlways && !tabsVisible) {
    tabsStyle.opacity = 0;
  }

  if (loading) return null;

  return (
    <>
      <Tabs
        value={showActiveTab ? tools[0].id : false}
        aria-label="ICER Tools"
        textColor="secondary"
        indicatorColor="secondary"
        classes={tabsClasses}
        style={tabsStyle}
      >
        {tools.map(({ id, name, href, to }, i) => (
          <Tab
            value={id}
            label={name}
            component={to ? Link : MaterialLink}
            href={href}
            to={to}
            classes={tabClasses}
            disabled={!toolAccess[`${id}Access`]}
            key={i}
            onClick={() => googleAnalytics.trackHeaderNav({ action: "tab", label: name })}
            tabIndex={showAlways || tabsVisible ? 0 : -1} // Only allow tab navigation if visible
          />
        ))}
      </Tabs>
    </>
  );
};

ToolTabs.defaultProps = {
  loading: true,
  toolAccess: {
    ecAccess: false,
    imAccess: false
  }
};

ToolTabs.propTypes = {
  loading: bool,
  toolAccess: shape({
    ecAccess: bool,
    imAccess: bool
  })
};

const mapStateToProps = (state) => {
  return {
    loading: state.userProfile.loading,
    toolAccess: {
      ecAccess: state.userProfile.data.ec_access,
      imAccess: state.userProfile.data.im_access
    }
  };
};

export default connectToState(mapStateToProps)(ToolTabs);
