import React from 'react';

const LandingIllustration = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 552 378">
    <g id="Group_62" data-name="Group 62" transform="translate(-639.5)">
      <g id="Component_98_1" data-name="Component 98 – 1" transform="translate(1068.5)">
        <rect id="Rectangle_30" data-name="Rectangle 30" width="108" height="378" transform="translate(8)" fill="rgba(255,255,255,0.2)"/>
        <rect id="Rectangle_34" data-name="Rectangle 34" width="50" height="178" transform="translate(37 200)" fill="rgba(0,0,0,0.2)"/>
        <g id="Group_61" data-name="Group 61" transform="translate(0 32)">
          <rect id="Rectangle_36" data-name="Rectangle 36" width="123" height="7" rx="3.5" transform="translate(0 153)" fill="#fff"/>
          <rect id="Rectangle_37" data-name="Rectangle 37" width="108" height="93" transform="translate(8 60)" fill="rgba(255,255,255,0.5)"/>
          <rect id="Rectangle_35" data-name="Rectangle 35" width="123" height="7" rx="3.5" transform="translate(0 53)" fill="#fff"/>
        </g>
      </g>
      <g id="Component_98_1-2" data-name="Component 98 – 1" transform="translate(925.5)">
        <rect id="Rectangle_30-2" data-name="Rectangle 30" width="108" height="378" transform="translate(8)" fill="rgba(255,255,255,0.2)"/>
        <rect id="Rectangle_34-2" data-name="Rectangle 34" width="50" height="192" transform="translate(37 186)" fill="rgba(0,0,0,0.2)"/>
        <g id="Group_61-2" data-name="Group 61" transform="translate(0 37)">
          <rect id="Rectangle_36-2" data-name="Rectangle 36" width="123" height="7" rx="3.5" transform="translate(0 153)" fill="#fff"/>
          <rect id="Rectangle_37-2" data-name="Rectangle 37" width="108" height="93" transform="translate(8 60)" fill="rgba(255,255,255,0.5)"/>
          <rect id="Rectangle_35-2" data-name="Rectangle 35" width="123" height="7" rx="3.5" transform="translate(0 53)" fill="#fff"/>
        </g>
      </g>
      <g id="Component_98_1-3" data-name="Component 98 – 1" transform="translate(782.5)">
        <rect id="Rectangle_30-3" data-name="Rectangle 30" width="108" height="378" transform="translate(8)" fill="rgba(255,255,255,0.2)"/>
        <rect id="Rectangle_34-3" data-name="Rectangle 34" width="50" height="378" transform="translate(37)" fill="rgba(0,0,0,0.2)"/>
        <g id="Group_61-3" data-name="Group 61" transform="translate(0 -3)">
          <rect id="Rectangle_36-3" data-name="Rectangle 36" width="123" height="7" rx="3.5" transform="translate(0 167)" fill="#fff"/>
          <rect id="Rectangle_37-3" data-name="Rectangle 37" width="108" height="121" transform="translate(8 46)" fill="rgba(255,255,255,0.5)"/>
          <rect id="Rectangle_35-3" data-name="Rectangle 35" width="123" height="7" rx="3.5" transform="translate(0 39)" fill="#fff"/>
        </g>
      </g>
      <g id="Component_98_1-4" data-name="Component 98 – 1" transform="translate(639.5)">
        <rect id="Rectangle_30-4" data-name="Rectangle 30" width="108" height="378" transform="translate(8)" fill="rgba(255,255,255,0.2)"/>
        <rect id="Rectangle_34-4" data-name="Rectangle 34" width="50" height="72" transform="translate(37 306)" fill="rgba(0,0,0,0.2)"/>
        <g id="Group_61-4" data-name="Group 61" transform="translate(0 87)">
          <rect id="Rectangle_36-4" data-name="Rectangle 36" width="123" height="7" rx="3.5" transform="translate(0 153)" fill="#fff"/>
          <rect id="Rectangle_37-4" data-name="Rectangle 37" width="108" height="93" transform="translate(8 60)" fill="rgba(255,255,255,0.5)"/>
          <rect id="Rectangle_35-4" data-name="Rectangle 35" width="123" height="7" rx="3.5" transform="translate(0 53)" fill="#fff"/>
        </g>
      </g>
    </g>
  </svg>
)

export default LandingIllustration;
