import React from "react";
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  Grid,
  Container,
  Box,
  Button,
  List,
  ListItem,
  ListItemText
} from "@mui/material";
import LandingTitle from "./LandingTitle";
import ModalWithLauncher from "../modals/ModalWithLauncher";
import Survey from "./Survey";
import { makeStyles } from "@mui/styles";
import ConnectSVG from "../../styles/landing/im-connect-to-ec.svg";
import CatchSVG from "../../styles/landing/ec-catch-meeting-highlights.svg";
import CompareSVG from "../../styles/landing/ec-compare-pricing.svg";
import SearchSVG from "../../styles/landing/ec-no-more-hunting.svg";
import DirectSVG from "../../styles/landing/im-direct-from-icer.svg";
import NewSVG from "../../styles/landing/im-stay-in-the-know.svg";
import IMImage from "../../styles/landing/im-preview1020x660.png";
import ECImage from "../../styles/landing/ec-preview1020x660.png";
import { appPageTitleStyle } from "../AppPages";
import googleAnalytics from "../../google-analytics";

const toolDescriptions = [
  {
    name: "Evidence Compendium",
    description: (className) => (
      <Typography variant="body1" className={className}>
        Streamline the use of ICER Analyses so you can make the <strong>best decisions</strong>.
      </Typography>
    ),
    image: ECImage,
    points: [
      {
        title: "No more hunting",
        icon: SearchSVG,
        description: (className) => (
          <Typography variant="body1" className={className}>
            You can search for ICER reviewed drugs by: <em>Generic Name</em>,<em>Trade Name</em>,{" "}
            <em>Condition</em>.
          </Typography>
        )
      },
      {
        title: "Compare pricing",
        icon: CompareSVG,
        description: (className) => (
          <Typography variant="body1" className={className}>
            Fast and interactive data visualization to compare how your price ranks against the ICER
            prices.
          </Typography>
        )
      },
      {
        title: "Catch the highlights",
        icon: CatchSVG,
        description: (className) => (
          <Typography variant="body1" className={className}>
            Report and meeting highlights captured in a brief video, along with the Report at a
            Glance, Patient Perspectives, and key evidence summaries and meeting snapshots.
          </Typography>
        )
      }
    ]
  },
  {
    name: "Interactive Modeler",
    description: (className) => (
      <Typography className={className}>
        Share <strong>your assumptions</strong> based on new evidence using ICER’s models.
      </Typography>
    ),
    image: IMImage,
    points: [
      {
        title: "Direct from ICER to you",
        icon: DirectSVG,
        description: (className) => (
          <Typography variant="body1" className={className}>
            Work directly with ICER’s models by varying inputs and estimates.
          </Typography>
        )
      },
      {
        title: "Connect to the Evidence Compendium",
        icon: ConnectSVG,
        description: (className) => (
          <Typography variant="body1" className={className}>
            Publish your alternative pricing benchmarks to all of the tool's users.
          </Typography>
        )
      },
      {
        title: "Stay in the Know",
        icon: NewSVG,
        description: (className) => (
          <Typography variant="body1" className={className}>
            Your subscription allows you rapid access to the new models as ICER releases them.
          </Typography>
        )
      }
    ]
  }
];

const toolStyles = makeStyles(({ spacing, breakpoints }) => {
  return {
    appPageTitleStyle: appPageTitleStyle(breakpoints),
    toolWrapper: {
      position: "relative",
      zIndex: 10,
      marginBottom: "100px"
    },
    toolCard: {
      flex: "1 1 100%"
    },
    toolCardWrapper: {
      display: "flex",
      marginBottom: spacing(6)
    },
    toolCardContainer: {
      display: "flex",
      flex: "1 1 auto",
      marginBottom: spacing(4),
      [breakpoints.up("sm")]: {
        marginBottom: 0
      }
    }
  };
});

const landingCardStyles = makeStyles(({ palette, spacing, breakpoints }) => {
  return {
    root: {
      background: "#FFFFFF 0% 0% no-repeat padding-box",
      boxShadow: "0px 3px 20px #00000029",
      border: "10px solid #FFFFFF80",
      borderRadius: "20px",
      minHeight: "100%"
    },
    imgPlaceholder: {
      minHeight: "250px",
      color: "#999"
    },
    title: {
      color: palette.primary.main,
      textTransform: "uppercase",
      font: "normal normal bold 35px/70px Lato",
      lineHeight: 1,
      marginBottom: spacing(1)
    },
    description: {
      marginBottom: spacing(3),
      "& strong": {
        fontWeight: 600
      }
    },
    listItemRoot: {
      marginBottom: spacing(2),
      paddingBottom: spacing(3),
      borderBottom: "2px solid #F4F4F4",
      justifyContent: "space-between",
      "&:last-of-type": {
        borderBottom: 0,
        margin: 0
      }
    },
    content: {
      padding: spacing(2),
      [breakpoints.up("sm")]: {
        padding: spacing(3)
      },
      [breakpoints.up("md")]: {
        padding: spacing(4)
      }
    },
    iconWrapper: {
      flex: "1 1 75px",
      minWidth: "75px",
      maxWidth: "75px",
      display: "flex",
      justifyContent: "center",
      marginRight: spacing(3)
    },
    icon: {
      width: "100%",
      flex: "1 1 100%"
    },
    listItemPrimary: {
      fontSize: 20,
      fontWeight: "bold",
      lineHeight: "26px"
    },
    listItemSecondary: {
      fontSize: 18,
      fontWeight: 300,
      lineHeight: "22px"
    }
  };
});

const buttonStyles = makeStyles(({ palette, spacing }) => {
  return {
    root: {
      width: "100%",
      padding: "15px",
      background: palette.primary.main,
      fontSize: 32,
      color: "white",
      fontWeight: 700,
      "&:hover,&:focus": {
        background: palette.secondary.main
      }
    },
  };
});

const LandingTools = () => {
  const classes = toolStyles();
  const buttonClasses = buttonStyles();
  const tools = () => {
    return toolDescriptions.map((tool, i) => (
      <Grid item md={6} mb={2} key={i} className={classes.toolCardContainer}>
        <Box mb={4} className={classes.toolCard}>
          <ToolContent tool={tool} />
        </Box>
      </Grid>
    ));
  };

  const ModalLauncher = () => (
    <Button color="primary" classes={buttonClasses} onClick={() => googleAnalytics.trackTrialRequest("under tool cards") }>
      Request Free Trial
    </Button>
  );

  return (
    <Box id="tools" className={classes.toolWrapper} component="section">
      <Typography
        variant="h2"
        component="h2"
        className={classes.appPageTitleStyle}
        align="center"
        style={{ color: "white", marginBottom: 32 }}
      >
        ICER Analytics: two tools in one
      </Typography>
      <Container>
        <Grid container spacing={2} className={classes.toolCardWrapper}>
          {tools()}
        </Grid>
        <ModalWithLauncher
          i18nKey="surveyTrial"
          Content={Survey}
          Launcher={ModalLauncher}
          mx={false}
        />
      </Container>
    </Box>
  );
};

const ToolContent = ({ tool }) => {
  const classes = landingCardStyles();
  const renderPoint = ({ title, icon, description }, key) => (
    <ListItem key={key} classes={{ root: classes.listItemRoot }}>
      <Box className={classes.iconWrapper}>
        <img src={icon} className={classes.icon} data-object-fit="cover" />
      </Box>
      <ListItemText
        primary={title}
        secondary={description()}
        classes={{ primary: classes.listItemPrimary, secondary: classes.listItemSecondary }}
      />
    </ListItem>
  );
  return (
    <Card title={tool.title} className={classes.root}>
      <CardContent classes={{ root: classes.content }}>
        <Typography variant="h3" className={classes.title}>
          {tool.name}
        </Typography>
        {tool.description(classes.description)}
        <Box className={classes.imgPlaceholder}>
          <CardMedia image={tool.image} title={tool.title} component="img" />
        </Box>
        <List>{tool.points && tool.points.map((p, i) => renderPoint(p, i))}</List>
      </CardContent>
    </Card>
  );
};

export default LandingTools;
