import { TextField } from "@mui/material";
import { useField } from "formik";
import React, { useEffect, useState } from "react";
import { IconButton, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const AppPasswordField = ({ value, newPassword = true, ...props }) => {
  const [field, { error }] = useField(props.name);
  const [showPassword, setShowPassword] = useState(false);
  // hide the password again after a timeout
  useEffect(() => {
    if (showPassword) {
      const ttl = 1000 * 60 * 5; // 5 minutes
      const timeout = setTimeout(() => setShowPassword(false), ttl);
      return () => clearTimeout(timeout);
    }
  }, [showPassword]);
  return (
    <TextField
      id={props.name}
      error={!!error}
      helperText={error}
      fullWidth
      {...field}
      {...props}
      variant="outlined"
      value={value ?? ""}
      type={showPassword ? "text" : "password"}
      InputProps={{
        autoComplete: newPassword ? "new-password" : undefined,
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              tabIndex={-1}
              aria-label="toggle password visibility"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        )
      }}
    />
  );
};

export default AppPasswordField;
