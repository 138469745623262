import React from "react";
import { shape } from "prop-types";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Zoom from "@mui/material/Zoom";
import withStyles from "@mui/styles/withStyles";
import { withRouter } from "react-router-dom";

import { tabKeyType, columnConfigType } from "../../constants/proptypes";
import AnalysesTableHeaderCell from "./AnalysesTableHeaderCell";
import { connectToState } from "../../redux/connect-api";
import { columnConfigSelector } from "../../selectors";
import { tooltipCopy } from "../../config";

const styles = ({ spacing, palette }) => ({
  tableHeading: {
    backgroundColor: palette.common.white, // otherwise, scrolled-up rows are visible
    fontSize: "12.5px",
    lineHeight: "15px",
    fontWeight: "bold",
    padding: spacing(0.5),
    verticalAlign: "bottom",
    "&.trade_name": {
      whiteSpace: "nowrap"
    },
    "&.icer_vbpb_price_label": {
      whiteSpace: "nowrap"
    },
    "&:first-of-type": {
      paddingLeft: spacing(4)
    },
    "&:last-of-type": {
      paddingRight: spacing(4)
    }
  }
});

class AnalysesTableHeaderRow extends React.PureComponent {
  render() {
    const { classes, columnConfig } = this.props;
    const { row1Props, row2Props } = columnConfig;
    const TOOLTIP_FIELDS = {
      date_of_review: tooltipCopy.dateOfReview
    };
    const getTooltip = (fieldKey) => {
      const tooltipsForField = TOOLTIP_FIELDS[fieldKey];
      return tooltipsForField ? <>{tooltipsForField}</> : null;
    };
    return (
      <TableHead>
        <TableRow>
          {row1Props.map((cellProps) => (
            <AnalysesTableHeaderCell
              key={cellProps.renderKey}
              fieldKey={cellProps.fieldKey}
              colSpan={cellProps.colSpan}
              rowSpan={cellProps.rowSpan}
              className={`${classes.tableHeading} ${cellProps.classes}`}
              align={cellProps.align}
              tooltip={getTooltip(cellProps.fieldKey)}
            >
              <div>{cellProps.label}</div>
            </AnalysesTableHeaderCell>
          ))}
        </TableRow>
        <TableRow>
          {row2Props.map((cellProps) => (
            <TableCell
              key={cellProps.renderKey}
              className={`${classes.tableHeading} ${cellProps.classes}`}
              align={cellProps.align}
            >
              <div>{cellProps.label}</div>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }
}

AnalysesTableHeaderRow.propTypes = {
  classes: shape({}).isRequired,
  // activeTabKey from react-router
  activeTabKey: tabKeyType.isRequired, // eslint-disable-line react/no-unused-prop-types
  columnConfig: columnConfigType.isRequired
};

const mapStateToProps = (state, ownProps) => ({
  columnConfig: columnConfigSelector(state, ownProps)
});

export default withStyles(styles)(
  withRouter(connectToState(mapStateToProps)(AnalysesTableHeaderRow))
); // eslint-disable-line max-len
