import React from "react";
import { makeStyles } from "@mui/styles";
import { array, bool } from "prop-types";
import { Box, Container } from "@mui/material";
import OndemandVideoRoundedIcon from "@mui/icons-material/OndemandVideoRounded";
import DescriptionRoundedIcon from "@mui/icons-material/DescriptionRounded";
import VideoSettingsRoundedIcon from "../../icons/VideoSettingsRoundedIcon";
import TipsAndUpdatesRoundedIcon from "../../icons/TipsAndUpdatesRoundedIcon";

import { connectToState } from "../../../redux/connect-api";
import ComingSoonCard from "../ComingSoon/ComingSoonCard";
import VideoGrid from "../../VideoGrid";
import DocumentGrid from "../../DocumentGrid";
import CurrentVideo from "../../CurrentVideo";
import RecentUpdates from "../../recent_updates/RecentUpdates";
import ResourceWrapper from "../../ResourceWrapper";
import { sectionStyles } from "../../../config/styles";
import {
  comingSoonLoadingSelector,
  comingSoonSelector
} from "../../../selectors";

const COMING_SOON_ITEM_LIMIT = 3;

const useStyles = makeStyles(({ palette, spacing }) => {
  return {
    featuredWrapper: {
      marginBottom: 55
    },
    featuredContainer: {
      alignItems: "center"
    },
    mediaContext: {
      background: palette.secondary.main,
      color: palette.text.light,
      borderTopLeftRadius: 20,
      borderBottomLeftRadius: 20,
      padding: spacing(4)
    },
    videoResourceWrapper: {
      display: "flex"
    },
    videoResourceContainer: {
      flex: 1
    },
    link: {
      color: "white",
      textDecoration: "underline",
      "&:hover,&:focus": {
        color: "rgba(255,255,255,.85)"
      }
    }
  };
});

const AppLandingContent = ({
  mediaResourcesLoading,
  mediaResources = [],
  recentUpdatesLoading,
  recentUpdates = [],
  comingSoonItemsLoading,
  comingSoonItems
}) => {
  const classes = useStyles();

  if (!mediaResources.length) return null;

  const resourcesByType = (type) => {
    return mediaResources
      .filter((r) => r.attributes.resource_type === type)
      .map((r) => r.attributes);
  };

  const resourceVideos = resourcesByType("resource");
  const resourceDocuments = resourcesByType("document");
  const webinars = resourcesByType("webinar");

  const currentWebinar = (webinars.length && webinars[0]) || null;

  let linkToMore = null;
  if (webinars.length > 1) {
    linkToMore = {
      text: "webinars",
      count: webinars.length
    };
  }
  const CurrentWebinar = () => {
    if (!currentWebinar) return null;
    return (
      <ResourceWrapper
        loading={mediaResourcesLoading}
        descriptionPaddingX={2}
        paddingX={0}
        wrapperCount={webinars.length}
        wrapperHref="/webinars"
        WrapperIcon={OndemandVideoRoundedIcon}
        wrapperTitle="Webinars"
      >
        <CurrentVideo
          video={currentWebinar}
          linkToMore={linkToMore}
          style={{ marginTop: "-10px" }}
        />
      </ResourceWrapper>
    );
  };
  return (
    <Box component="section" style={sectionStyles}>
      <Container className={classes.toolSelectorTools}>
        <CurrentWebinar />
        <ResourceWrapper
          loading={mediaResourcesLoading}
          wrapperCount={resourceVideos.length}
          wrapperLimit={3}
          wrapperDescription="Watch our collection of short demo videos to explore the possibilities of ICER Analytics:"
          wrapperHref="/videos"
          WrapperIcon={VideoSettingsRoundedIcon}
          wrapperTitle="Demo Videos"
        >
          <VideoGrid videos={resourceVideos.slice(0, 3)} />
        </ResourceWrapper>
        <ResourceWrapper
          loading={mediaResourcesLoading}
          wrapperCount={resourceDocuments.length}
          wrapperLimit={3}
          wrapperDescription="Download our ICER Analytics training documents:"
          wrapperHref="/documents"
          WrapperIcon={DescriptionRoundedIcon}
          wrapperTitle="Documents"
        >
          <DocumentGrid documents={resourceDocuments.slice(0, 3)} />
        </ResourceWrapper>
        <ResourceWrapper
          loading={recentUpdatesLoading}
          wrapperCount={recentUpdates.length}
          wrapperLimit={1}
          wrapperHref="/updates"
          WrapperIcon={TipsAndUpdatesRoundedIcon}
          wrapperLabel="Updates"
          wrapperTitle="Recent Updates"
        >
          <RecentUpdates limit={1} />
        </ResourceWrapper>
        <ResourceWrapper
          loading={comingSoonItemsLoading}
          wrapperCount={comingSoonItems.length}
          wrapperLimit={3}
          wrapperHref="/coming-soon"
          WrapperIcon={TipsAndUpdatesRoundedIcon}
          wrapperLabel="Updates"
          wrapperTitle="Coming Soon"
        >
          <Box display="flex" flexDirection="column" style={{ gap: "30px" }}>
            {comingSoonItems.map((props, i) => (
              <ComingSoonCard {...props} key={`coming-soon-item-${i}`} />
            ))}
          </Box>
        </ResourceWrapper>
      </Container>
    </Box>
  );
};

AppLandingContent.propTypes = {
  mediaResources: array,
  recentUpdates: array,
  loading: bool
};

const mapStateToProps = ({ mediaResources, recentUpdates, ...state }, props) => {
  return {
    mediaResourcesLoading: mediaResources.loading,
    mediaResources: mediaResources.data,
    recentUpdatesLoading: recentUpdates.loading,
    recentUpdates: recentUpdates.data,
    comingSoonItemsLoading: comingSoonLoadingSelector(state),
    comingSoonItems: comingSoonSelector(state, {
      limit: COMING_SOON_ITEM_LIMIT
    })
  };
};

export default connectToState(mapStateToProps)(AppLandingContent);
