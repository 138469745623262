import React from "react";
import { makeStyles } from "@mui/styles";
import { shape, bool } from "prop-types";
import { Box, Container, Grid } from "@mui/material";
import ToolCard from "./ToolCard";
import WrappedProgressIndicator from "./WrappedProgressIndicator";

import { connectToState } from "../redux/connect-api";
import { platformConfig } from "../config";
import { sectionStyles } from "../config/styles";

const useStyles = makeStyles(({ breakpoints }) => ({
  toolCard: {
    display: "flex",
    textAlign: "initial",
    "&:hover *": {
      textDecoration: "none",
    },
  },
  toolSelectorTools: {
    marginTop: -70,
    [breakpoints.up("md")]: {
      marginTop: -70,
      "@media (min-height: 900px)": {
        marginTop: -140,
      },
    },
  },
  toolSelectorWrapper: {
    ...sectionStyles,
    minHeight: "auto",
    marginBottom: 25,
    "@media (min-height: 900px)": {
      marginBottom: 30,
    },
  },
  toolSelectorContainer: {
    "@media (max-height: 900px)": {
      maxWidth: "80%",
      flexBasis: "80%",
    },
  },
}));

const buttonStyles = makeStyles(({ palette }) => ({
  root: {
    background: "white",
    padding: 15,
    "&:hover, &:focus": {
      background: palette.secondary.main,
      "& svg": {
        fill: "white",
      },
    },
  },
  wrapper: {
    borderRadius: "50%",
    marginTop: -40,
    position: "relative",
    zIndex: 10,
    border: `1px solid ${palette.primary.light}`,
  },
  "@keyframes upAndDown": {
    "0%": { transform: "translateY(0) scale(.99)" },
    "50%": { transform: "translateY(1px) scale(1.07)" },
    "100%": { transform: "translateY(0) scale(.99)" },
  },
  iconRoot: {
    animationDuration: "2s",
    animationName: "$upAndDown",
    animationIterationCount: "infinite",
  },
}));

const ToolSelector = ({ profileData, loading }) => {
  const classes = useStyles();

  if (loading) {
    return <WrappedProgressIndicator loading={loading} />;
  }

  const toolAccessible = (toolId) => profileData[`${toolId}_access`];

  return (
    <Box component="section" className={classes.toolSelectorWrapper}>
      <Container>
        <Grid className={classes.toolSelectorTools} container>
          <Box>
            <Box display="flex" justifyContent="center" alignItems="stretch">
              <Grid
                container
                item
                spacing={3}
                xs={12}
              >
                {platformConfig.tools.map((tool, i) => (
                  <Grid
                    key={i}
                    item
                    xs={12}
                    sm={6}
                    className={classes.toolCard}
                  >
                    <ToolCard
                      tool={tool}
                      accessible={toolAccessible(tool.id)}
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Container>
    </Box>
  );
};

ToolSelector.defaultProps = {
  profileData: null,
};

ToolSelector.propTypes = {
  profileData: shape({
    ec_access: bool,
    im_access: bool,
  }),
};

const mapStateToProps = (state) => {
  return {
    loading: state.userProfile.loading,
    profileData: state.userProfile.data,
  };
};

export default connectToState(mapStateToProps)(ToolSelector);
