import React from "react";

import BaseMenu from "./BaseMenu";
import TipsAndUpdatesRoundedIcon from "../icons/TipsAndUpdatesRoundedIcon";
import MoreTimeRoundedIcon from "../icons/MoreTimeRoundedIcon";

const UpdatesMenu = () => {
  return (
    <BaseMenu
      menuTitle="Updates"
      MenuIcon={TipsAndUpdatesRoundedIcon}
      items={[
        {
          text: "Recent updates",
          icon: <MoreTimeRoundedIcon />,
          href: "/updates",
          googleAnalyticsLabel: "Updates: Updates"
        },
        {
          text: "Coming soon",
          icon: <TipsAndUpdatesRoundedIcon />,
          href: "/coming-soon",
          googleAnalyticsLabel: "Updates: Coming Soon"
        }
      ]}
    />
  );
};

export default UpdatesMenu;
