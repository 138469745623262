import React from "react";
import { shape, string, bool } from "prop-types";
import { Card, CardContent, Menu, MenuItem, Typography } from "@mui/material/";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { connectToState } from "../redux/connect-api";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import analytics from "../google-analytics";
import { headerLinkStyles, headerMenuStyles } from "../config/styles";
import googleAnalytics from "../google-analytics";

const ITEM_HEIGHT = 48;

const UserProfile = ({ profileData = {}, loading }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const {
    email,
    first_name, // not required in Rails model
    last_name // not required in Rails model
    // phone_number,
    // organization,
    // organization: {
    //   name: organization_name,
    //   phone_number: organization_phone_number,
    //   category: organization_category
    // } = {}
  } = profileData;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    googleAnalytics.trackHeaderNav({ action: "menu", label: "Profile" });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const history = useHistory();
  const handleMenuItemClick = (e) => {
    const href = e.target.attributes["data-href"].value;
    history.push(href);
    setAnchorEl(null);
  };
  const handleLogout = () => {
    analytics.trackAuthentication(false);

    document.location.href = "/app_logout";
  };

  const linkClasses = headerLinkStyles({ anchorEl: anchorEl });
  const menuClasses = headerMenuStyles();

  return (
    <>
      <button 
        className={linkClasses.root}
        onClick={handleClick}
        aria-label="User Menu"
        aria-controls="user-profile-menu"
        aria-haspopup="true"
      >
        <AccountCircleIcon />
        <Typography component="span">Profile</Typography>
      </button>
      <Menu
        id="user-profile-menu"
        classes={{ list: menuClasses.list }}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: 200
          }
        }}
      >
        <Card
          elevation={0}
          style={{ borderBottom: "1px solid #CCCCCC", borderRadius: 0, marginBottom: "10px" }}
        >
          <CardContent style={{ paddingBottom: "15px", paddingTop: "10px" }}>
            <Typography variant="body2" noWrap>
              {first_name} {last_name}
            </Typography>
            <Typography variant="body2" noWrap>
              {email}
            </Typography>
          </CardContent>
        </Card>
        <MenuItem onClick={handleMenuItemClick} data-href="/account">
          Account Settings
        </MenuItem>
        <MenuItem onClick={handleMenuItemClick} data-href="/password">
          Change Password
        </MenuItem>
        <MenuItem onClick={handleLogout}>Log Out</MenuItem>
      </Menu>
    </>
  );
};

UserProfile.defaultProps = {
  profileData: null
};

UserProfile.propTypes = {
  profileData: shape({
    email: string,
    first_name: string,
    last_name: string,
    phone_number: string,
    ec_access: bool,
    im_access: bool,
    organization: shape({
      name: string,
      phone_number: string,
      category: string
    })
  })
};

const mapStateToProps = (state) => ({
  profileData: state.userProfile.data
});

export default connectToState(mapStateToProps)(UserProfile);
