import React from 'react'
import { Chip, Tooltip } from '@mui/material';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { makeStyles } from '@mui/styles';
import { bool, string } from 'prop-types';

const styles = makeStyles(({palette}) => ({
  root: {
    background: palette.warning.main,
    border: '1px solid white'
  },
}))

const UnpublishedChip = ({unpublished=true, label='Unpublished', size='medium', tooltipTitle=null}) => {
  if (!unpublished) {
    return null
  }
  const classes = styles()

  const chip = (<Chip
    icon={<VisibilityOffIcon />}
    classes={classes}
    label={label}
    size={size}
  />)

  if (tooltipTitle) {
    return (
      <Tooltip title={tooltipTitle}>
        {chip}
      </Tooltip>
    )
  }

  return chip
}

UnpublishedChip.propTypes = {
  unpublished: bool,
  label: string
}

export default UnpublishedChip
