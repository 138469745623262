import React from 'react';
import TableBody from '@mui/material/TableBody';
import { string } from 'prop-types';
import { withRouter } from 'react-router-dom';
import AnalysesTableDataSubRow from './AnalysesTableDataSubRow';

import { tabKeyType, dataRowPropsType, analysisType } from '../../constants/proptypes';

import { connectToState } from '../../redux/connect-api';
import { activeTabKeySelector, analysisByIdSelector, dataRowPropsSelector } from '../../selectors';
import { userIsTrialSubscriberSelector } from '../../selectors/userProfile';

class AnalysesTableDataRow extends React.PureComponent {
  renderSubRows = () => {
    const {
      activeTabKey, analysisId, row, dataRowProps, subscriptionIsTrial
    } = this.props;

    return dataRowProps.map((subRowProps) => (
      <AnalysesTableDataSubRow
        suppressContent={subscriptionIsTrial}
        key={subRowProps.rowRenderKey}
        activeTabKey={activeTabKey}
        analysisId={analysisId}
        row={row}
        subRowProps={subRowProps}
      />
    ));
  }

  render() {
    const { analysisId, row } = this.props;
    const unpublished = row.unpublished ? 'unpublished' : '';
    return (
      <TableBody
        id={analysisId}
        className={`data-row ${unpublished}`}
      >
        {this.renderSubRows()}
      </TableBody>
    );
  }
}

AnalysesTableDataRow.propTypes = {
  activeTabKey: tabKeyType.isRequired,
  analysisId: string.isRequired,
  dataRowProps: dataRowPropsType.isRequired,
  row: analysisType.isRequired
};

const mapStateToProps = (state, ownProps) => ({
  activeTabKey: activeTabKeySelector(state, ownProps),
  dataRowProps: dataRowPropsSelector(state, ownProps),
  row: analysisByIdSelector(state, ownProps),
  subscriptionIsTrial: userIsTrialSubscriberSelector(state)
});

export default withRouter(connectToState(mapStateToProps)(AnalysesTableDataRow));
