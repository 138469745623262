import { connect } from 'react-redux';
import { actionTypes } from './epics';

const mapDispatchToProps = {
  initializeAfterLogin: () => ({
    type: actionTypes.INITIALIZE_AFTER_LOGIN
  }),
  setGridFilter: (fieldKeys) => ({
    type: actionTypes.SET_GRID_FILTER,
    payload: fieldKeys
  }),
  setSort: (sortField, sortDirection, type) => ({
    type: actionTypes.SET_SORT,
    payload: { sortField, sortDirection, type }
  }),
  setUserInput: (slug, key, value) => ({
    type: actionTypes.SET_USER_INPUT,
    // meta: { slug, key },
    payload: { slug, key, value }
  })
};

// default implementation of mergeProps: Object.assign({}, ownProps, stateProps, dispatchProps)
const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  actions: dispatchProps
});

// also, forwardRef, for cell onClick events:
export const connectToState = (mapStateSliceToProps) =>
  connect(mapStateSliceToProps, mapDispatchToProps, mergeProps, { forwardRef: true });
