import { OutlinedTextFieldProps, TextField } from '@mui/material';
import { useField } from 'formik';
import React from 'react';
import PropTypes from 'prop-types';

const AppTextField = ({ value, ...props }) => {
  const [field, { error }] = useField(props.name);

  /* eslint-disable react/jsx-props-no-spreading */
  return (
    <TextField
      id={props.name}
      error={!!error}
      helperText={error}
      fullWidth
      {...field}
      {...props}
      variant="outlined"
      value={value ?? ''}
    />
  );
  /* eslint-enable react/jsx-props-no-spreading */
};
AppTextField.propTypes = {
  name: PropTypes.string.isRequired,
};

export default AppTextField;
