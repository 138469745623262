import { Button, Paper } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ErrorOutlineTwoTone as ErrorIcon } from "@mui/icons-material";
import React from "react";

const useStyles = makeStyles(({ typography, spacing }) => ({
  root: {
    display: "flex",
    padding: spacing(3),
    marginTop: spacing(5),
    marginLeft: "auto",
    marginRight: "auto",
    maxWidth: 500,
  },
  text: {
    marginBottom: spacing(3),
  },
  icon: {
    marginRight: spacing(2),
    flexGrow: 0,
  },
  message: {
    ...typography.h6,
    flexGrow: 1,
  },
}));

const EnvironmentErrorDialog = ({ variant = "default" }) => {
  const classes = useStyles();
  return (
    <Paper key="error" className={classes.root} variant="outlined">
      <div className={classes.icon}>
        <ErrorIcon fontSize="large" />
      </div>
      <div className={classes.message}>
        <div className={classes.text}>
          {
            "We're sorry but something went wrong. We've been notified about this issue and we'll take a look at it shortly."
          }
        </div>
        <div className="text-right">
          <Button variant="contained" color="primary" component="a" href="/">
            Continue
          </Button>
        </div>
      </div>
    </Paper>
  );
};
export default EnvironmentErrorDialog;
