import React from "react";
import { oneOfType, arrayOf, shape, node, func, number, string, bool } from "prop-types";
import TableCell from "@mui/material/TableCell";
import CustomTooltip from "../CustomTooltip";
import { withStyles } from "@mui/styles";

import { stickyRowHeadingKeys, getType, getAlignment } from "../../config";
import ModalWithLauncher from "../modals/ModalWithLauncher";
import EbmRatingContent from "../modals/EbmRatingContent";

const TOOLTIP_FIELDS = ["population_and_comparator", "annual_dosage_assumptions"];

const styles = (theme) => ({
  tableCell: {
    padding: theme.spacing(0.5),
    fontSize: "13px",
    // width: '60px',
    maxWidth: "200px",
    "&.supportsTooltip": {
      "& div": {
        textOverflow: "ellipsis",
        display: "-webkit-box",
        "-webkit-line-clamp": "3",
        "-webkit-box-orient": "vertical",
        overflow: "hidden"
      },
      "&.unscrollable": {
        "& div": {}
      },
      "&.scrollable": {
        // bg image is in: app/javascript/client/styles/icer-ui.css
        paddingRight: "20px"
      }
    },
    '&[rowspan="4"].supportsTooltip': {
      // different style, if sub-rows are rendered, or not?
      "& div": {}
    }
  }
});

class AnalysesTableDataCell extends React.PureComponent {
  constructor(props) {
    super(props);
    const supportsTooltip = TOOLTIP_FIELDS.includes(props.fieldKey);
    this.state = { supportsTooltip, isScrollable: false, hovered: false };
    this.contentRef = React.createRef();
  }

  componentDidMount() {
    const { supportsTooltip } = this.state;
    if (supportsTooltip) {
      this.detectScrollable();
    }
  }

  componentDidUpdate() {
    const { supportsTooltip } = this.state;
    if (supportsTooltip) {
      this.detectScrollable();
    }
  }

  highlight = () => {
    this.setState({ hovered: true });
  };

  dehighlight = () => {
    this.setState({ hovered: false });
  };

  detectScrollable = () => {
    const { supportsTooltip } = this.state;
    if (supportsTooltip) {
      const { contentRef } = this;
      if (contentRef && contentRef.current) {
        const { scrollWidth, offsetWidth, scrollHeight, offsetHeight } = contentRef.current;
        if (scrollWidth > offsetWidth || scrollHeight > offsetHeight) {
          this.setState({ isScrollable: true });
        }
      }
    }
  };

  handleMouseOver = () => {
    const { onClick } = this.props;
    const { isScrollable } = this.state;
    if (isScrollable || onClick) {
      this.highlight();
    }
  };

  handleMouseOut = () => {
    const { onClick } = this.props;
    const { isScrollable } = this.state;
    if (isScrollable || onClick) {
      this.dehighlight();
    }
  };

  handleMouseClick = (e) => {
    const { onClick } = this.props;
    if (onClick) {
      onClick(e);
    }
  };

  renderContent = () => {
    const { children, fieldKey, tooltip, isSuppressed=false } = this.props;
    const { supportsTooltip } = this.state;

    if (isSuppressed) return children

    if (fieldKey == "ebm_rating") {
      let rating = ""
      const {props:childProps} = children
      if (typeof(childProps) === "object" && "children" in childProps) rating = childProps.children
      if (!rating) return null;
      return (
        <ModalWithLauncher
          i18nKey="modals.ebm_rating"
          title={"Evidence Rating Matrix"}
          Launcher={() => rating}
          Content={EbmRatingContent}
        />
      );
    }
    if (supportsTooltip || tooltip) {
      if (tooltip) {
        return <CustomTooltip id={fieldKey} content={children} title={tooltip} />;
      } else {
        return <CustomTooltip id={fieldKey} content={tooltip || children} />;
      }
    }
    return children;
  };

  getTooltip = (row, fieldKey) => {
    const tooltipsForField = TOOLTIP_FIELDS[fieldKey];
    if (!tooltipsForField) return null;
    const content = tooltipsForField[row[fieldKey]];
    return content ? <div>{content}</div> : null;
  };

  render() {
    const { rowSpan, fieldKey, classes, isSuppressed } = this.props;
    const { supportsTooltip, isScrollable, hovered } = this.state;

    const hoveredClass = hovered ? "hovered" : "";
    const typeClass = getType(fieldKey);
    const supportsTooltipClass = supportsTooltip ? "supportsTooltip" : "";
    const scrollClass = !supportsTooltip ? "" : isScrollable ? "scrollable" : "unscrollable"; // eslint-disable-line no-nested-ternary

    const renderWithSuppression = isSuppressed && !fieldKey.includes("add_to")

    return (
      <TableCell // eslint-disable-line jsx-a11y/mouse-events-have-key-events
        // TODO: mouse-events-have-key-events > accessibility ? > keyboard focus, for all users?
        rowSpan={rowSpan}
        onMouseOver={this.handleMouseOver}
        onMouseOut={this.handleMouseOut}
        onClick={this.handleMouseClick}
        className={`${classes.tableCell} ${fieldKey} ${typeClass} ${supportsTooltipClass} ${scrollClass} ${hoveredClass}`}
        component={stickyRowHeadingKeys.includes(fieldKey) ? "th" : "td"}
        align={getAlignment(fieldKey)}
        sx={{"> div": {filter: renderWithSuppression ? "blur(3px)" : undefined}}}
      >
        {this.renderContent()}
      </TableCell>
    );
  }
}

AnalysesTableDataCell.defaultProps = {
  rowSpan: 1,
  onClick: null,
  children: null
};

AnalysesTableDataCell.propTypes = {
  rowSpan: number,
  fieldKey: string.isRequired,
  onClick: func,
  classes: shape({}).isRequired,
  children: oneOfType([arrayOf(node), node]),
  isSuppressed: bool
};

export default withStyles(styles)(AnalysesTableDataCell);
