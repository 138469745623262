import { makeStyles } from "@mui/styles";

export const tooltipStyle = makeStyles(({ palette }) => ({
  root: {
    display: "flex !important",
    minHeight: 50
  },
  rootNoMinHeight: {
    display: "flex !important",
    minHeight: "auto"
  },
  reversed: {
    flexDirection: "row-reverse"
  },
  icon: {
    fill: palette.tooltip.icon,
    marginTop: "auto",
    marginLeft: "auto",
    fontSize: 16
  },
  iconLeft: {
    paddingRight: 2
  }
}));

export const popoverStyle = makeStyles(({ components, palette }) => ({
  trigger: {
    cursor: "pointer"
  },
  root: {
    "& p:last-of-type": {
      margin: 0
    }
  },
  paper: {
    backgroundColor: `${components.MuiTooltip.styleOverrides.tooltip.backgroundColor} !important`
  },
  box: {},
  description: {
    color: "white",
    marginTop: 0
  },
  list: {
    color: "white",
    marginBottom: 0,
    "& li": {
      lineHeight: "20px"
    }
  },
  link: {
    color: palette.text.secondaryLinkLight,
    "&:hover": {
      color: "white"
    }
  }
}));

export const baseCardStyle = (palette) => ({
  backgroundColor: "transparent",
  borderColor: palette.secondary.light,
  color: palette.secondary.main,
  fontSize: "1.4rem",
  justifyContent: "start",
  padding: "1rem 1.5rem",
  textTransform: "none"
});

export const cardHover = (palette) => ({
  transition: "all .22s ease-in-out",
  "&:hover,&:focus,&:focus-within": {
    transform: "translateY(-4px)",
    boxShadow:
      "0px 2px 1px -1px rgba(0, 0, 0, 0.1), 0px 5px 7px rgba(0, 0, 0, 0.05), 0px 4px 15px rgba(0, 0, 0, 0.05)",
    borderColor: palette.secondary.light,
    backgroundColor: palette.secondary.veryLight
  }
});

export const menu = {
  menu: {
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
    alignContent: "flex-start",
    paddingLeft: 32,
    paddingRight: 32,
    paddingBottom: 0,
    paddingTop: 0,
    borderBottom: "1px solid rgb(224, 224, 224)",
    borderRadius: 0,
    flex: "1 1 auto"
  },
  menuActionsSecondary: {
    marginLeft: "auto"
  },
  searchString: {
    alignSelf: "center"
  }
};
