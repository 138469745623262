import React from "react";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  root: {
    "& h4": {
      // border: '1px dashed red',
      marginBottom: "8px"
    },
    "& h5": {
      // color: 'red',
      margin: 0
    },
    "& li": {
      // border: '1px dashed #f0f',
      marginTop: 0
    },
    "& p": {
      // border: '1px dashed green',
      marginTop: 0
    },
    "& a": {}
  }
}));

const PrivacyContent = React.memo(() => {
  const classes = useStyles();

  /* eslint-disable max-len */
  return (
    <article className={classes.root}>
      <p>
        These Terms of Use and Conditions ("Terms") govern your ("you" and "your") use of the
        Evidence for Healthcare Improvement d/b/a&nbsp;Institute for Clinical and Economic Review
        ("ICER", "our", "us" or "our") website(s) available at{" "}
        <a target="_blank" href="https://analytics.icer.org/">https://analytics.icer.org/</a>,{" "}
        <a target="_blank" href="https://icer-review.org/">https://icer-review.org/</a>; and{" "}
        <a target="_blank" href="https://icer.org/">https://icer.org/</a> (each, a "Site" and collectively, the
        "Sites") and all features, modalities, applications, programs, and other materials
        accessible through or in connection with the Sites (collectively, the "
        <strong>Services</strong>").{" "}
        <strong>
          Please read the terms in full before using our Site. By accepting these Terms, or by
          accessing or using this Site, you agree to be legally bound by these Terms and our&nbsp;
        </strong>
        <a target="_blank" href="https://icer-review.org/about/privacy-policy/">
          <strong>Privacy Policy</strong>
        </a>
        <strong>. If you do not agree to these terms, please do not use our Site.</strong> We
        reserve the right to modify these Terms at any time without prior notice, and your use of
        the Site binds you to the changes made. We do occasionally update these Terms so please
        refer to them in the future.
      </p>
      <h4>Access to Site</h4>
      <p>
        You will be able to access our Site without having to register any details with us, however
        some functions available on the Site may require you to disclose certain personal
        information.
      </p>
      <h4>Use of Site</h4>
      <p>
        Our Site, the Services, and any images, data, text, audios, videos, photographs, custom
        graphics, logos, button icons, descriptions and all other material provided and the
        collection and compilation and assembly thereof are the exclusive property of ICER or its
        affiliates or licensors, and are protected by U.S. and international copyright laws. Certain
        Services may be subject to additional terms and conditions (collectively, the "
        <strong>Additional Terms</strong>") as may be set forth herein, on the relevant Sites, or in
        connection with your use of the Services. Your use of such Services constitutes your
        agreement to the applicable Additional Terms and such Additional Terms are hereby
        incorporated into these Terms.
      </p>
      <p>
        <strong>Conditioned upon your compliance with these Terms</strong>, the contents of our Site
        and our Services may be used for your personal, informational, non-commercial purposes only.
        Any other use of such contents, including the reproduction, modification, distribution,
        transmission, republication, display or performance of the contents without our prior
        written consent is expressly forbidden. Please note the foregoing license is non-exclusive,
        non-transferable, and non-sublicensable, and is revocable by ICER at any time for any or no
        reason.
      </p>
      <p>
        Any trademarks or service marks appearing anywhere on our Site are the property of their
        respective owners. A product name or logo not appearing with a trademark designation
        (&trade;, &reg;) does not constitute a waiver of any intellectual property rights that the
        Institute for Clinical and Economic Review, its affiliates or its licensors have established
        in any of their products, services, features, or service names or logos.
      </p>
      <p>
        You may not use, reproduce, modify, transmit, display, publish, sell, create derivative
        works of, or distribute by any means, method, or process whatsoever, now known or hereafter
        developed, any content of this Site or Services for commercial profit or gain, unless
        expressly authorized to do so by ICER. Any non-commercial analysis, commentary, manuscript,
        or other material developed based on use of the contents of this Site that is intended for
        presentation, submission, or any other means of dissemination must be shared with ICER for
        comment at least 30 days prior to planned presentation, submission, or other dissemination.
        Nothing in these Terms or on the Site shall be construed as conferring any intellectual
        property or other proprietary rights to you. You agree to observe copyright and other
        applicable laws and may not use the content in any manner that infringes or violates the
        rights of any person or entity, including us. We hold or have licensed all rights to the
        content on this Site.
      </p>
      <p>
        We welcome your comments and feedback regarding our Site, products, and services. We do not,
        however, accept confidential or proprietary information. Thus, all comments (whether public
        or private), feedback, ideas, suggestions, materials, information, and other submissions
        disclosed, submitted or offered to us using the Site or otherwise (collectively, "
        <strong>Communications</strong>") are not confidential and will become and remain our
        property. The disclosure, submission, or offer of any Communications will constitute an
        assignment to ICER of all worldwide rights, titles, and interests, and goodwill in any and
        all media now known or hereafter devised in perpetuity, in and to the Communications without
        payment of any compensation. Additionally, Communications submitted by you must not violate
        any right of any third party, and must not contain any libelous, abusive, obscene, or
        otherwise unlawful material. If your Communications violate these Terms, you may bear legal
        responsibility for such Communications, and shall indemnify, defend and hold harmless ICER,
        its affiliates and their licensors for any third party liability associated therewith.
      </p>
      <p>
        As a condition of your use of the Site, you will not use the Site, or any contents or
        services, for any purpose that is unlawful in any jurisdiction where our Site is being used,
        or prohibited by these terms, conditions, and notices, and you agree to abide by the terms
        and conditions set forth in the "User Conduct" section. You may not use the Site&rsquo;s
        contents or services in any manner that could damage, disable, overburden, or impair any of
        our equipment or interfere with any other party&rsquo;s use and enjoyment of the Site, or
        any contents or services. You may not attempt to gain access to any portion of the Site, or
        any of its contents or services, other than those for which you are authorized.
      </p>
      <p>
        While every effort is made to ensure the timeliness and accuracy of the Site content and
        services, we make no warranties either express or implied, and assume no liability or
        responsibility for the completeness, use, accuracy, reliability, correctness, timeliness or
        usefulness, of any of the results obtained from its use.
      </p>
      <p>
        We make no representation or warranty, express or implied, with respect to the content of
        the Site, or links to other sites, including but not limited to accurateness, completeness,
        correctness, timeliness or reliability. We make no representation or warranty regarding the
        merchantability, fitness for a particular purpose or use or non-infringement of third party
        rights with respect to any content or services whatsoever that may be accessed through this
        Site, or the results to be obtained from using the Site. We make no representation or
        warranty that the Site or content is free from defects or viruses. Your use of external
        links and third-party websites is at your own risk and subject to the terms and conditions
        of use for such links and sites.
      </p>
      <p>
        While every effort is made to ensure that all content provided on the Site does not contain
        computer viruses and/or harmful materials, you should take reasonable and appropriate
        precautions to scan for computer viruses or otherwise protect your computer and you should
        ensure that you have a complete and current backup of the applicable items on your computer.
        We disclaim any liability for the need for services or replacing equipment or data resulting
        from your use of the Site. While every effort is made to ensure smooth and continuous
        operation, we do not warrant the Site will operate error-free.
      </p>
      <h4>Site Uptime</h4>
      <p>
        We take all reasonable steps to ensure that our Site is available 24 hours every day, 365
        days per year. However, websites do sometimes encounter downtime due to server and other
        technical issues. Therefore, we will not be liable if this Site is unavailable at any time.
      </p>
      <p>
        Our Site may be temporarily unavailable due to issues such as system failure, maintenance or
        repair, or for reasons beyond our control. Where possible, we may try to give our users
        advance warning of maintenance issues, but shall not be obliged to do so.
      </p>
      <h4>User Conduct</h4>
      <p>
        Any material you send or post to our Site shall be considered non-proprietary and
        non-confidential. We do not collect or store any personally identifiable information about
        you while using our Site but please do not send any such information to us. See also our
        Privacy Policy and Notice of Privacy Practices, which can be found&nbsp;
        <a target="_blank" href="https://icer-review.org/about/privacy-policy/">here</a>.<br /> When using our Site,
        you shall not post or send to or from the Site:
      </p>
      <ul>
        <li>content for which you have not obtained all necessary consents;</li>
        <li>
          content that is discriminatory, obscene, pornographic, defamatory, liable to incite racial
          hatred, in breach of confidentiality or privacy obligations, which may cause annoyance or
          inconvenience to others, which encourages or constitutes conduct that would be deemed a
          criminal offense, give rise to a civil liability, or otherwise is contrary to the law in
          any applicable jurisdiction where our Site is being used; or
        </li>
        <li>
          content which is harmful in nature including, and without limitation, computer viruses,
          Trojan horses, corrupted data, or other potentially harmful software or data.
        </li>
      </ul>
      <p>
        ICER expects all users to respect ICER&rsquo;s and third parties&rsquo; intellectual
        property. As such, you agree that:
      </p>
      <ol type="i">
        <li>
          No material from the Sites may be copied, reproduced, republished, uploaded, posted,
          transmitted, or distributed in any way, except that you may download certain downloadable
          materials on any single computer for your personal, non-commercial use only, provided you
          keep intact all copyright and other proprietary notices. For purposes of this Agreement,
          the use of any such material on any other web site or networked computer environment is
          prohibited.
        </li>
        <li>
          You may not use the Site or Services to store or transmit infringing, libelous, or
          otherwise unlawful or tortious material, or material in violation of third-party privacy
          or other rights, or code, files, scripts, agents or programs intended to do harm,
          including, for example, viruses, worms, time bombs, and Trojan horses.
        </li>
        <li>
          You may not interfere with or disrupt the integrity or performance of the Site or Services
          or third-party data contained therein; attempt to gain unauthorized access to the Site or
          Services or its related systems or networks; copy the Services or any part, feature,
          function or user interface thereof unless expressly permitted hereunder.
        </li>
        <li>
          You may not modify, correct, adapt, translate, enhance or otherwise prepare derivative
          works or improvements of the Site or Services.
        </li>
        <li>
          You may not access the Site or Services in order to build a competitive product or
          service, or for purposes of monitoring its availability, performance or functionality, or
          for any other benchmarking or competitive purposes.
        </li>
        <li>
          You may not "harvest" or collect information from the Site or Services (including
          information about other users of the Site or Services or offerings, products or services
          available on the Site or Services) using an automated software tool or manually on a mass
          basis.
        </li>
        <li>
          You may not reverse engineer, disassemble, decompile, decode or otherwise attempt to
          derive or gain access to the source code of the Site or Services or any component thereof,
          in whole or in part, except as and only to the extent such restriction is permitted by any
          applicable federal, state, or local laws, codes, rules, regulations, or orders of any
          governmental authority ("Law").
        </li>
        <li>
          You may not integrate or link to any open source software or freeware with the Site or
          Services.
        </li>
        <li>
          You may not remove any proprietary notices, labels or marks from the Site or Services.
        </li>
        <li>
          You may not sell, resell, license, sublicense, distribute, rent, or lease the Services,
          include the Site or Services in a service bureau or outsourcing offering, or otherwise
          access or use the Site or Services other than as expressly permitted hereunder.
        </li>
        <li>You may not permit third parties to do any of the foregoing.</li>
        <li>
          You represent, warrant, and covenant that (i) you are of the legal age of majority in your
          jurisdiction and/or are an authorized representative of your company with the full power
          and authority to enter into the Terms; (ii) you will comply at all times with all relevant
          and applicable Laws; and (iii) to the extent you share with ICER any personal data of any
          third party for any purpose, you have the authority (including any necessary consents), as
          required under applicable Law, to provide ICER with such personal data, and allow ICER to
          use such personal data for the purposes for which you shared it.
        </li>
      </ol>
      <p>
        We will fully cooperate with any law enforcement authorities or court order requiring us to
        disclose the identity or other details or any person posting materials to our Site in breach
        of this Section.
      </p>
      <p>
        You shall not use our Site while distracted or preoccupied, such as when operating a motor
        vehicle. You should access our Site only with due regard for your own safety and the safety
        of others.
      </p>
      <h4>Links to and from Third Party Websites</h4>
      <p>
        Any links furnished on our Site may allow you to leave our Site (each a "Linked Site").
        These Linked Sites are not under our control and we disclaim any responsibility for the
        contents of such Linked Sites or any link(s) contained therein or any changes or updates
        thereto. We further disclaim any responsibility for any form of transmission received from
        any linked third party website or advertising. If we provide these links to you, it is done
        simply as a convenience to you and inclusion of any link herein shall in no way be construed
        as an endorsement by us of the site(s).
      </p>
      <p>
        If you would like to link to our Site, you may only do so on the basis that you link to, but
        do not replicate, any page of our Site, and subject to the following conditions:
      </p>
      <ul>
        <li>
          you do not in any way imply that we are endorsing any services or products unless this has
          been specifically agreed with us;
        </li>
        <li>
          you do not misrepresent your relationship with us or present any false information about
          us;
        </li>
        <li>you do not link from a website that is not owned by you; and</li>
        <li>
          your website does not contain content that is offensive, controversial, infringes any
          intellectual property rights or other rights of any other person or does not comply in any
          way with the applicable laws.
        </li>
      </ul>
      <p>
        If you choose to link our Site in breach of this Section, you shall fully indemnify us for
        any loss or damage suffered as a result of your actions.
      </p>
      <h4>Disclaimers</h4>
      <p>
        All information on our Site is of a general nature and is furnished for your knowledge and
        understanding and as an informational resource only. The documents and any related graphics
        published on this Site could include technical inaccuracies or typographical errors. Changes
        are periodically added to the information contained on our Site. We may make improvements
        and/or changes to any of the products or services depicted or described herein at any time.
        We expressly disclaim any responsibility or liability for any adverse consequences or
        damages resulting from your use of the Site or reliance on the information from it.
      </p>
      <p>
        THE SITE IS PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS. WE MAKE NO WARRANTIES, EXPRESS
        OR IMPLIED, REPRESENTATIONS OR ENDORSEMENTS WHATSOEVER WITH REGARD TO ANY PRODUCTS,
        INFORMATION OR SERVICE PROVIDED THROUGH THIS SITE, INCLUDING, TITLE, NON-INFRINGEMENT, OR
        ANY OTHER WARRANTY, CONDITION, GUARANTY, OR REPRESENTATION, WHETHER ORAL OR IN WRITING, OR
        IN ELECTRONIC FORM.
      </p>
      <h4>Limitation of Liability</h4>
      <p>
        {" "}
        YOUR USE OF OUR SITE IS ENTIRELY AT YOUR OWN RISK. WE WILL NOT BE RESPONSIBLE TO YOU OR TO
        ANY THIRD PARTIES FOR ANY DIRECT OR INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL, OR
        PUNITIVE DAMAGES OR LOSSES OR INJURY YOU OR ANY THIRD PARTY MAY INCUR IN CONNECTION WITH
        YOUR USE OR INABILITY TO USE THE SITE OR THE PRODUCTS AND SERVICES DESCRIBED THEREIN, THE
        INFORMATION CONTAINED ON THE SITE, OR SERVICES ACTUALLY PROVIDED BY US, OR THAT RESULT FROM
        MISTAKES, OMISSIONS, INTERRUPTIONS, DELETIONS OR ANY FAILURE OF PERFORMANCE, OR ANY OF THE
        DATA OR OTHER MATERIALS TRANSMITTED THROUGH OR RESIDING ON OUR SITE, REGARDLESS OF THE TYPE
        OF CLAIM OR THE NATURE OF THE CAUSE OF ACTION, EVEN IF WE HAVE BEEN ADVISED OF THE
        POSSIBILITY OF SUCH DAMAGE OR LOSS.
      </p>
      <h4>Indemnification</h4>
      <p>
        {" "}
        You agree to defend, indemnify, and hold us, our officers, directors, employees, volunteers,
        agents, licensors, and suppliers, harmless from and against any claims, actions or demands,
        liabilities and settlements including without limitation, reasonable legal and accounting
        fees, resulting from or alleged to result from, your use of and access to the Site or your
        violation of these Terms or your violation of any third party right, including without
        limitation any trademark, copyright or other proprietary or privacy right.
      </p>
      <h4>Assignment</h4>
      <p>
        {" "}
        These Terms, and any rights and licenses granted hereunder, may not be transferred or
        assigned by you.
      </p>
      <h4>Applicable Law</h4>
      <p>
        {" "}
        We make no representations that the content or the Site are appropriate or may be used or
        downloaded outside the United States. Access to the Site and/or the content may not be legal
        in certain countries outside the United States. If you access the Site from outside the
        United States, you do so at your own risk and are responsible for compliance with the laws
        of the jurisdiction from which you access the Site.
      </p>
      <p>
        Our Site is created and controlled in the Commonwealth of Massachusetts, United States of
        America. The Site, and its contents, and any disputes arising therefrom shall be construed
        and interpreted under the laws of the Commonwealth of Massachusetts, and applicable United
        States federal laws. Use of our Site constitutes agreement of the user to the exclusive
        jurisdiction of the state and federal courts located therein.
      </p>
      <p>
        Our Site is subject to the United States export control laws and regulations, and may be
        subject to export or import regulations in other countries. You agree to strictly comply
        with all such laws and regulations and acknowledge that you have the responsibility to
        obtain authorization to export, re-export, or import any data on our Site, as may be
        required.
      </p>
      <h4>General</h4>
      <p>
        These Terms, together with the Privacy Policy and Notice of Privacy Practices, and any legal
        notices published by us on the Site, shall constitute the entire agreement between us
        concerning use of the Site except to the extent we have agreed otherwise in writing. If any
        provision of these Terms is deemed invalid by a court of competent jurisdiction, the
        invalidity of such provision shall not affect the validity of the remaining provisions of
        these Terms, which shall remain in full force and effect. No waiver of any term shall be
        deemed a further or continuing waiver of such term or any other term, and our failure to
        assert any right or provision under these Terms shall not constitute a waiver of such right
        or provision. YOU AGREE THAT ANY CAUSE OF ACTION ARISING OUT OF OR RELATED TO THE SITE MUST
        COMMENCE WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES. OTHERWISE, SUCH CAUSE OF
        ACTION IS PERMANENTLY BARRED.
      </p>
      <h4>Our Contact Information</h4>
      <address>
        <div>Institute for Clinical and Economic Review</div>
        <div>
          14 Beacon Street, 8<sup>th</sup> Floor
        </div>
        <div>Boston, MA 02108</div>
        <div>(617) 528-4013</div>
        <div>
          {" "}
          <a href="mailto:info@icer-review.org">info@icer-review.org&nbsp;</a>
        </div>
      </address>
    </article>
  );
});

PrivacyContent.propTypes = {};

export default PrivacyContent;
