import React from 'react'
import { surveys } from '../../config'
import { Container } from '@mui/material'

class Survey extends React.Component {

  componentDidMount() {
    let el = document.createElement("script")
    el.src = "https://widget.surveymonkey.com/collect/website/js/tRaiETqnLgj758hTBazgd10Mk_2FpWCzXy2JaDS8uYlkoMgT7wiQxbRDL8hz4MyGPt.js"
    document.body.appendChild(el)
  }

  render() {
    return (
      <div id="smcx-sdk"></div>
    )
  }
}

export default Survey
