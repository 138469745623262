import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { Box, Chip, Link, Typography } from "@mui/material";
import OndemandVideoRoundedIcon from "@mui/icons-material/OndemandVideoRounded";
import DescriptionRoundedIcon from "@mui/icons-material/DescriptionRounded";
import { formateDate } from "../../../utils/date";
import LaunchIcon from "@mui/icons-material/Launch";
import VideoDialog from "../../VideoDialog";
import EventIcon from "@mui/icons-material/Event";
import googleAnalytics from "../../../google-analytics";
import NewChip from "../../NewChip";

// TODO: Relace the interpolated `gap` values after configuring the theme
// TODO: to know what unit to apply
const useStyles = makeStyles(({ spacing, palette, breakpoints }) => {
  return {
    wrapper: {
      display: "flex",
      gap: spacing(1.5),
      position: "relative"
    },
    wrapperWithBorder: {},
    iconWrapper: {
      color: palette.primary.main,
      fontSize: 35,
      [breakpoints.up("sm")]: {
        fontSize: 50
      }
    },
    contentWrapper: {
      display: "flex",
      flexDirection: "column",
      gap: 0,
      [breakpoints.up("sm")]: {
        gap: "2px"
      }
    },
    linkWithIcon: {
      display: "flex",
      alignItems: "center",
      gap: "3px",
      "& svg": {
        transform: "translateY(2px)"
      }
    }
  };
});

const ComingSoonCardSection = ({
  type = "report",
  title,
  date,
  registrationUrl = null,
  videoId = null,
  description = "",
  last = false,
  isNew = false,
  ...props
}) => {
  const classes = useStyles();
  let typeName = "Report";
  if (type !== "report") {
    typeName = type === "event" ? "Exclusive Event" : "Exclusive Webinar";
  }
  const showDate = !(type === "webinar" && videoId);
  const [modalOpen, setOpenModal] = useState(false);
  const toggleModal = () => {
    googleAnalytics.trackVideoThumbnailClick(title);
    setOpenModal(!modalOpen);
  };

  const wrapperClasses = [classes.wrapper];
  return (
    <Box className={wrapperClasses.join(" ")}>
      <Box>
        {type === "report" && <DescriptionRoundedIcon classes={{ root: classes.iconWrapper }} />}
        {type === "webinar" && <OndemandVideoRoundedIcon classes={{ root: classes.iconWrapper }} />}
        {type === "event" && <EventIcon classes={{ root: classes.iconWrapper }} />}
      </Box>
      <Box className={classes.contentWrapper}>
        <Typography variant="caption">{typeName}</Typography>
        <Typography
          component="h2"
          variant="h4"
          style={{ marginBottom: 3, fontWeight: "bold", display: "flex", gap: "5px" }}
        >
          {title}
          {isNew && <NewChip />}
        </Typography>
        <Box display="flex" alignItems="center" style={{ gap: "10px" }}>
          {date && showDate && (
            <Box display="flex" style={{ gap: "5px" }}>
              <Typography variant="body2">Date:</Typography>
              <Typography variant="body2" style={{ fontWeight: 600 }}>
                {formateDate(date, { format: "m d, Y" })}
              </Typography>
            </Box>
          )}
          {registrationUrl && date && showDate && <Typography>|</Typography>}
          {registrationUrl && (
            <Link
              target="_blank"
              variant="body2"
              href={registrationUrl}
              underline="hover"
              className={classes.linkWithIcon}
              onClick={() =>
                googleAnalytics.trackExternalLinkClick({
                  location: "Coming Soon Card",
                  url: registrationUrl
                })
              }
            >
              {type === "webinar" && "Register now for webinar"}
              {type === "event" && "Click here for more information"}
              <LaunchIcon fontSize="inherit" />
            </Link>
          )}
          {videoId && (
            <>
              <Link
                component="button"
                variant="body2"
                underline="hover"
                onClick={() => toggleModal()}
              >
                View webinar recording
              </Link>
              <VideoDialog
                modalOpen={modalOpen}
                toggleModal={toggleModal}
                video={{ id: videoId, title, description }}
              />
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};
export default ComingSoonCardSection;
