import React from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { node, object, string } from "prop-types";
import Button from "@mui/material/Button";
import { Menu, MenuItem, ListItemIcon, ListItemText, Typography } from "@mui/material/";
import { makeStyles } from "@mui/styles";
import { headerLinkStyles, headerMenuStyles } from "../../config/styles";

import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import googleAnalytics from "../../google-analytics";

const BaseMenu = ({ children, MenuIcon, menuTitle, items }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const linkClasses = headerLinkStyles({ anchorEl: anchorEl });
  const menuClasses = headerMenuStyles();

  const history = useHistory();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    googleAnalytics.trackHeaderNav({ action: "menu", label: menuTitle });
  };

  const handleClose = (event) => {
    setAnchorEl(null);
  };

  const slug = menuTitle
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, "")
    .replace(/[\s_-]+/g, "-")
    .replace(/^-+|-+$/g, "")
    .concat("-menu");

  return (
    <>
      <button
        className={linkClasses.root}
        onClick={handleClick}
        aria-label={menuTitle}
        aria-controls={anchorEl ? slug : undefined}
        aria-haspopup="true"
      >
        <MenuIcon />
        <Typography component="span">{menuTitle}</Typography>
        <ExpandMoreRoundedIcon />
      </button>
      <Menu
        id={slug}
        classes={{ list: menuClasses.list }}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "resources-button"
        }}
      >
        {items.map((item, i) => (
          <MenuItem
            key={i}
            onClick={() => {
              googleAnalytics.trackHeaderNav({
                action: "menu",
                label: item.googleAnalyticsLabel
              });
              history.push(item.href);
              setAnchorEl(null);
            }}
          >
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText>{item.text}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

BaseMenu.propTypes = {
  children: node,
  menuIcon: object,
  menuTitle: string
};

BaseMenu.defaultProps = {
  children: null,
  menuIcon: null,
  menuTitle: null
};

export default BaseMenu;
