import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { alpha } from "@mui/material/styles";
import { Box, Grid, Typography } from "@mui/material";

import VideoCard from "./VideoCard";
import ItemBadges from "./ItemBadges";
import { formateDate } from "../utils/date";
import VideoDialog from "./VideoDialog";
import { ClippedParagraph } from "./typography/ClippedParagraph";

const useStyles = makeStyles(({ palette, breakpoints }) => {
  const mediaContext = ({borderRadius, hasLeftBorder}) => ({
    position: "relative",
    "&::after": {
      content: '" "',
      display: "block",
      width: "125%",
      position: "absolute",
      borderLeft: hasLeftBorder ? `1px solid ${alpha(palette.secondary.main, 0.2)}` : "none",
      background: palette.secondary.main,
      top: 0,
      left: 0,
      right: "-100%",
      bottom: 0,
      zIndex: 1,
      borderRadius
    }
  })
  return {
    featuredWrapper: {
      marginTop: 20,
      marginBottom: 10,
      [breakpoints.up("md")]: {
        marginBottom: 36,
        marginTop: 36
      },
      overflow: "hidden"
    },
    featuredContainer: {
      alignItems: "center",
      color: palette.common.white,
      "& a:hover": {
        textDecoration: "none"
      }
    },
    mediaContext: (props) => mediaContext(props),
    mediaContextContent: {
      position: "relative",
      zIndex: 10,
      color: palette.text.dark,
    },
    videoResourceWrapper: {
      display: "flex",
      position: "relative",
      zIndex: 10
    },
    videoResourceContainer: {
      flex: 1
    },
    link: {
      color: "white",
      textDecoration: "underline",
      "&:hover,&:focus": {
        color: "rgba(255,255,255,.85)"
      }
    }
  };
});

const CurrentVideo = ({ video, borderRadius = 0, style, hasLeftBorder=false }) => {
  const classes = useStyles({ borderRadius, hasLeftBorder });
  const [modalOpen, setOpenModal] = useState(false);

  const toggleModal = () => {
    setOpenModal(!modalOpen);
  };

  // VideoPlayer expects the youtube video id as just `id`
  video.id = video.youtube_id;

  const date = formateDate(video.display_date);

  return (
    <>
      <Box style={{ overflow: "hidden", ...style }}>
        <Grid container spacing={0} className={classes.featuredContainer} component="article">
          <Grid item xs={12} md={7}>
            <Box className={classes.mediaContext} px={5} py={4}>
              <Box className={classes.mediaContextContent}>
                <Box display="flex" alignItems="center" mb={1} style={{ gap: "10px" }}>
                  {date && <Typography variant="overline">{date}</Typography>}
                  <ItemBadges item={video} positioned={false} size="small" />
                </Box>
                <Typography component="h2" variant="h4" style={{ marginBottom: 10 }}>
                  {video.title}
                </Typography>
                {video.description && <ClippedParagraph text={video.description} textColor="light" />}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={5} className={classes.videoResourceWrapper}>
            <Box className={classes.videoResourceContainer} display="flex" mr={4}>
              <VideoCard
                video={video}
                showDetails={false}
                handleClick={toggleModal}
                isFeatured={true}
                showChips={false}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <VideoDialog modalOpen={modalOpen} toggleModal={toggleModal} video={video} />
    </>
  );
};

export default CurrentVideo;
