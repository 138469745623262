import React from "react";
import { CircularProgress, Backdrop } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(({ zIndex }) => ({
  backdrop: {
    zIndex: zIndex.drawer + 1,
    color: "#fff",
  },
}));

const LoadingIndicator = () => {
  const classes = useStyles();
  return (
    <Backdrop open={true} className={classes.backdrop}>
      <CircularProgress color="inherit" size={60} aria-label="Loading" />
    </Backdrop>
  );
};

export default LoadingIndicator;
