import { actionTypes } from '../epics';
import {
  fieldConfig, icerBenchmarkPriceKeys, manufacturersPricePriceKeys, icerComparisonKeys, SECONDARY_SORT_FIELDS
} from '../../config';
import { computePercentDiscount } from '../../selectors';

const generateComparisonValues = (analysisAttributes) => icerBenchmarkPriceKeys.reduce((accumulatedBenchmarks, benchmarkPriceKey) => ({ // eslint-disable-line max-len
  ...accumulatedBenchmarks,
  [benchmarkPriceKey]: icerComparisonKeys.reduce((accumulatedComparisonValues, comparisonKey) => ({
    ...accumulatedComparisonValues,
    [comparisonKey]: computePercentDiscount(analysisAttributes[benchmarkPriceKey], analysisAttributes[comparisonKey], comparisonKey) // eslint-disable-line max-len
  }), {})
}), {});

const generateDerivedAttributes = (attributes) => Object.entries(attributes).reduce((acc, [n, v]) => {
  if (fieldConfig[n]) {
    const { type } = fieldConfig[n];
    if (type === 'usd') {
      acc[n] = parseFloat(v, 10) || null;
    }
  }
  acc.comparisonValues = generateComparisonValues(acc);
  return acc;
}, {});


export const preloadedAnalyses = {
  loading: true,
  error: null,
  included: [], // Array of referenced, JSONAPI relationships, e.g.: reports
  data: [], // Array of Analyses Objects, corresponding to rows
  sortedIds: [] // Array of ids, TODO: filter, in Redux state, 1st?
};

export const analysesReducer = (state = preloadedAnalyses, action) => {
  switch (action.type) {
    case actionTypes.FETCH_ANALYSES:
      return {
        ...state,
        loading: true,
        error: null
      };
    case actionTypes.FETCH_ANALYSES_FAILURE:
      return {
        loading: false,
        included: [],
        data: [],
        error: action.payload
      };
    case actionTypes.FETCH_ANALYSES_SUCCESS: {
      let { payload } = action
      const { data=[], included=false } = payload || {};
      return {
        loading: false,
        included,
        data: data.map(({ id, attributes, relationships }) => {
          attributes.manufacturer_price = null
          return { // attributes of one analysis
            slug: id, // TODO: use actual slug
            ...attributes,
            ...generateDerivedAttributes(attributes),
            hasManufacturersPrice: manufacturersPricePriceKeys.reduce((hasPrice, k) => ((attributes[k] !== null) || hasPrice), false),
            hasIcerPrice: icerBenchmarkPriceKeys.reduce((hasPrice, k) => ((attributes[k] !== null) || hasPrice), false),
            relationships
        }}),
        error: null
      };
    }
    default:
      return state;
  }
};

export const sortReducer = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.SET_SORT: {
      return {
        ...state,
        ...action.payload
      };
    }
    default:
      return state;
  }
};

const normalizeValue = (v, type) => {
  if (type && type === 'number') {
    // If value is actually a string, remove characters like '$' and commas
    if (typeof(v) === 'string')
      return Number(v.replace(/[^0-9.-]+/g,""));
    else
      return v;
  } else {
    return String(v instanceof Array ? v.join('') : v || '').toUpperCase();
  }
}

const sortFor = (sortField, sortDirection, type) => (a1, a2) => {
  // (a1, a2) === (analysis-1, analysis-2):
  // if necessary, sort by an empty string, '', instead of "null" or "undefined":
  const a1NormalizedValue = normalizeValue(a1[sortField], type);
  const a2NormalizedValue = normalizeValue(a2[sortField], type);
  if (a1NormalizedValue > a2NormalizedValue) {
    return sortDirection === 'asc' ? 1 : -1;
  } if (a1NormalizedValue < a2NormalizedValue) {
    return sortDirection === 'asc' ? -1 : 1;
  } if (a1NormalizedValue === a2NormalizedValue) {
    for (let i = 0; i < SECONDARY_SORT_FIELDS.length; i += 1) {
      const nextField = SECONDARY_SORT_FIELDS[i];
      if (sortField === nextField) {
        continue; // eslint-disable-line no-continue
      }
      const a1SecondaryValue = normalizeValue(a1[nextField], type);
      const a2SecondaryValue = normalizeValue(a2[nextField], type);
      // 2ndary is always ascending, regardless of primary sortDirection:
      const compareResult = a1SecondaryValue > a2SecondaryValue ? 1 : -1;
      if (compareResult !== 0) {
        return compareResult;
      }
    }
  }
  return 0;
};

export const sortedAnalysesReducer = (state = preloadedAnalyses, action) => {
  switch (action.type) {
    case actionTypes.APPLY_SORT: {
      const { analysesResponse, sort: { sortField, sortDirection, type } = {} } = action.payload;
      const { data = [] } = analysesResponse;
      const sortFunc = sortFor(sortField, sortDirection, type);
      return {
        ...analysesResponse,
        sortedIds: data.slice().sort(sortFunc).map(({ slug }) => slug)
      };
    }
    default:
      return state;
  }
};
