import React from 'react';
import { bool } from 'prop-types';
import { Box, CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(({spacing}) => ({
  progress: {
    padding: spacing(8),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: `calc(100% - ${spacing(8) * 2})`,
  }
}));

const ProgressIndicator = ({ active }) => {
  const classes = useStyles();
  if (active) {
    return (
      <Box className={classes.progress}>
        <CircularProgress aria-label="Loading"/>
      </Box>
    );
  }
  return null;
};

ProgressIndicator.propTypes = {
  active: bool.isRequired
};

export default ProgressIndicator;
