import React, { useMemo } from "react";
import NavButton from "../../menus/NavButton";
import { ReportSnapshotSection } from "./ReportSnapshotBody";
import { Box, Grid, Tooltip } from "@mui/material";
import { docLabels } from "../../../config";
import difference from "lodash/difference";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import InsertDriveFileRoundedIcon from "@mui/icons-material/InsertDriveFileRounded";
import PowerpointIcon from "../../icons/PowerpointIcon";
import WordIcon from "../../icons/WordIcon";
import PdfIcon, { DOCUMENT_ICON_COLOR } from "../../icons/PdfIcon";
import googleAnalytics from "../../../google-analytics";

const getDocInfo = (activeStorageContentType = "", filename = "") => {
  const iconStyle = { position: "relative", zIndex: 10, marginLeft: "-10px" };
  switch (true) {
    case activeStorageContentType.includes("presentation") ||
      filename.toLowerCase().includes("ppt"):
      return {
        icon: <PowerpointIcon fontSize="inherit" style={iconStyle} />,
        extension: "ppt"
      };
    case activeStorageContentType.includes("word"):
      return {
        icon: <WordIcon fontSize="inherit" style={iconStyle} />,
        extension: "doc"
      };
    case activeStorageContentType.includes("pdf"):
      return {
        icon: <PdfIcon fontSize="inherit" style={iconStyle} />,
        extension: "pdf"
      };
    default:
      return {
        icon: (
          <InsertDriveFileRoundedIcon
            fontSize="inherit"
            style={{ ...iconStyle, fill: DOCUMENT_ICON_COLOR }}
          />
        ),
        extension: "file"
      };
  }
};

const mapTooltips = {
  presentation_upload: (
    <div>PowerPoint presentation highlighting the key findings of the report</div>
  ),
  evidence_summary_upload: <div>Key tables and figures from the report</div>,
  patient_perspectives: <div>Narrative summary of insights gained from patients</div>,
  report_at_a_glance_upload: <div>Easy to read report summary</div>,
  recommendation_upload: (
    <div>Policy Roundtable perspectives on pricing and prior auth criteria</div>
  )
};

export const ReportSnapshotDocuments = ({ documents, calloutText }) => {
  const [featuredDoc, otherDocs] = useMemo(() => {
    const featured = documents.find(({ key }) => key.match(/recommendation_upload/));
    return [featured, difference(documents, [featured])];
  });
  return (
    <ReportSnapshotSection title="Related Documents" mb={5}>
      <Grid container spacing={2}>
        {featuredDoc && (
          <Grid xs={12} item>
            <Tooltip
              title={mapTooltips[featuredDoc.key]}
              aria-label={mapTooltips[featuredDoc.key]}
              placement="bottom"
              arrow
            >
              <Box component="span">
                <NavButton
                  handleClick={() =>
                    googleAnalytics.trackDocument(featuredDoc.key + ": " + featuredDoc.filename)
                  }
                  href={featuredDoc.url}
                  linkText={docLabels[featuredDoc.key]}
                  linkDescription={calloutText}
                  startIconBg={true}
                  linkIcon={getDocInfo(featuredDoc.content_type, featuredDoc.filename).icon}
                  endIcon={<CloudDownloadIcon color={DOCUMENT_ICON_COLOR} />}
                  variant="dark"
                  target="_blank"
                />
              </Box>
            </Tooltip>
          </Grid>
        )}
        {otherDocs.map(({ url, key, content_type, filename }) => (
          <Grid xs={12} md={6} item>
            <Tooltip
              arrow
              title={mapTooltips[key]}
              aria-label={mapTooltips[key]}
              placement="bottom"
            >
              <Box component="span">
                <NavButton
                  handleClick={() => googleAnalytics.trackDocument(key + ": " + filename)}
                  href={url}
                  linkText={docLabels[key]}
                  key={key}
                  variant="dark"
                  linkIcon={getDocInfo(content_type, filename).icon}
                  startIconBg={true}
                  endIcon={<CloudDownloadIcon color={DOCUMENT_ICON_COLOR} />}
                  target="_blank"
                />
              </Box>
            </Tooltip>
          </Grid>
        ))}
      </Grid>
    </ReportSnapshotSection>
  );
};
