import React from 'react';
import { string, func } from 'prop-types';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { modalStyles } from './styles'

const ModalOpened = ({ title='', Content, mx=true }) => {
  const classes = modalStyles();
  const [modalOpen, setOpen] = React.useState(true);

  const handleModalClose = (event) => {
    event.preventDefault()
    setOpen(false);
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      disableEscapeKeyDown={false}
      open={modalOpen}
      onClose={handleModalClose}
      classes={{root: classes.dialogRoot}}
    >
      <DialogActions classes={{root: classes.actionRoot}}>
        <DialogTitle> {title} </DialogTitle>
        <Button variant="text" onClick={handleModalClose}>
          Close
        </Button>
      </DialogActions>
      <DialogContent classes={{root: mx ? classes.contentRoot : classes.contentNoPaddingXRoot}}>
        <Content />
      </DialogContent>
    </Dialog>
  );
};

ModalOpened.propTypes = {
  title: string,
  Content: func
};

export default ModalOpened;
