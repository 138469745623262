import React from "react";
import { makeStyles } from "@mui/styles";
import { Box, Container } from "@mui/material";

import { sectionStyles } from "../../../config/styles";
import { connectToState } from "../../../redux/connect-api";

import OndemandVideoRoundedIcon from "@mui/icons-material/OndemandVideoRounded";
import VideoSettingsRoundedIcon from "../../icons/VideoSettingsRoundedIcon";
import DescriptionRoundedIcon from "@mui/icons-material/DescriptionRounded";
import TipsAndUpdatesRoundedIcon from "../../icons/TipsAndUpdatesRoundedIcon";
import MoreTimeRoundedIcon from "../../icons/MoreTimeRoundedIcon";

import ButtonGroup from "../../menus/ButtonGroup";
import NavButton from "../../menus/NavButton";
import {
  newWebinarsCountSelector,
  newVideosCountSelector,
  newDocumentsCountSelector,
  newRecentUpdatesCountSelector,
  newComingSoonCountSelector
} from "../../../selectors";
import useUserHasAccess from "../../../hooks/useUserHasAccess";
import ProgressIndicator from "../../ProgressIndicator";

const wrapperStyles = makeStyles(() => ({
  root: {
    ...sectionStyles,
    minHeight: "auto"
  }
}));

const AppLandingNav = ({
  loading,
  newWebinarsCount,
  newVideosCount,
  newDocumentsCount,
  newRecentUpdatesCount,
  newComingSoonCount
}) => {
  const userHasAccess = useUserHasAccess()
  const wrapperClasses = wrapperStyles();

  if (loading) {
    return <Box minHeight={200}><ProgressIndicator active={true} /></Box>
  }

  return (
    <Box component="section" className={wrapperClasses.root}>
      {userHasAccess && (
        <Container>
          <ButtonGroup groupTitle="Resources">
            <NavButton
              linkHref="/webinars"
              linkText="Webinars"
              linkIcon={<OndemandVideoRoundedIcon color="primary" />}
              newCount={newWebinarsCount}
            />
            <NavButton
              linkHref="/videos"
              linkText="Demo Videos"
              linkIcon={<VideoSettingsRoundedIcon color="primary" />}
              newCount={newVideosCount}
            />
            <NavButton
              linkHref="/documents"
              linkText="Documents"
              linkIcon={<DescriptionRoundedIcon color="primary" />}
              newCount={newDocumentsCount}
            />
          </ButtonGroup>
          <ButtonGroup groupTitle="Updates">
            <NavButton
              linkHref="/updates"
              linkText="Recent Updates"
              linkIcon={<MoreTimeRoundedIcon color="primary" />}
              newCount={newRecentUpdatesCount}
            />
            <NavButton
              linkHref="/coming-soon"
              linkText="Coming Soon"
              linkIcon={<TipsAndUpdatesRoundedIcon color="primary" />}
              newCount={newComingSoonCount}
            />
          </ButtonGroup>
        </Container>
      )}
    </Box>
  );
};

const mapStateToProps = ({ userProfile: { data }, ...state }) => ({
  loading: state.recentUpdates.loading || state.comingSoon.loading || state.mediaResources.loading,
  newWebinarsCount: newWebinarsCountSelector(state),
  newVideosCount: newVideosCountSelector(state),
  newDocumentsCount: newDocumentsCountSelector(state),
  newRecentUpdatesCount: newRecentUpdatesCountSelector(state),
  newComingSoonCount: newComingSoonCountSelector(state)
});

export default connectToState(mapStateToProps)(AppLandingNav);
