import React from "react";
import { string, array, number, oneOfType } from "prop-types";
import { makeStyles } from "@mui/styles";
import { Box, Typography } from "@mui/material";
import { formateDate } from "../../../utils/date";

// TODO: use i18n?

const useStyles = makeStyles(({ spacing, palette }) => ({
  detailBar: {
    display: "flex",
    justifyContent: "space-between",
    paddingLeft: spacing(3),
    paddingRight: spacing(3),
    paddingTop: spacing(2),
    paddingBottom: spacing(2),
    backgroundColor: "white",
    borderBottom: "1px solid #1A80D31A",
    alignItems: "baseline",
    flexWrap: "wrap",
    gap: spacing(1)
  },
  heading: {
    fontSize: 28,
    lineHeight: "32px",
    fontWeight: 600,
    color: "black",
    paddingRight: spacing(2)
  },
  date: {
    "& span": {
      fontWeight: 700
    }
  }
}));

const ReportSnapshotHeader = (props) => {
  const { condition, date } = props;

  const dateDisplay = date ? ` ${formateDate(date)}` : null;

  const classes = useStyles();
  return (
    <Box className={classes.detailBar}>
      <Typography className={classes.heading}>{condition}</Typography>
      {dateDisplay && (
        <Typography variant="body1" className={classes.date}>
          Date of review:
          <Typography component="span">{dateDisplay}</Typography>
        </Typography>
      )}
    </Box>
  );
};

ReportSnapshotHeader.propTypes = {
  drugs: array,
  condition: string.isRequired,
  year: oneOfType([string, number])
};

export default ReportSnapshotHeader;
