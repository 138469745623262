import Fuse from "fuse.js";
import { SEARCHABLE_FIELDS } from "../config";

export const fuseSearch = (searchString, options = {}) => {
  let _searchString = searchString.trim();
  const { collection, keys = SEARCHABLE_FIELDS } = options;
  if (!_searchString.length) return collection;
  const inQuotes = searchString.match(/'|"/);
  const fuse = new Fuse(collection, {
    keys,
    threshold: 0.35,
    useExtendedSearch: !inQuotes,
    includeScore: true,
    includeMatches: true
  });
  if (!inQuotes) {
    _searchString = searchString.replace(" ", "|");
  }
  const search = fuse.search(_searchString);
  return search.map((r) => r.item);
};
