import React from "react";
import { makeStyles } from "@mui/styles";
import { Box, Grid, Typography } from "@mui/material";
import BaseCard from "../../BaseCard";
import MedicationRoundedIcon from "../../icons/MedicationRoundedIcon";
import DrugList from "../../DrugList";
import ComingSoonCardSection from "./ComingSoonCardSection";

const useStyles = makeStyles(({ spacing, palette, breakpoints }) => {
  return {
    header: {
      display: "flex",
      gap: "5px",
      flexDirection: "column",
      borderBottom: `1px solid ${palette.borders.light}`,
      paddingBottom: spacing(2),
      marginTop: spacing(1),
      marginLeft: spacing(2),
      marginRight: spacing(2),
      marginBottom: spacing(0.25),
      [breakpoints.up("sm")]: {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        paddingBottom: spacing(0.5),
        marginBottom: 0
      }
    },
    headerContent: {
      display: "flex",
      alignItems: "center",
      [breakpoints.up("sm")]: {
        justifyContent: "space-between"
      }
    },
    iconWrapper: {
      transform: "translate(-3px, 3px)",
      [breakpoints.up("lg")]: {
        transform: "none"
      }
    },
    body: {
      padding: spacing(2)
    }
  };
});

const ComingSoonCard = ({ drugs = [], title, resource = null, report = null }) => {
  const classes = useStyles();
  return (
    <BaseCard allowHover={false}>
      {(!!title || !!drugs?.length) && (
        <Box className={classes.header}>
          <Typography component="h2" variant="h3">
            {title}
          </Typography>
          {drugs && !!drugs.length && (
            <Box className={classes.headerContent}>
              <Box className={classes.iconWrapper}>
                <MedicationRoundedIcon />
              </Box>
              <DrugList drugs={drugs} inlineText="Included Drugs:" inline darkChips />
            </Box>
          )}
        </Box>
      )}
      <Grid container className={classes.body} spacing={4}>
        {report && (
          <Grid item xs={12} md={5} lg={4}>
            <ComingSoonCardSection {...report} type="report" />
          </Grid>
        )}
        <Grid item xs={12} md={7} lg={8}>
          {resource && (
            <ComingSoonCardSection {...resource} type={resource.resource_type} last={true} />
          )}
        </Grid>
      </Grid>
    </BaseCard>
  );
};

export default ComingSoonCard;
