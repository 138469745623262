import React from 'react'
import { string, array, object } from 'prop-types'
import { makeStyles } from '@mui/styles'
import PlatformChips from './PlatformChips'
import ItemBadges from './ItemBadges'

import analytics from '../google-analytics'
import { cardHover } from '../component-styles';
import { Box, Link, Typography } from '@mui/material'

const useStyles = makeStyles(({ palette, spacing }) => {
  return {
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      gap: '1rem',
      color: palette.secondary.main,
      borderRadius: 4,
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: palette.secondary.light,
      padding: '1rem',
      width: '100%',
      // Position needed for docTitle::after styles
      position: 'relative',
      ...cardHover( palette ),
    },
    header: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    docTitle: {
      color: palette.secondary.main,
      display: 'block',
      fontSize: 22,
      marginRight: '1rem',
      '&:hover': {
        textDecoration: 'none',
      },
      // The ::after styles make the whole card clickable
      '&::after': {
        content: '""',
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      },
    },
    docDescription: {
      color: 'black',
    },
    chips: {
      // Needed to make chips clickable inside the card
      position: 'relative',
      alignSelf: 'flex-start',
    },
  }
})

const DocumentCard = props => {
  const classes = useStyles()
  const {
    doc,
    docTitle,
    docDescription,
    docUrl,
    platformTags,
  } = props

  const handleClick = () => {
    analytics.trackDocument(`document_resource: ${docTitle}`);
  };

  return (
    <Box className={ classes.wrapper } component="article">
      <Box className={ classes.header }>
        <Link
          className={ classes.docTitle }
          href={ docUrl }
          target='_blank'
          onClick={ handleClick }
          underline='none'
        >
          { docTitle }
        </Link>
        <ItemBadges item={ doc } positioned={ false } />
      </Box>
      <Typography className={classes.docDescription}>{ docDescription }</Typography>
      <Box className={ classes.chips }>
        { platformTags.length > 0 && <PlatformChips chips={ platformTags } style={{ flexWrap: 'wrap' }} /> }
      </Box>
    </Box>
  )
}

DocumentCard.defaultProps = {
  docTitle: '',
  docDescription: '',
  docUrl: '',
  platformTags: [],
}

DocumentCard.propTypes = {
  doc: object,
  docDescription: string,
  docUrl: string,
  platformTags: array,
}

export default DocumentCard
