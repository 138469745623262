import React from 'react';
import { Provider } from 'react-redux';
import { element } from 'prop-types';
import configureStore from '../redux';

const store = configureStore();

const StateProvider = ({ children }) => (
  <Provider store={store}>
    {children}
  </Provider>
);

StateProvider.propTypes = {
  children: element.isRequired
};

export default StateProvider;
