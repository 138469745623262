import React from 'react';
import { makeStyles } from '@mui/styles';
import EbmChart from '../../../images/EBM-v.2-1_Crop.png';

const useStyles = makeStyles((theme) => ({
  root: {
      '& img': {
          display: 'block',
          margin: 'auto',
      },
  }
}));

const ratings = {
  /* eslint-disable max-len */
  'A': [
    'Superior',
    'High certainty of a substantial (moderate-large) net health benefit',
  ],
  'B': [
    'Incremental',
    'High certainty of a small net health benefit',
  ],
  'C': [
    'Comparable',
    'High certainty of a comparable net health benefit',
  ],
  'D': [
    'Negative',
    'High certainty of an inferior net health benefit',
  ],
  'B+': [
    'Incremental or Better',
    'Moderate certainty of a small or substantial net health benefit, with high certainty of at least a small net health benefit',
  ],
  'C+': [
    'Comparable or Incremental',
    'Moderate certainty of a comparable or small net health benefit, with high certainty of at least a comparable net health benefit',
  ],
  'C-': [
    'Comparable or Inferior',
    'Moderate certainty that the net health benefit is either comparable or inferior, with high certainty of at best a comparable net health benefit',
  ],
  'C++': [
    'Comparable or Better',
    'Moderate certainty of a comparable, small, or substantial net health benefit, with high certainty of at least a comparable net health benefit',
  ],
  'P/I': [
    'Promising but Inconclusive',
    'Moderate certainty of a small or substantial net health benefit, small likelihood of a negative net health benefit',
  ],
  'I': [
    'Insufficient',
    'Any situation in which the level of certainty in the evidence is low',
  ],
}

const EbmRatingContent = () => {
  const classes = useStyles();

  return (
    <div className={ classes.root }>
      <img src={ EbmChart } alt='EBM Ratings Chart' />
      <ul>
        {
          Object.entries(ratings).map(([rating, description]) => {
              return (
                <li key={ rating }>
                  <em><strong>{ rating } = { description[0] }</strong> — { description[1] }</em>
                </li>
              )
          })
        }
      </ul>
      <p>
        <em>
          The ICER Integrated Evidence Rating™ combines a rating for comparative clinical effectiveness and a
          rating for comparative value. The clinical effectiveness rating arises from a joint judgment of the
          level of confidence provided by the body of evidence and the magnitude of the net health benefit — the
          overall balance between benefits and harms. This method for rating the clinical effectiveness is
          modeled on the “Evidence-Based Medicine (EBM) matrix” developed by a multi-stakeholder group convened
          by America’s Health Insurance Plans.
        </em>
      </p>
    </div>
  );
};

EbmRatingContent.propTypes = {
};

export default EbmRatingContent;
