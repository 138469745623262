import React from 'react';
import { makeStyles } from '@mui/styles';

import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import { Link } from "react-router-dom";
import { cardHover } from '../component-styles';

const useStyles = makeStyles(({ palette, spacing, components }) => {
  const heightMediaQuery = '@media (min-height: 900px)'
  return {
    toolCardRoot: {
      borderColor: palette.secondary.light,
      position: 'relative',
      display: 'flex',
      ...cardHover( palette ),
    },
    toolLogo: {
      minHeight: 120,
      minWidth: 200,
      flexBasis: 150,
      backgroundSize: '70%',
      backgroundPosition: 'center center',
      marginTop: spacing(2),
      marginBottom: spacing(3),
      [heightMediaQuery]: {
        minHeight: 120,
        minWidth: 200,
        backgroundSize: '70%',
        marginTop: spacing(1),
      },
    },
    toolCopy: {
      display: 'flex',
      alignItems: 'stretch',
      flexWrap: 'wrap',
      justifyContent: 'center',
      height: '100%',
      paddingLeft: 4,
      paddingRight: 4,
    },
    toolTitle: {
      color: components.MuiButton.styleOverrides.text.color,
      paddingBottom: 5,
      borderBottom: `3px solid ${palette.primary.light}`,
      marginBottom: spacing(2),
      paddingLeft: 15,
      paddingRight: 15,
      fontSize: 24,
    },
    toolTitleWord: {
      display: 'block',
    },
    toolDescription: {
      fontSize: 18,
      fontWeight: 500,
      letterSpacing: '0.15px',
      lineHeight: '20px',
      paddingLeft: 15,
      paddingRight: '15%',
      color: 'black',
    },
    disabledLink: {
      pointerEvents: 'none',
      opacity: '.4',
    },
    accessFlag: {
      position: 'absolute',
      top: '25%',
      width: '100%',
      zIndex: 10,
      textAlign: 'center',
      background: '#eadeb3',
      paddingTop: spacing(1.5),
      paddingBottom: spacing(1.5),
    },
    accessFlagStatement: {
      textAlign: 'center',
      lineHeight: 1,
      marginBottom: spacing(.5)
    }
  }
});
const useDetailStyles = makeStyles(({spacing}) => {
  return {
    toolDetailList: {
      paddingLeft: 35,
      paddingRight: spacing(2),
    },
    toolDetailListItem: {
      fontSize: 16,
      marginBottom: spacing(.5),
    },
  }
})

const ToolCard = ({tool, accessible=false}) => {
  const classes = useStyles();
  const { href, to, name, description, details, image } = tool
  return (
    <>
      <Card classes={{ root: classes.toolCardRoot }} variant='outlined'>
        { !accessible ? <AccessFlag toolName={name} /> : null }
        <CardActionArea
          disabled={!accessible}
          className={ accessible ? '' : classes.disabledLink}
          component={to ? Link : 'button'}
          href={accessible ? href : null}
          to={accessible ? to : null}
        >
          <CardContent className={classes.toolCopy}>
            <CardMedia
              className={classes.toolLogo}
              image={image}
              title={name}
            />
            <div style={{flexBasis: '60%', flexGrow: '1'}}>
              <Typography className={classes.toolTitle} gutterBottom variant="h5" component="h2">
                <span className={classes.toolTitleWord}>{name}</span>
              </Typography>
              <Typography className={classes.toolDescription} variant="body2" component="p">
                {description}
              </Typography>
              <ToolDetails detailItems={details} />
            </div>
          </CardContent>
        </CardActionArea>
      </Card>
    </>
)
}

const ToolDetails = ({detailItems}) => {
  const detailClasses = useDetailStyles();
  return (
    <ul className={detailClasses.toolDetailList}>
      {
        detailItems.map((detailItem, i) => (
          <li key={i} className={detailClasses.toolDetailListItem}> {detailItem} </li>
        ))
      }
    </ul>
  )
}

const AccessFlag = ({toolName}) => {
  const classes = useStyles();
  return (
    <div className={classes.accessFlag}>
      <Typography className={classes.accessFlagStatement} variant="subtitle1" component="p">
        Your organization currently does not <br /> have access to this tool
      </Typography>
      <Button size="small" color="secondary" target='_blank' href={`mailto:analytics@icer.org?subject=Access Request: ${toolName}`}>
        Request Access
      </Button>
    </div>
  )
}

export default ToolCard;
