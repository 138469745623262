import React from 'react';
import { number, bool } from 'prop-types';
import {
  Box,
  Card,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { videoType } from '../../constants/proptypes';
import PlatformChips from '../PlatformChips';

const useStyles = makeStyles(({spacing}) => ({
  root: {
    backgroundColor: 'transparent',
    paddingBottom: spacing(4),
    overflow: 'visible',
    '& > iframe': {
      backgroundColor: '#000',
      margin: spacing(0),
      padding: spacing(0)
    },
    '& > .video-title': {
      display: 'block',
      padding: spacing(0),
      paddingBottom: spacing(0.5),
      textAlign: 'left',
      '&.featured': {
        fontWeight: 'normal'
      },
      '&.related': {
        fontWeight: 'normal'
      }
    }
  },
  videoWrapper: {
    position: 'relative',
    paddingBottom: '56.25%',
    height: 0,
    '& iframe': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
    }
  },
  videoTitle: {
    margin: 0,
    marginRight: spacing(2),
  },
  videoTitleBelow: {
    margin: 0,
    marginTop: spacing(1),
  },
}));

const VideoPlayer = ({
  isFeatured, video, width, height,
}) => {
  const { id, title, tags=[], description='' } = video;
  const classes = useStyles();
  return (
    <Card
      className={classes.root}
      elevation={0}
    >
      { isFeatured &&
        <Box mb={2}>
          <Box display='flex' alignItems='center' mb={1}>
            <Typography component='h1' variant='h3' className={`video-title featured ${classes.videoTitle}`}>{title}</Typography>
            { tags.length > 0 && <PlatformChips chips={tags} /> }
          </Box>
          { description && <Typography className={classes.videoDescription}>{description}</Typography> }
        </Box>
      }
      <Box className={classes.videoWrapper}>
        <iframe
          title={title} // needed b/c: jsx-a11y/iframe-has-title
          id={`ytplayer-${id}`}
          // type="text/html"
          width={width}
          height={height}
          autohide="0" // show player as soon as possible; default is "2"
          src={`https://www.youtube.com/embed/${id}?hd=1`}
          frameBorder="0"
          allowFullScreen
          className={classes.playerIframe}
        />
      </Box>
      { !isFeatured && <Typography component='h4' className={`video-title related ${classes.videoTitleBelow}`}>{title}</Typography> }
    </Card>
  );
};

VideoPlayer.defaultProps = {
  isFeatured: false
};

VideoPlayer.propTypes = {
  isFeatured: bool,
  width: number,
  height: number,
  video: videoType.isRequired
};

export default VideoPlayer;
