import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

export const DOCUMENT_ICON_COLOR = "rgba(255, 255, 255, .9)";
const ACROBAT_ICON_COLOR = DOCUMENT_ICON_COLOR;

const WordIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <g>
        <path
          fill={DOCUMENT_ICON_COLOR}
          d="M13.5,7.5C12.7,7.5,12,6.8,12,6V0H4.5c-1.7,0-3,1.3-3,3v18c0,1.7,1.3,3,3,3H6v-0.8V21v-3.8c0-1.7,1.3-3,3-3
		h1.1h4.1h1.1h4.1V7.5H13.5z"
        />
        <g>
          <path
            fill={ACROBAT_ICON_COLOR}
            d="M15.4,16.5h-1.1c-0.4,0-0.8,0.3-0.8,0.8v6c0,0.4,0.3,0.8,0.8,0.8h1.1c1.2,0,2.2-1,2.2-2.2v-3
			C17.6,17.5,16.6,16.5,15.4,16.5z M16.1,21.8c0,0.4-0.3,0.7-0.7,0.7H15V18h0.4c0.4,0,0.7,0.3,0.7,0.8V21.8z"
          />
          <path
            fill={ACROBAT_ICON_COLOR}
            d="M10.1,16.5H9c-0.4,0-0.8,0.3-0.8,0.8V21v2.2C8.2,23.7,8.6,24,9,24s0.8-0.3,0.8-0.8v-1.5h0.4
			c1.4,0,2.6-1.2,2.6-2.6C12.8,17.7,11.6,16.5,10.1,16.5z M10.1,20.2H9.8V18h0.4c0.6,0,1.1,0.5,1.1,1.1
			C11.2,19.7,10.7,20.2,10.1,20.2z"
          />
          <path
            fill={ACROBAT_ICON_COLOR}
            d="M21.7,19.5h-1.5V18h1.5c0.4,0,0.8-0.3,0.8-0.8s-0.3-0.8-0.8-0.8h-2.2c-0.4,0-0.8,0.3-0.8,0.8v3v3
			c0,0.4,0.3,0.8,0.8,0.8s0.8-0.3,0.8-0.8V21h1.5c0.4,0,0.8-0.3,0.8-0.8C22.5,19.8,22.2,19.5,21.7,19.5z"
          />
        </g>
        <path
          fill={DOCUMENT_ICON_COLOR}
          class="st0"
          d="M19.5,6l-6-6v4.3c0,1,0.8,1.7,1.7,1.7H19.5z"
        />
      </g>
    </SvgIcon>
  );
};

export default WordIcon;
