import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';

import rootReducer, { preloadedState } from './reducers';
import epicMiddleware, { rootEpic } from './epics';

export default function configureStore() {
  // middleware executed in left-to-right order:
  const middleware = [
    // routerMiddleware(history),
    epicMiddleware
  ];

  const composeEnhancers = composeWithDevTools({
    // Specify name here, actionsBlacklist, actionsCreators and other options if needed
  });

  // https://redux.js.org/api/createstore
  const store = createStore(
    rootReducer,
    preloadedState,
    composeEnhancers(
      applyMiddleware(...middleware)
    )
  );

  epicMiddleware.run(rootEpic);

  return store;
}
