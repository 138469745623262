import {
  oneOf, arrayOf, shape, string, number, bool
} from 'prop-types';

import { tabKeys, allFieldKeys, docLabels } from '../config';

export const analysisType = shape({
  slug: string.isRequired,
  trade_name: string.isRequired,
  specific_condition: string.isRequired
});

// export const analysisArrayType = arrayOf(analysisType);

// export const analysesType = shape({
//  loading: bool.isRequired,
//  error: string,
//  included: arrayOf(shape({}).isRequired).isRequired,
//  data: analysisArrayType.isRequired
// });

export const subRowPropsType = shape({
  rowRenderKey: string.isRequired,
  columnProps: arrayOf(
    shape({
      rowSpan: number.isRequired,
      renderKey: string.isRequired,
      isFiltered: bool.isRequired,
      fieldKey: oneOf(allFieldKeys).isRequired
    }).isRequired
  ).isRequired
});

export const dataRowPropsType = arrayOf(
  subRowPropsType.isRequired
);

export const columnConfigType = shape({
  row1Props: arrayOf(shape({
    renderKey: string.isRequired,
    label: string.isRequired
  })).isRequired,
  row2Props: arrayOf(shape({
    renderKey: string.isRequired,
    label: string.isRequired
  })).isRequired,
  dataRowProps: dataRowPropsType.isRequired
});

export const documentType = shape({
  key: oneOf(Object.keys(docLabels)).isRequired,
  filename: string.isRequired,
  content_type: string.isRequired, // cannot be fully enumerated, beforehand?
  byte_size: number.isRequired,
  checksum: string.isRequired,
  url: string.isRequired
});

export const documentArrayType = arrayOf(documentType);

export const videoType = shape({
  field_name: oneOf([
    'youtube_id_1',
    'youtube_id_2',
    'youtube_id_3',
    'youtube_id_4',
    'youtube_id_5'
  ]),
  id: string.isRequired,
  title: string.isRequired
});

export const videoArrayType = arrayOf(videoType);

export const tabKeyType = oneOf(tabKeys);

// props from react-router:
// export const locationType = shape({
//  pathname: string.isRequired,
//  search: string.isRequired
// });
//
// export const matchType = shape({
//  params: shape({
//    activeTabKey: tabKeyType.isRequired
//  })
// });
//
// export const historyType = shape({
//  push: func.isRequired
// });
