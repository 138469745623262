import React from 'react';
import {
  arrayOf, string, bool
} from 'prop-types';
import { withRouter } from 'react-router-dom';
import Button from '@mui/material/Button';
import BarChartIcon from '@mui/icons-material/BarChart';
import Tooltip from '@mui/material/Tooltip';
import ComparisonChartModal from '../chart/ComparisonChartModal';

import { tabKeyType } from '../../constants/proptypes';
import { connectToState } from '../../redux/connect-api';
import { activeTabKeySelector } from '../../selectors';
import analytics from '../../google-analytics';

const ChartButtonTooltip = ({ rowIds }) => {
  if (rowIds.length === 0) {
    return (
      <div>
        To see a chart, pick one or more drugs from a different tab.
      </div>
    );
  }
  return (
    <div>
      Compare these <b>{rowIds.length}</b> rows in a chart
    </div>
  );
};

ChartButtonTooltip.propTypes = {
  rowIds: arrayOf(string).isRequired
};

class ComparisonChart extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false
    };
  }

  handleModalOpen = () => {
    const { rowIds, ...rest } = this.props;
    if (rowIds.length > 0) {
      this.setState((prevState) => ({ ...prevState, modalOpen: true }));
      analytics.trackChart('open');
    }
  }

  handleModalClose = () => {
    this.setState((prevState) => ({ ...prevState, modalOpen: false }));
    analytics.trackChart('close');
  }

  render() {
    const {
      activeTabKey, disabled, rowIds
    } = this.props;

    if (activeTabKey !== 'compare-your-own-pricing') {
      return null;
    }

    const { modalOpen } = this.state;

    return (
      <>
        <Tooltip
          title={<ChartButtonTooltip rowIds={rowIds} />}
          aria-label="open comparison chart"
          placement="right"
        >
          <span>
            <Button
              onClick={this.handleModalOpen}
              disabled={disabled}
              aria-label="Compare selected rows"
              aria-controls={modalOpen ? "comparison-chart-modal" : undefined} // ComparisonChartModal.jsx
              startIcon={<BarChartIcon />}
              aria-haspopup="true"
            >
              Visualized Comparison
            </Button>
          </span>
        </Tooltip>
        {modalOpen && (<ComparisonChartModal
          modalOpen={modalOpen}
          onClose={this.handleModalClose}
          rowIds={rowIds}
        />)}
      </>
    );
  }
}

ComparisonChart.defaultProps = {
};

ComparisonChart.propTypes = {
  // match: matchType.isRequired,
  activeTabKey: tabKeyType.isRequired,
  disabled: bool.isRequired,
  rowIds: arrayOf(string).isRequired
};

const mapStateToProps = (state, ownProps) => ({
  activeTabKey: activeTabKeySelector(state, ownProps)
});

export default withRouter((connectToState(mapStateToProps)(ComparisonChart)));
