import React, { useState, useMemo, useCallback } from "react";
import { shape } from "prop-types";
import { Box, Grid, Typography } from "@mui/material";
import { videoArrayType, documentArrayType } from "../../../constants/proptypes";
import compact from "lodash/compact";

import VideoDialog from "../../VideoDialog";
import VideoCard from "../../../components/VideoCard";
import { ReportSnapshotHeading } from "./ReportSnapshotHeading";
import { ReportSnapshotDocuments } from "./ReportSnapshotDocuments";
import DrugList from "../../DrugList";

export const ReportSnapshotSection = ({ children, title, mb = 4 }) => (
  <Box mb={mb}>
    <ReportSnapshotHeading text={title} />
    {children}
  </Box>
);

const ReportSnapshotBody = ({ report }) => {
  // Add class to
  const pageContainer = document.getElementById("icer-spa");
  pageContainer && pageContainer.classList.add("body-flex");

  const [modalOpen, setModalOpen] = useState(false);
  const [currentVideoId, setCurrentVideoId] = useState(null);

  const [drugs, documents] = useMemo(() => [report.drugs, compact(report.documents)], [report]);
  const featuredVideo = useMemo(
    () => report.videos.find(({ field_name }) => field_name === "youtube_id_1"),
    [report.videos]
  );
  const relatedVideos = useMemo(
    () => report.videos.filter(({ field_name }) => field_name !== "youtube_id_1"),
    [report.videos]
  );

  const toggleModal = useCallback(() => setModalOpen(!modalOpen));
  const activateVideo = useCallback((videoId) => {
    setCurrentVideoId(videoId);
    setModalOpen(true);
  });

  return (
    <Box id="details-body" p={3}>
      <Grid container spacing={6}>
        <Grid item xs={12} lg={6}>
          {featuredVideo && (
            <ReportSnapshotSection title="Featured Video">
              <VideoCard
                video={{ youtube_id: featuredVideo.id, ...featuredVideo }}
                showDetails={false}
                handleClick={() => activateVideo(featuredVideo.id)}
                isFeatured
              />
            </ReportSnapshotSection>
          )}
          <ReportSnapshotSection title="Drugs in Report">
            {drugs.length > 0 ? (
              <DrugList drugs={drugs} darkChips={true} showTitle={false} />
            ) : (
              <Typography variant="body3">No drugs associated with this report.</Typography>
            )}
          </ReportSnapshotSection>
        </Grid>
        <Grid item xs={12} lg={6}>
          {compact(documents).length > 0 && (
            <ReportSnapshotDocuments
              documents={documents}
              calloutText={report.recommendation_callout}
            />
          )}
          {relatedVideos.length > 0 && (
            <ReportSnapshotSection title="Related Videos">
              <Grid container spacing={2}>
                {relatedVideos.map((v, i) => (
                  <Grid xs={12} lg={6} item key={`related-video-${i}`}>
                    <VideoCard
                      video={{ youtube_id: v.id, ...v }}
                      variant="horizontal"
                      handleClick={() => activateVideo(v.id)}
                    />
                  </Grid>
                ))}
              </Grid>
            </ReportSnapshotSection>
          )}
        </Grid>
      </Grid>
      <VideoDialog
        modalOpen={modalOpen}
        toggleModal={toggleModal}
        video={{ id: currentVideoId, youtube_id: currentVideoId }}
      />
    </Box>
  );
};

ReportSnapshotBody.defaultProps = {
  report: null
};

ReportSnapshotBody.propTypes = {
  report: shape({
    videos: videoArrayType.isRequired,
    documents: documentArrayType.isRequired
  })
};

export default ReportSnapshotBody;
