import { useEffect } from "react";
import { withRouter } from 'react-router-dom';
import { connectToState } from "../redux/connect-api";

const StateInitializer = ({children, actions}) => {
  useEffect(() => {
   actions.initializeAfterLogin?.()
  }, [])

  return children;
};

export default withRouter(connectToState()(StateInitializer))
