import React from 'react';
import {
  oneOfType, oneOf, string, number, bool
} from 'prop-types';
import { makeStyles } from '@mui/styles';

import { connectToState } from '../redux/connect-api';

import {
  addToCompareCountSelector
} from '../selectors';
import { Box } from '@mui/material';

const VIEWBOX_SIZE = '22px';

const useStyles = makeStyles(({palette}) => ({
  container: {
    display: "flex",
    alignItems: "center",
    gap: "8px"
  },
  indicator: {
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    background: "white",
    border: `1px solid ${palette.primary.main}`,
    color: "black",
    fontWeight: 600,
    fontSize: "14px",
    height: VIEWBOX_SIZE,
    width: VIEWBOX_SIZE,
  }
}));

const CountIndicator = ({ count, active }) => {
  const classes = useStyles();
  return <Box className={classes.indicator}>{count}</Box>

};

CountIndicator.propTypes = {
  count: number.isRequired,
  active: bool.isRequired
};

const AddToCompareCountIndicator = ({ label, active, addToCompareCount }) => {
  const classes = useStyles();
  const indicatorGraphic = addToCompareCount === null ? null : (
    <CountIndicator
      count={addToCompareCount}
      active={active}
    />
  );

  return (
    <Box className={classes.container}>
      {label}
      {indicatorGraphic}
    </Box>
  );
};

AddToCompareCountIndicator.defaultProps = {
  addToCompareCount: null
};

AddToCompareCountIndicator.propTypes = {
  label: string.isRequired,
  active: bool.isRequired,
  addToCompareCount: oneOfType([oneOf([null]), number])
};

const mapStateToProps = (state, ownProps) => ({
  addToCompareCount: addToCompareCountSelector(state, ownProps)
});

export default connectToState(mapStateToProps)(AddToCompareCountIndicator);
