import { Box, Button, Container, Paper, Typography } from "@mui/material";
import Alert from "@mui/material/Alert";
import LoadingIndicator from "client/components/LoadingIndicator";
import { Field, Form, Formik } from "formik";
import React, { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import AppPasswordField from "../forms/AppPasswordField";
import { useChangePassword } from "../userHooks";
import AppPage from ".";

const initialValues = {
  password: ""
};

const validate = (values) => {
  const errors = {};
  if (!values.password) {
    errors.password = "can't be blank";
  } else if (values.password.length < 6) {
    errors.password = "must be at least 6 characters long";
  }
  return errors;
};

const ChangePassword = () => {
  const [updatePassword, loading] = useChangePassword();
  const { email } = useSelector((state) => state.userProfile.data);
  const [success, setSuccess] = useState(false);

  const handleSubmit = useCallback(
    (values, { setErrors, setSubmitting }) => {
      updatePassword(values).then((data) => {
        setSubmitting(false);
        if (data && data.errors) {
          setErrors(data.errors);
        } else {
          setSuccess(true);
        }
      });
    },
    [updatePassword]
  );

  const history = useHistory();
  const goHome = useCallback(() => history.push("/"), [history]);

  if (success) {
    return (
      <AppPage docTitle="Password Successfully Updated">
        <Container maxWidth="xs">
          <Box my={5}>
            <Alert
              variant="filled"
              severity="success"
              action={
                <Button variant="outlined" color="inherit" size="small" onClick={goHome}>
                  Continue
                </Button>
              }
            >
              Password Changed
            </Alert>
          </Box>
        </Container>
      </AppPage>
    );
  }

  return (
    <AppPage docTitle="Change Password">
      <Container maxWidth="xs">
        <Box my={5}>
          {!email && <LoadingIndicator />}
          <Paper variant="outlined">
            <Box p={4}>
              <Formik
                validateOnBlur={false}
                validateOnChange={false}
                initialValues={initialValues}
                validate={validate}
                onSubmit={handleSubmit}
              >
                <Form>
                  <Box mb={2}>
                    <Typography variant="h5" component="h1">Change Password</Typography>
                  </Box>
                  <Box mb={2}>
                    <Typography variant="caption">Email</Typography>
                    <Typography variant="body1">{email}</Typography>
                  </Box>
                  <Box mb={2}>
                    <Field name="password" label="New Password" as={AppPasswordField} newPassword />
                  </Box>

                  <Box textAlign="right">
                    <Button onClick={goHome}>Cancel</Button>
                    <Button type="submit" variant="contained" color="secondary" disabled={loading}>
                      Set Password
                    </Button>
                  </Box>
                </Form>
              </Formik>
            </Box>
          </Paper>
        </Box>
      </Container>
    </AppPage>
  );
};

export default ChangePassword;
