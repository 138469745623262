import React from 'react';
import {
  Typography,
  Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(({palette, spacing, breakpoints}) => {
  return {
    base: {
      color: palette.text.dark,
      fontSize: '1.2rem',
      fontWeight: 'normal',
      marginBottom: 15,
    },
  }
})

const LandingTitle = ({ children, variant, align, style }) => {
  const classes = useStyles();
  return (
    <Typography
      component={ variant }
      className={ `${classes.base}` }
      align={ align }
      style={ style }
    >
      {children}
    </Typography>
  )
}

LandingTitle.defaultProps = {
  align: 'left',
  variant: 'p',
  style: {},
}

export default LandingTitle;
