import { Box, Button, Container, Paper, Typography } from "@mui/material";
import Alert from "@mui/material/Alert";
import LoadingIndicator from "client/components/LoadingIndicator";
import { Field, Form, Formik } from "formik";
import React, { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import AppPage from ".";
import AppTextField from "../forms/AppTextField";
import { useChangeUser } from "../userHooks";

const initialValues = {
  email: "",
  first_name: "",
  last_name: ""
};

const ChangeAccountInfo = () => {
  const [updateUser, loading] = useChangeUser();
  const profile = useSelector((state) => state.userProfile.data);
  const initialValues = useMemo(() => {
    const { email, first_name, last_name } = profile;
    return { email, first_name, last_name };
  }, [profile]);

  const [success, setSuccess] = useState(false);

  const handleSubmit = useCallback(
    (values, { setErrors, setSubmitting }) => {
      updateUser(values).then((data) => {
        setSubmitting(false);
        if (data && data.errors) {
          setErrors(data.errors);
        } else {
          setSuccess(true);
        }
      });
    },
    [updateUser]
  );

  const history = useHistory();
  const goHome = useCallback(() => history.push("/"), [history]);

  if (success) {
    return (
      <AppPage docTitle="Account Info Successfully Updated">
        <Container maxWidth="xs">
          <Box my={5}>
            <Alert
              variant="filled"
              severity="success"
              action={
                <Button variant="outlined" color="inherit" size="small" onClick={goHome}>
                  Continue
                </Button>
              }
            >
              Changes Changed
            </Alert>
          </Box>
        </Container>
      </AppPage>
    );
  }

  return (
    <AppPage docTitle="Account Info">
      <Container maxWidth="xs">
        <Box my={5}>
          {!profile && <LoadingIndicator />}
          <Paper variant="outlined">
            <Box p={4}>
              <Formik
                validateOnBlur={false}
                validateOnChange={false}
                enableReinitialize
                initialValues={initialValues}
                onSubmit={handleSubmit}
              >
                <Form>
                  <Box mb={2}>
                    <Typography variant="h5" component="h1">Update Your Account Info</Typography>
                  </Box>
                  <Box mb={2}>
                    <Field name="email" label="Email" as={AppTextField} type="email" />
                  </Box>
                  <Box mb={2}>
                    <Field name="first_name" label="First Name" as={AppTextField} />
                  </Box>
                  <Box mb={2}>
                    <Field name="last_name" label="Last Name" as={AppTextField} />
                  </Box>

                  <Box textAlign="right">
                    <Button onClick={goHome}>Cancel</Button>
                    <Button type="submit" variant="contained" color="secondary" disabled={loading}>
                      Save
                    </Button>
                  </Box>
                </Form>
              </Formik>
            </Box>
          </Paper>
        </Box>
      </Container>
    </AppPage>
  );
};

export default ChangeAccountInfo;
