import { makeStyles } from '@mui/styles';

export const useBreadcrumbStyles = makeStyles(() => ({
  root: {
    marginBottom: 10,
  },
  breadcrumb: {
    color: 'white',
    fontSize: '14px',
  },
  separator: {
    color: 'white',
  },
  typography: {
    fontWeight: 'inherit',
  },
}));

export const sectionStyles = {
  marginBottom: 100,
  minHeight: '400px',
}

export const headerLinkStyles = makeStyles(({palette, spacing, breakpoints}) => ({
  root: {
    background: 'none',
    border: 'none',
    display: 'flex',
    alignItems: 'center',
    paddingRight: spacing(2),
    paddingLeft: spacing(2),
    height: '2.5em',
    color: props => props.anchorEl
      ? palette.secondary.main
      : palette.grey[600],
    textDecoration: 'none',
    cursor: 'pointer',
    fontSize: '.875rem',
    textTransform: 'none',
    whiteSpace: 'nowrap',
    '&:hover,&:focus': {
      textDecoration: 'none',
      color: palette.primary.dark,
    },
    '&:active': {
      color: palette.secondary.main,
    },
    '& svg:nth-of-type(1)': {
      marginRight: 8,
    },
    [breakpoints.down('sm')]: {
      paddingRight: spacing(1),
      paddingLeft: spacing(1),
      '& span': {
        display: 'none',
      }
    }
  },
}));

export const headerMenuStyles = makeStyles( ({ palette }) => ({
  list: {
    '& li, & li div': {
      color: palette.secondary.main,
      minWidth: '35px',
      transition: 'none',
    },
    '& li:hover': {
      backgroundColor: palette.secondary.main,
      color: 'white',
      '& div, & svg': {
        color: 'white',
      },
    },
  },
}))
