import React from "react";
import readCsrfToken from "client/utils/readCsrfToken";
import Button from '@mui/material/Button';
import AccountCircle from '@mui/icons-material/AccountCircle';
import analytics from '../google-analytics';
import { formId } from './landing/config'
import googleAnalytics from "../google-analytics";
import useAuthentication from "../hooks/useAuthentication";

const csrf = readCsrfToken();
const style = { display: "inline-block" };

const LoginButton = () => {
  const { applicationLoginUrl, applicationLoginMethod } = useAuthentication()
  return (
    <form id={formId} method={applicationLoginMethod} action={applicationLoginUrl} style={style}>
      <input name="authenticity_token" type="hidden" value={csrf} />
      <Button
        type="submit"
        variant="contained"
        color="primary"
        startIcon={<AccountCircle />}
        onClick={() => googleAnalytics.trackLoginClick("Header")}
      >
        Login
      </Button>
    </form>
  );
};

export default LoginButton;
