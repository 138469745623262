import React, { useCallback, useMemo } from 'react';
import {
  object, shape, bool
} from 'prop-types';
import { withRouter } from 'react-router-dom';
import Button from '@mui/material/Button';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import Tooltip from '@mui/material/Tooltip';

import { tabKeyType } from '../../constants/proptypes';
import { connectToState } from '../../redux/connect-api';
import {
  activeTabKeySelector, fieldsForExportSelector,
  analysisCollectionSelector, userInputSelector, csvArraySelector
} from '../../selectors';
import analytics from '../../google-analytics';

import { saveAs } from 'file-saver';
import * as XLSX from "xlsx";
import { tabConfig } from '../../config';
import { userIsTrialSubscriberSelector } from '../../selectors/userProfile';

// https://medium.com/an-idea/export-excel-files-client-side-5b3cc5153cf7
export const exportToSpreadsheet = (sheets, fileName) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

  const fileExtension = ".xlsx";
  //Create a new Work Sheet using the data stored in an Array of Arrays.
  const worksheets = {}
  Object.keys(sheets).forEach((name) => {
    worksheets[name] = XLSX.utils.aoa_to_sheet(sheets[name]);
  })
  // Generate a Work Book containing the above sheet.
  const workBook = {
    Sheets: { ...worksheets, cols: [] },
    SheetNames: Object.keys(sheets),
  };
  // Exporting the file with the desired name and extension.
  const excelBuffer = XLSX.write(workBook, { bookType: "xlsx", type: "array" });
  const fileData = new Blob([excelBuffer], { type: fileType });
  saveAs(fileData, fileName + fileExtension);
};

const CsvDownload = ({ disabled, subscriptionIsTrial=false, rowIds, analysisCollection, userInputs, fieldsForExport}) => {
  const handleClick = useCallback(() => {
    if (subscriptionIsTrial) return
    const sheets = {}
    Object.keys(rowIds).filter((k) => !k.match(/report/i)).forEach(key => {
      sheets[key] = [
        fieldsForExport[key],
        ...csvArraySelector(rowIds[key], fieldsForExport[key], analysisCollection, userInputs)
       ]
    })
    exportToSpreadsheet(sheets, 'icer-price-data')
    analytics.trackCSV();
  }, [rowIds, fieldsForExport, analysisCollection, userInputs, subscriptionIsTrial])

  const tabs = useMemo(() => {
    const tabs = Object.values(tabConfig)
    tabs.pop()
    return tabs
  }, [])

  const tooltipTitle = (
    <div>
      {!subscriptionIsTrial && (
        `Download an XLSX representation of all 3 tables
        (${tabs.join(', ')}), with the currently-applied filters.`
      )}
      {subscriptionIsTrial && (
        "A subscription is needed to download data."
      )}
    </div>
  );

  return (
    <Tooltip
      title={tooltipTitle}
      aria-label="download XLSX"
      placement="right"
    >
      <span>
        <Button
          onClick={handleClick}
          disabled={disabled || subscriptionIsTrial}
          aria-label="Download XLSX of all tables"
            // aria-controls="download-csv-dialog" // < if this were in the DOM
          startIcon={<CloudDownloadIcon />}
          aria-haspopup="false"
        >
          Download Excel Workbook
        </Button>
      </span>
    </Tooltip>
  );
}

CsvDownload.defaultProps = { };

CsvDownload.propTypes = {
  // match: matchType.isRequired,
  activeTabKey: tabKeyType.isRequired, // eslint-disable-line react/no-unused-prop-types
  disabled: bool.isRequired,
  rowIds: shape({}).isRequired,
  fieldsForExport: object.isRequired,
  analysisCollection: shape({}).isRequired,
  userInputs: shape({}).isRequired
};

const mapStateToProps = (state, ownProps) => ({
  activeTabKey: activeTabKeySelector(state, ownProps),
  fieldsForExport: fieldsForExportSelector(state, ownProps),
  userInputs: userInputSelector(state, ownProps),
  analysisCollection: analysisCollectionSelector(state, ownProps),
  subscriptionIsTrial: userIsTrialSubscriberSelector(state)
});

export default withRouter((connectToState(mapStateToProps)(CsvDownload)));
