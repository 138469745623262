import { createTheme } from "@mui/material/styles";

import { orange, red, grey } from "@mui/material/colors";

/**
 *  For customization of theme/s, see:
 *
 *    https://material-ui.com/customization/theming/#api
 *
 */

const defaultTheme = createTheme();

const primary = "#009D77";
const secondary = "#0071ce";

export default createTheme({
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true
      }
    },
    MuiTypography: {
      // https://material-ui.com/components/typography/#changing-the-semantic-element
      variantMapping: {
        // h1: 'h2',
        // h2: 'h2',
        // h3: 'h2',
        // h4: 'h2',
        // h5: 'h2',
        // h6: 'h2',
        // subtitle1: 'h2',
        // subtitle2: 'h2',
        // body1: 'span',
        // body2: 'span'
      },
      styleOverrides: {
        h5: {
          // borderBottom: '1px #009D77 solid'
        }
      }
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: secondary
        },
        underlineHover: {
          textDecoration: "underline",
          "&:hover": {
            textDecoration: "none"
          }
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          whiteSpace: "nowrap",
          padding: "4px 32px 4px 32px"
        },
        // variant: text (default), outlined, contained
        text: {
          padding: "4px 32px 4px 32px", // does not inherit from root?
          color: secondary,
          "&:hover,&:focus": {
            backgroundColor: "#0071ce33"
          }
        },
        outlined: {
          // backgroundColor: 'blue',
          "&:hover": {
            borderColor: secondary,
          },
          "&:focus": {
            boxShadow: `0 0 0 0.2rem ${secondary}`,
            backgroundColor: "#f2f8fc"
          }
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: "rgba(0,0,0,.8)"
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          backgroundColor: "#FFFFFF"
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: grey[700]
        }
      }
    },
    MuiTextField: {
      defaultProps: {
        variant: "standard"
      }
    },
    MuiSelect: {
      defaultProps: {
        variant: "standard"
      }
    },
    MuiFormControl: {
      defaultProps: {
        variant: "standard"
      }
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          "&:focus-within": {
            boxShadow: `0px 0px 0px 2px ${secondary} inset`
          }
        }
      }
    }
  },
  palette: {
    // background: {
    //  paper: '#0ff',
    //  default: '#ff0'
    text: {
      primary: "#444444", // not supported:  'var(--primary-text-color)',
      secondary: "#9b9b9b", // not supported: 'var(--secondary-text-color)'
      secondaryLinkLight: "#48acff",
      light: "#FFFFFF",
      gray: "#737373"
    },
    // TODO: expand primary/secondary to a full color palette:
    primary: {
      light: "rgba(0,113,206,0.15)",
      main: primary
    },
    // secondary: pink,
    secondary: {
      dark: "#064782",
      main: "#0071CE",
      light: "#8ab1d1",
      A400: "#0071CE",
      veryLight: "#f2f8fc"
    },
    tooltip: {
      icon: "#94c2e8"
    },
    error: {
      main: red[400]
    },
    warning: {
      main: orange[200]
    },
    borders: {
      light: "#D9D9D9"
    },
    // Used by `getContrastText()` to maximize the contrast between the background and
    // the text.
    contrastThreshold: 3
    // Used to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    // tonalOffset: 0.2,
  },
  typography: {
    // useNextVariants: true, // Not necessary, b/c we are on > 4.0 ?
    fontFamily: [
      "Lato",
      "Roboto", // default for Android, and also material-ui's implementation of Material Design
      "Helvetica Neue",
      "Helvetica",
      "Arial",
      "sans-serif"
    ].join(","),
    // https://fonts.googleapis.com/css?family=Lato:100,300,400,700,900" />
    fontWeightMedium: 400,
    body1: {
      fontSize: 16,
      lineHeight: "24px"
    },
    body2: {
      fontSize: 14,
      lineHeight: "18px"
    },
    h1: {
      fontSize: "3.5rem",
      fontWeight: 300,
      marginBottom: defaultTheme.spacing(1.5),
      [defaultTheme.breakpoints.up("sm")]: {
        fontSize: "5rem"
      },
      [defaultTheme.breakpoints.up("md")]: {
        fontSize: "6rem"
      }
    },
    h2: {
      fontWeight: "bold",
      fontSize: 30,
      letterSpacing: "0px",
      marginBottom: defaultTheme.spacing(4),
      [defaultTheme.breakpoints.up("md")]: {
        fontSize: 36
      }
    },
    h3: {
      fontSize: 24,
      lineHeight: "26px",
      fontWeight: 600
    },
    h4: {
      fontSize: 20,
      fontWeight: 600
    },
    h5: {
      fontSize: 16,
      fontWeight: 500
    },
    overline: {
      fontSize: 16
    },
    caption: {
      fontSize: 14
    }
  }
});
