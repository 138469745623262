import React, { useCallback, useState, createRef } from 'react';
import {
  arrayOf, object, oneOf, shape, string, number
} from 'prop-types';
import { ResponsiveBullet } from '@nivo/bullet';

import { Alert, AlertTitle, Box, Button, Typography, CircularProgress } from '@mui/material';

import ComparisonChartLegend from './ComparisonChartLegend';

import { connectToState } from '../../redux/connect-api';
import { chartDataSelector } from '../../selectors';
import { baseFieldConfig, icerBenchmarkPriceKeys } from '../../config';
import DownloadIcon from '@mui/icons-material/GetApp';
import { exportElementAsPng } from '../../utils/export';
import { saveAs } from 'file-saver'
import analytics from '../../google-analytics';

// const benchmarkValues = {
//  health_system_vbpb_50k_per_qaly: 50000,
//  health_system_vbpb_100k_per_qaly: 100000,
//  health_system_vbpb_150k_per_qaly: 150000,
//  health_system_vbpb_200k_per_qaly: 200000,
//  health_system_vbpb_50k_per_evlyg: 50000,
//  health_system_vbpb_100k_per_evlyg: 100000,
//  health_system_vbpb_150k_per_evlyg: 150000,
//  health_system_vbpb_200k_per_evlyg: 200000,
//  societal_vbpb_50k_per_qaly: 50000,
//  societal_vbpb_100k_per_qaly: 100000,
//  societal_vbpb_150k_per_qaly: 150000,
//  societal_vbpb_200k_per_qaly: 200000,
//  societal_vbpb_50k_per_evlyg: 50000,
//  societal_vbpb_100k_per_evlyg: 100000,
//  societal_vbpb_150k_per_evlyg: 150000,
//  societal_vbpb_200k_per_evlyg: 200000
// };

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.

const DownloadButton = ({handleDownload, text="Download chart"}) => {
  const [ downloading, setDownloadState ] = useState(false)
  const handleClick = () => {
    setDownloadState(true)
    handleDownload().finally(() => setDownloadState(false))
  }
  return (
    <Button
      onClick={() => handleClick()}
      style={{marginLeft: 'auto'}}
      startIcon={downloading ? <CircularProgress size={20} /> : <DownloadIcon />}
    >
      {text}
    </Button>
  )
}

const ComparisonChartContent = ({ comparisonKeys, data, height, selectedChartOptions }) => {
  let maxValue = 0;
  const chartValues = []
  data.forEach((d) => {
    if (d.user_proposed_price >= maxValue) {
      maxValue = d.user_proposed_price;
    }
    comparisonKeys.forEach((k) => {
      if (d[k] >= maxValue) {
        maxValue = d[k] || 0;
      }
    });
  });

  const bulletData = data
    .map((d) => {
      const {
        id,
        trade_name,
        differentiator,
        user_proposed_price
      } = d;

      const measures = [];
      const title = (
        <text dy={-10}>
          <tspan style={{
            fill: '#000',
            fontWeight: 500,
            fontSize: '14px'
          }}
          >
            {trade_name}
          </tspan>
          <tspan
            x={0}
            dy={18}
            style={{
              fill: '#999',
              fontSize: '10px'
            }}
          >
            {differentiator}
          </tspan>
        </text>
      );
      if (user_proposed_price) {
        measures.push(user_proposed_price);
      }
      const values = comparisonKeys.map((k) => d[k]).filter((v) => !!v)
      chartValues.push(values)
      return ({
        id,
        title,
        ranges: [ ...values , 0, maxValue ], // number[] -- stacked, background bars
        measures, // number[] -- center bars
        markers: values, // number[] -- vertical rects overlayed on bars
      });
    });

  const labelDictionary = {
    qaly: 'per QALY',
    evlyg: 'per evLYG',
    health_system: 'Health System',
    societal: 'Societal',
  }

  // Handle case where no data is available to render
  // (all values are null)
  if (chartValues.flat().length === 0) {
    const perspectiveName = (key) => (
      `${key.match(/health_system/) ? labelDictionary.health_system : labelDictionary.societal} Perspective`
    )
    return (
      <Alert severity='error' sx={{mt: 2}}>
        <AlertTitle sx={{fontWeight: "bold"}}>Insufficient data available to render this chart </AlertTitle>
        <Typography sx={{m: 0}}>None of the selected analyses have data for:</Typography>
        <Box component="ul" sx={{m: 0}}>
          {comparisonKeys.map((k) => (<li key={k}>{perspectiveName(k)} {baseFieldConfig[k].label}</li>))}
        </Box>
      </Alert>
    )
  }


  const title = `Price Comparison`
  const subTitle = `(Perspective: ${labelDictionary[selectedChartOptions.perspective]}, Measure: ${labelDictionary[selectedChartOptions.measure]})`
  const labelStyle = {
    position: 'absolute',
    left: 3,
    top: '50%',
    fontSize: 14,
    transform: 'rotate(-90deg)',
  }

  const chartRef = createRef()

  const downloadChart = useCallback(() => {
    return new Promise((resolve, reject) => {
      const chartElement = chartRef.current
      if (!chartElement) {
        console.error('Chart element can not be found')
        reject()
      }
      exportElementAsPng(chartElement, {style: {paddingTop: '10px'}})
        .then((blob) => {
          saveAs(blob, 'comparison-chart.png')
          analytics.trackChart('download', `width: ${chartElement.clientWidth}px, height: ${chartElement.clientHeight}px`)
          resolve()
        })
        .catch((error) => {
          console.error(`Chart image export error: ${error}`)
          reject()
        })
    })
  })

  return (
    <Box>
      <Box ref={chartRef} id="comparison-chart" sx={{pb: "30px"}} >
        <Box textAlign='center' mb={2}>
          <Typography variant='h4'>{title}</Typography>
          <Typography>{subTitle}</Typography>
        </Box>
        <Box height={height} width='100%' position='relative' >
          <Typography style={labelStyle}>Price ($)</Typography>
          <ResponsiveBullet
            // -- Miscellaneous:
            // passed to custom components/layers, in *bar* chart, e.g.: BarMarkersLayer:
            // comparisonKeys={comparisonKeys}
          /**
            *  See: https://nivo.rocks/bullet/
            */

            // -- Base:
            data={bulletData}
            /** Chart data, which must conform to this structure:
                Array<{
                    id:        {string|number}
                    title?:    {ReactNode}
                    subtitle?: {ReactNode}
                    data: Array<{
                        ranges:   number[]
                        measures: number[]
                        markers?: number[]
                    }>
                }>
                If title is undefined, id will be used for title.
            */

            // width={height} // number required
            height={height} // number required
            layout="vertical" // string optional default:'horizontal'
            reverse={false} // boolean optional default:false
            margin={{
              top: 50, right: 90, bottom: 25, left: 90
            }} // object optional default: { top: 50, right: 90, bottom: 50, left: 90 }
            spacing={50} // number optional default:30
            measureSize={0.4} // number optional default:0.4
            markerSize={1.02} // number optional default:0.6

            // -- Style:
            // rangeComponent={ComponentClass} Function optional
            rangeColors={['#F4F4F4', '#CBEBE4', '#F4F4F4']} // string | Function | string[] optional default:'seq:cool'
            // measureComponent={ComponentClass} Function optional
            measureColors="#484349" // string | Function | string[] optional default:'seq:red_purple'
            // markerComponent={ComponentClass} Function optional
            markerColors={['#46BBA0', '#489BE0']} // string | Function | string[] optional default:'seq:red_purple'

            // -- Axes:
            axisPosition="before" // string optional default:'after'

            // -- Title:
            titlePosition="before" // string optional default:'before'
            titleAlign="start" // string optional default:'middle'
            titleOffsetX={-25} // number optional
            titleOffsetY={-15} // number optional
            // titleRotation={0} // number optional default:0
            // -- Interactivity:
            // onRangeClick={ƒ} // (range, event) => void optional -- onClick handler for ranges.
            // onMeasureClick={ƒ} // (measure, event) => void optional -- onClick handler for measures.
            // onMarkerClick={ƒ} // (marker, event) => void optional -- onClick handler for markers.
            /* -- onMarkerClick:
              onClick handler for markers, will receive marker data as first argument
              & event as second one.
                The data has the following shape:
                {
                    id:    string,
                    value: number,
                    index: number,
                    color: string,
                }
            */

            // -- Motion
            animate // boolean optional default:true
            motionStiffness={90} // number optional default:90
            motionDamping={15} // number optional default:15
          />
        </Box>
        <Box display='flex' justifyContent='center'>
          <ComparisonChartLegend />
        </Box>
      </Box>
      <DownloadButton handleDownload={() => downloadChart()}/>
    </Box>
  );
};

ComparisonChartContent.defaultProp = {
};

ComparisonChartContent.propTypes = {
  height: number.isRequired,
  comparisonKeys: arrayOf(oneOf(icerBenchmarkPriceKeys)).isRequired,
  data: arrayOf(shape({
    id: string.isRequired,
    user_proposed_price: number,
    trade_name: string.isRequired,
    specific_condition: string.isRequired,
    differentiator: string.isRequired
  })).isRequired,
  selectedChartOptions: object,
};

const mapStateToProps = (state, ownProps) => ({
  data: chartDataSelector(state, ownProps)
});

export default (connectToState(mapStateToProps)(ComparisonChartContent));
