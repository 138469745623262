import React from "react";
import { makeStyles } from "@mui/styles";
import { array, bool, number } from "prop-types";
import { Box, Container, Grid, Link } from "@mui/material";

import WrappedProgressIndicator from "../WrappedProgressIndicator";
import RecentUpdatesListItem from "./RecentUpdatesListItem";

import { connectToState } from "../../redux/connect-api";

const containerStyles = makeStyles(({ palette, spacing }) => ({
  updatesContainer: {
    paddingBottom: 15,
    position: "relative",
    "&::after": {
      content: "''",
      display: "block",
      position: "absolute",
      bottom: 0,
      left: 0,
      right: 0,
      height: 25,
      background: "white",
      background: "linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%)",
      zIndex: 10
    }
  },
  updatesLink: {
    "& a": {
      textDecoration: "none",
      fontSize: "1.5em"
    }
  }
}));

const RecentUpdates = ({ recentUpdates = [], loading = false, limit = 1 }) => {
  const classes = containerStyles();

  if (loading) {
    return <WrappedProgressIndicator loading={loading} />;
  }

  if (!recentUpdates.length) return null;

  const updates = recentUpdates.filter((item, index) => {
    return limit > 0 ? index < limit : true;
  });

  return (
    <Box className={classes.updatesContainer}>
      {updates.map((item, i) => (
        <RecentUpdatesListItem item={item.attributes} key={i} />
      ))}
    </Box>
  );
};

RecentUpdates.defaultProps = {
  recentUpdates: [],
  loading: false,
  limit: 1
};

RecentUpdates.propTypes = {
  recentUpdates: array,
  loading: bool,
  limit: number
};

const mapStateToProps = (state) => {
  return {
    recentUpdates: state.recentUpdates.data,
    loading: state.recentUpdates.loading
  };
};

export default connectToState(mapStateToProps)(RecentUpdates);
