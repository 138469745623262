import React from 'react'
import { makeStyles } from '@mui/styles';
import { array, func } from 'prop-types'
import { Grid } from '@mui/material'
import DocumentCard from './DocumentCard'

const useStyles = makeStyles(({ spacing }) => {
  return {
    wrapper: {
      paddingTop: spacing(0),
    },
  }
})

const DocumentGrid = props => {
  const { documents, Header } = props
  const classes = useStyles()

  // TODO: Can we remove the Header prop from this component?
  return (
    <div className={ classes.wrapper }>
      { Header && <Header /> }
      <Grid container spacing={3}>
        {
          documents.map((doc, i) => (
            <Grid key={i} item xs={12} sm={6} lg={4} style={{display: 'flex', alignItems: 'stretch'}}>
              <DocumentCard
                doc={ doc }
                docTitle={ doc.title }
                docDescription={ doc.description }
                docUrl={ doc.attachments[0].url }
                platformTags={ doc.platform_tags }
              />
            </Grid>
          ))
        }
      </Grid>
    </div>
  )
}

DocumentGrid.defaultProps = {
  documents: [],
}

DocumentGrid.propTypes = {
  documents: array,
  Header: func,
}

export default DocumentGrid
