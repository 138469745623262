import React from 'react';
import { makeStyles } from '@mui/styles';
import {
  Box,
  Container,
  Grid,
  Card,
  CardContent,
  Typography,
  Link,
  CardActionArea} from '@mui/material';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import ModalWithLauncher from '../modals/ModalWithLauncher';
import VideoPlayer from '../details/VideoPlayer';
import DocThumb from '../../../images/doc_thumb.png';
import VideoPlaceholder from '../../../images/video_placeholder.png';
import googleAnalytics from '../../google-analytics';

const useStyles = makeStyles(({palette, breakpoints}) => {
  return {
    imgPlaceholder: {
      background: '#f9f9f9',
      flex: '1 1 100%',
      minHeight: '400px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      '&:hover': {
        '& img': {
          opacity:  '.6',
        },
        '& svg': {
          opacity:  '.8',
        }
      },
      '& img': {
        maxWidth: '100%',
        maxHeight: '100%',
        objectFit: 'cover',
        opacity: '.5',
      }
    },
    cardWrapper: {
      maxWidth: '100vw',
      position: 'relative',
      [breakpoints.up('md')]: {
        '&::after': {
          top: "50%",
          left: "50%",
          width: "200vw",
          content: '""',
          display: "block",
          zIndex: 0,
          position: "absolute",
          transform: "translate(-50%, -50%)",
          background: palette.secondary.main,
          minHeight: "200px",
          borderTopLeftRadius: "102% 95%",
          borderTopRightRadius: "100% 95%",
          borderBottomLeftRadius: "120% 95%",
          borderBottomRightRadius: "90% 95%"
        }
      }
    }
  }
});

const iconStyles = makeStyles(({palette, spacing}) => {
  return {
    root: {
      fontSize: '200px',
      color: palette.secondary.main,
      marginBottom: spacing(3),
      position: 'absolute',
      top:  '50%',
      left:  '50%',
      transform: 'translate(-50%, -50%)',
      zIndex: '10',
      opacity: '.6',
    }
  }
});

const landingCardStyles = makeStyles(({palette, spacing, breakpoints}) => {
  return {
    root: {
      background: '#FFFFFF 0% 0% no-repeat padding-box',
      boxShadow: '0px 3px 20px #00000029',
      border: '10px solid #FFFFFF80',
      borderRadius: "20px",
      display: 'flex',
      position: 'relative',
      zIndex: 10,
      [breakpoints.up('sm')]: {
        minHeight: '500px',
      },
    },
    content: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flex: '1 1 100%',
    },
    imgPlaceholder: {
      background: '#EAEAEA',
      minHeight: '250px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: '#999',
    },
    title: {
      color: palette.primary.main,
      textTransform: 'uppercase',
      font: 'normal normal bold 35px/70px Lato',
      lineHeight: 1,
      marginBottom: spacing(1),
    },
    description: {
      marginBottom: spacing(3),
      '& strong': {
        color: palette.secondary.main,
        fontWeight: 600,
      }
    },
    externalDescription: {
      fontWeight: 'bold',
      marginBottom: spacing(4),
      fontSize: 18,
      lineHeight: '22px',
      [breakpoints.up('sm')]: {
        maxWidth: '65%',
        marginLeft: 'auto',
        marginRight: 'auto',
        fontSize: 20,
        lineHeight: '26px',
      },
    },
    firstCardRoot: {
      marginBottom: spacing(4),
      [breakpoints.up('md')]: {
        marginBottom: 0,
      },
    },
  }
});

const modalStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: '50vw',
    backgroundColor: 'white',
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const LandingLearnMore = () => {
  const classes = useStyles();
  const cardClasses = landingCardStyles();
  const iconClasses = iconStyles();
  const VideoCardContent = () => {
    return (
      <Card classes={{root: cardClasses.root}} onClick={() => googleAnalytics.trackVideoThumbnailClick("Landing Page Demo")}>
        <CardContent classes={{root: cardClasses.content}}>
          <Box className={classes.imgPlaceholder}>
            <PlayCircleFilledIcon classes={iconClasses} />
            <img src={VideoPlaceholder} className="media" data-object-fit='cover' />
          </Box>
        </CardContent>
      </Card>
    )
  }
  const ModalVideoPlayer = () => (
    <VideoPlayer video={{ id: 'OSaWHiYE2L8', title: '' }} width={640} height={360} />
  )

  const VideoCard = () => (
    <Box className={cardClasses.firstCardRoot}>
      <Box mb={3}>
        <Typography align='center' className={cardClasses.externalDescription}>
          Watch a demo, and hear from payers and life science companies about how they're using ICER Analytics
        </Typography>
      </Box>
      <ModalWithLauncher
        i18nKey='previewVideo'
        Content={ModalVideoPlayer}
        Launcher={VideoCardContent}
      />
    </Box>
  )

  const trackDocClick = () => {
    googleAnalytics.trackDownload({fileName: '/docs/ICER.Analytics.Overview.pdf', location: "Landing page - Learn more"})
    googleAnalytics.trackDocument('ICER.Analytics.Overview')
  }

  const DocCard = () => (
    <Box className={cardClasses.lastCardRoot}>
      <Box mb={3}>
        <Typography align='center' className={cardClasses.externalDescription}>
          Download the ICER Analytics Overview
        </Typography>
      </Box>
      <Link href={'/docs/ICER.Analytics.Overview.pdf'} target="_blank" onClick={() => trackDocClick()}>
        <Card classes={{root: cardClasses.root}}>
          <CardActionArea >
            <CardContent classes={{root: cardClasses.content}}>
              <Box className={classes.imgPlaceholder}>
                <CloudDownloadIcon classes={iconClasses} />
                <img src={DocThumb} data-object-fit='cover' />
              </Box>
            </CardContent>
          </CardActionArea>
        </Card>
      </Link>
    </Box>
  )

  return (
    <Box id='learn-more' component='section'>
      <Container>
        <Box className={classes.cardWrapper}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={8}>
              <VideoCard />
            </Grid>
            <Grid item xs={12} md={4}>
              <DocCard />
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default LandingLearnMore;
