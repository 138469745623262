import { makeStyles } from "@mui/styles";

export const modalStyles = makeStyles(() => ({
  dialogRoot: { },
  actionRoot: {
    justifyContent: 'space-between',
    padding: '8px 16px 8px 16px',
    '& .MuiDialogTitle-root': {
      padding: 0
    }
  },
  contentRoot: {
    margin: '0 0 8px 0', // only on bottom
    padding: '0 16px 8px 16px'
  },
  contentNoPaddingXRoot: {
    margin: '0 0 8px 0', // only on bottom
    padding: '0 0 8px 0'
  }
}));
