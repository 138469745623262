import React from "react";
import { string } from "prop-types";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";

import OutboundLink from "./OutboundLink";

const useStyles = makeStyles(() => ({
  root: {
    "& dl": {
      marginTop: "8px",
      "& dt": {
        margin: 0,
        fontWeight: "bold"
      },
      "& dd": {
        marginTop: "4px"
      }
    }
  }
}));

const DefinitionList = ({ term, definition }) => (
  <dl key={term}>
    <dt>{term}</dt>
    <ReactMarkdown
      skipHtml={true}
      components={{
        p: "dd",
        a: OutboundLink
      }}>
      {definition}
    </ReactMarkdown>
  </dl>
);

DefinitionList.propTypes = {
  term: string.isRequired,
  definition: string.isRequired
};

const GlossaryContent = React.memo(({ i18nKey }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const terms = t(i18nKey);
  return <dl className={classes.root}>{terms.map(DefinitionList)}</dl>;
});

GlossaryContent.propTypes = {
  i18nKey: string.isRequired
};

export default GlossaryContent;
