import React from "react";
import { Link } from "react-router-dom";
import { Box, CardActionArea, CardContent, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import BaseCard from "./BaseCard";
import DrugList from "./DrugList";
import { urlEncode } from "../utils/url";
import UnpublishedChip from "./UnpublishedChip";
import { isDataSuppressibleForTrial } from "../config";

const useStyles = makeStyles(({ palette }) => ({
  cardWrapper: {
    display: "flex"
  },
  cardAction: {
    display: "flex",
    flexGrow: 1,
    alignItems: "stretch",
    textDecoration: "none !important",
    "&:hover *": {
      textDecoration: "none !important"
    }
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1
  },
  cardTitle: {
    marginBottom: 30
  },
  chipTitle: {
    marginBottom: 5
  },
  chip: {
    marginRight: 4,
    marginBottom: 4
  }
}));

const ReportSnapshotCard = ({ attributes, searchString = "", subscriptionIsTrial=false }) => {
  const { specific_conditions = [], drugs = [], id, date_of_review, status } = attributes;
  const isUnpublished = status !== "published";
  const classes = useStyles();
  let path = `/report-snapshots/${id}`;
  if (searchString) {
    path += `?search=${urlEncode(searchString)}`;
  }

  const dateOfReview = new Date(date_of_review)
  let year = "";
  if (date_of_review && specific_conditions) {
    year = ` (${dateOfReview.getFullYear()})`;
  }

  let isSuppressed = subscriptionIsTrial && isDataSuppressibleForTrial(dateOfReview)

  const content = (
    <CardContent classes={{ root: classes.cardContent }}>
      <Box display="flex" justifyContent="space-between">
        {specific_conditions.length && (
          <Typography variant="h4" component="h3" className={classes.cardTitle}>
            {specific_conditions[0]}
            {year}
          </Typography>
        )}
        {isUnpublished && <UnpublishedChip />}
        {isSuppressed && (<UnpublishedChip
          label="Access restricted"
          tooltipTitle="This report snapshot is not available during the free trial period." />
        )}
      </Box>
      {drugs.length > 0 && <DrugList drugs={drugs} darkChips={true} />}
    </CardContent>
  )

  return (
    <Grid item sm={12} md={6} lg={4} classes={{ root: classes.cardWrapper }} >
      <BaseCard classes={{ root: classes.card }} cardGrows={true} allowHover={!isSuppressed}>
        {
          !isSuppressed && (
            <CardActionArea component={Link} to={path} className={classes.cardAction} disabled={isSuppressed}>
              {content}
            </CardActionArea>
          )
        }
        {isSuppressed && content}
      </BaseCard>
    </Grid>
  );
};

export default ReportSnapshotCard;
